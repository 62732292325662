import React, { CSSProperties } from 'react';
import Visible from 'material-ui/svg-icons/action/visibility';
import Hidden from 'material-ui/svg-icons/action/visibility-off';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import ConfirmAction from 'commons/ConfirmAction';
import { RoutesGroupDTO } from 'api/planner/types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  routesGroup: RoutesGroupDTO;
  edit: (routesGroup: RoutesGroupDTO) => any;
  deleteRoutesGroup: (routesgroup: RoutesGroupDTO) => any;
  showRoutesGroup: (routesGroupId: string) => void;
  hideRoutesGroup: (routesGroupId: string) => void;
};

type State = {
  hidden: boolean;
  isOpen: boolean;
};

const STYLE_VISIBILITY: CSSProperties = {
  margin: '0px 8px 0px 0px',
  width: 16,
  height: 16,
  cursor: 'pointer',
  paddingBottom: 2,
};

const STYLE_ACTIONS: CSSProperties = {
  margin: '0px 0px 0px 15px',
  width: 20,
  height: 20,
  cursor: 'pointer',
  paddingBottom: 2,
};

const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  width: '53%',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflow: 'hidden',
  paddingBottom: 2,
};

class RoutesGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hidden: false, isOpen: false };
  }

  onConfirmDelete = (): void => {
    const { routesGroup, deleteRoutesGroup } = this.props;
    deleteRoutesGroup(routesGroup);
    this.setState({ isOpen: false });
  };

  onConfirmDeleteCancel = (): void => {
    this.setState({ isOpen: false });
  };

  deleteRoutesGroup = (): void => {
    this.setState({ isOpen: true });
  };

  editRoutesGroup = (): void => {
    const { routesGroup, edit } = this.props;
    edit(routesGroup);
  };

  showRoutesGroupStub = (routesGroupId: string): void => {
    const { showRoutesGroup } = this.props;
    this.setState({ hidden: false });
    showRoutesGroup(routesGroupId);
  };

  hideRoutesGroupStub = (routesGroupId: string): void => {
    const { hideRoutesGroup } = this.props;
    this.setState({ hidden: true });
    hideRoutesGroup(routesGroupId);
  };

  render(): React.ReactNode {
    const { routesGroup } = this.props;
    const { hidden, isOpen } = this.state;
    const icon = hidden ? (
      <span title={_tg('action.display')}>
        <Hidden
          style={{ color: routesGroup.color, ...STYLE_VISIBILITY }}
          onClick={() => {
            this.showRoutesGroupStub(routesGroup.id);
          }}
        />
      </span>
    ) : (
      <span title={_tg('action.hide')}>
        <Visible
          style={{ color: routesGroup.color, ...STYLE_VISIBILITY }}
          onClick={() => {
            this.hideRoutesGroupStub(routesGroup.id);
          }}
        />
      </span>
    );

    return (
      <div style={{ height: '30px' }}>
        {icon}
        <span style={STYLE_NAME}>{routesGroup.name}</span>
        <span title={_tg('action.modify')}>
          <Edit
            style={{ color: '#ddd', ...STYLE_ACTIONS }}
            onClick={this.editRoutesGroup}
          />
        </span>
        <span title={_tg('action.delete')}>
          <ConfirmAction
            action={this.onConfirmDelete}
            isOpen={isOpen}
            onClose={this.onConfirmDeleteCancel}
            message={_t('feedback.confirmDeleteMessage')}
            enabled
          >
            <IconButton
              onClick={this.deleteRoutesGroup}
              iconStyle={{
                color: '#aaa',
                width: 20,
                height: 20,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ConfirmAction>
        </span>
      </div>
    );
  }
}

export default RoutesGroup;
