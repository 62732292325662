import React, { CSSProperties } from 'react';
import CommentIcon from 'material-ui/svg-icons/communication/comment';

import SimpleTable from 'commons/SimpleTable';
import Date from 'commons/Date';
import { AlertOverviewDTO, AlertSearchFilterDTO } from 'api/planner/types';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { askAlertsExport } from 'api/planner/index';
import { DEFAULT_PAGER } from 'commons/const';

import { filtersToRequest } from '../AlertFilter/ducks/utils';

import CommentsModal from './CommentsModal';
import { alertNatureToLocale, alertStatusToLocale } from './utils';
import AlertExporter from './AlertExporter';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_COMMENT_BUTTON: CSSProperties = {
  cursor: 'pointer',
};

export type AlertTableProps = {
  items: Array<AlertOverviewDTO>;
  alertFilters: AlertSearchFilterDTO;
  loadMoreAlerts?: (arg0: { startIndex: number }) => Promise<void>;
  fetchAlerts: (shouldDropPreviousRows: boolean) => void;
  totalHits?: number;
  style?: Record<string, any>;
};

type AlertTableState = {
  alertToComment: AlertOverviewDTO | undefined;
};

class AlertTable extends React.Component<AlertTableProps, AlertTableState> {
  constructor(props: AlertTableProps) {
    super(props);
    this.state = {
      alertToComment: undefined,
    };
  }

  showCommentsModal = (event: React.MouseEvent<HTMLSpanElement>): void => {
    const { alertId } = event.currentTarget.dataset;
    const { items } = this.props;
    const alertToComment = items.find(x => x.alertId === alertId);

    if (alertToComment) {
      this.updateAlertToComment(alertToComment);
    }
  };

  hideCommentsModal = (): void => {
    const { fetchAlerts } = this.props;
    this.updateAlertToComment(undefined);
    fetchAlerts(true);
  };

  updateAlertToComment = (
    alertToComment: AlertOverviewDTO | undefined
  ): void => {
    this.setState({ alertToComment });
  };

  render(): React.ReactNode {
    const { alertToComment } = this.state;
    const {
      alertFilters,
      loadMoreAlerts,
      totalHits,
      items,
      style,
    } = this.props;
    const translateTableCols = () => [
      { label: _tg('field.status'), width: 125 },
      { label: _tg('field.date.beginning'), width: 150 },
      {
        label: _tg('field.date.end'),
        width: 150,
      },
      { label: _t('table.nature'), width: 125 },
      { label: _t('table.agentInvolved'), width: 125 },
      {
        label: _t('table.lastComment'),
        width: 250,
      },
      { label: _tg('action.actions'), width: 60 },
    ];
    const filters = filtersToRequest(alertFilters, DEFAULT_PAGER);

    return (
      <ListWrapper style={{ ...style }}>
        <ListBody loading={!items} style={{ minHeight: 300 }}>
          <SimpleTable
            cols={translateTableCols()}
            rowHeight={50}
            itemsRenderer={(alert: AlertOverviewDTO) => {
              const lastComment = alert.comments[alert.comments.length - 1];
              const lastTextComment = lastComment ? lastComment.content : '-';

              const row = [
                alertStatusToLocale(alert.status),
                <Date datetime={alert.startDateTime} />,
                <Date datetime={alert.endDateTime} />,
                alertNatureToLocale(alert.nature),
                alert.agentName,
                lastTextComment,
                <span
                  title={_t('table.displayComments')}
                  data-alert-id={alert.alertId}
                  onClick={this.showCommentsModal}
                >
                  <CommentIcon style={STYLE_COMMENT_BUTTON} />
                </span>,
              ];
              return row;
            }}
            items={items}
            loadMoreRows={loadMoreAlerts}
            remoteRowCount={totalHits}
          />
        </ListBody>
        <ListBottom>
          {alertToComment != null && (
            <CommentsModal
              open={alertToComment !== null}
              handleClose={this.hideCommentsModal}
              alertToComment={alertToComment}
              updateAlertToComment={this.updateAlertToComment}
            />
          )}
          <div style={{ paddingTop: 32 }}>
            <AlertExporter
              disabled={!totalHits}
              filters={filters}
              exportRequest={askAlertsExport}
            />
          </div>
        </ListBottom>
      </ListWrapper>
    );
  }
}

export default AlertTable;
