/* eslint-disable prettier/prettier */
import React, { CSSProperties, useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { Checkbox, IconButton } from 'material-ui';
import ForwardIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import BackwardIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import ActionBuild from 'material-ui/svg-icons/action/build';

import BoButton from 'facade/BoButton';
import { LapiReviewImage } from 'api/lapiReview/types';
import BlurTool from 'tefps/LapiReview/BlurTool/BlurTool';
import { SHADOW } from 'tefps/LapiReview/theme';

type Props = {
  improveLapiImagesEnabled: boolean;
  imagesAreImproved: boolean;
  contextImages: Array<LapiReviewImage>;
  controlId: string;
  selectedImages: Set<number>;
  selectImage: (index: number, checked: boolean) => void;
  handleManualBlurring: (
    arg0: Array<LapiReviewImage>,
    arg1: boolean,
    arg2: number
  ) => void;
  handleUseImprovedImages: () => void;
  lockTreatment: boolean;
};

const CONTAINER_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const BUTTONS_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
};

const INDEX_OVERLAY_SPAN_STYLE: CSSProperties = {
  position: 'relative',
  zIndex: 2,
  marginTop: '-5%',
  marginLeft: '1%',
  marginRight: '1%',
  color: 'black',
  lineHeight: '30px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
};

const OVERLAY_ITEM: CSSProperties = {
  backgroundColor: 'white',
  width: '50px',
  textAlign: 'center',
};

const ContextImages = ({
  improveLapiImagesEnabled,
  imagesAreImproved,
  contextImages,
  controlId,
  selectedImages,
  selectImage,
  handleManualBlurring,
  handleUseImprovedImages,
  lockTreatment,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const unique = contextImages.length <= 1;

  const handleLeftCarouselCLick = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(contextImages.length - 1);
    } else setSelectedIndex(selectedIndex - 1);
  };

  const handleRightCarouselCLick = () => {
    if (selectedIndex === contextImages.length - 1) {
      setSelectedIndex(0);
    } else setSelectedIndex(selectedIndex + 1);
  };

  const modifyContextImage = (blob: Blob) => {
    const newContextImages = contextImages.slice();
    if (newContextImages[selectedIndex]) {
      newContextImages[selectedIndex].file = new File(
        [blob],
        contextImages[selectedIndex].name,
        {
          type: blob.type,
        }
      );
    }
    handleManualBlurring(newContextImages, imagesAreImproved, selectedIndex);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [controlId]);

  const dataSource =
    contextImages[selectedIndex] && contextImages[selectedIndex].file
      ? URL.createObjectURL(contextImages[selectedIndex].file)
      : null;

  const isSelected = selectedImages.has(selectedIndex);

  return (
    <div style={CONTAINER_STYLE}>
      <div style={ROW_STYLE}>
        {!unique && (
          <IconButton
            onClick={handleLeftCarouselCLick}
            iconStyle={{ width: 50, height: 50 }}
          >
            <BackwardIcon />
          </IconButton>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {dataSource && (
            <ReactImageMagnify
              style={SHADOW}
              smallImage={{
                src: dataSource,
                width: 740,
                height: 400,
                isFluidWidth: false,
              }}
              largeImage={{
                src: dataSource,
                width: 1920,
                height: 1024,
              }}
              enlargedImagePosition="over"
              lensStyle={{ backgroundColor: 'rgba(0,0,0,.6)' }}
            />
          )}
          <div style={INDEX_OVERLAY_SPAN_STYLE}>
            <div style={OVERLAY_ITEM}>
              {selectedIndex + 1}/{contextImages.length}
            </div>
            <div
              style={{ ...OVERLAY_ITEM, paddingTop: '3px', paddingLeft: '3px' }}
            >
              <Checkbox
                checked={isSelected}
                onCheck={(e, checked) => selectImage(selectedIndex, checked)}
              />
            </div>
          </div>

          <div style={BUTTONS_STYLE}>
            {!lockTreatment && dataSource && (
              <BlurTool
                contextImage={contextImages[selectedIndex].file}
                modifyContextImage={modifyContextImage}
              />
            )}
            {improveLapiImagesEnabled && (
              <BoButton
                label={`Utiliser les images ${
                  imagesAreImproved ? 'normales' : 'améliorées'
                }`}
                onClick={handleUseImprovedImages}
                icon={<ActionBuild />}
                labelPosition="before"
                style={{ marginLeft: '5px' }}
              />
            )}
          </div>
        </div>
        {!unique && (
          <IconButton
            onClick={handleRightCarouselCLick}
            iconStyle={{ width: 50, height: 50 }}
          >
            <ForwardIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ContextImages;
