import React, { CSSProperties, useState } from 'react';
import { List } from 'material-ui/List';
import _cloneDeep from 'lodash.clonedeep';

import { LapiReviewConfigurationDTO } from 'api/lapiReviewConfiguration/types';
import Tag from 'commons/Tag';
import { BKG_CYAN } from 'theme';

const LIST_STYLE: CSSProperties = {
  display: 'flex',
  alignContent: 'space-evenly',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
};

type ComplexModalProps = {
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
  tagsSelected: Set<string>;
  setTagsSelected: (tags: Set<string>) => void;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
};

const TagsList = ({
  tagsSelected,
  setTagsSelected,
  lapiReviewConfigurationDTO,
  containerStyle,
  style,
}: ComplexModalProps): JSX.Element => {
  const handleToggle = (text: string): void => {
    const newTagsSelected = _cloneDeep(tagsSelected);
    if (newTagsSelected.has(text)) {
      newTagsSelected.delete(text);
    } else {
      newTagsSelected.add(text);
    }
    setTagsSelected(newTagsSelected);
  };

  return (
    <List style={containerStyle || LIST_STYLE}>
      {lapiReviewConfigurationDTO.tags
        .sort((tag1, tag2) => tag1.priority - tag2.priority)
        .map(tag => (
          <div style={style || { minWidth: '50%' }}>
            <Tag
              key={tag.text}
              tag={tag.text}
              backgroundColor={
                tagsSelected.has(tag.text) ? BKG_CYAN : '#d0d0d0' // cyan or grey
              }
              labelColor={tagsSelected.has(tag.text) ? 'white' : 'black'}
              onClick={handleToggle}
            />
          </div>
        ))}
    </List>
  );
};

export default TagsList;
