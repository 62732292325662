import React, { CSSProperties } from 'react';
import moment from 'moment';

import { AlertCommentDTO } from 'api/planner/types';

const { _t } = window.loadTranslations(__filename);

const STYLE_COMMENT: CSSProperties = {
  marginTop: 20,
  marginBottom: 10,
  border: 'none',
  borderBottom: '1px #e0e0e0 solid',
};

const STYLE_COMMENT_NAME: CSSProperties = {
  fontWeight: 'bold',
  marginBottom: 10,
};

type CommentProps = {
  comment: AlertCommentDTO;
};

const Comment = ({ comment }: CommentProps): JSX.Element => {
  if (!comment) {
    return <div />;
  }
  return (
    <div key={comment.commentId}>
      <div style={STYLE_COMMENT}>
        <div style={STYLE_COMMENT_NAME}>
          {comment.agentName} -{' '}
          {moment(comment.creationDateTime).format(
            _t('element.dateTimeFormat')
          )}
        </div>
        {comment.content}
      </div>
    </div>
  );
};

export default Comment;
