import React, { useEffect, useState } from 'react';

import { signGoogleMapUrl, BASE_GOOGLE_MAPS_URL } from 'api/url-signer';
import { UrlDTO } from 'api/url-signer/types';
import { Coordinates } from 'api/commonTypes';
import {
  buildPolygonsPaths,
  getAddressMarkerUrl,
  getMidPoint,
  getPositionMarkerUrl,
} from 'tefps/LapiReview/Geolocalisation/utils';
import { ZoningDTO } from '@cvfm-front/tefps-types';

type ControlMapProps = {
  zoom?: number;
  controlAddressCoordinates: Coordinates;
  controlPositionCoordinates: Coordinates;
  size: {
    height: number;
    width: number;
  };
  style?: Record<string, any>;
  zoning?: ZoningDTO | null | undefined;
};

const ControlMap = ({
  zoom,
  size,
  controlAddressCoordinates,
  controlPositionCoordinates,
  style,
  zoning,
}: ControlMapProps): JSX.Element => {
  const [signedUrl, setSignedUrl] = useState<UrlDTO | null>(null);
  const positionMarkerUrl = getPositionMarkerUrl(window.location.href);
  const addressMarkerUrl = getAddressMarkerUrl(window.location.href);

  const zoneQuery = buildPolygonsPaths(zoning, controlPositionCoordinates);

  const center = getMidPoint(
    controlPositionCoordinates,
    controlAddressCoordinates
  );

  const signUrl = async (url: string) => {
    setSignedUrl(await signGoogleMapUrl(url));
  };

  if (!center) return <span />;

  const mapUrl = `${BASE_GOOGLE_MAPS_URL}/maps/api/staticmap?${
    zoom ? `zoom=${zoom}` : ''
  }&size=${size.width}x${size.height}&style=feature:poi|visibility:off&center=${
    center.coordinates[0]
  },${center.coordinates[1]}&markers=icon:${positionMarkerUrl}%7C${
    controlPositionCoordinates.latitude
  },${
    controlPositionCoordinates.longitude
  }&markers=icon:${addressMarkerUrl}%7C${controlAddressCoordinates.latitude},${
    controlPositionCoordinates.longitude
  }${zoneQuery}`;

  useEffect(() => {
    void signUrl(mapUrl);
  }, [controlAddressCoordinates, controlPositionCoordinates]);

  if (signedUrl) {
    return (
      <img style={style} src={`${BASE_GOOGLE_MAPS_URL}${signedUrl.url}`} />
    );
  }

  return <></>;
};

export default ControlMap;
