import _cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';

import {
  PolylinePath,
  RoutesGroupDTO,
  RouteTemplateDTO,
} from 'api/planner/types';
import { Coordinates } from 'api/commonTypes';

import LatLng = google.maps.LatLng;

export const timeToDurationFormat = (s: string): string => {
  const time = s.split(':');
  if (time.length === 2 && !isNaN(Number(time[0])) && !isNaN(Number(time[1]))) {
    return `PT${time[0]}H${time[1]}M`;
  }
  return '';
};

export const durationToTimeFormat = (s: string): string => {
  const time = /^PT((\d+)H)?((\d+)M)?$/.exec(s);
  if (!time) {
    return '';
  }
  const hours = time[2] ? time[2] : '00';
  const minutes = time[4] ? time[4] : '00';
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const polylineToPath = (polyline: google.maps.Polyline): PolylinePath => ({
  id: uuidv4(),
  routeTemplateId: polyline.routeTemplateId,
  points: polyline
    .getPath()
    .getArray()
    .map((p: LatLng) => ({ latitude: p.lat(), longitude: p.lng() })),
});

export const polylinesToPathes = (
  polylines: Array<google.maps.Polyline>
): Array<PolylinePath> => {
  return polylines.map(p => polylineToPath(p));
};

export const routesGroupHasChanges = (
  routesGroup: RoutesGroupDTO,
  backupGroups: Array<RoutesGroupDTO>
): boolean => {
  const backupGroup = backupGroups.find(group => group.id === routesGroup.id);
  return !(
    backupGroup &&
    backupGroup.routeTemplateIds.length ===
      routesGroup.routeTemplateIds.length &&
    backupGroup.routeTemplateIds.every(id =>
      routesGroup.routeTemplateIds.includes(id)
    )
  );
};

const isSamePath = (
  path1: Array<Coordinates>,
  path2: Array<Coordinates>
): boolean =>
  path1.length === path2.length &&
  path1.every((point, index) => {
    return (
      point.latitude === path2[index].latitude &&
      point.longitude === path2[index].longitude
    );
  });

export const computeTemplateToUpdate = (
  routeTemplate: RouteTemplateDTO,
  polyline: PolylinePath
): RouteTemplateDTO | null | undefined => {
  const result: RouteTemplateDTO = _cloneDeep(routeTemplate);

  if (!result.paths) {
    result.paths = Array.of({
      id: polyline.id,
      points: polyline.points,
    });
    return result;
  }
  const pathIndex = result.paths.findIndex(p => p.id === polyline.id);

  if (
    pathIndex !== -1 &&
    !isSamePath(result.paths[pathIndex].points, polyline.points)
  ) {
    result.paths[pathIndex].points = polyline.points;
    return result;
  }
  if (pathIndex === -1) {
    result.paths.push({
      id: polyline.id,
      points: polyline.points,
    });
    return result;
  }

  return null;
};
