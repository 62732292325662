import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import { PricingPolicyDTO } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  editedPolicyId: string;
  currentPolicies: Array<PricingPolicyDTO>;
  combinedPolicy?: PricingPolicyDTO | null;
  onChange: (combinedPolicy?: PricingPolicyDTO) => void;
};

function CombinedPolicyGridSelector({
  currentPolicies,
  combinedPolicy,
  onChange,
  editedPolicyId,
}: Props): JSX.Element {
  function onChangePolicy(
    _e: React.SyntheticEvent<{}>,
    _index: number,
    menuItemValue: string
  ) {
    const selectedPolicy = currentPolicies.find(
      policy => policy.id === menuItemValue
    );
    onChange(selectedPolicy);
  }
  const filteredPolicies = currentPolicies.filter(
    policy => !(editedPolicyId === policy.id)
  );

  return (
    <>
      {filteredPolicies.length !== 0 && (
        <SelectField
          floatingLabelText={_tg('tefps.pricing.pricing.combiningPolicies')}
          value={combinedPolicy ? combinedPolicy.id : 'none'}
          onChange={onChangePolicy}
          style={{ width: '100%' }}
        >
          <MenuItem
            value="none"
            primaryText={_tg('field.noneFeminin')}
            label={_tg('field.noneFeminin')}
          />
          {filteredPolicies.map(policy => {
            return (
              <MenuItem
                value={policy.id}
                primaryText={policy.name}
                label={policy.name}
              />
            );
          })}
        </SelectField>
      )}
    </>
  );
}

export default CombinedPolicyGridSelector;
