import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'material-ui';
import { cloneDeep } from 'lodash';

import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { GenericFormV2 } from '@cvfm-front/commons-ui';
import {
  ParkingOrientation,
  ParkingSpaceRegime,
  ParkingSpaceRegimeList,
  ParkingOrientationList,
  EsParkingSpaceUpdateRequestDTO,
  EsCityParkingSpaceDTOFactory,
  EsCityParkingSpaceDTO,
  Address,
  AddressFactory,
} from '@cvfm-front/tefps-types';
import {
  FormFieldTypeWithTitleV2,
  arrayToOption,
  ValidatorType,
  AbstractFieldTypeV2,
} from '@cvfm-front/commons-types';
import { mapLabelToTraduction } from '@cvfm-front/commons-utils';
import useZoning from 'commons/hooks/useZoning';
import { getLocale } from 'commons/Utils/localeUtils';
import { EditionType } from 'commons/services/ParkingSpace/ParkingSpaceEditionService';

import { useEditVersionsForm } from './useEditVersionsForm';

const { _tg } = window.loadTranslations(__filename);

const ParkingSpaceEditionModal = (): JSX.Element => {
  /*
   * States
   */
  const [parkingSpace, setParkingSpace] = useState<EsCityParkingSpaceDTO>(
    EsCityParkingSpaceDTOFactory()
  );
  const [isExists, setIsExists] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  /*
   * Callbacks
   */
  const updateAndValidateParkingSpace = useCallback(
    async (newParking: EsCityParkingSpaceDTO) => {
      try {
        await services.parkingSpaceApi.validateVersions(newParking);
        setErrorMessage('');
      } catch (e) {
        setErrorMessage(e.message);
      }
      setParkingSpace(newParking);
    },
    []
  );

  /*
   * Watchers
   */
  const editedParkingSpace = useWatcher(
    services.parkingSpaceEdition.watchEditedParkingSpace,
    null
  );
  const editiontype = useWatcher(
    services.parkingSpaceEdition.watchEditionType,
    EditionType.CREATION
  );

  const isModification =
    editiontype !== EditionType.CREATION &&
    editiontype !== EditionType.DUPLICATION;
  const shouldDisplayEditVersionForm =
    editiontype === EditionType.VERSION_EDITION;
  const zoning = useZoning();
  const zoneIds = zoning?.zones.map(zone => zone.id);
  const zoneNames = zoning?.zones.map(zone => zone.name);
  const isOpen = editedParkingSpace !== null;
  const lastVersion = parkingSpace.versions[parkingSpace.versions.length - 1];

  /*
   * Callbacks
   */
  const handleOnClose = useCallback(
    () => services.parkingSpaceEdition.reset(),
    []
  );

  const handleSave = useCallback(() => {
    void services.parkingSpaceEdition.saveEdition(parkingSpace);
  }, [parkingSpace]);

  const handleValidator = useCallback(async () => {
    try {
      await services.parkingSpaceApi.fetchOneById(parkingSpace.parkingSpaceId);
      setIsExists(true);
    } catch (e) {
      if (e instanceof Error && e.name === 'GenericNotFound') {
        setIsExists(false);
      }
    }
  }, [parkingSpace]);

  const handleFieldChange = useCallback(
    <
      K extends keyof EsParkingSpaceUpdateRequestDTO,
      V extends EsParkingSpaceUpdateRequestDTO[K]
    >(
      key: K
    ) => {
      if (key === 'parkingSpaceId' && parkingSpace.parkingSpaceId) {
        void handleValidator();
      }
      return (value: V) => {
        void updateAndValidateParkingSpace({
          ...parkingSpace,
          [key]: value,
        });
      };
    },
    [parkingSpace]
  );

  const handleFieldChangeAddress = useCallback(
    <K extends keyof Address, V extends Address[K]>(key: K) => {
      return (value: V) => {
        void updateAndValidateParkingSpace({
          ...parkingSpace,
          address: {
            ...(parkingSpace.address || AddressFactory()),
            [key]: value,
          },
        });
      };
    },
    [parkingSpace]
  );

  const validateParkingSpaceId: AbstractFieldTypeV2<
    unknown
  >['validator'] = () => {
    if (!isExists) {
      return {
        type: ValidatorType.SUCCESS,
      };
    }
    return {
      type: ValidatorType.WARNING,
      message: _tg('feedback.error.parkingSpaceIdAlreadyExists'),
    };
  };

  /*
    Form fields
   */

  const formFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: isModification
        ? _tg('editParkingSpace')
        : _tg('creationParkingSpace'),
    },
    {
      type: 'text',
      label: _tg('id'),
      value: parkingSpace.parkingSpaceId,
      onValueChange: handleFieldChange('parkingSpaceId'),
      required: true,
      validator:
        !isModification && parkingSpace.parkingSpaceId
          ? validateParkingSpaceId
          : undefined,
      disabled: isModification,
    },
    {
      type: 'text',
      label: _tg('groupId'),
      value: parkingSpace.parkingSpaceAreaId ?? '',
      onValueChange: handleFieldChange('parkingSpaceAreaId'),
    },
    {
      type: 'select',
      label: _tg('zoneId'),
      value: parkingSpace.zoneId ?? '',
      selectOptions: arrayToOption(zoneIds ?? [], zoneNames ?? []),
      onValueChange: v => {
        const newValue = cloneDeep(parkingSpace);
        newValue.zoneId = v;
        updateAndValidateParkingSpace(newValue);
      },
    },
    {
      type: 'text',
      label: _tg('field.address.streetNumber'),
      value: parkingSpace?.address?.streetNumber ?? '',
      onValueChange: handleFieldChangeAddress('streetNumber'),
    },
    {
      type: 'text',
      label: _tg('field.address.streetType_1'),
      value: parkingSpace?.address?.streetType ?? '',
      onValueChange: handleFieldChangeAddress('streetType'),
    },
    {
      type: 'text',
      label: _tg('field.address.streetName'),
      value: parkingSpace?.address?.streetName ?? '',
      onValueChange: handleFieldChangeAddress('streetName'),
    },
    {
      type: 'text',
      label: _tg('field.address.postalCode'),
      value: parkingSpace?.address?.postalCode ?? '',
      onValueChange: handleFieldChangeAddress('postalCode'),
    },
    {
      type: 'text',
      label: _tg('field.address.locality'),
      value: parkingSpace?.address?.addressLocality ?? '',
      onValueChange: handleFieldChangeAddress('addressLocality'),
    },
    {
      type: 'select',
      label: _tg('type'),
      value: lastVersion.parkingType ?? '',
      selectOptions: arrayToOption(
        ParkingOrientationList,
        mapLabelToTraduction(ParkingOrientationList, _tg, 'ParkingOrientation')
      ),
      onValueChange: v => {
        const newValue = cloneDeep(parkingSpace);
        const newLastVersion = newValue.versions[newValue.versions.length - 1];
        newLastVersion.parkingType = v as ParkingOrientation;
        void updateAndValidateParkingSpace(newValue);
      },
      required: true,
    },
    {
      type: 'select',
      label: _tg('regime'),
      value: lastVersion.parkingRegime ?? '',
      selectOptions: arrayToOption(
        ParkingSpaceRegimeList,
        mapLabelToTraduction(ParkingSpaceRegimeList, _tg, 'ParkingSpaceRegime')
      ),
      onValueChange: v => {
        const newValue = cloneDeep(parkingSpace);
        const newLastVersion = newValue.versions[newValue.versions.length - 1];
        newLastVersion.parkingRegime = v as ParkingSpaceRegime;
        void updateAndValidateParkingSpace(newValue);
      },
      required: true,
    },
    {
      type: 'date',
      label: _tg('startDate'),
      language: getLocale(),
      value: lastVersion.startDatetime
        ? new Date(lastVersion.startDatetime).toString()
        : new Date().toString(),
      onValueChange: v => {
        const newValue = cloneDeep(parkingSpace);
        const newLastVersion = newValue.versions[newValue.versions.length - 1];
        newLastVersion.startDatetime = v;
        void updateAndValidateParkingSpace(newValue);
      },
      required: true,
    },
    {
      type: 'date',
      label: _tg('endDate'),
      language: getLocale(),
      value: lastVersion.endDatetime
        ? new Date(lastVersion.endDatetime).toString()
        : '',
      onValueChange: v => {
        const newValue = cloneDeep(parkingSpace);
        const newLastVersion = newValue.versions[newValue.versions.length - 1];
        newLastVersion.endDatetime = v;
        void updateAndValidateParkingSpace(newValue);
      },
    },
  ];

  const editVersionsForm = useEditVersionsForm(
    parkingSpace,
    updateAndValidateParkingSpace
  );
  const form = shouldDisplayEditVersionForm ? editVersionsForm : formFields;

  /*
   * Effects
   */
  useEffect(() => {
    if (editedParkingSpace) {
      void updateAndValidateParkingSpace(cloneDeep(editedParkingSpace));
    } else {
      void updateAndValidateParkingSpace(EsCityParkingSpaceDTOFactory());
    }
  }, [editedParkingSpace]);

  return (
    <Dialog open={isOpen} onRequestClose={handleOnClose} autoScrollBodyContent>
      {lastVersion && (
        <GenericFormV2
          fields={form}
          onSubmitCallback={handleSave}
          submitLabel={_tg('save')}
          onCancelCallback={handleOnClose}
          cancelLabel={_tg('close')}
          disableOnlySubmit={errorMessage !== ''}
          errorMessage={errorMessage}
        />
      )}
    </Dialog>
  );
};

export default ParkingSpaceEditionModal;
