import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import {
  fetchCurrentPingMap,
  fetchHistoryPingMap,
  fetchPatrolZones,
} from 'api/planner';
import { AgentPingHistoryDTO, PingPointDTO } from 'api/planner/types';
import { PatrolZoneDTO } from '@cvfm-front/tefps-types';

import PingFilters from './PingFilters';
import { MapFilters } from './types';
import { getPingFiltersState } from './duck';
import { displayOptions, filtersToRequest } from './utils';
import PingsMapV2 from './PingsMapV2';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

type Props = {
  filters: MapFilters;
};

type State = {
  currentPings: Array<PingPointDTO>;
  historyPings: Array<AgentPingHistoryDTO>;
  displayGpsStatusAsColors: boolean;
  displayPatrolZone: boolean;
  patrolZones: PatrolZoneDTO[];
};

const INITIAL_STATE = {
  currentPings: [],
  historyPings: [],
  displayGpsStatusAsColors: false,
  displayPatrolZone: false,
  patrolZones: [],
};

class Pings extends React.Component<Props, State> {
  currentPingsInterval: any = null;

  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { filters } = nextProps;
    void this.fetchCurrentPings(filters);
    void this.fetchHistoryPings(filters);
    if (filters.displayType === displayOptions.CURRENT) {
      this.startCurrentPingsInterval();
    } else {
      this.stopCurrentPingsInterval();
    }
    void this.fetchPatrolZones();
  }

  componentWillUnmount() {
    this.stopCurrentPingsInterval();
  }

  fetchCurrentPings = async (filters: MapFilters) => {
    if (filters.displayType === displayOptions.CURRENT) {
      try {
        const result = await fetchCurrentPingMap(filtersToRequest(filters));
        this.setState({ currentPings: result });
      } catch (error) {
        // ignored
      }
    } else {
      // remove pings previously plotted
      this.setState({ currentPings: [] });
    }
  };

  fetchHistoryPings = async (filters: MapFilters) => {
    if (filters.displayType === displayOptions.HISTORY) {
      try {
        const result = await fetchHistoryPingMap(filtersToRequest(filters));
        this.setState({ historyPings: result });
      } catch (error) {
        // ignored
      }
    } else {
      // remove pings previously plotted
      this.setState({ historyPings: [] });
    }
  };

  startCurrentPingsInterval = () => {
    // Start updating map every 10s for real time positions
    if (this.currentPingsInterval == null) {
      this.currentPingsInterval = setInterval(() => {
        const { filters } = this.props;
        void this.fetchCurrentPings(filters);
      }, 10 * 1000);
    }
  };

  stopCurrentPingsInterval = () => {
    if (this.currentPingsInterval != null) {
      clearInterval(this.currentPingsInterval);
    }
    this.currentPingsInterval = null;
  };

  fetchPatrolZones = async () => {
    const patrolZones = await fetchPatrolZones();
    this.setState({ patrolZones: patrolZones ?? [] });
  };

  render() {
    const {
      currentPings,
      historyPings,
      displayGpsStatusAsColors,
      displayPatrolZone,
      patrolZones,
    } = this.state;

    return (
      <div style={STYLE_CONTENT}>
        <PingFilters
          displayGpsStatusAsColors={displayGpsStatusAsColors}
          setDisplayGpsStatusAsColors={(value: boolean) =>
            this.setState({ displayGpsStatusAsColors: value })
          }
          displayPatrolZone={displayPatrolZone}
          setDisplayPatrolZone={(value: boolean) =>
            this.setState({ displayPatrolZone: value })
          }
        />
        <div style={STYLE_LIST_WRAPPER}>
          <PingsMapV2
            displayPatrolZone={displayPatrolZone}
            patrolZones={patrolZones ?? []}
            currentPings={currentPings}
            historyPings={historyPings}
            displayGpsStatusAsColors={displayGpsStatusAsColors}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const filters = getPingFiltersState(state);
  return { filters };
})(Pings);
