import { TextField } from 'material-ui';
import React from 'react';

interface Props {
  label: string;
  autoComplete: (input: string) => void;
}

const BillDetailAutoComplete = ({
  label,
  autoComplete,
}: Props): JSX.Element => {
  return (
    <TextField
      floatingLabelText={`Import depuis ${label}`}
      fullWidth
      multiLine
      rows={5}
      rowsMax={5}
      onChange={(ev, value) => autoComplete(value)}
    />
  );
};

export default BillDetailAutoComplete;
