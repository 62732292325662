import React from 'react';

import { TXT_RED } from 'theme';
import SimpleTable from 'commons/SimpleTable';
import BoButton from 'facade/BoButton';

import { AbusiveParkingDTO, ParamsDTO } from './types';
import { HEADER_COLS } from './utils';

const { _tg } = window.loadTranslations(__filename);

interface IProps {
  abusiveParkings: AbusiveParkingDTO[];
  emptyResults: boolean;
  searchDone: boolean;
  searchParams: ParamsDTO;
}

const Results = ({
  emptyResults,
  searchDone,
  abusiveParkings,
  searchParams,
}: IProps): JSX.Element => {
  const showControls = (plate: string, parkingSpaceId: string): void => {
    let openFocusUrl = window.location.href.replace(
      'planner/abusiveParking',
      'controls/list?'
    );

    openFocusUrl += `plate=${plate}&`;
    openFocusUrl += `parkingSpaceId=${parkingSpaceId}&`;
    openFocusUrl += `statementStartDatetime=${searchParams.statementStartDatetime.toISOString()}&`;
    openFocusUrl += `statementEndDatetime=${searchParams.statementEndDatetime.toISOString()}&`;
    openFocusUrl += `controlStatus=KO`;

    window.open(openFocusUrl, '_blank', 'noopener,noreferrer');
  };

  const rowRenderer = (row: AbusiveParkingDTO) => {
    return [
      <div style={{ wordWrap: 'break-word' }}>{row.plate}</div>,
      <div>{row.parkingSpaceId}</div>,
      <div>{row.numberOfControlsKO}</div>,
      <div>
        <BoButton
          label={_tg('action.showDetails')}
          primary
          onClick={() => showControls(row.plate, row.parkingSpaceId)}
        />
      </div>,
    ];
  };

  const getComponentContent = (): JSX.Element => {
    if (emptyResults) {
      return (
        <p style={{ textAlign: 'center', color: TXT_RED }}>
          {_tg('tefps.Planner.AbusiveParking.info.noResults')}
        </p>
      );
    }
    if (!searchDone) {
      return (
        <p style={{ textAlign: 'center' }}>
          {_tg('tefps.Planner.AbusiveParking.info.searchAction')}
        </p>
      );
    }
    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        cols={HEADER_COLS}
        rowHeight={50}
        itemsRenderer={rowRenderer}
        items={abusiveParkings}
      />
    );
  };

  return <div style={{ marginTop: 25 }}>{getComponentContent()}</div>;
};

export default Results;
