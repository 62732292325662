import {
  EsCityParkingSpaceDTO,
  EsCityParkingSpaceVersionDTO,
} from '@cvfm-front/tefps-types';

// go through all the versions (right to left) and find the first who:
// - is not deprecated
// - has started
// - has not ended
// return it, or null if none is found
export const computeParkingSpaceCurrentVersion = (
  parkingSpace: EsCityParkingSpaceDTO
): EsCityParkingSpaceVersionDTO | null => {
  const now = new Date();
  // optimized, by ending early instead of going through the whole list
  // but that way we duplicate the list by using slice
  return parkingSpace.versions
    .slice(0)
    .reduceRight(
      (
        _previousVersion: EsCityParkingSpaceVersionDTO | null,
        currentVersion: EsCityParkingSpaceVersionDTO,
        _currentIndex: number,
        arrayCopy: Array<EsCityParkingSpaceVersionDTO>
      ): EsCityParkingSpaceVersionDTO | null => {
        if (new Date(currentVersion.startDatetime) > now) {
          return null;
        }
        if (
          currentVersion.endDatetime !== null &&
          new Date(currentVersion.endDatetime) < now
        ) {
          return null;
        }
        if (
          currentVersion.deprecatedDatetime !== null &&
          new Date(currentVersion.deprecatedDatetime) < now
        ) {
          return null;
        }
        // trick to do an early return
        arrayCopy.length = 0;
        return currentVersion;
      },
      null
    );
};
