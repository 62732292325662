const { _tg } = window.loadTranslations(__filename);

export const HEADER_COLS = [
  {
    label: _tg('tefps.Planner.AbusiveParking.headers.plate'),
    width: 100,
  },
  {
    label: _tg('tefps.Planner.AbusiveParking.headers.parkingSpace'),
    width: 100,
  },
  {
    label: _tg('tefps.Planner.AbusiveParking.headers.numberOfControlsKO'),
    width: 100,
  },
  {
    label: '',
    width: 50,
  },
];
