import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CircularProgress, Dialog } from 'material-ui';

import { Flex } from '@cvfm-front/commons-ui';
import PageContainer from 'commons/PageContainer';
import { Bill } from '@cvfm-front/tefps-types';
import SectionTitle from 'commons/SectionTitle';

import BillDetailService from './BillDetailService';
import BillDetailForm from './BillDetailForm';
import { BILL_PLACEHOLDER } from './BillPlaceholder';
import './BillDetailInformation.css';
import BillDetailInformation from './BillDetailInformation';
import BillVersionDisplayer from './BillVersionDisplayer';

const BillDetail = (): JSX.Element => {
  const [bill, setBill] = useState<Bill>(BILL_PLACEHOLDER);
  const [dialog, setDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { billId } = useParams<{ billId: string }>();

  useEffect(() => {
    void BillDetailService.fetchBill(billId).then(() => setIsLoading(false));
  }, []);

  useEffect(() => BillDetailService.watchBill(setBill), []);

  if (isLoading) {
    return (
      <Flex width100 height100 justifyContent="center" alignItems="center">
        <CircularProgress />
      </Flex>
    );
  }

  return (
    <PageContainer>
      <Flex gap={32} flexDirection="column">
        <BillDetailInformation bill={bill} setDialog={setDialog} />

        <SectionTitle title="Historique" />

        <Flex gap={32} width100 flexDirection="column" alignItems="center">
          {bill.versions.map(version => (
            <BillVersionDisplayer bill={bill} version={version} />
          ))}
        </Flex>
      </Flex>

      {dialog && (
        <Dialog
          open={dialog}
          autoScrollBodyContent
          onRequestClose={() => setDialog(false)}
        >
          <BillDetailForm
            plate={bill.plate.plate}
            submitCallback={() => setDialog(false)}
          />
        </Dialog>
      )}
    </PageContainer>
  );
};

export default BillDetail;
