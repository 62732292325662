import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlatButton } from 'material-ui';
import HomeIcon from 'material-ui/svg-icons/action/home';

import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';
import { BKG_PINK } from 'theme';

const COMMON_LABEL_STYLE: CSSProperties = {
  textTransform: 'unset',
  color: BKG_PINK,
};

const LABEL_STYLE: { [k: string]: CSSProperties } = {
  s: {
    ...COMMON_LABEL_STYLE,
    fontSize: '12px',
  },
  m: {
    ...COMMON_LABEL_STYLE,
    fontSize: '18px',
  },
};

const ICON_STYLE: { [k: string]: CSSProperties } = {
  s: {
    height: 16,
    width: 16,
    fill: BKG_PINK,
  },
  m: {
    height: 28,
    width: 28,
    fill: BKG_PINK,
  },
};

type TEFPSControlButtonProps = {
  userInfo: InternalAgent;
  size?: string;
};

const TEFPSControlButton = ({
  userInfo,
  size = 's',
}: TEFPSControlButtonProps) => {
  const history = useHistory();

  const redirect = () => {
    if (userInfo.rights.includes('BACKOFFICE_CONTROL')) {
      history.push('/controls/list');
    } else if (userInfo.rights.includes('BACKOFFICE_FPS')) {
      history.push('/fps/list');
    } else if (userInfo.rights.includes('BACKOFFICE_TV')) {
      history.push('/tickets/list');
    } else {
      history.push('/planner');
    }
  };

  if (userInfo.rights.includes('BACKOFFICE_CONTROL')) {
    return (
      <FlatButton
        labelStyle={LABEL_STYLE[size]}
        label="Retour vers TEFPS"
        onClick={redirect}
        icon={<HomeIcon style={ICON_STYLE[size]} />}
      />
    );
  }
  return <></>;
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return { userInfo };
})(TEFPSControlButton);
