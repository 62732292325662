import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog } from 'material-ui';
import SaveIcon from 'material-ui/svg-icons/content/save';
import UndoIcon from 'material-ui/svg-icons/content/undo';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';
import EditIcon from 'material-ui/svg-icons/image/edit';

import BoButton from 'facade/BoButton';
import BlurToolEditor from 'tefps/LapiReview/BlurTool/BlurToolEditor';
import { BKG_DARK_BLUE, ICON_RED, TXT_GREY } from 'theme';

const CUSTOM_CONTENT_STYLE: CSSProperties = {
  maxWidth: 'none',
  maxHeight: 'none',
  width: '80vw',
};

const CUSTOM_BODY_STYLE: CSSProperties = {
  padding: 0,
  height: '80vh',
  width: '80vw',
};

const BUTTON_STYLE: CSSProperties = {
  margin: '0px 10px',
  width: 155,
};

const LABEL_STYLE: CSSProperties = {
  color: TXT_GREY,
};

type BlurToolProps = {
  contextImage: Blob;
  modifyContextImage: (arg0: Blob) => void;
};

const BlurTool = ({ contextImage, modifyContextImage }: BlurToolProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [contextImageCopy, setContextImageCopy] = useState(contextImage);

  useEffect(() => {
    setContextImageCopy(contextImage);
  }, [contextImage]);

  const handleModeChange = () => {
    setEdit(!edit);
  };

  const cancelOperations = () => {
    setContextImageCopy(contextImage);
  };

  const quitAndSave = () => {
    modifyContextImage(contextImageCopy);
    handleModeChange();
  };

  const quitWithOutSave = () => {
    cancelOperations();
    handleModeChange();
  };

  const getActions = (): React.ReactElement => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <BoButton
        label="recharger la version actuelle"
        onClick={cancelOperations}
        labelPosition="before"
        icon={<UndoIcon />}
      />
      <div>
        <BoButton
          label="Annuler"
          onClick={quitWithOutSave}
          style={BUTTON_STYLE}
          labelPosition="before"
          backgroundColor={ICON_RED}
          labelStyle={LABEL_STYLE}
          icon={<CancelIcon />}
        />
        <BoButton
          label="Enregistrer"
          onClick={quitAndSave}
          style={BUTTON_STYLE}
          labelPosition="before"
          backgroundColor={BKG_DARK_BLUE}
          labelStyle={LABEL_STYLE}
          icon={<SaveIcon />}
        />
      </div>
    </div>
  );

  return (
    <div>
      <BoButton
        label="Floutage manuel"
        onClick={handleModeChange}
        icon={<EditIcon />}
        labelPosition="before"
      />
      <Dialog
        open={edit}
        onRequestClose={handleModeChange}
        modal
        contentStyle={CUSTOM_CONTENT_STYLE}
        bodyStyle={CUSTOM_BODY_STYLE}
        actions={[getActions()]}
      >
        <BlurToolEditor
          contextImageCopy={contextImageCopy}
          setContextImageCopy={setContextImageCopy}
        />
      </Dialog>
    </div>
  );
};

export default BlurTool;
