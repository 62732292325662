import { ParkingSpaceRegimeList } from '@cvfm-front/tefps-types';
import { arrayToOption } from '@cvfm-front/commons-types';
import { mapLabelToTraduction } from '@cvfm-front/commons-utils';

const { _tg } = window.loadTranslations(__filename);

export const parkingRegimeOptions = arrayToOption(
  ParkingSpaceRegimeList,
  mapLabelToTraduction(ParkingSpaceRegimeList, _tg, 'ParkingSpaceRegime')
);
