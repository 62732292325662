import React, { CSSProperties } from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import MissNotFound from 'commons/MissNotFound';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import { getApiState } from 'api/duck';

import Policies from './Policies';
import Zoning from './Zoning';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  width: '60%',
  margin: '0 auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: 30,
};

const STYLE_MODULE: CSSProperties = {
  width: 500,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  margin: 30,
  fontWeight: 'bold',
  fontSize: 35,
};

const DashboardHome = ({ canRead }: { canRead: boolean }) => {
  if (!canRead) {
    return (
      <FlexCenter>
        <ErrorBlock
          message="Erreur lors de la tentative d'accès à l'onglet de tarification"
          error={{
            message: "Vous n'avez pas les droits d'accès à cette partie",
          }}
        />
      </FlexCenter>
    );
  }

  return (
    <div style={{ height: '100%', fontFamily: 'Roboto' }}>
      <div style={STYLE_CONTAINER}>
        <span style={STYLE_TITLE}>{_tg('tefps.pricing.select')}</span>
      </div>
      <div style={STYLE_CONTAINER}>
        <Link to="/pricing/zoning" style={STYLE_MODULE}>
          {_tg('tefps.pricing.zoning.label')}
        </Link>
        <Link to="/pricing/policies" style={STYLE_MODULE}>
          {_tg('tefps.pricing.pricing.label')}
        </Link>
      </div>
    </div>
  );
};

const ConnectedHome = connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canRead: userInfo && userInfo.rights.includes('PRICING_READ'),
  };
})(DashboardHome);

const Pricing = ({ match }: RouteComponentProps): JSX.Element => (
  <div style={{ height: '100%' }}>
    <Switch>
      <Route path={`${match.url}`} exact component={ConnectedHome} />
      <Route path={`${match.url}/policies`} exact component={Policies} />
      <Route path={`${match.url}/zoning`} exact component={Zoning} />
      <MissNotFound />
    </Switch>
  </div>
);

export default Pricing;
