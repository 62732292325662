import * as React from 'react';
import { useState } from 'react';

import Sidebar from 'commons/SidebarV2';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import {
  BillEsSearchQueryDTO,
  DateRangeDTO,
  EsBillStatus,
} from '@cvfm-front/tefps-types';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Input from 'commons/SidebarV2/Components/Input';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';

import { translateEsBillStatus } from './BillStatusTranslator';

const { _t } = window.loadTranslations(__filename);

type BillTableSidebarProps = {
  initialFilters: BillEsSearchQueryDTO;
  onChangeFilters: (filters: BillEsSearchQueryDTO) => void;
  totalHits: number;
};

const BillTableSidebar = ({
  initialFilters,
  onChangeFilters,
  totalHits,
}: BillTableSidebarProps): JSX.Element => {
  const [filters, setFilters] = useState<BillEsSearchQueryDTO>({
    ...initialFilters,
  });

  const billStatuses =
    Object.values(EsBillStatus).map(status => ({
      value: status,
      label: translateEsBillStatus(status),
    })) || [];

  function resetFilters() {
    const updateFilters = { ...initialFilters };
    setFilters(updateFilters);
    onChangeFilters(updateFilters);
  }

  function onChangeArrayText(id: string, value: string) {
    const updateValue = value ? [value] : undefined;
    const newFilters = {
      ...filters,
      [id]: updateValue,
    } as BillEsSearchQueryDTO;
    setFilters(newFilters);
    onChangeFilters(newFilters);
  }

  function dateRangeToFilterDate(dateRange?: DateRangeDTO): FilterDate {
    return {
      from: dateRange?.from,
      to: dateRange?.to,
    };
  }

  function onChangeDate(id: string, newDates: FilterDate) {
    const rangeDto: DateRangeDTO = {
      from: newDates.from,
      to: newDates.to,
    };
    const newFilters = {
      ...filters,
      [id]: rangeDto,
    };
    setFilters(newFilters);
    onChangeFilters(newFilters);
  }

  function onChangeStatus(id: string, value: Set<EsBillStatus>) {
    const newFilters = {
      ...filters,
      [id]: Array.from(value),
    } as BillEsSearchQueryDTO;
    setFilters(newFilters);
    onChangeFilters(newFilters);
  }

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Dates
        id="creationDatetime"
        title={_t('creationDatetime')}
        onChange={onChangeDate}
        dates={dateRangeToFilterDate(filters.creationDatetime)}
      />
      <Input
        id="billIds"
        title={_t('billId')}
        placeholder={_t('billId')}
        onChange={onChangeArrayText}
        value={filters.billIds ? filters.billIds[0] : ''}
      />
      <Input
        id="plates"
        title={_t('plate')}
        placeholder={_t('plate')}
        onChange={onChangeArrayText}
        value={filters.plates ? filters.plates[0] : ''}
      />
      <Checkboxes
        id="statuses"
        title={_t('statuses')}
        options={billStatuses}
        filters={new Set(filters.statuses)}
        onChange={onChangeStatus}
      />
    </Sidebar>
  );
};

export default BillTableSidebar;
