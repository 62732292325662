import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from 'material-ui';
import FlatButton from 'material-ui/FlatButton';

import { AddressAutoComplete } from '@cvfm-front/tefps-ui';
import { findAddress } from 'api/searchingCompletion';
import { LocalizedAddressDTO } from 'api/searchingCompletion/types';
import FormComponent from 'commons/FormComponent';
import AddressForm from 'commons/Address/AddressForm';
import { Address, ControlAddressType } from 'api/commonTypes';
import { BKG_LIGHT_BLUE } from 'theme';
import './ControlAddressModal.css';
import { Flex } from '@cvfm-front/commons-ui';
import { EsCityParkingSpaceDTO, FnmsLocation } from '@cvfm-front/tefps-types';
import services from 'commons/services';
import useCurrentLapiReview from 'commons/hooks/useCurrentLapiReview';
import useWatcher from 'commons/hooks/useWatcher';

import RecomputeControlPosition from './RecomputeControlPosition';

const { _tg } = window.loadTranslations(__filename);

type ControlAddressModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: (newAddress: ControlAddressType) => void;
  address: ControlAddressType;
  darkMode: boolean;
};

const ControlAddressModal = ({
  open,
  onCancel,
  onConfirm,
  address,
  darkMode,
}: ControlAddressModalProps): React.ReactElement<any> => {
  const lapiReview = useCurrentLapiReview();
  const [newLocation, setNewLocation] = useState<FnmsLocation | null>(null);
  const [newZoneId, setNewZoneId] = useState<string | null>(null);
  const [newPatrolZoneId, setNewPatrolZoneId] = useState<string | null>(null);
  const handleZoneChange = useCallback((location, zoneId, patrolZoneId) => {
    setNewLocation(location);
    setNewZoneId(zoneId);
    setNewPatrolZoneId(patrolZoneId);
  }, []);

  let addressForm: FormComponent | null = null;
  const [editedAddress, setEditedAddress] = useState<ControlAddressType>(
    address
  );

  const [
    newParkingSpace,
    setNewParkingSpace,
  ] = useState<EsCityParkingSpaceDTO | null>(null);
  const selectedParkingSpace = useWatcher(
    services.parkingSpaceMap.watchSeletected,
    null
  );

  // Watcher for parkingspace selected
  useEffect(() => {
    setNewParkingSpace(selectedParkingSpace);
    if (selectedParkingSpace != null && selectedParkingSpace.address != null) {
      setEditedAddress(selectedParkingSpace.address);
      setNewZoneId(selectedParkingSpace.zoneId);
      setNewLocation({
        latitude: selectedParkingSpace.latLong.latitude,
        longitude: selectedParkingSpace.latLong.longitude,
      });
    }
  }, [selectedParkingSpace]);

  useEffect(() => {
    if (!open) {
      setEditedAddress(address);
    }
  });

  const attachRef = (node: FormComponent) => {
    addressForm = node;
  };

  const handleConfirm = async (): Promise<void> => {
    if (addressForm && addressForm.isValid()) {
      const newAddress = addressForm.getFormEntries() as Address;
      if (newLocation !== null && newZoneId !== null) {
        await services.lapiReview.patchLocationAddressZone(
          newLocation,
          newAddress,
          newZoneId,
          newParkingSpace
        );
      }

      await services.lapiReview.registerNewPatrolZoneControl(
        lapiReview,
        newPatrolZoneId
      );

      onConfirm(newAddress);
    }
  };

  const handleCancel = (): void => {
    onCancel(); // Parent will hide
  };

  const autoCompleteAddress = async (
    searchValue: string
  ): Promise<LocalizedAddressDTO[]> => {
    const completed: LocalizedAddressDTO[] = await findAddress(
      searchValue,
      true
    );
    return completed;
  };

  const onSelectedAddress = (selectedAddress: LocalizedAddressDTO): void => {
    setEditedAddress(selectedAddress.address);
  };

  const actions = [
    <FlatButton label="Annuler" secondary onClick={handleCancel} />,
    <FlatButton
      label="Confirmer"
      labelStyle={{ color: BKG_LIGHT_BLUE }}
      primary
      keyboardFocused
      onClick={handleConfirm}
    />,
  ];

  return (
    <Dialog
      title="Géolocalisation"
      actions={actions}
      open={open}
      onRequestClose={handleCancel}
      autoDetectWindowHeight
      autoScrollBodyContent
      contentStyle={{ maxWidth: 1200 }}
    >
      <div className="address-modal">
        <div className="address-modal__autocomplete-label">
          <span>
            {_tg(
              'tefps.lapiReview.geolocalisation.components.controlAddressModal.search'
            )}
          </span>
        </div>
        <div className="address-modal__autocomplete-component">
          <AddressAutoComplete
            findAddress={autoCompleteAddress}
            onAddressSelected={onSelectedAddress}
            noAddressText={_tg('noResults')}
            loadingAddressText={_tg('researchInProgress')}
            prefersDarkMode={darkMode}
          />
        </div>
      </div>
      <Flex justifyContent="space-between" alignItems="center">
        <FormComponent ref={attachRef}>
          <AddressForm title="Edition de l'adresse" preFill={editedAddress} />
        </FormComponent>
        <RecomputeControlPosition
          newParkingSpace={newParkingSpace}
          address={editedAddress}
          handleZoneChange={handleZoneChange}
        />
      </Flex>
    </Dialog>
  );
};

export default ControlAddressModal;
