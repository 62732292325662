import React from 'react';

import { AlertCommentDTO } from 'api/planner/types';

import Comment from './Comment';

type Props = {
  comments: Array<AlertCommentDTO>;
};

const CommentList = ({ comments }: Props): JSX.Element => {
  return (
    <>
      {comments.map(comment => (
        <Comment comment={comment} />
      ))}
    </>
  );
};

export default CommentList;
