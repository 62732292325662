import React, { useEffect, useState } from 'react';

import './PeriodSelector.css';
import {
  Arrow,
  getCurrentMonth,
  getCurrentWeek,
  getWeekDateRange,
  periodOptions,
  TemporalUnit,
} from './helpers';

type Props = {
  temporalUnit: TemporalUnit;
  year: number;
  selectPeriod: (period: number, year: number) => void;
};

const PeriodSelector = ({
  temporalUnit,
  year,
  selectPeriod,
}: Props): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const periods = periodOptions(temporalUnit) as string[];
  const isPeriodEqualsTo = (unit: TemporalUnit) => {
    return temporalUnit === unit;
  };

  const [currentPeriodIndex, setCurrentPeriodIndex] = useState(
    isPeriodEqualsTo(TemporalUnit.MONTH) ? getCurrentMonth() : getCurrentWeek()
  );

  useEffect(() => {
    selectPeriod(
      isPeriodEqualsTo(TemporalUnit.MONTH)
        ? getCurrentMonth()
        : getCurrentWeek(),
      year
    );
  }, []);

  const handlePrev = () => {
    const index = (currentPeriodIndex - 1 + periods.length) % periods.length;
    if (index + 1 === periods.length) {
      selectPeriod(index, year - 1);
    } else {
      selectPeriod(index, year);
    }
    setCurrentPeriodIndex(index);
  };

  const handleNext = () => {
    const index = (currentPeriodIndex + 1) % periods.length;
    if (index === 0) {
      selectPeriod(index, year + 1);
    } else {
      selectPeriod(index, year);
    }
    setCurrentPeriodIndex(index);
  };

  const lastPeriod = (N: number): string => {
    return periods[(currentPeriodIndex - N + periods.length) % periods.length];
  };

  const nextPeriod = (N: number): string => {
    return periods[(currentPeriodIndex + N) % periods.length];
  };

  const getCurrentPeriodStyle = () => {
    if (isPeriodEqualsTo(TemporalUnit.MONTH)) {
      return currentPeriodIndex === getCurrentMonth() && currentYear === year
        ? 'current-month-period current-period'
        : 'current-month-period';
    }
    if (isPeriodEqualsTo(TemporalUnit.WEEK)) {
      return currentPeriodIndex === getCurrentWeek() && currentYear === year
        ? 'current-week-period current-period'
        : 'current-week-period';
    }
    return '';
  };

  const getCurrentPeriod = (): string => {
    if (isPeriodEqualsTo(TemporalUnit.MONTH)) {
      return `${periods[currentPeriodIndex]}`;
    }
    if (isPeriodEqualsTo(TemporalUnit.WEEK)) {
      return `${getWeekDateRange(currentPeriodIndex, year)} (${
        periods[currentPeriodIndex]
      })`;
    }
    return '';
  };

  return (
    <div className="selector">
      <div className="periods">
        <span className="period smogged-left">{lastPeriod(2)}</span>
        <span className="period">{lastPeriod(1)}</span>
        <button onClick={handlePrev} type="button" className="arrow-button">
          <Arrow direction="back" />
        </button>
        <span className={`period current ${getCurrentPeriodStyle()}`}>
          {getCurrentPeriod()}
        </span>
        <button onClick={handleNext} type="button" className="arrow-button">
          <Arrow direction="next" />
        </button>
        <span className="period">{nextPeriod(1)}</span>
        <span className="period smogged-right">{nextPeriod(2)}</span>
      </div>
    </div>
  );
};

export default PeriodSelector;
