import { AlertSearchFilterDTO } from 'api/planner/types';

import { AlertSetFilterAction, AlertSetHitsAction } from './types';

export const setAlertFilters = (
  filters: AlertSearchFilterDTO
): AlertSetFilterAction => ({
  type: 'SET_ALERT_FILTERS',
  payload: filters,
});

export const setAlertHits = (hits: number): AlertSetHitsAction => ({
  type: 'SET_ALERT_HITS',
  payload: hits,
});
