import { Coordinates } from 'api/commonTypes';
import { Marker, Position } from 'commons/ClusterMap/types';

function ControlMapService() {
  function computeCenterCoordinates(coordinates: Coordinates[]): Position {
    const sumCoordinates = coordinates.reduce((sum, value) => {
      return {
        latitude: sum.latitude + value.latitude,
        longitude: sum.longitude + value.longitude,
      };
    });
    return {
      lat: sumCoordinates.latitude / coordinates.length,
      lng: sumCoordinates.longitude / coordinates.length,
    };
  }

  function computeReviewMarkers(
    address: Coordinates,
    position: Coordinates,
    newAddress?: Coordinates
  ): Marker[] {
    const markers = [
      {
        status: 'address',
        id: Math.random().toString(),
        position: {
          lat: address.latitude,
          lng: address.longitude,
        },
      },
      {
        status: 'position',
        id: Math.random().toString(),
        position: {
          lat: position.latitude,
          lng: position.longitude,
        },
      },
    ];

    if (newAddress) {
      markers.push({
        status: 'new_address',
        id: Math.random().toString(),
        position: {
          lat: newAddress.latitude,
          lng: newAddress.longitude,
        },
      });
    }

    return markers;
  }

  return {
    computeReviewMarkers,
    computeCenterCoordinates,
  };
}

export default ControlMapService();
