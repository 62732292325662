import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import { PricingPolicyDTO } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  editedPolicyId: string;
  currentPolicies: Array<PricingPolicyDTO>;
  contiguousPolicy?: PricingPolicyDTO | null;
  onChange: (contiguousPricing?: PricingPolicyDTO) => void;
};

function CongituousPolicySelector({
  currentPolicies,
  contiguousPolicy,
  onChange,
  editedPolicyId,
}: Props): JSX.Element {
  function onChangePolicy(
    e: React.SyntheticEvent<{}>,
    index: number,
    menuItemValue: string
  ) {
    const selectedPolicy = currentPolicies.find(
      policy => policy.id === menuItemValue
    );
    onChange(selectedPolicy);
  }
  const filteredPolicies = currentPolicies.filter(
    policy => !!policy.tariffCode && !(editedPolicyId === policy.id)
  );

  return (
    <>
      {filteredPolicies.length !== 0 && (
        <SelectField
          floatingLabelText={_tg('tefps.pricing.pricing.contiguousPolicies')}
          value={contiguousPolicy ? contiguousPolicy.id : 'none'}
          onChange={onChangePolicy}
        >
          <MenuItem
            value="none"
            primaryText={_tg('field.noneFeminin')}
            label={_tg('field.noneFeminin')}
          />
          {filteredPolicies.map(policy => {
            return (
              <MenuItem
                value={policy.id}
                primaryText={policy.name}
                label={policy.name}
              />
            );
          })}
        </SelectField>
      )}
    </>
  );
}

export default CongituousPolicySelector;
