import React, { CSSProperties, useEffect, useState } from 'react';
import _cloneDeep from 'lodash.clonedeep';

import Content from 'commons/Content';
import { TXT_BLACK } from 'theme';
import { getAbusiveParking } from 'api/control';

import Parameters from './Parameters';
import Results from './Results';
import { AbusiveParkingDTO, ParamsDTO } from './types';

const STYLE_TITLE: CSSProperties = {
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  textAlign: 'center',
  color: TXT_BLACK,
  fontSize: 22,
  marginTop: 25,
};

const { _tg } = window.loadTranslations(__filename);

const AbusiveParkingPage = (): JSX.Element => {
  const [searchDone, setSearchDone] = useState<boolean>(!false);
  const [searchParams, setSearchParams] = useState<ParamsDTO | undefined>(
    undefined
  );
  const [emptyResults, setEmptyResults] = useState<boolean>(false);
  const [abusiveParkings, setAbusiveParkings] = useState<AbusiveParkingDTO[]>(
    []
  );

  const search = async (params: ParamsDTO): Promise<void> => {
    const newAbusiveParkings = await getAbusiveParking(
      params.statementStartDatetime.toISOString(),
      params.statementEndDatetime.toISOString(),
      params.minNumberOfControlsKO
    );
    setAbusiveParkings(newAbusiveParkings);
    setSearchDone(true);
    setSearchParams(params);
    setEmptyResults(newAbusiveParkings.length === 0);
  };

  return (
    <Content>
      <div style={STYLE_TITLE}>{_tg('tefps.Planner.AbusiveParking.title')}</div>

      <Parameters search={search} />

      {searchParams && (
        <Results
          abusiveParkings={abusiveParkings}
          emptyResults={emptyResults}
          searchDone={searchDone}
          searchParams={searchParams}
        />
      )}
    </Content>
  );
};

export default AbusiveParkingPage;
