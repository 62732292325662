import React, { CSSProperties, useState } from 'react';

import BoButton from 'facade/BoButton';
import { BKG_LIGHT, BKG_ORANGE } from 'theme';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { patchLapiReview } from 'api/lapiReview';
import { LapiComplexDTO, LapiReviewDTO } from 'api/lapiReview/types';
import { PatchObject } from '@cvfm-front/commons-types';

import ComplexModal from './ComplexModal';

const STYLE_BUTTON: CSSProperties = { width: '150px', margin: '10px' };

type Props = {
  lapiReview: LapiReviewDTO;
  fetchNew: Function;
};

const Complex = ({ lapiReview, fetchNew }: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const setMessage = useSnackbar();

  const handleComplex = async (comment: string, tags: Set<string>) => {
    try {
      const patch: PatchObject<LapiComplexDTO> = {
        op: 'replace',
        path: '/isComplex',
        value: {
          vehicleType: lapiReview.vehicleCategory
            ? lapiReview.vehicleCategory
            : null,
          controlId: lapiReview.controlId,
          comment,
          tags: Array.from(tags),
        },
      };

      await patchLapiReview(lapiReview.controlId, [patch]);
      setMessage('Marqué comme cas complexe');
      fetchNew();
    } catch (e) {
      setMessage(`${(e as Error).message}`);
    }
  };

  const onConfirm = (comment: string, tags: Set<string>) => {
    void handleComplex(comment, tags);
    setOpen(false);
  };

  return (
    <div>
      <BoButton
        label="CAS COMPLEXE"
        backgroundColor={BKG_ORANGE}
        labelColor={BKG_LIGHT}
        onClick={() => setOpen(true)}
        style={STYLE_BUTTON}
      />
      <ComplexModal
        open={open}
        onConfirm={onConfirm}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default Complex;
