import React, { CSSProperties, useCallback } from 'react';
import Visible from 'material-ui/svg-icons/action/visibility';
import Hidden from 'material-ui/svg-icons/action/visibility-off';
import Add from 'material-ui/svg-icons/content/add-box';
import Edit from 'material-ui/svg-icons/editor/mode-edit';

import { PatrolZoneDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';
import useHiddenPatrolZoneIds from 'commons/hooks/useHiddenPatrolZoneIds';
import { Flex } from '@cvfm-front/commons-ui';
import { MapId } from 'commons/services/MapService';

const { _tg } = window.loadTranslations(__filename);

const STYLE_VISIBILITY: CSSProperties = {
  color: '#ddd',
  margin: '0px 8px 0px 0px',
  width: 16,
  height: 16,
  cursor: 'pointer',
  paddingBottom: 2,
};
const STYLE_ACTIONS: CSSProperties = {
  margin: '0px 0px 0px 15px',
  width: 20,
  height: 20,
  cursor: 'pointer',
  paddingBottom: 2,
};
const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  width: '59%',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflow: 'hidden',
  paddingBottom: 2,
};

type Props = {
  patrolZone: PatrolZoneDTO;
};

const PatrolZoneItem = ({ patrolZone }: Props): JSX.Element => {
  const hiddenPatrolZoneIds = useHiddenPatrolZoneIds();
  const hidden = hiddenPatrolZoneIds.some(id => id === patrolZone.patrolZoneId);

  const setHidden = useCallback(
    (value: boolean): void => {
      services.patrolZoneService.setPatrolZoneHidden(
        MapId.PATROL_ZONE,
        patrolZone.patrolZoneId,
        value
      );
    },
    [patrolZone]
  );

  const icon = hidden ? (
    <span title={_tg('action.display')}>
      <Hidden style={STYLE_VISIBILITY} onClick={() => setHidden(false)} />
    </span>
  ) : (
    <span title={_tg('action.hide')}>
      <Visible style={STYLE_VISIBILITY} onClick={() => setHidden(true)} />
    </span>
  );

  return (
    <Flex flexDirection="row">
      {icon}
      <span style={{ ...STYLE_NAME, color: patrolZone.color }}>
        {patrolZone.name}
      </span>
      <span title={_tg('action.modify')}>
        <Add
          style={{
            color: '#ddd',
            ...STYLE_ACTIONS,
          }}
          onClick={() =>
            services.patrolZoneService.addPolygonToPatrolZone(patrolZone)
          }
        />
      </span>
      <span title={_tg('action.modify')}>
        <Edit
          style={{ color: '#ddd', ...STYLE_ACTIONS }}
          onClick={() =>
            services.patrolZoneService.setPatrolZoneToEdit(patrolZone)
          }
        />
      </span>
    </Flex>
  );
};

export default PatrolZoneItem;
