import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Toggle from 'material-ui/Toggle';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/MenuItem';

import { BKG_CYAN, TXT_GREY } from 'theme';
import { computeFps, computeTicket } from 'api/pricing';
import {
  FnmsCityFpsPriceDTO,
  MapZoning,
  ParkingTicketPriceDTO,
  ZoneMap,
} from 'api/pricing/types';
import { signGoogleMapUrl, BASE_GOOGLE_MAPS_URL } from 'api/url-signer';
import { ItemIdName } from 'api/commonTypes';
import { PolygonDTO, VehicleCategory } from '@cvfm-front/tefps-types';
import { AuthorizedVehicleCategory } from 'config/duck';

const { _tg } = window.loadTranslations(__filename);

const DEFAULT_PROFILE_ID = 'NONE';
const SIXTY_SECONDS = 60;

const MAP_HEIGHT = '82%';
const MAP_CONTROLLER_HEIGHT = '18%';

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

const STYLE_INPUT: CSSProperties = {
  ...STYLE_TEXT,
  color: 'white',
  textAlign: 'center',
};

const STYLE_SELECT: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  color: 'white',
};

const STYLE_MAP_CONTROL_PANEL: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: BKG_CYAN,
  width: '100%',
  height: MAP_CONTROLLER_HEIGHT,
  flex: 1,
};

const ChooseZone = ({
  event,
  zones,
  displayPrice,
}: {
  event: any;
  zones: Array<ZoneMap>;
  displayPrice: (
    displayEvent: Record<string, unknown>,
    zone: ZoneMap | null | undefined
  ) => Promise<void>;
}) => (
  <span>
    {_tg('tefps.pricing.pricing.tariffMap.chooseZone')}
    <br />
    {zones.map(zone => (
      <span>
        <input
          value={zone.name}
          type="button"
          style={{ border: `1px solid ${zone.color}` }}
          onClick={() => displayPrice(event, zone)}
        />
        <br />
      </span>
    ))}
  </span>
);

type Position = {
  lat: number;
  lng: number;
};

type TariffMapProps = {
  center: Position;
  zones: MapZoning;
  profiles: Array<ItemIdName>;
  pricingId: string;
  displayVehicleCategory: boolean;
  authorizedVehicleCategories: AuthorizedVehicleCategory[];
};

type TariffMapState = {
  profiles: Array<ItemIdName>;
  selectedProfile: string;
  vehicleCategory: VehicleCategory | null | undefined;
  date: Date;
  time: Date;
  duration: number; // en minutes
  result: ParkingTicketPriceDTO | null | undefined;
  error: string | null | undefined;
  isComputingFps: boolean; // vrai si les tarifs affichés sont ceux des FPS, faux si ce sont ceux des tickets
};

class TariffMap extends React.Component<TariffMapProps, TariffMapState> {
  map: google.maps.Map | null | undefined = null;
  polygons: Array<google.maps.Polygon> = [];
  currentInfoWindow: google.maps.InfoWindow | null | undefined = null;
  refMap: HTMLDivElement | undefined = undefined;

  constructor(props: TariffMapProps) {
    super(props);
    this.state = {
      ...TariffMap.getInitState(props),
      profiles: [
        { id: DEFAULT_PROFILE_ID, name: _tg('field.none') },
        ...props.profiles,
      ],
    };
  }

  componentDidMount(): void {
    // Connect the initMap() function within this class to the global window context,
    // so Google Maps can invoke it
    window.initMap = this.initMap;
    // Asynchronously load the Google Maps script, passing in the callback reference
    void this.loadJS(
      `${BASE_GOOGLE_MAPS_URL}/maps/api/js?callback=initMap&libraries=geometry`
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: TariffMapProps): void {
    const { zones } = nextProps;
    const profiles = [
      { id: DEFAULT_PROFILE_ID, name: _tg('field.none') },
      ...nextProps.profiles,
    ];
    this.setState({ profiles });
    this.removeAllPolygons();
    this.drawPolygons(zones || []);
  }

  static getInitState(props: TariffMapProps): TariffMapState {
    const initState: TariffMapState = {
      selectedProfile: DEFAULT_PROFILE_ID,
      profiles: [],
      date: new Date(),
      time: new Date(),
      duration: 15,
      vehicleCategory: null,
      result: null,
      error: null,
      isComputingFps: false,
    };

    // set vehicleCategory default value
    const { displayVehicleCategory } = props;
    if (displayVehicleCategory) {
      const { authorizedVehicleCategories } = props;
      if (authorizedVehicleCategories.length > 0) {
        initState.vehicleCategory = authorizedVehicleCategories.some(
          authorizedVehicle =>
            VehicleCategory.CAR ===
            VehicleCategory[authorizedVehicle.vehicleCategory]
        )
          ? ('CAR' as VehicleCategory)
          : authorizedVehicleCategories[0].vehicleCategory;
      }
    }

    return initState;
  }

  onChangeDate = (_event: Event | null | undefined, newDate: Date): void => {
    if (newDate) this.setState({ date: newDate });
  };
  onChangeHour = (_event: Event | null | undefined, newTime: Date): void => {
    if (newTime) this.setState({ time: newTime });
  };
  onChangeDuration = (
    _event: React.ChangeEvent<HTMLInputElement>,
    newDuration: string
  ): void => {
    if (newDuration) this.setState({ duration: Number(newDuration) });
  };
  onChangeVehicleCategory = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    id: string
  ): void => {
    this.setState({ vehicleCategory: id as VehicleCategory });
  };
  onChangeProfile = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    id: string
  ): void => {
    if (id) this.setState({ selectedProfile: id });
  };
  onToggleCalculationType = (
    _e: Record<string, any>,
    isInputChecked: boolean
  ): void => this.setState({ isComputingFps: isInputChecked });

  getHittedZones = (event: any): Array<number> => {
    return this.polygons
      .filter((polygon: google.maps.Polygon) =>
        google.maps.geometry.poly.containsLocation(event.latLng, polygon)
      )
      .map((p: any) => p.zones)
      .reduce((a, b) => a.concat(b));
  };

  getPrice = (event: any): void => {
    const zoneIds = this.getHittedZones(event);
    const allZones = this.props.zones.zones;
    // In case several zones are possible, let the user choose
    if (zoneIds.length > 1) {
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close();
      }
      const content = document.createElement('div');
      ReactDOM.render(
        <ChooseZone
          event={event}
          zones={allZones.filter(z =>
            zoneIds.find((id: number) => id.toString() === z.id)
          )}
          displayPrice={this.displayPrice}
        />,
        content
      );
      this.currentInfoWindow = new google.maps.InfoWindow({
        content,
        position: event.latLng,
      });
      if (this.map) this.currentInfoWindow.open(this.map, event.LatLng);
    } else if (zoneIds.length === 1) {
      void this.displayPrice(
        event,
        allZones.find(z => z.id === zoneIds[0].toString())
      );
    }
  };

  createRefMap = (node: HTMLDivElement): void => {
    this.refMap = node;
  };

  initMap = (): void => {
    const { center, zones } = this.props;
    if (!this.refMap) return;
    this.map = new google.maps.Map(this.refMap, {
      zoom: 13,
      center,
      disableDefaultUI: true,
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });
    this.drawPolygons(zones);
  };

  loadJS = async (src: string): Promise<void> => {
    const signedUrl = await signGoogleMapUrl(src);
    const script = window.document.createElement('script');
    script.src = BASE_GOOGLE_MAPS_URL + signedUrl.url;
    script.async = true;
    window.document.body.appendChild(script);
  };

  buildComputingResultPopup = (
    zoneName: string,
    resultTicket: ParkingTicketPriceDTO | null | undefined,
    resultFps: FnmsCityFpsPriceDTO | null | undefined
  ): HTMLDivElement => {
    const content = document.createElement('div');
    const { error } = this.state;
    if (!error) {
      if (resultFps === undefined && !resultTicket) {
        ReactDOM.render(
          <span>
            {zoneName}
            <br />
            <hr />
            {_tg('tefps.pricing.pricing.tariffMap.freeParking')}
          </span>,
          content
        );
      } else if (resultTicket) {
        const start = moment(resultTicket.startDate).format('HH:mm');
        const end = moment(resultTicket.endDate).format('HH:mm');
        ReactDOM.render(
          <span>
            {zoneName}
            <br />
            <hr />
            {_tg('commons.price')} = {String(resultTicket.price / 100)}{' '}
            {_tg('field.payment.currency')}
            <br />
            {_tg('field.date.from')} {start} {_tg('field.date.to')} {end}
          </span>,
          content
        );
      } else if (!resultFps || !resultFps.validityDatetime) {
        const { date, time } = this.state;
        const statementDatetime = _tg('field.date.statementDatetime', {
          date: moment(date).format('DD-MM-YYYY'),
          time: moment(time).format('HH:mm'),
        });
        ReactDOM.render(
          <span>
            {_tg('tefps.pricing.pricing.tariffMap.parkingImpossible', {
              statementDatetime,
            })}
          </span>,
          content
        );
      } else if (resultFps) {
        const validityDatetime = moment(resultFps.validityDatetime).format(
          'DD-MM-YYYY à HH:mm'
        );
        const finePrice = String(resultFps.finePrice / 100);
        const reducedFinePrice = resultFps.reducedFinePrice
          ? `${_tg('commons.priceReduction')} = ${resultFps.reducedFinePrice /
              100} ${_tg('field.payment.currency')}`
          : '';
        ReactDOM.render(
          <span>
            {zoneName} <br /> <hr />
            {_tg('commons.price')} = {finePrice} {_tg('field.payment.currency')}
            <br />
            {reducedFinePrice}
            <br />
            {_tg('field.date.validUntil')}
            {validityDatetime}
          </span>,
          content
        );
      }
    } else if (
      error === 'Requested duration is beyond maximum parking duration'
    ) {
      content.innerHTML = _tg(
        'tefps.pricing.pricing.tariffMap.requestedDurationTooLong'
      );
    } else {
      content.innerHTML = _tg(
        'tefps.pricing.pricing.tariffMap.priceComputationError'
      );
    }

    return content;
  };

  displayPrice = async (
    event: any,
    zone: ZoneMap | null | undefined
  ): Promise<void> => {
    if (!zone) return;
    const {
      selectedProfile,
      duration,
      time,
      vehicleCategory,
      isComputingFps,
    } = this.state;
    let date = new Date(this.state.date.getTime());
    if (date && time) {
      date.setHours(time.getUTCHours());
      date.setMinutes(time.getMinutes());
    } else {
      date = new Date();
    }

    const datetime = `${
      moment(date)
        .format()
        .split('+')[0]
    }Z`;
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
    try {
      if (isComputingFps) {
        const result = await computeFps(
          this.props.pricingId,
          zone.id,
          selectedProfile,
          vehicleCategory,
          datetime
        );
        this.currentInfoWindow = new google.maps.InfoWindow({
          content: this.buildComputingResultPopup(zone.name, undefined, result),
          position: event.latLng,
        });
      } else {
        const result = await computeTicket(
          this.props.pricingId,
          zone.id,
          selectedProfile,
          vehicleCategory,
          duration * SIXTY_SECONDS,
          datetime
        );
        this.currentInfoWindow = new google.maps.InfoWindow({
          content: this.buildComputingResultPopup(zone.name, result, undefined),
          position: event.latLng,
        });
      }
    } catch (error) {
      const errorMessage = error.json
        ? error.json.message
        : _tg('tefps.pricing.pricing.errors.unknownError');
      this.setState({ error: errorMessage });
      this.currentInfoWindow = new google.maps.InfoWindow({
        content: this.buildComputingResultPopup(zone.name, null, null),
        position: event.latLng,
      });
    }
    if (this.map) this.currentInfoWindow.open(this.map, event.LatLng);
    this.setState({ error: null });
  };

  highlightPolygon = (id: string): void => {
    const polygon = this.polygons.find(p => p.id === id);
    if (polygon) polygon.zIndex = this.polygons.length + 1;
  };

  hideZone = (zoneId: string): void => {
    this.polygons
      .filter(p => p.zones.includes(zoneId))
      .forEach(p => {
        p.hidden = true;
        p.setEditable(false);
        p.setVisible(false);
      });
  };

  showZone = (zoneId: string): void => {
    this.polygons
      .filter(p => p.zones.includes(zoneId))
      .forEach(p => {
        p.hidden = false;
        p.setVisible(true);
      });
  };

  drawPolygon = (
    data: PolygonDTO,
    color: string,
    zoneIds: Array<string>,
    zoneName: string,
    zIndex: number
  ): void => {
    // Polygon Coordinates
    if (data && data.points) {
      const coords = data.points.map(p => new google.maps.LatLng(p.lat, p.lon));
      // Styling & Controls
      const polygon = new google.maps.Polygon({
        id: data.id,
        name: data.name,
        zones: zoneIds,
        zoneName,
        paths: coords,
        draggable: false,
        editable: false,
        strokeColor: color,
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.25,
        zIndex,
      });
      if (this.map) polygon.setMap(this.map);
      this.polygons.push(polygon);

      // Add action onClick on the polygon
      google.maps.event.addListener(polygon, 'click', (event: unknown) =>
        this.getPrice(event)
      );
    }
  };

  drawPolygons = (zones: MapZoning): void => {
    zones.polygons.forEach((polygon, i) => {
      const containingZones = zones.zones.filter(z =>
        polygon.zoneIds.includes(z.id)
      );
      if (
        containingZones.length > 0 &&
        containingZones[0].color !== undefined
      ) {
        const belongsToMultipleZones = containingZones.length > 1;
        const color = belongsToMultipleZones
          ? '#000'
          : containingZones[0].color;
        const zoneName = belongsToMultipleZones
          ? `${containingZones.map(z => z.name).join(', ')}`
          : `${containingZones[0].name}`;
        this.drawPolygon(polygon, color, polygon.zoneIds, zoneName, i);
      }
    });
  };

  removeAllPolygons(): void {
    this.polygons.map((p: google.maps.Polygon) => p.setMap(null));
    this.polygons = [];
  }

  render(): JSX.Element {
    const {
      date,
      selectedProfile,
      duration,
      time,
      profiles,
      vehicleCategory,
      isComputingFps,
    } = this.state;
    const { displayVehicleCategory, authorizedVehicleCategories } = this.props;

    return (
      <span>
        <div
          ref={this.createRefMap}
          style={{
            height: MAP_HEIGHT,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        />
        <div style={STYLE_MAP_CONTROL_PANEL}>
          <div
            style={{
              ...STYLE_TEXT,
              color: isComputingFps ? '#ffcdcd' : '#cdffcd',
            }}
          >
            {isComputingFps ? (
              <span style={{ marginLeft: 9 }}>{_tg('commons.fps')}</span>
            ) : (
              <span style={{ marginLeft: 12 }}>{_tg('tefps.tv')}</span>
            )}
            <Toggle
              toggled={isComputingFps}
              onToggle={this.onToggleCalculationType}
              thumbStyle={{ backgroundColor: 'green' }}
              trackStyle={{ backgroundColor: '#9dff9d' }}
              thumbSwitchedStyle={{ backgroundColor: 'red' }}
              trackSwitchedStyle={{ backgroundColor: '#ff9d9d' }}
              style={{ width: 0, marginTop: 6 }}
            />
          </div>
          <DatePicker
            name="date"
            okLabel={_tg('tefps.filters.misc.OK')}
            cancelLabel={_tg('action.cancel')}
            locale="fr"
            onChange={this.onChangeDate}
            value={date}
            textFieldStyle={{ ...STYLE_TEXT, width: 86 }}
            inputStyle={STYLE_INPUT}
            style={{ margin: 10 }}
          />
          <TimePicker
            name="hour"
            okLabel={_tg('tefps.filters.misc.OK')}
            cancelLabel={_tg('action.cancel')}
            format="24hr"
            onChange={this.onChangeHour}
            value={time}
            textFieldStyle={{ ...STYLE_TEXT, width: 58 }}
            inputStyle={{ ...STYLE_INPUT }}
            style={{ marginRight: 10 }}
          />
          <TextField
            floatingLabelText={_tg('field.date.duration')}
            floatingLabelStyle={{ color: TXT_GREY }}
            disabled={isComputingFps}
            name="duration"
            title={_tg('field.date.in_minutes')}
            value={duration}
            onChange={this.onChangeDuration}
            type="number"
            style={{ marginRight: 10, marginBottom: 24, width: 50 }}
            hintStyle={STYLE_SELECT}
            inputStyle={{ ...STYLE_SELECT, textAlign: 'center' }}
          />
          {displayVehicleCategory && (
            <SelectField
              floatingLabelText={_tg('tefps.filters.fps.vehicleCategory')}
              floatingLabelStyle={{ color: TXT_GREY, lineHeight: '17px' }}
              value={vehicleCategory}
              onChange={this.onChangeVehicleCategory}
              labelStyle={STYLE_SELECT}
              style={{
                marginBottom: 24,
                marginRight: 10,
                width: 140,
              }}
            >
              {authorizedVehicleCategories.map(vehicle => (
                <MenuItem
                  key={vehicle.vehicleCategory}
                  value={vehicle.vehicleCategory}
                  primaryText={_tg(
                    `field.vehicleType.${vehicle.vehicleCategory}`
                  )}
                />
              ))}
            </SelectField>
          )}
          <SelectField
            floatingLabelText={_tg(
              'tefps.pricing.pricing.tariffMap.userProfile'
            )}
            floatingLabelStyle={{ color: TXT_GREY }}
            value={selectedProfile}
            onChange={this.onChangeProfile}
            labelStyle={STYLE_SELECT}
            style={{ marginBottom: 24, marginRight: 10, width: 140 }}
          >
            {profiles.map(userProfile => (
              <MenuItem
                key={userProfile.id}
                value={userProfile.id}
                primaryText={userProfile.name}
              />
            ))}
          </SelectField>
        </div>
      </span>
    );
  }
}

export default TariffMap;
