import { AlertNature, AlertStatus } from 'api/planner/types';

const { _t } = window.loadTranslations(__filename);

export function alertNatureToLocale(nature: AlertNature): string {
  switch (nature) {
    case 'PATH':
      return _t('element.PATH');
    case 'INACTIVITY':
      return _t('element.INACTIVITY');
    default:
      return nature;
  }
}

export function alertStatusToLocale(status: AlertStatus): string {
  switch (status) {
    case 'OPENED':
      return _t('element.OPENED');
    case 'CLOSED':
      return _t('element.CLOSED');
    default:
      return status;
  }
}
