import React from 'react';
import { Checkbox, TextField } from 'material-ui';

import { PricingPolicyDTO } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  policy: PricingPolicyDTO;
  disabled: boolean;
  handleCheckWaitingPeriod: (
    _e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    waitingPeriodEnabled: boolean
  ) => void;
  handleWaitingPeriodChange: (
    _e: React.FormEvent<Element>,
    value: string
  ) => void;
};

const WaitingPeriodField = ({
  policy,
  disabled,
  handleCheckWaitingPeriod,
  handleWaitingPeriodChange,
}: Props): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        width: '40vw',
        justifyContent: 'flex-start',
      }}
    >
      <Checkbox
        label={_tg('tefps.pricing.pricing.waitingPeriod.addWaitingPeriod')}
        checked={policy.waitingPeriodEnabled}
        onCheck={handleCheckWaitingPeriod}
        style={{ marginTop: 20, width: '50%' }}
        disabled={disabled}
      />
      {policy.waitingPeriodEnabled && (
        <TextField
          floatingLabelText={_tg(
            'tefps.pricing.pricing.waitingPeriod.waitingPeriodDuration'
          )}
          value={policy.waitingPeriod}
          onChange={handleWaitingPeriodChange}
          floatingLabelStyle={{ width: '50vw' }}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default WaitingPeriodField;
