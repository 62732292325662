import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import {
  EsCityParkingSpaceDTO,
  EsCityParkingSpaceVersionDTO,
  EsCityParkingSpaceVersionDTOFactory,
  ParkingOrientation,
  ParkingSpaceRegime,
} from '@cvfm-front/tefps-types';
import {
  FormFieldTypeWithTitleV2,
  SelectOptionTypeV2,
} from '@cvfm-front/commons-types';
import { getLocale } from 'commons/Utils/localeUtils';

import { parkingRegimeOptions } from './ParkingRegimeOptions';
import { parkingTypeOptions } from './ParkingTypeOptions';

const { _tg } = window.loadTranslations(__filename);

function parkingSpaceVersionToString(version: EsCityParkingSpaceVersionDTO) {
  const { parkingRegime } = version;
  const { parkingType } = version;
  const hasEndDate = !!version.endDatetime;
  const startDate = Intl.DateTimeFormat().format(
    Date.parse(version.startDatetime)
  );
  const endDate = version.endDatetime
    ? Intl.DateTimeFormat().format(Date.parse(version.endDatetime))
    : '';
  const dateWithStartAndEnd = _tg('fromDateTo', {
    from: startDate,
    to: endDate,
  });
  const dateWithOnlyStartDate = _tg('fromDate', { from: startDate });
  const displayedDate = hasEndDate
    ? dateWithStartAndEnd
    : dateWithOnlyStartDate;
  return `${_tg(`ParkingOrientation.${parkingType}`)}, ${_tg(
    `ParkingSpaceRegime.${parkingRegime}`
  )}, ${displayedDate}`;
}

export const useEditVersionsForm = (
  parking: EsCityParkingSpaceDTO,
  setParkingSpace: (
    newParkingSpace: EsCityParkingSpaceDTO
  ) => void | Promise<void>
): FormFieldTypeWithTitleV2[] => {
  const [versionIndex, setVersionIndex] = useState(0);
  const versionsSelectOptions: SelectOptionTypeV2[] = parking.versions.map(
    (version, index) => {
      return {
        label: `${index + 1}: ${parkingSpaceVersionToString(version)}`,
        value: index.toString(),
      };
    }
  );
  const selectedVersion = parking.versions[versionIndex] ?? parking.versions[0];
  const hasEndDate = selectedVersion.endDatetime !== null;

  useEffect(() => {
    if (parking.versions.length < versionIndex + 1) {
      setVersionIndex(0);
    }
  }, [parking]);

  const versionEditionFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: _tg('editVersions'),
    },
    {
      type: 'button',
      label: _tg('addVersion'),
      onClick: () => {
        const newParkingSpace = cloneDeep(parking);
        const newVersion = EsCityParkingSpaceVersionDTOFactory();
        newParkingSpace.versions.push(newVersion);
        setVersionIndex(newParkingSpace.versions.length - 1);
        setParkingSpace(newParkingSpace);
      },
    },
    {
      type: 'select',
      label: _tg('version'),
      value: versionIndex.toString(),
      selectOptions: versionsSelectOptions,
      onValueChange: v => {
        setVersionIndex(parseInt(v, 10));
      },
      required: true,
    },
    {
      type: 'select',
      label: _tg('type'),
      value: selectedVersion.parkingType,
      selectOptions: parkingTypeOptions,
      onValueChange: v => {
        const newParkingSpace = cloneDeep(parking);
        newParkingSpace.versions[
          versionIndex
        ].parkingType = v as ParkingOrientation;
        setParkingSpace(newParkingSpace);
      },
      required: true,
    },
    {
      type: 'select',
      label: _tg('regime'),
      value: selectedVersion.parkingRegime,
      selectOptions: parkingRegimeOptions,
      onValueChange: v => {
        const newParkingSpace = cloneDeep(parking);
        newParkingSpace.versions[
          versionIndex
        ].parkingRegime = v as ParkingSpaceRegime;
        setParkingSpace(newParkingSpace);
      },
      required: true,
    },
    {
      type: 'date',
      label: _tg('startDate'),
      language: getLocale(),
      value: selectedVersion.startDatetime,
      onValueChange: v => {
        const newParkingSpace = cloneDeep(parking);
        newParkingSpace.versions[versionIndex].startDatetime = v;
        setParkingSpace(newParkingSpace);
      },
      required: true,
    },
    {
      type: 'checkbox',
      label: _tg('addEndDate'),
      value: hasEndDate,
      onValueChange: v => {
        const newParkingSpace = cloneDeep(parking);
        if (v === false) {
          newParkingSpace.versions[versionIndex].endDatetime = null;
        } else {
          newParkingSpace.versions[
            versionIndex
          ].endDatetime = new Date().toISOString();
        }
        setParkingSpace(newParkingSpace);
      },
    },
    {
      type: 'date',
      label: _tg('endDate'),
      language: getLocale(),
      value: selectedVersion.endDatetime ?? '',
      disabled: !hasEndDate,
      onValueChange: v => {
        const newParkingSpace = cloneDeep(parking);
        newParkingSpace.versions[versionIndex].endDatetime = v;
        setParkingSpace(newParkingSpace);
      },
    },
    {
      type: 'checkbox',
      label: _tg('ignoreThisVersion'),
      value: selectedVersion.deprecatedDatetime !== null,
      onValueChange: v => {
        const newParkingSpace = cloneDeep(parking);
        if (v === false) {
          newParkingSpace.versions[versionIndex].deprecatedDatetime = null;
        } else {
          newParkingSpace.versions[
            versionIndex
          ].deprecatedDatetime = new Date().toISOString();
        }
        setParkingSpace(newParkingSpace);
      },
    },
  ];

  return versionEditionFields;
};
