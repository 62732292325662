import React, { useEffect, useState } from 'react';
import { Dialog } from 'material-ui';
import EditIcon from 'material-ui/svg-icons/image/edit';
import FlatButton from 'material-ui/FlatButton';

import { isPlateValid } from 'commons/Utils/plateUtils';
import { LapiReviewDTO } from 'api/lapiReview/types';

import './Plate.css';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

const { _t } = window.loadTranslations(__filename);

type Props = {
  plateUrl: string;
  lapiReview: LapiReviewDTO;
  changePlate: (newValue: string) => Promise<void>;
  lockTreatment: boolean;
  muiTheme?: MuiTheme;
};

function Plate({
  plateUrl,
  lapiReview,
  changePlate,
  lockTreatment,
  muiTheme,
}: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [plate, setPlate] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [cursor, setCursor] = useState<number>(0);

  useEffect(() => {
    if (!open) {
      setPlate(lapiReview.licensePlate.plate.toUpperCase());
    }
  });

  const handleCancel = () => {
    setPlate(lapiReview.licensePlate.plate.toUpperCase());
    setOpen(false);
  };

  const handleConfirm = () => {
    void changePlate(plate.toUpperCase());
    setOpen(false);
  };

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(target.selectionStart ?? 0);
    const upperCasedPlate = target.value ? target.value.toUpperCase() : '';
    setPlate(upperCasedPlate);
    setValid(isPlateValid(upperCasedPlate));
  };

  const openModal = () => {
    setOpen(true);
    setValid(isPlateValid(plate));
  };

  const actions = [
    <FlatButton
      label={_t('plate.action.cancel')}
      secondary
      onClick={handleCancel}
    />,
    <FlatButton
      label={_t('plate.action.confirm')}
      keyboardFocused
      primary={valid}
      disabled={!valid}
      onClick={handleConfirm}
    />,
  ];

  return (
    <>
      <div className="lapi-plate__container">
        <img src={plateUrl} alt="plate" className="lapi-plate__image" />
        <span
          className="lapi-plate"
          style={{ backgroundColor: muiTheme?.palette?.canvasColor }}
        >
          <span>{lapiReview.licensePlate.plate.toUpperCase()}</span>
          {!lockTreatment && (
            <EditIcon onClick={openModal} style={{ marginLeft: 20 }} />
          )}
        </span>
      </div>

      <Dialog title={_t('plate.title')} actions={actions} modal open={open}>
        <div className="lapi-plate__dialog-inner">
          <img src={plateUrl} alt="plate" className="lapi-plate__image" />
          <div className="lapi-plate__text-field">
            <label
              className="lapi-plate__text-field-label"
              htmlFor="plate-input"
            >
              {_t('plate.label')}
            </label>
            <input
              type="text"
              id="plate-input"
              className="lapi-plate__text-field-input"
              style={{ color: muiTheme?.textField?.textColor }}
              ref={input => {
                if (input) {
                  input.selectionStart = cursor;
                  input.selectionEnd = cursor;
                }
              }}
              onChange={onChange}
              value={plate}
            />
            <div>
              <hr className="lapi-plate__text-field-hr" />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default muiThemeable()(Plate);
