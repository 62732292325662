import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import { FlatButton } from 'material-ui';
import DisconnectIcon from 'material-ui/svg-icons/action/power-settings-new';
import { connect } from 'react-redux';

import { logoutAction } from 'api/duck';
import { BKG_BLUE } from 'theme';

const COMMON_LABEL_STYLE: CSSProperties = {
  textTransform: 'none',
  color: BKG_BLUE,
};

const LABEL_STYLE: { [k: string]: CSSProperties } = {
  s: {
    ...COMMON_LABEL_STYLE,
    fontSize: '12px',
  },
  m: {
    ...COMMON_LABEL_STYLE,
    fontSize: '18px',
  },
};

const ICON_STYLE: { [k: string]: CSSProperties } = {
  s: {
    height: 16,
    width: 16,
    fill: BKG_BLUE,
  },
  m: {
    height: 28,
    width: 28,
    fill: BKG_BLUE,
  },
};

type LogoutProps = {
  dispatch: (arg0: any) => any;
  size?: string;
};

const Logout = ({ dispatch, size = 's' }: LogoutProps) => {
  const history = useHistory();

  const logOut = () => {
    dispatch(logoutAction());
    history.push('/');
  };

  return (
    <FlatButton
      labelStyle={LABEL_STYLE[size]}
      labelPosition="before"
      label="Déconnexion"
      onClick={logOut}
      icon={<DisconnectIcon style={ICON_STYLE[size]} />}
    />
  );
};

export default connect()(Logout);
