import * as React from 'react';

import { TXT_ERROR_RED } from 'theme';
import RemovableCriteria from 'commons/RemovableCriteria';
import { DateIntervalCriteria } from '@cvfm-front/tefps-types/build/dateTime/DateIntervalCriteria';

import { STYLE_CRITERIA_CONTAINER } from '../theme';

import DayMonthPicker from './DayMonthPicker';

const { _tg } = window.loadTranslations(__filename);

type DateRangeProps = {
  period: DateIntervalCriteria;
  idx: number;
  onChange: (field: string, day: number, month: number, id: number) => void;
  remove?: (event: any) => void;
  disabled: boolean;
  style?: Record<string, any>;
};

class DayMonthRangePicker extends React.Component<DateRangeProps> {
  onChangeStart = (day: number, month: number): void =>
    this.change('start', day, month);

  onChangeEnd = (day: number, month: number): void =>
    this.change('end', day, month);

  change = (field: string, day: number, month: number): void => {
    const { disabled, onChange, idx } = this.props;
    if (!disabled) {
      onChange(field, day, month, idx);
    }
  };

  render(): JSX.Element {
    const {
      remove,
      idx,
      disabled,
      period: { startDay, startMonth, endDay, endMonth, error },
    } = this.props;

    const content = (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px',
          }}
        >
          <DayMonthPicker
            dayOfMonth={startDay}
            month={startMonth}
            onChange={this.onChangeStart}
            disabled={disabled}
          />
          <div style={{ margin: '0 4px' }}>
            {_tg('field.date.toAlternative')}
          </div>
          <DayMonthPicker
            dayOfMonth={endDay}
            month={endMonth}
            onChange={this.onChangeEnd}
            disabled={disabled}
          />
        </div>
        <div style={{ color: TXT_ERROR_RED, fontSize: 12 }}>{error}</div>
      </div>
    );

    if (remove && !disabled) {
      return (
        <div
          style={{ ...STYLE_CRITERIA_CONTAINER, padding: 4, display: 'block' }}
        >
          <RemovableCriteria deleteFunc={remove} index={idx}>
            {content}
          </RemovableCriteria>
        </div>
      );
    }

    return (
      <div style={{ ...STYLE_CRITERIA_CONTAINER, padding: 4 }}>{content}</div>
    );
  }
}

export default DayMonthRangePicker;
