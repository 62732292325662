import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  BillEsPrivateDTO,
  BillEsSearchQueryDTO,
  BillEsSearchQueryPagedDTO,
  BillEsSearchResultDTO,
  SortOrder,
} from '@cvfm-front/tefps-types';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import {
  getExport,
  searchEsBillPaged,
} from 'api/cvfm-parking/BillEsPrivateApi';

import './BillTablePage.css';

import Exporter from '../../../commons/Exporter';

import BillTable from './BillTable';
import BillTableSidebar from './BillTableSidebar';
import { BILL_EXPORT_COLS } from './BillExporterUtil';

function BillTablePage(): JSX.Element {
  const defaultQuery = {
    creationDatetime: {
      from: moment()
        .startOf('day')
        .toDate(),
    },
  } as BillEsSearchQueryDTO;

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState<BillEsSearchQueryDTO>({
    ...defaultQuery,
  });
  const searchMaxRecords = 50;
  const [searchSortOrder, setSearchSortOrder] = useState<SortOrder>(
    SortOrder.DESC
  );
  const [searchSortIndex, setSearchSortIndex] = useState<number>(3);
  const [searchSortField, setSearchSortField] = useState<string>(
    'creationDatetime'
  );
  const [searchResults, setSearchResults] = useState<BillEsSearchResultDTO>();
  const [bills, setBills] = useState<Array<BillEsPrivateDTO>>([]);

  async function loadBills(searchPage: number): Promise<BillEsSearchResultDTO> {
    // Build current search from state
    const search = {
      page: searchPage,
      maxRecords: searchMaxRecords,
      query: searchQuery,
      sort: {
        sortOrder: searchSortOrder,
        sortField: searchSortField,
      },
    } as BillEsSearchQueryPagedDTO;
    return new Promise((resolve, reject) => {
      searchEsBillPaged(search)
        .then(result => resolve(result))
        .catch(err => {
          reject(err);
        });
    });
  }

  async function moreBills(searchPage: number): Promise<void> {
    return new Promise(resolve => {
      loadBills(searchPage)
        .then(searchResult => {
          // Add results
          setSearchResults(searchResult);
          setBills([...bills, ...searchResult.results]);
        })
        .catch(err => {
          setError(err as Error);
        })
        .finally(() => {
          resolve();
        });
    });
  }

  async function filterBills(): Promise<void> {
    return new Promise(resolve => {
      setLoading(true);
      loadBills(0)
        .then(searchResult => {
          // Store results
          setBills(searchResult.results);
          setSearchResults(searchResult);
        })
        .catch(err => {
          setError(err as Error);
        })
        .finally(() => {
          setLoading(false);
          resolve();
        });
    });
  }

  function onTableSort(index: number, ascending: boolean, field: string) {
    const sortOrder = ascending ? SortOrder.ASC : SortOrder.DESC;
    setSearchSortIndex(index);
    setSearchSortOrder(sortOrder);
    setSearchSortField(field);
  }

  function onTableRange(fromIndex: number): Promise<void> {
    const nextPage = Math.floor(fromIndex / searchMaxRecords);
    return moreBills(nextPage);
  }

  function onChangeFilters(changedFilters: BillEsSearchQueryDTO) {
    setSearchQuery(changedFilters);
  }

  useEffect(() => {
    void filterBills();
  }, [searchQuery, searchSortField, searchSortOrder]);

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock message="Error: " error={error} />
      </FlexCenter>
    );
  }

  return (
    <div className="bill-page_container">
      <BillTableSidebar
        initialFilters={defaultQuery}
        onChangeFilters={onChangeFilters}
        totalHits={searchResults?.totalHits || 0}
      />
      <div className="bill-page_content">
        <div className="bill-page_table">
          <ListWrapper
            style={{
              padding: '32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '90%',
            }}
          >
            <ListBody loading={loading}>
              <BillTable
                pagedBills={bills}
                totalBills={searchResults?.totalHits || 0}
                onSort={onTableSort}
                sortIndex={searchSortIndex}
                sortAscending={searchSortOrder === SortOrder.ASC}
                onRange={onTableRange}
              />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              <Exporter
                disabled={!bills || bills.length === 0}
                columns={BILL_EXPORT_COLS}
                type="BILL"
                filters={searchQuery}
                fileExport={getExport}
              />
            </ListBottom>
          </ListWrapper>
        </div>
      </div>
    </div>
  );
}

export default BillTablePage;
