import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import _cloneDeep from 'lodash.clonedeep';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import {
  validateMandatoryArrayInput,
  validateMandatoryInput,
  validateMandatoryNumberInput,
} from 'commons/Validators';
import { TextFieldCustom } from 'commons/FormComponent/Fields';
import FormComponent from 'commons/FormComponent';
import { RoutesGroupDTO, RouteTemplateDTO } from 'api/planner/types';
import { BKG_LIGHT_BLUE, STYLE_ERROR_WRAPPER } from 'theme';

import { durationToTimeFormat, timeToDurationFormat } from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  marginTop: 14,
  color: 'black',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

const STYLE_INPUT: CSSProperties = { margin: '0 10px' };

const ID = 'id';
const NAME = 'name';
const MAX_NB_AGENTS = 'maxNbAgents';
const DURATION = 'duration';
const DESCRIPTION = 'description';

type Props = {
  editType: string;
  routeTemplate: RouteTemplateDTO | null | undefined;
  routesGroups: Array<RoutesGroupDTO>;
  close: () => any;
  saveRouteTemplate: (body: unknown, groupIds: string[]) => void;
};

type State = {
  error: string | null | undefined;
  routeTemplate: {
    id: string;
    name: string;
    maxNbAgents: number;
    description: string;
    duration: string;
  };
  groupIds: Array<string>;
  errors: {
    [key: string]: string | null | undefined;
  };
  status: string;
};

const INITIAL_STATE = {
  error: null,
  groupIds: [],
  errors: {},
};

const computeFromProps = (props: Props): State => {
  const { routeTemplate, routesGroups } = props;
  if (!routeTemplate) {
    return {
      ...INITIAL_STATE,
      status: 'ACTIVATED',
      // eslint-disable-next-line
      // @ts-ignore
      routeTemplate: { id: Date.now().toString() },
    };
  }
  const routeTemplateToEdit = _cloneDeep(routeTemplate);
  const groupIds = routesGroups
    .filter(g => g.routeTemplateIds.includes(routeTemplateToEdit.id))
    .map(g => g.id);
  routeTemplateToEdit.duration = durationToTimeFormat(
    routeTemplateToEdit.duration
  );
  return {
    error: null,
    routeTemplate: routeTemplateToEdit,
    groupIds,
    status: routeTemplateToEdit.status,
    errors: {},
  };
};

class AddRouteTemplate extends React.Component<Props, State> {
  routeTemplateEditionForm: any = null;

  constructor(props: Props) {
    super(props);
    this.state = computeFromProps(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    this.setState(computeFromProps(nextProps));
  }

  onClose = (): void => {
    const { close } = this.props;
    close();
    this.setState({ ...INITIAL_STATE });
  };

  onClickValidate = (): void => {
    const { saveRouteTemplate } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { routeTemplateEditionForm } = this;
    const { groupIds, status } = this.state;

    if (!routeTemplateEditionForm) {
      return;
    }

    const body = {
      ...routeTemplateEditionForm.getFormEntries(), // eslint-disable-line
      status,
    } as RouteTemplateDTO;
    if (body.duration !== undefined) {
      body.duration = timeToDurationFormat(body.duration);
    }

    const errors = {
      name: validateMandatoryInput(body.name),
      id: validateMandatoryInput(body.id),
      maxNbAgents: validateMandatoryNumberInput(body.maxNbAgents, 1),
      groupIds: validateMandatoryArrayInput(groupIds),
      duration: validateMandatoryInput(body.duration),
    };

    if (!Object.values(errors).some(v => !!v)) {
      try {
        saveRouteTemplate(body, groupIds);
        this.setState({ error: null, errors: {} });
      } catch (error) {
        const msg = (error as Error).message;
        this.setState({
          error: _tg('feedback.error.msg', { error: msg }),
        });
      }
    }
    this.setState({ errors });
  };

  onChangeGroup = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    groupIds: Array<string>
  ): void => this.setState({ groupIds });

  onCheckStatus = (): void =>
    this.setState(prevState => {
      return {
        status: prevState.status === 'ACTIVATED' ? 'DESACTIVATED' : 'ACTIVATED',
      };
    });

  attachRef = (node: unknown): void => {
    this.routeTemplateEditionForm = node;
  };

  render(): React.ReactNode {
    const { editType, routesGroups } = this.props;
    const { groupIds, error, errors, routeTemplate, status } = this.state;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label={editType === 'EDIT' ? _tg('action.edit') : _tg('action.add')}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ];

    return (
      <Dialog
        actions={actions}
        title={
          editType === 'ADD' ? _t('action.addRoute') : _t('action.modifyRoute')
        }
        open={editType === 'ADD' || editType === 'EDIT'}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
      >
        <FormComponent ref={this.attachRef}>
          <div style={STYLE_CONTENT_WRAPPER}>
            <TextFieldCustom
              name={ID}
              style={STYLE_INPUT}
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              defaultValue={routeTemplate.id}
              disabled={editType === 'EDIT'}
              hint={_t('element.routeId')}
              error={errors.id}
            />
            <TextFieldCustom
              name={NAME}
              style={STYLE_INPUT}
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              defaultValue={routeTemplate.name}
              hint={_t('element.routeName')}
              error={errors.name}
            />
            <TextFieldCustom
              name={MAX_NB_AGENTS}
              style={STYLE_INPUT}
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              defaultValue={routeTemplate.maxNbAgents}
              hint={_t('element.maxAgents')}
              error={errors.maxNbAgents}
              type="number"
            />
          </div>
          <div style={STYLE_CONTENT_WRAPPER}>
            <TextFieldCustom
              name={DURATION}
              style={STYLE_INPUT}
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              floatingLabelFixed
              defaultValue={routeTemplate.duration}
              hint={_t('element.estimatedDuration')}
              error={errors.duration}
              type="time"
            />
            <SelectField
              value={groupIds}
              style={{ width: '70%', margin: '0 10px' }}
              floatingLabelText={_t('element.routeGroup')}
              floatingLabelStyle={{ color: '#aaa' }}
              errorText={errors.groupIds}
              multiple
              onChange={this.onChangeGroup}
            >
              {routesGroups.map(({ id, name }) => (
                <MenuItem
                  key={id}
                  value={id}
                  primaryText={name || id}
                  insetChildren
                />
              ))}
            </SelectField>
          </div>
          <div style={STYLE_CONTENT_WRAPPER}>
            <TextFieldCustom
              name={DESCRIPTION}
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              defaultValue={routeTemplate.description}
              hint={_tg('field.description')}
              multiLine
              fullWidth
              rowsMax={3}
              rows={3}
              error={errors.description}
              margin="dense"
            />
          </div>
          <div style={STYLE_CONTENT_WRAPPER}>
            <Checkbox
              label={_t('element.activeRoute')}
              checked={status === 'ACTIVATED'}
              onCheck={this.onCheckStatus}
            />
          </div>
        </FormComponent>
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default AddRouteTemplate;
