import React, { useEffect, useRef, useState } from 'react';

type Rect = {
  startX?: number;
  startY?: number;
  w?: number;
  h?: number;
};

type BlurToolEditorProps = {
  contextImageCopy: Blob;
  setContextImageCopy: (arg0: Blob) => void;
};

const BlurToolEditor = ({
  contextImageCopy,
  setContextImageCopy,
}: BlurToolEditorProps) => {
  const [drawing, setDrawing] = useState<boolean>(false);
  const [rect, setRect] = useState<Rect>({});
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null | undefined>(
    null
  );
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null | undefined>(
    null
  );
  const [img, setImg] = useState<HTMLImageElement | null | undefined>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const offsetLeft = window.innerWidth * 0.1;
  // didn't find a way to remove material-ui dialog paddingTop of 16px
  const offsetTop = window.innerHeight * 0.1 - 16;
  const width = window.innerWidth * 0.8;
  const height = window.innerHeight * 0.8;

  const effectUpdate = () => {
    const image = new Image();
    image.src = URL.createObjectURL(contextImageCopy);
    image.onload = () => {
      if (canvasRef.current) {
        const context = canvasRef.current.getContext('2d');
        if (!context) return;
        context.drawImage(image, 0, 0, width, height);
        setImg(image);
      }
    };
  };

  useEffect(() => {
    setCanvas(canvasRef.current);
    if (canvasRef.current) {
      setCtx(canvasRef.current.getContext('2d'));
    }
    effectUpdate();
  }, []);

  useEffect(() => {
    effectUpdate();
    setRect({});
  }, [contextImageCopy]);

  const mouseDown = (e: React.MouseEvent<any>) => {
    setRect({
      ...rect,
      startX: e.pageX - offsetLeft,
      startY: e.pageY - offsetTop,
    });
    setDrawing(true);
  };

  const mouseUp = () => {
    setDrawing(false);
    if (canvas) {
      canvas.toBlob(blob => {
        if (blob) {
          setContextImageCopy(blob);
        }
      }, 'image/jpeg');
    }
  };

  const mouseMove = (e: React.MouseEvent<any>) => {
    const { startX, startY } = rect;
    if (drawing && startX && startY && ctx && canvas && img) {
      ctx.filter = 'blur(10px)';
      ctx.drawImage(img, 0, 0, width, height);
      // On met a jour la largeur et la longueur
      const w = e.pageX - offsetLeft - startX;
      const h = e.pageY - offsetTop - startY;
      setRect({
        ...rect,
        w,
        h,
      });
      let rectBlur = null;
      if (w && h) {
        // On récupère le rectangle flouté
        rectBlur = ctx.getImageData(startX, startY, w, h);
      }

      ctx.filter = 'none';
      ctx.drawImage(img, 0, 0, width, height);

      if (w && h) {
        // calcul des coordonées de l'insertion du rectangle en fonction du sens du dessin
        const dx = w < 0 ? startX + w : startX;
        const dy = h < 0 ? startY + h : startY;
        if (rectBlur) {
          //  Ajout du rectangle flouté à l'image
          ctx.putImageData(rectBlur, dx, dy);
        }
      }
    }
  };

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      onMouseDown={mouseDown}
      onMouseMove={mouseMove}
      onMouseUp={mouseUp}
    />
  );
};

export default BlurToolEditor;
