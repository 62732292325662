import { MAX_RECORDS } from 'commons/const';

import { AlertFilterActions, AlertFilterStore } from './types';

export const INITIAL_STORE: AlertFilterStore = {
  filters: {
    alertStartDate: {
      from: undefined,
      to: undefined,
    },
    alertEndDate: {
      from: undefined,
      to: undefined,
    },
    alertNatures: new Set(),
    alertStatuses: new Set(),
    agentIds: new Set(),
    teamIds: new Set(),
    page: 0,
    maxRecords: MAX_RECORDS,
  },
  hits: 0,
};

export const reducer = (
  store: AlertFilterStore = INITIAL_STORE,
  action: AlertFilterActions
): AlertFilterStore => {
  switch (action.type) {
    case 'SET_ALERT_FILTERS':
      return {
        ...store,
        filters: action.payload,
      };
    case 'SET_ALERT_HITS':
      return {
        ...store,
        hits: action.payload,
      };
    default:
      return store;
  }
};

export default reducer;
