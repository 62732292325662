import { formatFilterDate } from 'commons/Utils/dateFilterUtils';
import { AlertFiltersParam, AlertSearchFilterDTO } from 'api/planner/types';
import { Pager } from 'api/commonTypes';

export const filtersToRequest = (
  {
    alertStartDate,
    alertEndDate,
    alertStatuses,
    agentIds,
    alertNatures,
    teamIds,
  }: AlertSearchFilterDTO,
  pager: Pager
): PagedQuery<AlertFiltersParam> => ({
  query: {
    alertStatuses: Array.from(alertStatuses),
    agentIds: Array.from(agentIds),
    alertNatures: Array.from(alertNatures),
    teamIds: Array.from(teamIds),
    alertStartDate: formatFilterDate(alertStartDate),
    alertEndDate: formatFilterDate(alertEndDate),
  },
  page: pager.page,
  maxRecords: pager.maxRecords,
});
