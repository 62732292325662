import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import { red500 } from 'material-ui/styles/colors';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { BKG_CYAN_SELECTED, BKG_ORANGE, TXT_ERROR_RED } from 'theme';
import { translateDaysOfWeek } from 'commons/Days';
import MultiSelect from 'commons/MultiSelect';
import { ItemIdName } from 'api/commonTypes';
import {
  PricingConfiguration,
  TimeIntervalCriteria,
  ZoningDTO,
  PriceModulationDTO,
  PricingPolicyDTO,
  VehicleCategory,
} from '@cvfm-front/tefps-types';
import { getConfigState } from 'config/duck';
import { getLabelFromVehicleType } from 'commons/Utils/vehicleTypeUtils';

import { DAYS_OFF, DEFAULT_FPS_REDUCTION } from '../helpers';
import { STYLE_CRITERIA_CONTAINER } from '../theme';

import TariffCodeField from './TariffCodeField';
import PricingGrid from './PricingGrid';
import DateRangePicker from './DayMonthRangePicker';
import TimeInterval from './TimeInterval';
import TimeCriteriaMenu from './TimeCriteriaMenu';
import PriceModulationField from './PriceModulationField';
import ContiguousPolicySelector from './ContiguousPolicySelector';
import CombinedPolicyGridSelector from './CombinedPolicyGridSelector';
import WaitingPeriodField from './WaitingPeriodField';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  fontFamily: 'Roboto',
  width: '100%',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  fontWeight: 'bold',
};

const STYLE_FORMULAR_STEP_LABEL: CSSProperties = {
  fontSize: 21,
  marginLeft: 8,
  marginRight: 8,
  fontWeight: 'bold',
};

const STYLE_FORMULAR_STEP: CSSProperties = {
  fontSize: 23,
  fontWeight: 'bold',
  color: BKG_CYAN_SELECTED,
};

const STYLE_SECTION: CSSProperties = {
  width: '100%',
  marginTop: 12,
  marginBottom: 24,
};

const STYLE_CRITERIA: CSSProperties = {
  fontWeight: 'bold',
  width: '40%',
  verticalAlign: 'top',
  color: 'black',
  padding: 20,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN_SELECTED,
};

const STYLE_PRICE_MODULATION_FIELD: CSSProperties = {
  height: 24,
  width: 44,
  marginLeft: 4,
  borderRadius: 5,
  marginRight: 5,
};

type SyntheticInputEventWithTarget = React.ChangeEvent<HTMLInputElement>;

const isPeriodConsistent = (
  startDay: number,
  startMonth: number,
  endDay: number,
  endMonth: number
) => {
  if (startMonth > endMonth || (startMonth === endMonth && startDay > endDay)) {
    return _tg('tefps.pricing.pricing.errors.periodNotConsistent');
  }
  return undefined;
};

const DEFAULT_DATE_INTERVAL = {
  startDay: 1,
  startMonth: 7,
  endDay: 31,
  endMonth: 8,
}; // Période estivale, juillet et août

type TimeCriteriaProps = {
  start: string;
  end: string;
  idx?: number; // eslint-disable-line react/no-unused-prop-types
  onChange: Function;
  remove?: (event: any) => void;
  disabled: boolean;
  style?: Record<string, any>; // eslint-disable-line react/no-unused-prop-types
};
const TimeCriteria = ({
  start,
  end,
  idx,
  onChange,
  remove,
  disabled,
  style,
}: TimeCriteriaProps) => (
  <div style={{ ...STYLE_CRITERIA_CONTAINER, ...style }}>
    <TimeInterval
      from={start}
      to={end}
      onChange={onChange}
      idx={idx}
      remove={remove}
      disabled={disabled}
    />
  </div>
);

const AddButton = ({
  tooltip,
  onClick,
}: {
  tooltip: string;
  onClick: () => void;
}) => (
  <IconButton tooltip={tooltip} iconStyle={{ width: 24 }} onClick={onClick}>
    <AddIcon />
  </IconButton>
);

type Props = {
  isOpen: boolean;
  disabled: boolean;
  defaultChargedPeriod: TimeIntervalCriteria;
  defaultFpsReduction: PriceModulationDTO | null | undefined;
  zoning: ZoningDTO | null | undefined;
  profiles: Array<ItemIdName>;
  editedPolicy: PricingPolicyDTO | null | undefined;
  create: (policy: PricingPolicyDTO) => void;
  edit: (oldId: string, policy: PricingPolicyDTO) => void;
  currentPolicies: Array<PricingPolicyDTO>;
  close: () => any;
  parkingMetersEnabled: boolean;
  cityPricingConfiguration: PricingConfiguration;
  vehicleTypesEnabled: Array<VehicleCategory>;
};

type State = {
  policy: PricingPolicyDTO;
  contiguousPolicy?: PricingPolicyDTO | null;
  combinedPolicy?: PricingPolicyDTO | null;
  zoneItems: Array<ItemIdName>;
  hasErrors: {
    criteria: number;
    pricingGrid: boolean | null | undefined;
    tariffCode: boolean;
    idConflictPolicy: boolean;
  };
  idFieldError?: {
    text: string;
    color: string;
  };
};

const INITIAL_STATE: State = {
  policy: {
    id: '',
    name: '',
    profilesCriteria: [],
    dayOfWeekCriteria: null,
    specialDayCriteria: null,
    dayOffCriteria: null,
    dayOfMonthCriteria: null,
    dateIntervalCriteria: [],
    timeIntervalCriteria: [],
    cityZoneIds: [],
    vehicleCategories: [],
    pricingGrid: {},
    dailyChargedPeriod: { start: '', end: '' },
    dailyBreaks: [],
    concernedByReduction: true,
    fpsReduction: null,
    endOnNextDay: false,
    tariffCode: null,
    blockingGrid: false,
    progressivityGrid: false,
    extendTicketsAfterEndOfPolicy: true,
    waitingPeriodEnabled: false,
    useCityDaysConfig: false,
  },
  zoneItems: [],
  hasErrors: {
    criteria: 0,
    pricingGrid: false,
    tariffCode: false,
    idConflictPolicy: false,
  },
  contiguousPolicy: null,
  combinedPolicy: null,
};

const initializeState = (
  id: string,
  zoning: ZoningDTO | null | undefined,
  editedPolicy: PricingPolicyDTO | null | undefined,
  defaultChargedPeriod: TimeIntervalCriteria,
  currentPolicies: Array<PricingPolicyDTO>
): State => {
  const zoneItems = zoning
    ? zoning.zones.map(z => ({ id: z.id, name: z.name }))
    : [];
  if (editedPolicy) {
    return {
      policy: editedPolicy,
      zoneItems,
      hasErrors: {
        criteria: 0,
        pricingGrid: false,
        tariffCode: false,
        idConflictPolicy: false,
      },
      contiguousPolicy: editedPolicy.contiguousPolicyId
        ? currentPolicies.find(p => p.id === editedPolicy.contiguousPolicyId)
        : null,
      combinedPolicy: editedPolicy.combinedPolicyIdGrid
        ? currentPolicies.find(p => p.id === editedPolicy.combinedPolicyIdGrid)
        : null,
    };
  }
  return {
    ...INITIAL_STATE,
    policy: {
      ...INITIAL_STATE.policy,
      id,
      dailyChargedPeriod: defaultChargedPeriod,
    },
    zoneItems,
  };
};

class AddPolicy extends React.Component<Props, State> {
  grid: any = null;

  oldId?: string;

  constructor(props: Props) {
    super(props);
    this.state = initializeState(
      Date.now().toString(),
      props.zoning,
      props.editedPolicy,
      props.defaultChargedPeriod,
      props.currentPolicies
    );
    this.oldId = props.editedPolicy?.id;
  }

  onClickValidate = () => {
    const { create, edit, editedPolicy } = this.props;
    const { policy } = this.state;
    if (policy.name && !!policy.name.trim()) {
      const modifiedPolicy = { ...policy };
      modifiedPolicy.pricingGrid = this.grid.getPricingGrid();
      modifiedPolicy.parkingMeterDurationId = this.grid.getParkingMeterDurationId();
      if (editedPolicy && this.oldId) {
        edit(this.oldId, modifiedPolicy);
      } else {
        create(modifiedPolicy);
      }
    }
  };

  createRefGrid = (node: any) => {
    this.grid = node;
  };

  changeName = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    this.setState({ policy: { ...this.state.policy, name } });
  };

  getIdError = (id: string): { text: string; color: string } | undefined => {
    const { currentPolicies } = this.props;

    const matchingPolicy =
      this.oldId !== id && currentPolicies.find(pol => pol.id === id);

    if (matchingPolicy) {
      return {
        text: _tg('tefps.pricing.pricing.errors.ppIdConflict', {
          policy: matchingPolicy.name,
        }),
        color: TXT_ERROR_RED,
      };
    }

    const combinedPoliciesToChange =
      this.oldId !== id
        ? currentPolicies.filter(pol => pol.combinedPolicyIdGrid === this.oldId)
        : [];

    if (combinedPoliciesToChange.length) {
      return {
        text: _tg('tefps.pricing.pricing.errors.ppIdChangesCombinedPPs', {
          policies: combinedPoliciesToChange.map(pol => pol.name).join(', '),
        }),
        color: BKG_ORANGE,
      };
    }

    return undefined;
  };

  changeId = (_e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { currentPolicies } = this.props;

    const matchingPolicy =
      this.oldId !== id && currentPolicies.find(pol => pol.id === id);

    this.setState(state => ({
      policy: { ...state.policy, id },
      hasErrors: {
        ...state.hasErrors,
        idConflictPolicy: !!matchingPolicy,
      },
      idFieldError: this.getIdError(id),
    }));
  };

  changeParkingMeterLabel = (
    _e: React.ChangeEvent<HTMLInputElement>,
    parkingMeterLabel: string
  ) => {
    const { policy } = this.state;
    this.setState({ policy: { ...policy, parkingMeterLabel } });
  };

  changePricingCategory = (
    _e: React.ChangeEvent<HTMLInputElement>,
    pricingCategory: string
  ) => {
    const { policy } = this.state;
    this.setState({ policy: { ...policy, pricingCategory } });
  };

  changeComment = (
    _e: React.ChangeEvent<HTMLInputElement>,
    comment: string
  ) => {
    const { policy } = this.state;
    this.setState({ policy: { ...policy, comment } });
  };

  checkConcernedReduction = (e: any, checked: boolean) => {
    this.setState({
      policy: {
        ...this.state.policy,
        concernedByReduction: checked,
        fpsReduction: checked ? this.state.policy.fpsReduction : null,
      },
    });
  };

  checkDefineReduction = (e: any, checked: boolean) => {
    const { editedPolicy, defaultFpsReduction } = this.props;
    const { policy } = this.state;
    if (checked) {
      const fpsReduction =
        editedPolicy && editedPolicy.fpsReduction
          ? editedPolicy.fpsReduction
          : defaultFpsReduction || DEFAULT_FPS_REDUCTION;
      this.setState({
        policy: { ...policy, fpsReduction },
      });
    } else {
      this.setState({
        policy: { ...policy, fpsReduction: null },
      });
    }
  };

  checkEndOnNextDay = (e: any, checked: boolean) =>
    this.setState({
      policy: {
        ...this.state.policy,
        endOnNextDay: checked,
      },
    });

  addPolicyBreak = () => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        dailyBreaks: [...policy.dailyBreaks, { start: '12:00', end: '14:00' }],
      },
    });
  };

  addVehicleCategory = () => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        vehicleCategories: [...policy.vehicleCategories, ''],
      },
    });
  };

  addTemporalCriteria = (criteriaKey: string) => {
    const policy = { ...this.state.policy };

    if (criteriaKey === 'dateIntervalCriteria') {
      this.setState({
        policy: {
          ...policy,
          dateIntervalCriteria: [
            ...policy.dateIntervalCriteria,
            DEFAULT_DATE_INTERVAL,
          ],
        },
      });
    } else if (criteriaKey === 'timeIntervalCriteria') {
      this.setState({
        policy: {
          ...policy,
          timeIntervalCriteria: [
            ...policy.timeIntervalCriteria,
            { start: '08:00', end: '14:00' },
          ],
        },
      });
    } else {
      this.setState({ policy: { ...policy, [criteriaKey]: [] } });
    }
  };

  deleteDateIntervalCriteria = (event: SyntheticInputEventWithTarget) => {
    const { hasErrors, policy } = this.state;
    const dateIntervalCriteria = [...policy.dateIntervalCriteria];
    const id = event.currentTarget.dataset.idx as string;
    const newCriteriaErrorsCount = dateIntervalCriteria[id].error
      ? hasErrors.criteria - 1
      : hasErrors.criteria;
    dateIntervalCriteria.splice(parseInt(id, 10), 1);
    this.setState({
      policy: { ...policy, dateIntervalCriteria },
      hasErrors: { ...hasErrors, criteria: newCriteriaErrorsCount },
    });
  };

  deleteTimeIntervalCriteria = (event: SyntheticInputEventWithTarget) => {
    const { policy } = this.state;
    const timeIntervalCriteria = [...policy.timeIntervalCriteria];
    timeIntervalCriteria.splice(
      parseInt(event.currentTarget.dataset.idx as string, 10),
      1
    );
    this.setState({ policy: { ...policy, timeIntervalCriteria } });
  };

  deleteBreak = (event: SyntheticInputEventWithTarget) => {
    const idx = Number(event.currentTarget.dataset.idx);
    const dailyBreaks = this.state.policy.dailyBreaks.filter(
      (b, i) => i !== idx
    );
    this.setState({ policy: { ...this.state.policy, dailyBreaks } });
  };

  deleteVehicleCategoryField = (event: SyntheticInputEventWithTarget) => {
    const idx = Number(event.currentTarget.dataset.idx);
    const vehicleCategories = this.state.policy.vehicleCategories.filter(
      (b, i) => i !== idx
    );
    this.setState({ policy: { ...this.state.policy, vehicleCategories } });
  };

  deleteDayOffCriteria = () =>
    this.setState({ policy: { ...this.state.policy, dayOffCriteria: null } });

  deleteDayOffWeekCriteria = () =>
    this.setState({
      policy: { ...this.state.policy, dayOfWeekCriteria: null },
    });
  deleteSpecialDayCriteria = () =>
    this.setState({
      policy: { ...this.state.policy, specialDayCriteria: null },
    });

  changeDateInterval = (
    field: string,
    day: number,
    month: number,
    id: number
  ) => {
    const { policy, hasErrors } = this.state;
    const dateIntervalCriteria = [...policy.dateIntervalCriteria];
    const dayField = `${field}Day`;
    const monthField = `${field}Month`;
    dateIntervalCriteria[id] = {
      ...dateIntervalCriteria[id],
      [dayField]: day,
      [monthField]: month,
    };
    const hadError = dateIntervalCriteria[id].error;
    const { startDay, startMonth, endDay, endMonth } = dateIntervalCriteria[id];
    const error = isPeriodConsistent(startDay, startMonth, endDay, endMonth);
    dateIntervalCriteria[id].error = error;

    const { criteria } = hasErrors;
    let newCriteriaErrorsCount = criteria;
    if (!hadError && error) {
      newCriteriaErrorsCount = criteria + 1;
    } else if (hadError && !error) {
      newCriteriaErrorsCount = criteria - 1;
    }
    this.setState({
      policy: { ...policy, dateIntervalCriteria },
      hasErrors: { ...hasErrors, criteria: newCriteriaErrorsCount },
    });
  };

  changeDailyPeriod = (start: string, end: string) => {
    this.setState({
      policy: { ...this.state.policy, dailyChargedPeriod: { start, end } },
    });
  };

  changeVehicleCategoryField = (vehicleCategory: string, idx: number) => {
    const { policy } = this.state;
    const vehicleCategories = [...policy.vehicleCategories];
    vehicleCategories[idx] = vehicleCategory;
    this.setState({ policy: { ...policy, vehicleCategories } });
  };

  changeTimeInterval = (start: string, end: string, id: number) => {
    const { policy } = this.state;
    const timeIntervalCriteria = [...policy.timeIntervalCriteria];
    timeIntervalCriteria[id] = { start, end };
    this.setState({ policy: { ...policy, timeIntervalCriteria } });
  };

  changeDailyBreak = (start: string, end: string, id: number) => {
    const { policy } = this.state;
    const dailyBreaks = [...policy.dailyBreaks];
    dailyBreaks[id] = { start, end };
    this.setState({ policy: { ...policy, dailyBreaks } });
  };

  changeListCriteria = (criteriaName: string, selection: Array<string>) => {
    const { policy } = this.state;
    this.setState({ policy: { ...policy, [criteriaName]: selection } });
  };

  changeFpsReduction = (fpsReduction: PriceModulationDTO) => {
    const { policy } = this.state;
    this.setState({ policy: { ...policy, fpsReduction } });
  };

  changePolicy = (updatedPolicy: PricingPolicyDTO) => {
    const { policy } = this.state;
    this.setState({ policy: { ...policy, ...updatedPolicy } });
  };

  pricingGridHasErrors = (value: boolean) => {
    this.setState({
      hasErrors: { ...this.state.hasErrors, pricingGrid: value },
    });
  };

  tariffCodeHasErrors = (value: boolean) => {
    this.setState({
      hasErrors: { ...this.state.hasErrors, tariffCode: value },
    });
  };

  changeContiguousPolicy = (contiguousPolicy?: PricingPolicyDTO | null) => {
    const dailyChargedPeriod = { ...this.state.policy.dailyChargedPeriod };
    const dailyStart = contiguousPolicy
      ? contiguousPolicy.dailyChargedPeriod.end
      : this.props.defaultChargedPeriod.start;
    this.setState({
      contiguousPolicy,
      policy: {
        ...this.state.policy,
        contiguousPolicyId: contiguousPolicy?.id,
        tariffCode: contiguousPolicy?.tariffCode,
        dailyChargedPeriod: { ...dailyChargedPeriod, start: dailyStart },
      },
    });
  };

  changeCombinedPolicy = (combinedPolicy?: PricingPolicyDTO | null) => {
    this.setState({
      combinedPolicy,
      policy: {
        ...this.state.policy,
        combinedPolicyIdGrid: combinedPolicy?.id,
      },
    });
  };

  handleCheckBlockingGrid = (
    _e: React.MouseEvent<HTMLInputElement>,
    blockingGrid: boolean
  ) => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        blockingGrid,
        progressivityGrid: blockingGrid && policy.progressivityGrid,
      },
    });
  };

  handleCheckProgressivityGrid = (
    _e: React.MouseEvent<HTMLInputElement>,
    progressivityGrid: boolean
  ) => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        progressivityGrid,
        blockingGrid: progressivityGrid || policy.blockingGrid,
      },
    });
  };

  handleCheckExtendTicket = (
    _e: React.MouseEvent<HTMLInputElement>,
    extendTicketsAfterEndOfPolicy: boolean
  ) => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        extendTicketsAfterEndOfPolicy,
      },
    });
  };

  handleShouldUseCityDaysConfig = (
    _e: React.MouseEvent<HTMLInputElement>,
    useCityDaysConfig: boolean
  ) => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        useCityDaysConfig,
      },
    });
  };

  handleCheckWaitingPeriod = (
    _e: React.MouseEvent<HTMLInputElement>,
    waitingPeriodEnabled: boolean
  ) => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        waitingPeriodEnabled,
      },
    });
  };

  handleWaitingPeriodChange = (_e: React.FormEvent, value: string) => {
    const { policy } = this.state;
    this.setState({
      policy: {
        ...policy,
        waitingPeriod: Number(value),
      },
    });
  };

  render() {
    const {
      isOpen,
      editedPolicy,
      profiles,
      disabled,
      currentPolicies,
      parkingMetersEnabled,
      cityPricingConfiguration,
      vehicleTypesEnabled,
    } = this.props;
    const {
      policy,
      zoneItems,
      hasErrors,
      contiguousPolicy,
      combinedPolicy,
      idFieldError,
    } = this.state;

    const allVehiclesLabels = Object.keys(VehicleCategory).map(
      (vc: VehicleCategory) => {
        return {
          id: vc,
          name: getLabelFromVehicleType(vc),
        };
      }
    );

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={disabled ? _tg('action.close') : _tg('action.cancel')}
        onClick={this.props.close}
      />,
    ];
    if (!disabled) {
      actions.push(
        <BoButton
          key={2}
          label={editedPolicy ? _tg('action.edit') : _tg('action.create')}
          primary
          keyboardFocused
          disabled={
            hasErrors.criteria > 0 ||
            hasErrors.pricingGrid ||
            hasErrors.tariffCode ||
            hasErrors.idConflictPolicy ||
            !policy.name ||
            !policy.name.trim()
          }
          onClick={this.onClickValidate}
        />
      );
    }

    return (
      <span>
        <Dialog
          actions={actions}
          title={
            editedPolicy
              ? _tg('tefps.pricing.pricing.policy.editPolicy')
              : _tg('tefps.pricing.pricing.policy.createPolicy')
          }
          open={isOpen}
          onRequestClose={this.props.close}
          titleStyle={STYLE_TITLE}
          autoScrollBodyContent
          modal
          contentStyle={{ width: '80%', maxWidth: 'none' }}
        >
          <div style={STYLE_CONTENT_WRAPPER}>
            <div
              style={{
                ...STYLE_SECTION,
                marginBottom: 14,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                <span style={STYLE_FORMULAR_STEP}>1/</span>
                <span style={STYLE_FORMULAR_STEP_LABEL}>
                  {_tg('field.name')} :
                </span>
                <TextField
                  name="name"
                  underlineFocusStyle={STYLE_INPUTS}
                  value={policy.name}
                  onChange={this.changeName}
                  errorText={
                    (!policy.name || !policy.name.trim()) &&
                    _tg('feedback.error.mandatoryField')
                  }
                  disabled={disabled}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={STYLE_FORMULAR_STEP_LABEL}>
                  {_tg('field.technicalId')} :
                </span>
                <TextField
                  name="id"
                  underlineFocusStyle={STYLE_INPUTS}
                  value={policy.id ?? ''}
                  onChange={this.changeId}
                  disabled={disabled}
                  errorText={idFieldError?.text}
                  errorStyle={{
                    color: idFieldError?.color,
                    marginTop: 3,
                  }}
                />
              </div>
            </div>
            {parkingMetersEnabled && (
              <div
                style={{
                  ...STYLE_SECTION,
                  marginBottom: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={STYLE_FORMULAR_STEP_LABEL}>
                  {_tg('tefps.pricing.pricing.policy.parkingMeterLabel')} :
                </span>
                <TextField
                  name="parkingMeterLabel"
                  underlineFocusStyle={STYLE_INPUTS}
                  value={policy.parkingMeterLabel}
                  onChange={this.changeParkingMeterLabel}
                  disabled={disabled}
                />
              </div>
            )}
            <div>
              <Checkbox
                label={_tg('tefps.pricing.pricing.policy.concernedByReduction')}
                checked={policy.concernedByReduction}
                disabled={disabled}
                onCheck={this.checkConcernedReduction}
              />
            </div>
            {policy.concernedByReduction && (
              <div>
                <Checkbox
                  label={_tg('tefps.pricing.pricing.policy.fpsReduction')}
                  checked={!!policy.fpsReduction}
                  disabled={disabled}
                  onCheck={this.checkDefineReduction}
                />
                {policy.fpsReduction && (
                  <div style={{ marginLeft: 40 }}>
                    <PriceModulationField
                      priceModulation={policy.fpsReduction}
                      updatePriceModulation={this.changeFpsReduction}
                      disabled={disabled}
                      styleField={STYLE_PRICE_MODULATION_FIELD}
                    />
                  </div>
                )}
              </div>
            )}
            <ContiguousPolicySelector
              editedPolicyId={editedPolicy ? editedPolicy.id : ''}
              currentPolicies={currentPolicies}
              contiguousPolicy={contiguousPolicy}
              onChange={this.changeContiguousPolicy}
            />
            <div
              style={{
                ...STYLE_SECTION,
                marginTop: 24,
                display: 'flex',
                alignItems: 'top',
              }}
            >
              <span style={STYLE_FORMULAR_STEP}>2/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.dailyChargedPeriod.label')}
              </span>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                <TimeInterval
                  from={policy.dailyChargedPeriod.start}
                  to={policy.dailyChargedPeriod.end}
                  onChange={this.changeDailyPeriod}
                  disabled={disabled}
                  endOnNextDay={policy.endOnNextDay}
                />
                <div
                  style={{ marginTop: 12, marginLeft: 6 }}
                  title={_tg(
                    'tefps.pricing.pricing.policy.dailyChargedPeriod.example'
                  )}
                >
                  <Checkbox
                    label={_tg(
                      'tefps.pricing.pricing.policy.dailyChargedPeriod.endOnNextDay'
                    )}
                    checked={policy.endOnNextDay}
                    disabled={disabled}
                    onCheck={this.checkEndOnNextDay}
                  />
                </div>
              </div>
            </div>
            <div style={{ ...STYLE_SECTION }}>
              <span style={STYLE_FORMULAR_STEP}>3/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.breaks.label')}
              </span>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {policy.dailyBreaks.map((policyBreak, i) => (
                  <TimeCriteria
                    style={{ width: '50%' }}
                    idx={i}
                    key={i}
                    start={policyBreak.start}
                    end={policyBreak.end}
                    onChange={this.changeDailyBreak}
                    remove={this.deleteBreak}
                    disabled={disabled}
                  />
                ))}
                {!disabled && (
                  <AddButton
                    tooltip={_tg(
                      'tefps.pricing.pricing.policy.breaks.newBreak'
                    )}
                    onClick={this.addPolicyBreak}
                  />
                )}
              </span>
            </div>
            <div style={STYLE_SECTION}>
              <span style={STYLE_FORMULAR_STEP}>4/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.criterias.label')}
              </span>
              <table style={{ width: '96%' }}>
                <tbody>
                  <tr>
                    <td style={STYLE_CRITERIA}>
                      {_tg(
                        'tefps.pricing.pricing.policy.criterias.profilesCriteria.label'
                      )}
                    </td>
                    <td style={{ padding: 20 }}>
                      <MultiSelect
                        items={profiles}
                        checkedIds={policy.profilesCriteria}
                        title={_tg(
                          'tefps.pricing.pricing.policy.criterias.profilesCriteria.title'
                        )}
                        itemNameSingular={_tg(
                          'tefps.pricing.pricing.policy.criterias.profilesCriteria.itemNameSingular'
                        )}
                        itemNamePlural={_tg(
                          'tefps.pricing.pricing.policy.criterias.profilesCriteria.itemNamePlural'
                        )}
                        save={(selection: Array<string>) =>
                          this.changeListCriteria('profilesCriteria', selection)
                        }
                        disabled={disabled}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={STYLE_CRITERIA}>
                      {_tg(
                        'tefps.pricing.pricing.policy.criterias.vehicleCategories.label'
                      )}
                    </td>
                    <td style={{ padding: 20 }}>
                      <MultiSelect
                        items={allVehiclesLabels.filter(
                          ({ id }) =>
                            policy.vehicleCategories.includes(id) ||
                            vehicleTypesEnabled.includes(id)
                        )}
                        checkedIds={policy.vehicleCategories}
                        title={_tg(
                          'tefps.pricing.pricing.policy.criterias.vehicleCategories.legend'
                        )}
                        itemNameSingular={_tg(
                          'tefps.pricing.pricing.policy.criterias.vehicleCategories.itemNameSingular'
                        )}
                        itemNamePlural={_tg(
                          'tefps.pricing.pricing.policy.criterias.vehicleCategories.itemNamePlural'
                        )}
                        disabled={disabled}
                        save={(selection: Array<string>) =>
                          this.changeListCriteria(
                            'vehicleCategories',
                            selection
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={STYLE_CRITERIA}>
                      {_tg(
                        'tefps.pricing.pricing.policy.criterias.cityZoneIds.label'
                      )}
                    </td>
                    <td style={{ padding: 20 }}>
                      <MultiSelect
                        items={zoneItems}
                        checkedIds={policy.cityZoneIds}
                        title={_tg(
                          'tefps.pricing.pricing.policy.criterias.cityZoneIds.title'
                        )}
                        itemNameSingular={_tg(
                          'tefps.pricing.pricing.policy.criterias.cityZoneIds.itemNameSingular'
                        )}
                        itemNamePlural={_tg(
                          'tefps.pricing.pricing.policy.criterias.cityZoneIds.itemNamePlural'
                        )}
                        save={(selection: Array<string>) =>
                          this.changeListCriteria('cityZoneIds', selection)
                        }
                        disabled={disabled}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={STYLE_CRITERIA}>
                      {_tg(
                        'tefps.pricing.pricing.policy.criterias.temporalCriteria.label'
                      )}
                    </td>
                    <td style={{ padding: 20 }}>
                      {!disabled && (
                        <TimeCriteriaMenu
                          dayOffCriteriaExists={!!policy.dayOffCriteria}
                          dayOfWeekCriteriaExists={!!policy.dayOfWeekCriteria}
                          addCriteria={this.addTemporalCriteria}
                        />
                      )}
                      <br />
                      {policy.dayOfWeekCriteria && (
                        <MultiSelect
                          items={translateDaysOfWeek()}
                          checkedIds={policy.dayOfWeekCriteria}
                          title={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.title'
                          )}
                          itemNameSingular={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.itemNameSingular'
                          )}
                          itemNamePlural={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.itemNamePlural'
                          )}
                          save={(selection: Array<string>) =>
                            this.changeListCriteria(
                              'dayOfWeekCriteria',
                              selection
                            )
                          }
                          remove={this.deleteDayOffWeekCriteria}
                          disabled={disabled}
                        />
                      )}
                      <br />
                      {policy.dayOffCriteria && (
                        <MultiSelect
                          items={DAYS_OFF()}
                          checkedIds={policy.dayOffCriteria}
                          title={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.title'
                          )}
                          itemNameSingular={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.itemNameSingular'
                          )}
                          itemNamePlural={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.itemNamePlural'
                          )}
                          save={(selection: Array<string>) =>
                            this.changeListCriteria('dayOffCriteria', selection)
                          }
                          remove={this.deleteDayOffCriteria}
                          disabled={disabled}
                        />
                      )}
                      <br />
                      {policy.dateIntervalCriteria &&
                        policy.dateIntervalCriteria.map((period, i) => (
                          <DateRangePicker
                            idx={i}
                            key={i}
                            period={period}
                            onChange={this.changeDateInterval}
                            remove={this.deleteDateIntervalCriteria}
                            disabled={disabled}
                          />
                        ))}
                      {policy.timeIntervalCriteria &&
                        policy.timeIntervalCriteria.map((interval, i) => (
                          <TimeCriteria
                            idx={i}
                            key={i}
                            start={interval.start}
                            end={interval.end}
                            onChange={this.changeTimeInterval}
                            remove={this.deleteTimeIntervalCriteria}
                            disabled={disabled}
                          />
                        ))}
                      {policy.specialDayCriteria && (
                        <MultiSelect
                          items={cityPricingConfiguration.specialDays.map(
                            day => {
                              return { id: day.id, name: day.label };
                            }
                          )}
                          checkedIds={policy.specialDayCriteria}
                          title={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.specialDayCriteria.title'
                          )}
                          itemNameSingular={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.specialDayCriteria.itemNameSingular'
                          )}
                          itemNamePlural={_tg(
                            'tefps.pricing.pricing.policy.criterias.temporalCriteria.specialDayCriteria.itemNamePlural'
                          )}
                          save={(selection: Array<string>) =>
                            this.changeListCriteria(
                              'specialDayCriteria',
                              cityPricingConfiguration.specialDays
                                .map(day => day.id)
                                .filter(dayId => selection.includes(dayId))
                            )
                          }
                          remove={this.deleteSpecialDayCriteria}
                          disabled={disabled}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={STYLE_SECTION}>
              <span style={STYLE_FORMULAR_STEP}>5/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.pricingCategory.label')}
              </span>
              <TextField
                name="pricingCategory"
                underlineFocusStyle={STYLE_INPUTS}
                value={policy.pricingCategory}
                onChange={this.changePricingCategory}
                disabled={disabled}
              />
            </div>
            <div style={STYLE_SECTION}>
              <span style={STYLE_FORMULAR_STEP}>6/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.tariffCode.label')}
              </span>
              <TariffCodeField
                policy={policy}
                onChangePolicy={this.changePolicy}
                hasErrors={this.tariffCodeHasErrors}
                contiguousPolicy={contiguousPolicy}
                disabled={disabled}
              />
            </div>
            <div style={STYLE_SECTION}>
              <span style={STYLE_FORMULAR_STEP}>7/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.waitingPeriod.label')}
              </span>
              <WaitingPeriodField
                policy={policy}
                disabled={disabled}
                handleCheckWaitingPeriod={this.handleCheckWaitingPeriod}
                handleWaitingPeriodChange={this.handleWaitingPeriodChange}
              />
            </div>
            <div style={STYLE_SECTION}>
              <span style={STYLE_FORMULAR_STEP}>8/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.pricingGrid.label')}
              </span>
              {hasErrors.pricingGrid && (
                <span style={{ color: red500 }}>
                  {_tg('tefps.pricing.pricing.policy.pricingGrid.hasErrors')}
                </span>
              )}
              <div
                style={{
                  marginTop: 12,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <Checkbox
                    checked={policy.blockingGrid}
                    onCheck={this.handleCheckBlockingGrid}
                    style={{ width: '50%' }}
                    label={_tg(
                      'tefps.pricing.pricing.policy.pricingGrid.blockingGrid'
                    )}
                    disabled={disabled}
                  />
                  <Checkbox
                    checked={policy.progressivityGrid}
                    onCheck={this.handleCheckProgressivityGrid}
                    style={{ width: '50%' }}
                    label={_tg(
                      'tefps.pricing.pricing.policy.pricingGrid.progressivityGrid'
                    )}
                    disabled={disabled}
                  />
                  <Checkbox
                    checked={policy.extendTicketsAfterEndOfPolicy}
                    onCheck={this.handleCheckExtendTicket}
                    style={{ width: '50%' }}
                    label={_tg(
                      'tefps.pricing.pricing.policy.pricingGrid.extendTicketsAfterEndOfPolicy'
                    )}
                    disabled={disabled}
                  />
                  <Checkbox
                    checked={policy.useCityDaysConfig}
                    onCheck={this.handleShouldUseCityDaysConfig}
                    style={{ width: '50%' }}
                    label={_tg(
                      'tefps.pricing.pricing.policy.pricingGrid.useCityDaysConfig'
                    )}
                    disabled={disabled}
                  />
                </div>
                <CombinedPolicyGridSelector
                  editedPolicyId={editedPolicy ? editedPolicy.id : ''}
                  currentPolicies={currentPolicies}
                  combinedPolicy={combinedPolicy}
                  onChange={this.changeCombinedPolicy}
                />
                <PricingGrid
                  ref={this.createRefGrid}
                  pricingGrid={policy.pricingGrid}
                  parkingMeterDurationId={policy.parkingMeterDurationId}
                  hasErrors={this.pricingGridHasErrors}
                  disabled={disabled}
                  parkingMetersEnabled={parkingMetersEnabled}
                />
              </div>
            </div>
            <div style={STYLE_SECTION}>
              <span style={STYLE_FORMULAR_STEP}>9/</span>
              <span style={STYLE_FORMULAR_STEP_LABEL}>
                {_tg('tefps.pricing.pricing.policy.comment.label')}
              </span>
              <div style={{ marginTop: 12 }}>
                <TextField
                  name="comment"
                  underlineFocusStyle={STYLE_INPUTS}
                  value={policy.comment}
                  onChange={this.changeComment}
                  multiLine
                  rows={2}
                  rowsMax={3}
                  disabled={disabled}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </Dialog>
      </span>
    );
  }
}

export default connect(state => {
  const { modulesConfiguration, authorizedVehicleCategories } = getConfigState(
    state
  );
  return {
    parkingMetersEnabled: modulesConfiguration.parkingMeter.enabled,
    vehicleTypesEnabled: authorizedVehicleCategories.map(
      avc => avc.vehicleCategory
    ),
  };
})(AddPolicy);
