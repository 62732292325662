import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import { getFpsSimulationResult } from 'api/simulation';
import { FpsSimulationResultDTO } from 'api/simulation/types';

import { STYLE_TITLE } from '../theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER_HEADER: CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};

const STYLE_INCREASE: CSSProperties = {
  color: '#0C0',
  fontWeight: 'bold',
};

const STYLE_DECREASE: CSSProperties = {
  color: '#C00',
  fontWeight: 'bold',
};

type SimulationProps = {
  price: number;
  reducedPrice: number;
};

type SimulationState = {
  result: FpsSimulationResultDTO | null | undefined;
  error: Error | null | undefined;
  computing: boolean;
};

const INITIAL_STATE: SimulationState = {
  result: null,
  error: null,
  computing: false,
};

const SimulationResult = ({ delta }: { delta: number }) => {
  const formatedPrice = Math.abs(delta).toLocaleString();
  return (
    <span style={delta >= 0 ? STYLE_INCREASE : STYLE_DECREASE}>
      {delta >= 0
        ? _tg('tefps.pricing.pricing.simulations.higherIncome', {
            formatedPrice,
          })
        : _tg('tefps.pricing.pricing.simulations.lowerIncome', {
            formatedPrice,
          })}
    </span>
  );
};

class Simulation extends React.Component<SimulationProps, SimulationState> {
  constructor(props: SimulationProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  launchSimulation = async (): Promise<void> => {
    const { price, reducedPrice } = this.props;
    this.setState({ computing: true });
    try {
      const result = await getFpsSimulationResult(
        price * 100,
        (price - reducedPrice) * 100
      );
      if (result) {
        this.setState({ error: null, result, computing: false });
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  render(): JSX.Element {
    const { result, error, computing } = this.state;

    if (error) {
      return (
        <div style={{ flex: 1, width: '100%', height: '100%' }}>
          <div style={{ ...STYLE_CONTAINER_HEADER, ...STYLE_TITLE }}>
            {_tg('tefps.pricing.pricing.simulations.projections')}
          </div>
          <ErrorBlock message={_tg('field.error')} error={error} />
        </div>
      );
    }

    return (
      <div style={{ flex: 1, width: '100%' }}>
        <div style={{ ...STYLE_CONTAINER_HEADER, ...STYLE_TITLE }}>
          {_tg('tefps.pricing.pricing.simulations.projections')}
        </div>
        <div>
          <div style={{ margin: 20 }}>
            <BoButton
              label={_tg('tefps.pricing.pricing.simulations.startComputation')}
              disabled={computing}
              fullWidth
              onClick={this.launchSimulation}
            />
          </div>
          {computing ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            result && (
              <span>
                {_tg('tefps.pricing.pricing.simulations.changesResults')}
                <SimulationResult delta={result.delta} />
              </span>
            )
          )}
        </div>
      </div>
    );
  }
}

export default Simulation;
