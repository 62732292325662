import React from 'react';
import Paper from 'material-ui/Paper';

import { ControlSubscriber } from 'api/control/types';
import { BKG_BLUE_GREY, BKG_LIGHT_VIOLET } from 'theme';
import Panel from 'tefps/LapiReview/components/Panel';
import * as INNER_THEME from 'tefps/LapiReview/TicketInformation/theme';
import RightsRow from 'tefps/LapiReview/TicketInformation/component/RightsRow';
import TicketsRow from 'tefps/LapiReview/TicketInformation/component/TicketsRow';
import { FnmsCityFpsPriceDTO, LightLapiZone } from 'api/pricing/types';
import { ControlParkingRightDTO } from 'api/tickets/types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  parkingRights?: Array<ControlParkingRightDTO>;
  subscriptions?: Array<ControlSubscriber>;
  subscriptionPlanMap: Map<string, string> | undefined;
  fpsPrice: FnmsCityFpsPriceDTO | null | undefined;
  zonesMap: Map<string, LightLapiZone>;
  relevantParkingRight?: ControlParkingRightDTO;
  relevantSubscription?: ControlSubscriber;
  isForControl: boolean;
  controlZone: string;
  controlDate: string;
  controlPricingCategory: string | undefined;
  darkMode: boolean;
};

const TicketInformation = ({
  parkingRights,
  subscriptions,
  subscriptionPlanMap,
  fpsPrice,
  zonesMap,
  relevantParkingRight,
  relevantSubscription,
  isForControl,
  controlDate,
  controlZone,
  controlPricingCategory,
  darkMode,
}: Props) => {
  return (
    <Panel
      style={INNER_THEME.panelStyle}
      name="Tickets et droits de stationnement"
    >
      <Paper
        style={{ backgroundColor: BKG_LIGHT_VIOLET, ...INNER_THEME.headStyle }}
      >
        {_tg('tefps.lapiReview.ticketInformation.tickets')}
      </Paper>
      <TicketsRow
        parkingRights={parkingRights}
        fpsPrice={fpsPrice}
        zonesMap={zonesMap}
        relevantParkingRight={relevantParkingRight}
        isForControl={isForControl}
        controlDate={controlDate}
        controlZone={controlZone}
        controlPricingCategory={controlPricingCategory}
        darkMode={darkMode}
      />
      <Paper
        style={{ backgroundColor: BKG_BLUE_GREY, ...INNER_THEME.headStyle }}
      >
        {_tg('tefps.lapiReview.ticketInformation.rights')}
      </Paper>
      <RightsRow
        subscriptions={subscriptions}
        subscriptionPlanMap={subscriptionPlanMap}
        zonesMap={zonesMap}
        relevantSubscription={relevantSubscription}
        isForControl={isForControl}
        controlDate={controlDate}
        controlZone={controlZone}
        controlPricingCategory={controlPricingCategory}
        darkMode={darkMode}
      />
    </Panel>
  );
};

export default TicketInformation;
