import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Add from 'material-ui/svg-icons/content/add-box';

import Sidebar from 'commons/SidebarV2';
import { getApiState } from 'api/duck';
import { STYLE_TITLE_BORDER, TXT_GREY } from 'theme';
import services from 'commons/services';
import usePatrolZones from 'commons/hooks/usePatrolZones';
import BoButton from 'facade/BoButton';
import { Flex } from '@cvfm-front/commons-ui';

import PatrolZoneItem from './PatrolZoneItem';

const { _tg } = window.loadTranslations(__filename);

const STYLE_BLOCK_TITLE: CSSProperties = {
  fontSize: 15,
  marginBottom: 20,
  marginTop: 8,
  fontWeight: 'bold',
  display: 'flex',
  color: TXT_GREY,
};

const PatrolZoneSidebar = (): JSX.Element => {
  const patrolZones = usePatrolZones();

  const createPatrolZone = (): void => {
    services.patrolZoneService.createPatrolZone();
  };

  return (
    <Sidebar>
      <div style={STYLE_BLOCK_TITLE}>
        {_tg('tefps.Planner.index.element.patrolZone')}
        <hr style={STYLE_TITLE_BORDER} />
      </div>
      <Flex flexDirection="row" alignItems="center" gap={10}>
        {_tg('action.create')}
        <Add
          style={{
            color: 'white',
            marginLeft: 0,
            height: 30,
            width: 30,
            cursor: 'pointer',
          }}
          onClick={createPatrolZone}
        />
      </Flex>
      {patrolZones?.map((patrolZone, index) => (
        <PatrolZoneItem patrolZone={patrolZone} key={`patrolZone-${index}`} />
      ))}
      <div>
        <BoButton
          key={2}
          label={_tg('action.save_1')}
          primary
          fullWidth
          onClick={services.patrolZoneService.savePatrolZones}
          disabled={!services.patrolZoneService.hasAnyPatrolZoneChange()}
        />
      </div>
    </Sidebar>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const canWritePatrolZone = userInfo
    ? userInfo.rights.includes('PATROL_ZONE_WRITE')
    : null;
  return {
    canWritePatrolZone,
  };
})(PatrolZoneSidebar);
