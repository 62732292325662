import * as React from 'react';
import { connect } from 'react-redux';

import { searchAlerts } from 'api/planner/index';
import { Pager } from 'api/commonTypes';
import { AlertOverviewDTO, AlertSearchFilterDTO } from 'api/planner/types';
import ErrorBlock from 'commons/ErrorBlock';
import { MAX_RECORDS } from 'commons/const';

import {
  mapAlertActionToProps,
  mapAlertStoreToProps,
} from './AlertFilter/ducks/mappers';
import { filtersToRequest } from './AlertFilter/ducks/utils';

const { _tg } = window.loadTranslations();

type ReduxProps = {
  // Store variables
  alertFilters: AlertSearchFilterDTO;
};

type AlertFetcherProps = ReduxProps & {
  children: (arg0: {
    alerts: AlertOverviewDTO[];
    alertFilters: AlertSearchFilterDTO;
    totalHits: number;
    loadMoreRows: (arg00: { startIndex: number }) => void;
  }) => React.ReactNode;
};

type AlertFetcherState = {
  totalHits: number;
  alerts: AlertOverviewDTO[];
  error: Error | null | undefined;
  isFetchingData: boolean;
  pager: Pager;
};

class AlertFetcher extends React.Component<
  AlertFetcherProps,
  AlertFetcherState
> {
  constructor(props: AlertFetcherProps) {
    super(props);
    this.state = {
      totalHits: 0,
      alerts: [],
      error: null,
      isFetchingData: false,
      pager: { page: 0, maxRecords: MAX_RECORDS },
    };
  }

  componentDidMount(): void {
    void this.fetchAlerts(true);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: AlertFetcherProps): void {
    void this.fetchAlerts(true, newProps);
  }

  fetchAlerts = async (
    shouldDropPreviousRows: boolean,
    props: AlertFetcherProps = this.props
  ) => {
    const { alertFilters } = props;
    const { alerts, pager, isFetchingData } = this.state;

    // Prevent from running multiple times
    if (isFetchingData) {
      return;
    }

    // Request for first page if we change filters
    if (shouldDropPreviousRows) {
      pager.page = 0;
    }

    try {
      // Prevent from running multiple times
      this.setState({ isFetchingData: true });

      // Construct your paged query
      const pagedQueryForAlerts = filtersToRequest(alertFilters, pager);

      // Submit the query to the api
      const alertSearchResult = await searchAlerts(pagedQueryForAlerts);

      const newAlerts: AlertOverviewDTO[] = Object.values(
        alertSearchResult.alerts
      );

      // fetch next page in next Request
      pager.page += 1;

      // Init or append the new alert to the state
      this.setState({
        alerts: shouldDropPreviousRows ? newAlerts : [...alerts, ...newAlerts],
        error: null,
        totalHits: alertSearchResult.totalHits,
        pager,
      });
    } catch (error) {
      this.setState({ error: error as Error, alerts: [] });
    } finally {
      this.setState({ isFetchingData: false });
    }
  };

  loadMoreRows = () => {
    void this.fetchAlerts(false);
  };

  render() {
    const { alertFilters, children } = this.props;
    const { alerts, totalHits, error } = this.state;
    if (error) {
      return (
        <ErrorBlock message={_tg('feedback.error.fetchAlerts')} error={error} />
      );
    }
    return children({
      alerts,
      alertFilters,
      totalHits,
      // fetchAlerts: this.fetchAlerts, // TODO: commented for switch to TS, really used ?
      loadMoreRows: this.loadMoreRows,
    });
  }
}

export default connect(
  mapAlertStoreToProps,
  mapAlertActionToProps
)(AlertFetcher as any);
