import { MapFilters } from './types';
import { displayOptions } from './utils';

const FILTER_CHANGE = 'PING_MAP_FILTER_CHANGE';

export const initialFilters = (): MapFilters => {
  // from is set to current minus one hour
  // if at 00:10, it sets to 23:10, but the BO shouldn't be used at that time
  const fromDate = new Date();
  fromDate.setHours(fromDate.getHours() - 1);
  const from = fromDate.toLocaleTimeString('fr', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return {
    displayType: displayOptions.CURRENT, // By default display only last positions
    organizationIds: new Set(),
    teamId: null,
    agentId: null,
    datetime: new Date(),
    lapi: new Set(),
    referent: 'null',
    hasRouteInstance: 'null',
    times: {
      from,
      to: '23:59',
    },
    gpsStatuses: new Set(),
  };
};

export default function pingFiltersReducer(
  state: MapFilters = initialFilters(),
  action: any
): Record<string, any> {
  switch (action.type) {
    case FILTER_CHANGE: {
      const { filters } = action;
      return {
        ...state,
        ...filters,
      };
    }
    default:
      return state;
  }
}

export function getPingFiltersState(state: any): MapFilters {
  return state.pingFilters;
}

export function pingFiltersChange(filters: MapFilters) {
  return {
    type: FILTER_CHANGE,
    filters,
  };
}
