import React, { Component, CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import { red500 } from 'material-ui/styles/colors';

import { convertToCents } from 'commons/Utils/paymentUtil';

import { MAX_TICKET_PRICE } from './index';

const { _tg } = window.loadTranslations(__filename);

const INPUT_STYLE: CSSProperties = {
  textAlign: 'right',
};

const INPUT_STYLE_ERROR: CSSProperties = {
  ...INPUT_STYLE,
  color: red500,
};

function eurosToCents(euros: number): number {
  return Math.min(Math.max(0, convertToCents(euros)), MAX_TICKET_PRICE);
}

type PricingFieldProps = {
  index: number;
  price: number;
  previousPrice: number;
  nextPrice: number;
  changePrice: (index: number, price: number) => void;
  disabled: boolean;
};

type PricingFieldState = {
  error: string | null | undefined;
};

class PricingField extends Component<PricingFieldProps, PricingFieldState> {
  constructor(props: PricingFieldProps) {
    super(props);
    this.state = this.computeState(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    price,
    previousPrice,
    nextPrice,
  }: PricingFieldProps): void {
    this.setState(this.computeState({ price, previousPrice, nextPrice }));
  }

  computeState = ({
    price,
    previousPrice,
    nextPrice,
  }: {
    price: number;
    previousPrice: number;
    nextPrice: number;
  }): {
    error: string | undefined;
  } => {
    return { error: this.isPriceValid({ price, previousPrice, nextPrice }) };
  };

  isPriceValid = ({
    price,
    previousPrice,
    nextPrice,
  }: {
    price: number;
    previousPrice: number;
    nextPrice: number;
  }): string | undefined => {
    if (price < previousPrice || price > nextPrice) {
      return _tg('tefps.pricing.pricing.errors.pricesShouldBeIncreasing');
    }
    return undefined;
  };

  changePrice = (_event: React.MouseEvent<any>, value: string): void => {
    const { index, changePrice } = this.props;
    const correctedPrice = eurosToCents(Number(value));
    changePrice(index, correctedPrice);
  };

  render(): JSX.Element {
    const { price, disabled } = this.props;
    const { error } = this.state;
    const inputStyle = error ? INPUT_STYLE_ERROR : INPUT_STYLE;

    return (
      <div>
        <TextField
          name="price"
          value={
            price / 100
            /* Convert cents to euros */
          }
          onChange={this.changePrice}
          type="number"
          inputStyle={inputStyle}
          style={{ width: 90 }}
          underlineShow={false}
          disabled={disabled}
        />
        <span>{_tg('field.payment.currency')}</span>
      </div>
    );
  }
}

export default PricingField;
