import * as React from 'react';

import AdvertisingModal from 'commons/AdvertisingModal';
import { AlertOverviewDTO, AlertSearchFilterDTO } from 'api/planner/types';

import AlertTable from './AlertTable/index';
import AlertFilter from './AlertFilter';
import AlertFetcher from './fetcher';

const AlertList = () => {
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <AdvertisingModal module="planner" />
      <AlertFilter withFilters />
      <AlertFetcher>
        {({
          alerts,
          alertFilters,
          loadMoreRows,
          totalHits,
          fetchAlerts,
        }: {
          alerts: AlertOverviewDTO[];
          alertFilters: AlertSearchFilterDTO;
          loadMoreRows: (arg0: { startIndex: number }) => Promise<void>;
          totalHits: number;
          fetchAlerts: (shouldDropPreviousRows: boolean) => void;
        }) => (
          <AlertTable
            items={alerts}
            alertFilters={alertFilters}
            loadMoreAlerts={loadMoreRows}
            totalHits={totalHits}
            fetchAlerts={fetchAlerts}
            style={{ paddingTop: 25 }}
          />
        )}
      </AlertFetcher>
    </div>
  );
};

export default AlertList;
