import React, { useState } from 'react';

import { AddressAutoComplete, FilterBarSection } from '@cvfm-front/tefps-ui';
import { Point } from '@cvfm-front/tefps-types';
import { findAddress } from 'api/searchingCompletion';
import { LocalizedAddressDTO } from 'api/searchingCompletion/types';
import services from 'commons/services';
import { MapId } from 'commons/services/MapService';

const { _tg } = window.loadTranslations(__filename);

const ParkingSpaceAutocompleteAddress = (): JSX.Element => {
  const [editedAddress, setEditedAddress] = useState<LocalizedAddressDTO>();

  const autoCompleteAddress = async (
    searchValue: string
  ): Promise<LocalizedAddressDTO[]> => {
    const completed: LocalizedAddressDTO[] = await findAddress(
      searchValue,
      true
    );
    return completed;
  };

  const onSelectedAddress = (selectedAddress: LocalizedAddressDTO): void => {
    setEditedAddress(selectedAddress);
    const locationPoint: Point = {
      latitude: selectedAddress.latitude,
      longitude: selectedAddress.longitude,
    };
    services.mapService.get(MapId.PARKING_SPACE)?.center?.set(locationPoint);
    services.mapService.get(MapId.PARKING_SPACE)?.zoom?.set(18);
  };

  return (
    <FilterBarSection
      title={_tg('searchAddress')}
      fields={[
        <div>
          <AddressAutoComplete
            findAddress={autoCompleteAddress}
            onAddressSelected={onSelectedAddress}
            noAddressText={_tg('noResults')}
            loadingAddressText={_tg('researchInProgress')}
            prefersDarkMode
          />
        </div>,
      ]}
      actions={[]}
    />
  );
};

export default ParkingSpaceAutocompleteAddress;
