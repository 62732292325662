import React, { useEffect, useState } from 'react';
import { Dialog, TextField } from 'material-ui';
import FlatButton from 'material-ui/FlatButton';
import { MuiTheme } from 'material-ui/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { ControlAddressType } from 'api/commonTypes';
import { LightLapiZone } from 'api/pricing/types';
import { ControlPlateDTO, LapiReviewDTO } from 'api/lapiReview/types';
import { BKG_LIGHT_BLUE } from 'theme';
import AddressRenderer from 'commons/Address/AddressRenderer';
import './FPSConfirmationModal.css';
import { LapiReviewConfigurationDTO } from 'api/lapiReviewConfiguration/types';
import { VehicleCategory, ZoningDTO } from '@cvfm-front/tefps-types';

import StaticControlMap from '../Geolocalisation/StaticControlMap';

import TagsList from './TagsList';

export interface FPSConfirmationModalProps {
  lapiReview: LapiReviewDTO;
  qualityControlMode: boolean;
  open: boolean;
  contextImages: Array<File>;
  selectedImages: Set<number>;
  onCancel: () => void;
  onConfirm: (tagsSet: Set<string>, comment?: string) => void;
  fpsId: string;
  agentShortId: string;
  timeStamp: Date;
  price: string;
  ticketPrice: string;
  zone?: LightLapiZone;
  address: ControlAddressType;
  plateImage: File;
  vehicleBrand?: string;
  vehicleModel?: string;
  licensePlate: ControlPlateDTO;
  muiTheme?: MuiTheme;
  comment?: string;
  zoning?: ZoningDTO | null | undefined;
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
}

const FPSConfirmationModal = ({
  open,
  qualityControlMode,
  onCancel,
  onConfirm,
  fpsId,
  agentShortId,
  timeStamp,
  price,
  ticketPrice,
  zone,
  address,
  plateImage,
  vehicleBrand,
  vehicleModel,
  licensePlate,
  muiTheme,
  lapiReview,
  zoning,
  lapiReviewConfigurationDTO,
  contextImages,
  selectedImages,
}: FPSConfirmationModalProps): JSX.Element => {
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [tagsSelected, setTagsSelected] = useState<Set<string>>(new Set());

  function confirmFps() {
    onConfirm(tagsSelected, comment);
  }

  function onChangeComment(e: React.FormEvent<unknown>, newValue: string) {
    setComment(newValue);
  }

  const actions = [
    <FlatButton label="Annuler" secondary onClick={onCancel} />,
    <FlatButton
      label="Valider"
      labelStyle={{ color: BKG_LIGHT_BLUE }}
      primary
      keyboardFocused
      onClick={confirmFps}
    />,
  ];

  const labelFpsId = 'Numéro du FPS :'; // t('label.fpsId')
  const labelShortId = "Identifiant court de l'agent :";
  const labelTimeStamp = 'Date et heure de constatation :';
  const labelPrice = 'Montant du FPS:';
  const labelTicketPrice = 'Montant du ticket réglé:';
  const labelAddress = 'Addresse de constatation :';
  const labelZone = 'Zone :';
  const labelLicensePlate = 'Immatriculation :';
  const labelLicensePlateCountry = 'Pays :';
  const labelLicensePlatePricingCategory = 'Catégorie :';
  const labelLicensePlateVehicleType = 'Type de véhicule :';
  const labelVehicleBrand = 'Marque :';
  const labelVehicleModel = 'Modèle :';

  useEffect(() => {
    setComment(undefined);
    setTagsSelected(new Set());
  }, [lapiReview]);

  return (
    <Dialog
      title="Validation du FPS"
      actions={actions}
      modal
      open={open}
      autoScrollBodyContent
      contentClassName="lapi-fps-confirm"
      actionsContainerClassName="lapi-fps-confirm-actions"
    >
      <div
        className="lapi-fps-confirm__container"
        style={{ color: muiTheme?.textField?.textColor }}
      >
        <div className="lapi-fps-confirm--row">
          <div className="lapi-fps-confirm--col-two-third">
            <div className="lapi-fps-confirm--row lapi-fps-confirm--box lapi-fps-confirm__field">
              <div className="lapi-fps-confirm__label">{labelFpsId}</div>
              <div className="lapi-fps-confirm__value">{fpsId}</div>
            </div>

            <div className="lapi-fps-confirm--col lapi-fps-confirm--box">
              <div className="lapi-fps-confirm--col">
                <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                  <div className="lapi-fps-confirm__label">{labelShortId}</div>
                  <div className="lapi-fps-confirm__value">{agentShortId}</div>
                </div>
                <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                  <div className="lapi-fps-confirm__label">
                    {labelTimeStamp}
                  </div>
                  <div className="lapi-fps-confirm__value">
                    {timeStamp.toLocaleString()}
                  </div>
                </div>
                <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                  <div className="lapi-fps-confirm__label">{labelPrice}</div>
                  <div className="lapi-fps-confirm__value">{price}</div>
                </div>
                <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                  <div className="lapi-fps-confirm__label">
                    {labelTicketPrice}
                  </div>
                  <div className="lapi-fps-confirm__value">{ticketPrice}</div>
                </div>
              </div>

              <div className="lapi-fps-confirm--row">
                <div className="lapi-fps-confirm--col">
                  <div className="lapi-fps-confirm--row lapi-fps-confirm__field lapi-fps-confirm__address">
                    <div className="lapi-fps-confirm__label">
                      {labelAddress}
                    </div>
                    <div className="lapi-fps-confirm__value">
                      <AddressRenderer address={address} />
                    </div>
                  </div>
                  <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                    <div className="lapi-fps-confirm__label">{labelZone}</div>
                    <div className="lapi-fps-confirm__value">
                      {zone ? zone.name : 'Non définie'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lapi-fps-confirm--col lapi-fps-confirm--box">
              <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                <div className="lapi-fps-confirm__label">
                  {labelLicensePlate}
                </div>
                <div className="lapi-fps-confirm__value">
                  {licensePlate.plate}
                </div>
              </div>

              <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                <div className="lapi-fps-confirm__label">
                  {labelLicensePlateCountry}
                </div>
                <div className="lapi-fps-confirm__value">
                  {licensePlate.plateCountry}
                </div>
              </div>

              <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                <div className="lapi-fps-confirm__label">
                  {labelLicensePlatePricingCategory}
                </div>
                <div className="lapi-fps-confirm__value">
                  {licensePlate.pricingCategory}
                </div>
              </div>

              <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                <div className="lapi-fps-confirm__label">
                  {labelLicensePlateVehicleType}
                </div>
                <div className="lapi-fps-confirm__value">
                  {lapiReview.vehicleCategory
                    ? VehicleCategory[lapiReview.vehicleCategory]
                    : VehicleCategory.CAR}
                </div>
              </div>

              <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                <div className="lapi-fps-confirm__label">
                  {labelVehicleBrand}
                </div>
                <div className="lapi-fps-confirm__value">{vehicleBrand}</div>
              </div>

              <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                <div className="lapi-fps-confirm__label">
                  {labelVehicleModel}
                </div>
                <div className="lapi-fps-confirm__value">{vehicleModel}</div>
              </div>
            </div>
          </div>

          <div className="lapi-fps-confirm--col-third">
            <>
              <img
                src={URL.createObjectURL(plateImage)}
                className="lapi-fps-confirm__plate-img"
              />
              <div className="lapi-fps-confirm__item">
                {/* Control Map size & zoom should be the same as in Geolocalisation component to use navigator cache */}
                <StaticControlMap
                  size={{ height: 400, width: 640 }}
                  controlPositionCoordinates={{
                    longitude: lapiReview.controlLongitude,
                    latitude: lapiReview.controlLatitude,
                  }}
                  controlAddressCoordinates={{
                    longitude: lapiReview.addressLongitude,
                    latitude: lapiReview.addressLatitude,
                  }}
                  style={{ width: 448, height: 280, marginTop: 20 }}
                  zoom={18}
                  zoning={zoning}
                />
              </div>
              <div className="lapi-fps-confirm__item">
                {contextImages
                  .filter((f, index) => selectedImages.has(index))
                  .map(f => (
                    <img
                      height={95}
                      style={{ marginLeft: 5 }}
                      src={URL.createObjectURL(f)}
                    />
                  ))}
              </div>
              {/* In quality control mode, there is no comment, and if tags are enabled, then the tags are located lower */}
              {!qualityControlMode && !lapiReviewConfigurationDTO.tagsEnabled && (
                <div className="lapi-fps-confirm--row lapi-fps-confirm__item lapi-fps-confirm__field">
                  <TextField
                    id="lapi-fps-confirm--col-third-text-field"
                    floatingLabelText="Commentaire"
                    floatingLabelFixed
                    value={comment}
                    multiLine
                    onChange={onChangeComment}
                    fullWidth
                    maxlength="200"
                  />
                </div>
              )}
            </>
          </div>
        </div>
        {lapiReviewConfigurationDTO.tagsEnabled && (
          <div className="lapi-fps-confirm--row lapi-fps-confirm-space-between">
            <TagsList
              lapiReviewConfigurationDTO={lapiReviewConfigurationDTO}
              tagsSelected={tagsSelected}
              setTagsSelected={setTagsSelected}
              containerStyle={{
                flex: qualityControlMode ? '0 1 100%' : '0 1 64%',
                display: 'flex',
                flexFlow: 'row wrap',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              style={{}}
            />
            {!qualityControlMode && (
              <div className="lapi-fps-confirm--col-third">
                <div className="lapi-fps-confirm--row lapi-fps-confirm__field">
                  <TextField
                    id="lapi-fps-confirm--col-third-text-field"
                    floatingLabelText="Commentaire"
                    floatingLabelFixed
                    value={comment}
                    multiLine
                    onChange={onChangeComment}
                    fullWidth
                    maxlength="200"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default muiThemeable()(FPSConfirmationModal);
