import React, { CSSProperties, useState } from 'react';
import { TextField } from 'material-ui';
import Checkbox from 'material-ui/Checkbox';

import { PricingPolicyDTO } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

const CONTAINER_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
};

type Props = {
  policy: PricingPolicyDTO;
  onChangePolicy: (policy: PricingPolicyDTO) => void;
  fractionable?: boolean;
  splitNumber?: number | undefined;
  hasErrors: (arg0: boolean) => void;
  contiguousPolicy?: PricingPolicyDTO | null;
  disabled: boolean;
};

const TariffCodeField = ({
  policy,
  contiguousPolicy,
  disabled,
  onChangePolicy,
  hasErrors,
}: Props): JSX.Element => {
  const [isDefault, setIsDefault] = useState<boolean>(!policy.tariffCode);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [tariffCode, setTariffCode] = useState<string>(
    policy.tariffCode || 'default'
  );

  const validateTariffCode = (code: string): boolean => {
    // iS LIKE T_XXX_XXX
    return /[DTZ](_[A-Z]{3}){2}|default$/.test(code);
  };

  const handleTariffCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    code: string
  ) => {
    setTariffCode(code);
    const validate = validateTariffCode(code);
    hasErrors(!validate);
    setIsValid(validate);
    if (validate) {
      onChangePolicy({ ...policy, tariffCode: code });
    }
  };

  const handleCheck = (
    e: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    setIsDefault(!isInputChecked);
    if (!isInputChecked) {
      onChangePolicy({
        ...policy,
        tariffCode: null,
        fractionable: false,
        splitNumber: undefined,
      });
    }
  };

  const handleCheckFractionability = (
    e: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    onChangePolicy({ ...policy, fractionable: isInputChecked });
    if (!isInputChecked) {
      onChangePolicy({
        ...policy,
        fractionable: isInputChecked,
        splitNumber: undefined,
      });
    } else {
      onChangePolicy({ ...policy, fractionable: isInputChecked });
    }
  };

  const handleSplitNumberChange = (splitNumber: string) => {
    onChangePolicy({ ...policy, splitNumber: Number(splitNumber) });
  };

  const displayCondition = !!contiguousPolicy || !isDefault;
  const fractionabilityEnabled = displayCondition && policy.fractionable;

  return (
    <div style={CONTAINER_STYLE}>
      <Checkbox
        label={_tg('tefps.pricing.pricing.tariffCodeField.useATariffCode')}
        checked={displayCondition}
        onCheck={handleCheck}
        style={{ marginTop: 20, width: '100%' }}
        disabled={disabled}
      />
      {displayCondition && (
        <>
          <TextField
            value={
              (contiguousPolicy ? contiguousPolicy.tariffCode : tariffCode) ||
              ''
            }
            onChange={handleTariffCodeChange}
            errorText={
              !isValid &&
              _tg('tefps.pricing.pricing.tariffCodeField.incorrectFormat')
            }
            underlineStyle={{ width: 100 }}
            hintText="X_XXX_XXX"
            floatingLabelFixed
            floatingLabelText={_tg(
              'tefps.pricing.pricing.policy.tariffCode.label'
            )}
            disabled={!!contiguousPolicy || disabled}
            style={{ width: '50%' }}
          />
          <Checkbox
            checked={policy.fractionable} // fractionability
            onCheck={handleCheckFractionability}
            style={{ width: '100%' }}
            label={_tg('tefps.pricing.pricing.tariffCodeField.fractionable')}
            disabled={disabled}
          />
          <TextField
            type="number"
            value={policy.splitNumber || 1}
            disabled={!fractionabilityEnabled || disabled}
            onChange={(_e, value) => handleSplitNumberChange(value)}
            style={{ width: '20%' }}
          />
        </>
      )}
    </div>
  );
};

export default TariffCodeField;
