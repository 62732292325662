import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { getConfigState } from 'config/duck';
import {
  ObservatoryConfigurationDTO,
  SupersetDashboardConfigurationDTO,
} from '@cvfm-front/tefps-types';
import './observatory.css';
import MissNotFound from 'commons/MissNotFound';
import ErrorBlock from 'commons/ErrorBlock';
import { Button, Flex } from '@cvfm-front/commons-ui';
import Select from 'commons/SidebarV2/Components/Select';
import { SupersetDashboardFilters } from 'api/observatory/types';
import { KeyLabel } from '@cvfm-front/commons-types';
import { exportDashboard } from 'api/backoffice';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';
import { highlightDownloads, updateDownloadsList } from 'Topbar/Download/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import translateError from 'commons/Utils/translateErrorUtil';

import {
  DropDownArrow,
  export1D,
  matchesCurrentPeriod,
  loadEmbeddedDashboard,
  periodOptions,
  STYLE_OBS_TEXT,
  STYLE_OBS_TEXT_SELECTED,
  TemporalUnit,
  computeDateInterval,
} from './helpers';
import PeriodSelector from './PeriodSelector';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  observatoryConfigurationDTO: ObservatoryConfigurationDTO;
  authorizedOrganizations: string[]; // TODO in CVFMDEV-7148 get only authorized agent organization ids + get label from initialOrganizationsConfigurations
  userInfo: InternalAgent;
  refreshDownloadsList: () => void;
  highlightDownloadsIcon: () => void;
};

const Observatory = ({
  observatoryConfigurationDTO,
  authorizedOrganizations,
  userInfo,
  refreshDownloadsList,
  highlightDownloadsIcon,
}: Props): JSX.Element => {
  if (!observatoryConfigurationDTO.observatoryEnabled) {
    return <MissNotFound />;
  }
  if (
    observatoryConfigurationDTO.supersetDashboardConfigurations.length === 0
  ) {
    return (
      <div className="superset-error-component">
        {_tg('tefps.observatory.noAvailableDashboard')}
      </div>
    );
  }

  const [error, setError] = useState<string | null>(null);
  const dashboardRef = useRef<HTMLDivElement | null>(null);
  const [filters, setFilters] = useState<SupersetDashboardFilters>(() => {
    const date = new Date();
    const selectedDashboard =
      observatoryConfigurationDTO?.supersetDashboardConfigurations[0];
    return {
      dashboardId: selectedDashboard?.dashboardId,
      organizationId: selectedDashboard?.organizationFilterEnabled
        ? authorizedOrganizations[0]
        : undefined,
      month: date.getMonth() + 1,
      quarter: Math.floor((date.getMonth() + 3) / 3),
      year: date.getFullYear(),
    };
  });
  const [selectedDashboardType, setSelectedDashboardType] = useState<
    SupersetDashboardConfigurationDTO
  >(observatoryConfigurationDTO?.supersetDashboardConfigurations[0]);
  const setMessage = useSnackbar();

  useEffect(() => {
    loadEmbeddedDashboard(filters, dashboardRef, setError);
  }, [filters]);

  const isCurrentPeriod = (period: TemporalUnit) => {
    return matchesCurrentPeriod(filters, period);
  };

  const selectOrganization = (organization: string) => {
    setFilters({ ...filters, organizationId: organization });
  };

  const selectDashboard = (dashboard: SupersetDashboardConfigurationDTO) => {
    setFilters({ ...filters, dashboardId: dashboard.dashboardId });
    setSelectedDashboardType(dashboard);
  };

  const onSelectPeriod = (id: string, period: string) => {
    setFilters({ ...filters, [id]: parseInt(period, 10) });
  };

  const onSelectMonth = (month: number, year: number): void => {
    setFilters({ ...filters, month: month + 1, year });
  };

  const onSelectWeek = (week: number, year: number): void => {
    setFilters({ ...filters, week: week + 1, year });
  };

  const handleClickExport1D = async (): Promise<void> => {
    await export1D(filters, userInfo.agentId);
    highlightDownloadsIcon();
    setTimeout(refreshDownloadsList, 2000);
  };

  const handleClickExportDashboard = async (): Promise<void> => {
    const { organizationId, year, quarter, month, week } = filters;

    let date = new Date();
    if (selectedDashboardType.timeGranularitiesFilter.includes('WEEK')) {
      date = new Date(year, 0, 1 + (week ? week - 1 : 0) * 7, 0, 0, 0);
      date.setDate(date.getDate() + (1 - date.getDay()));
    } else if (
      selectedDashboardType.timeGranularitiesFilter.includes('MONTH')
    ) {
      date = new Date(year, month || 0, 1, 0, 0, 0);
    } else if (
      selectedDashboardType.timeGranularitiesFilter.includes('QUARTER')
    ) {
      const { startDate } = computeDateInterval(year, quarter);
      date = startDate;
    } else if (selectedDashboardType.timeGranularitiesFilter.includes('YEAR')) {
      date = new Date(year, 0, 1);
    } else {
      throw new Error(
        'At least one of the three fields quarter, month, week should be defined'
      );
    }

    const request = {
      organizationId: organizationId?.toLowerCase() || '',
      agentId: userInfo.agentId,
      dashboardId: filters.dashboardId,
      date,
    };

    setMessage("Génération de l'export");
    await exportDashboard(request)
      .then((): void => void setMessage('Envoi par email en cours'))
      .catch(err => {
        const message = err?.json?.error
          ? translateError(err?.json?.error)
          : _tg('commons.tagError');
        setMessage(message);
      });
  };

  const { dashboardId } = filters;

  return (
    <div>
      <Flex className="title">
        <span>{_tg('tefps.observatory.title')}</span>
      </Flex>

      <div className="observatory-container">
        <table className="filters">
          <tbody>
            <tr>
              <td colSpan={2} width="100%">
                <Flex justifyContent="space-between">
                  <Flex>
                    {observatoryConfigurationDTO?.supersetDashboardConfigurations.map(
                      dashboard => {
                        return (
                          <button
                            key={dashboard.dashboardId}
                            type="button"
                            className={`observatory-button-text observatory-button ${
                              filters.dashboardId === dashboard.dashboardId
                                ? 'observatory-button-selected'
                                : ''
                            }`}
                            onClick={() => selectDashboard(dashboard)}
                          >
                            {dashboard.name}
                          </button>
                        );
                      }
                    )}
                  </Flex>
                  <Flex justifyContent="flex-end">
                    {observatoryConfigurationDTO.supersetDashboardConfigurations
                      .filter(config => config.dashboardId === dashboardId)
                      .some(config => config.export1D) && (
                      <>
                        <Button
                          id="export1D"
                          key="Export_1D"
                          className="export1d"
                          onClick={handleClickExport1D}
                        >
                          {_tg('tefps.observatory.export1D')}
                        </Button>
                      </>
                    )}
                    {observatoryConfigurationDTO.exportEnabled && (
                      <Button
                        id="exportDashboard"
                        key="Export_Dashboard"
                        className="exportDashboard"
                        onClick={handleClickExportDashboard}
                      >
                        {_tg('tefps.observatory.export')}
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </td>
            </tr>
            {selectedDashboardType.filtersEnabled &&
              selectedDashboardType.organizationFilterEnabled && (
                <tr>
                  <td>
                    <Flex className="organization-button-group">
                      {authorizedOrganizations.map(organization => {
                        return (
                          <button
                            key={organization}
                            type="button"
                            className={`observatory-button-text ${
                              filters.organizationId === organization
                                ? 'organization-button-group-selected'
                                : ''
                            }`}
                            onClick={() => selectOrganization(organization)}
                          >
                            {organization}
                          </button>
                        );
                      })}
                    </Flex>
                  </td>
                </tr>
              )}
            {selectedDashboardType.filtersEnabled && (
              <tr>
                <td>
                  <Flex>
                    <Select
                      id="year"
                      onChange={onSelectPeriod}
                      selected={`${filters.year}`}
                      textStyle={
                        isCurrentPeriod(TemporalUnit.YEAR)
                          ? STYLE_OBS_TEXT_SELECTED
                          : STYLE_OBS_TEXT
                      }
                      options={periodOptions(TemporalUnit.YEAR) as KeyLabel[]}
                      underlineStyle={{ display: 'none' }}
                      optionStyle={{ color: 'black' }}
                      iconStyle={{ marginTop: -5 }}
                      dropDownMenuProps={{
                        iconButton: (
                          <DropDownArrow
                            selected={isCurrentPeriod(TemporalUnit.YEAR)}
                          />
                        ),
                      }}
                    />
                    {selectedDashboardType?.timeGranularitiesFilter?.includes(
                      'QUARTER'
                    ) && (
                      <Select
                        id="quarter"
                        onChange={onSelectPeriod}
                        selected={`${filters.quarter}`}
                        textStyle={
                          isCurrentPeriod(TemporalUnit.QUARTER)
                            ? STYLE_OBS_TEXT_SELECTED
                            : STYLE_OBS_TEXT
                        }
                        options={
                          periodOptions(TemporalUnit.QUARTER) as KeyLabel[]
                        }
                        underlineStyle={{ display: 'none' }}
                        optionStyle={{ color: 'black' }}
                        iconStyle={{ marginTop: -5 }}
                        dropDownMenuProps={{
                          iconButton: (
                            <DropDownArrow
                              selected={isCurrentPeriod(TemporalUnit.QUARTER)}
                            />
                          ),
                        }}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      gap={10}
                    >
                      {selectedDashboardType?.timeGranularitiesFilter?.includes(
                        'MONTH'
                      ) && (
                        <PeriodSelector
                          temporalUnit={TemporalUnit.MONTH}
                          year={filters.year}
                          selectPeriod={onSelectMonth}
                        />
                      )}
                      {selectedDashboardType?.timeGranularitiesFilter?.includes(
                        'WEEK'
                      ) && (
                        <PeriodSelector
                          temporalUnit={TemporalUnit.WEEK}
                          year={filters.year}
                          selectPeriod={onSelectWeek}
                        />
                      )}
                    </Flex>
                  </Flex>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {error && (
          <div className="superset-error-component">
            <ErrorBlock
              level="WARN"
              error={{
                message: error,
              }}
            />
          </div>
        )}
      </div>
      <div
        ref={dashboardRef}
        style={{ display: error ? 'none' : 'flex', height: 'max-content' }}
        id="superset_embedding_div"
      />
    </div>
  );
};

export default connect(
  state => {
    const { observatoryConfigurationDTO, kpiConfiguration } = getConfigState(
      state
    );
    const { userInfo } = getApiState(state);
    return {
      observatoryConfigurationDTO,
      authorizedOrganizations:
        kpiConfiguration.kpiOrganizationThresholds.map(organization =>
          organization.organizationId.toUpperCase()
        ) || [],
      userInfo,
    };
  },
  dispatch => ({
    refreshDownloadsList: () => dispatch(updateDownloadsList()),
    highlightDownloadsIcon: () => dispatch(highlightDownloads(1)),
  })
)(Observatory);
