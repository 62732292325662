import React, { CSSProperties } from 'react';

import DateComponent from 'commons/Date';
import { ICON_GREEN, ICON_RED } from 'theme';
import ClipBoardButton from 'commons/ClipBoardButton';
import { ControlStatus } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type PhotoInformationProps = {
  datetime: string;
  zoneName: string | undefined;
  complex: boolean;
  complexTreatmentAgent: string | undefined;
  controlId: string;
  controlAgentName: string;
  terminalId?: string;
  controlStatus: ControlStatus;
  reviewReason?: string;
  qualityControlMode: boolean;
};

const SPECIAL_STATE_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  padding: '8px',
  color: 'white',
  alignSelf: 'center',
  marginRight: '10px',
};

const COMPLEX_STYLE: CSSProperties = {
  ...SPECIAL_STATE_STYLE,
  width: '120px',
  backgroundColor: ICON_RED,
};

const WAITING_REVIEW_STYLE: CSSProperties = {
  ...SPECIAL_STATE_STYLE,
  backgroundColor: ICON_GREEN,
};

const BOLD_STYLE: CSSProperties = {
  fontWeight: 'bold',
};

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
};

const PhotoInformation = ({
  datetime,
  zoneName,
  complex,
  complexTreatmentAgent,
  controlId,
  controlAgentName,
  terminalId,
  controlStatus,
  reviewReason,
  qualityControlMode,
}: PhotoInformationProps) => {
  return (
    <div>
      {complex && !qualityControlMode && (
        <div style={ROW_STYLE}>
          <span style={COMPLEX_STYLE}>
            {_tg('tefps.lapiReview.photoInformation.complexeCase')}
          </span>
          {complexTreatmentAgent ? (
            <span>
              {_tg('commons.by')} {complexTreatmentAgent}
            </span>
          ) : (
            <span>
              {_tg('tefps.lapiReview.photoInformation.agentNotFound')}
            </span>
          )}
        </div>
      )}
      {controlStatus === 'WAITING_REVIEW' && reviewReason && (
        <div style={ROW_STYLE}>
          <span style={WAITING_REVIEW_STYLE}>
            {reviewReason === 'vehicleType'
              ? _tg('tefps.lapiReview.photoInformation.verifyVehicleType')
              : _tg('tefps.lapiReview.photoInformation.verifyCategory')}
          </span>
        </div>
      )}
      <div style={ROW_STYLE}>
        {_tg('tefps.lapiReview.photoInformation.preControlAlreadyDone')}
        <DateComponent
          datetime={datetime}
          withYear={false}
          withAlternativeSeparator
          style={BOLD_STYLE}
        />
        {_tg('tefps.lapiReview.photoInformation.preControlAlreadyDoneAtZone')}
        <span style={BOLD_STYLE}>{zoneName}</span>
        {_tg('tefps.lapiReview.photoInformation.preControlAlreadyDoneByAgent')}
        <span style={BOLD_STYLE}>{controlAgentName}</span>
        {terminalId && (
          <>
            <span style={BOLD_STYLE}> {`(${terminalId})`}</span>
          </>
        )}
        {!qualityControlMode && (
          <ClipBoardButton
            subject={_tg('tefps.lapiReview.photoInformation.controlId')}
            textToClip={controlId}
          />
        )}
      </div>
    </div>
  );
};

export default PhotoInformation;
