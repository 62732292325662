import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';

import { getConfigState } from 'config/duck';
import { RoutesGroupDTO, RouteTemplateDTO } from 'api/planner/types';
import {
  deleteRoutesGroup,
  deleteRouteTemplate,
  fetchRoutesGroups,
  fetchRouteTemplates,
  upsertRoutesGroup,
  upsertRouteTemplate,
} from 'api/planner/index';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { InternalApiState } from 'api/duck';

import RoutesMap from './RoutesMap';
import RoutesSidebar from './RoutesSidebar';
import { computeTemplateToUpdate, routesGroupHasChanges } from './helpers';

const { _tg } = window.loadTranslations();

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

type RoutesProps = {
  location: {
    latitude: number;
    longitude: number;
  };
};

type RoutesStates = {
  routesGroups: Array<RoutesGroupDTO>;
  backupGroups: Array<RoutesGroupDTO>;
  routeTemplates: Array<RouteTemplateDTO>;
  error: Error | null | undefined;
  selectedRouteTemplateId: string | null | undefined;
  hasChanged: boolean;
  hiddenRoutesGroupIds: Array<string>;
};

const INITIAL_STATE: RoutesStates = {
  routesGroups: [],
  backupGroups: [],
  routeTemplates: [],
  error: null,
  selectedRouteTemplateId: null,
  hasChanged: false,
  hiddenRoutesGroupIds: [],
};

/**
 * /!\ L'état de cette classe est modifié par le composant enfant RoutesMap via les callbacks onChange
 */
class Routes extends React.Component<RoutesProps, RoutesStates> {
  map: RoutesMap | null | undefined = null;

  constructor(props: RoutesProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    void this.fetch();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    void this.fetch();
  }

  onChange = () => this.setState({ hasChanged: true });

  saveRoutesGroup = async (routesGroup: RoutesGroupDTO, fetch: boolean) => {
    try {
      await upsertRoutesGroup(routesGroup.id, routesGroup);
      if (fetch) {
        void this.fetch();
      }
    } catch (e) {
      this.setState({ error: e as Error });
    }
  };

  saveRouteTemplate = async (
    routeTemplate: RouteTemplateDTO,
    fetch: boolean
  ) => {
    try {
      await upsertRouteTemplate(routeTemplate.id, routeTemplate);
      if (fetch) {
        void this.fetch();
      }
    } catch (e) {
      this.setState({ error: e as Error });
    }
  };

  addOrEditRoute = (
    routeTemplate: RouteTemplateDTO,
    groupIds: Array<string>
  ) => {
    const { routeTemplates, routesGroups } = this.state;
    const newRouteTemplates = routeTemplates.slice();
    const newRoutesGroups = routesGroups.slice();

    const routeTemplateIndex = routeTemplates.findIndex(
      r => r.id === routeTemplate.id
    );
    if (routeTemplateIndex !== -1) {
      newRouteTemplates[routeTemplateIndex] = routeTemplate;
    } else {
      newRouteTemplates.push(routeTemplate);
    }

    newRoutesGroups.forEach(g => {
      if (
        groupIds.includes(g.id) &&
        !g.routeTemplateIds.includes(routeTemplate.id)
      ) {
        g.routeTemplateIds.push(routeTemplate.id);
      } else if (
        g.routeTemplateIds.includes(routeTemplate.id) &&
        !groupIds.includes(g.id)
      ) {
        g.routeTemplateIds.splice(
          g.routeTemplateIds.indexOf(routeTemplate.id),
          1
        );
      }
    });

    this.setState({
      routeTemplates: newRouteTemplates,
      routesGroups: newRoutesGroups,
      hasChanged: true,
      selectedRouteTemplateId: routeTemplate.id,
    });
  };

  saveRouteTemplates = async () => {
    const { routeTemplates, routesGroups, backupGroups } = this.state;
    const toUpdateTemplates: Array<RouteTemplateDTO> = [];

    if (!this.map) {
      return;
    }

    const polylinesPathes = this.map.saveMap();
    polylinesPathes.forEach(polyline => {
      const templateIndex = routeTemplates.findIndex(
        r => r.id === polyline.routeTemplateId
      );

      if (templateIndex !== -1) {
        const template = computeTemplateToUpdate(
          routeTemplates[templateIndex],
          polyline
        );
        if (template) {
          toUpdateTemplates.push(template);
        }
      }
    });

    const promises: Array<Promise<any>> = [];
    toUpdateTemplates.forEach(routeTemplate => {
      promises.push(this.saveRouteTemplate(routeTemplate, false));
    });
    routesGroups.forEach(routesGroup => {
      if (routesGroupHasChanges(routesGroup, backupGroups)) {
        promises.push(this.saveRoutesGroup(routesGroup, false));
      }
    });
    await Promise.all(promises);

    void this.fetch();
  };

  deleteRoutesGroup = async (routesGroup: RoutesGroupDTO) => {
    try {
      await deleteRoutesGroup(routesGroup.id);
      void this.fetch();
    } catch (e) {
      this.setState({ error: e as Error });
    }
  };

  deleteRouteTemplate = async (routeTemplate: RouteTemplateDTO) => {
    try {
      await deleteRouteTemplate(routeTemplate.id);
      void this.fetch();
    } catch (e) {
      this.setState({ error: e as Error });
    }
  };

  editRoutePath = (routeTemplateId: string) => {
    this.setState({ selectedRouteTemplateId: routeTemplateId });
  };

  fetch = async () => {
    try {
      const routesGroups = await fetchRoutesGroups();
      const routeTemplates = await fetchRouteTemplates(false);
      this.setState({
        error: null,
        routesGroups,
        backupGroups: _cloneDeep(routesGroups),
        routeTemplates,
        hasChanged: false,
      });
      if (this.map) {
        this.map.redraw();
      }
    } catch (e) {
      this.setState({ error: e as Error });
    }
  };

  // Permet de récupérer la couleur qui sera affichée pour le parcours
  findColor = (routeTemplateId: string): string => {
    const { routesGroups } = this.state;
    const group = routesGroups.find(g =>
      g.routeTemplateIds.includes(routeTemplateId)
    );
    return group ? group.color : 'black';
  };

  showRoutesGroup = (routesGroupId: string) => {
    const { hiddenRoutesGroupIds } = this.state;
    const newList = [...hiddenRoutesGroupIds];
    newList.splice(newList.indexOf(routesGroupId), 1);
    this.setState({ hiddenRoutesGroupIds: newList });
  };

  hideRoutesGroup = (routesGroupId: string) => {
    const { hiddenRoutesGroupIds } = this.state;
    const newList = [...hiddenRoutesGroupIds, routesGroupId];
    this.setState({ hiddenRoutesGroupIds: newList });
  };

  render() {
    const { location } = this.props;
    const {
      routesGroups,
      routeTemplates,
      selectedRouteTemplateId,
      hasChanged,
      error,
      hiddenRoutesGroupIds,
    } = this.state;

    if (error) {
      return (
        <FlexCenter>
          <ErrorBlock
            message={_tg('feedback.error.fetchRoutes')}
            error={error}
          />
        </FlexCenter>
      );
    }

    return (
      <div style={STYLE_CONTENT}>
        <RoutesSidebar
          routesGroups={routesGroups}
          routeTemplates={routeTemplates}
          saveRoutesGroup={this.saveRoutesGroup}
          saveRouteTemplates={this.saveRouteTemplates}
          addOrEditRoute={this.addOrEditRoute}
          deleteRoutesGroup={this.deleteRoutesGroup}
          deleteRouteTemplate={this.deleteRouteTemplate}
          selectedRouteTemplateId={selectedRouteTemplateId}
          editRoutePath={this.editRoutePath}
          hasChanged={hasChanged}
          showRoutesGroup={this.showRoutesGroup}
          hideRoutesGroup={this.hideRoutesGroup}
        />
        <div style={STYLE_LIST_WRAPPER}>
          <RoutesMap
            ref={ref => {
              this.map = ref;
            }}
            routeTemplates={routeTemplates}
            routesGroups={routesGroups}
            selectedRouteTemplateId={selectedRouteTemplateId}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            center={location}
            onChange={this.onChange}
            findColor={this.findColor}
            editRoutePath={this.editRoutePath}
            hiddenRoutesGroupIds={hiddenRoutesGroupIds}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const { location } = getConfigState(state);
  return {
    location,
  };
};

export default connect(mapStateToProps)(Routes);
