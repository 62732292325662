import { connect } from 'react-redux';
import React, { CSSProperties, useEffect, useRef } from 'react';

import services from 'commons/services';
import { Config, getConfigState } from 'config/duck';
import { DrawingType, MapId, MarkerWrapper } from 'commons/services/MapService';
import { Marker, Position } from 'commons/ClusterMap/types';
import { InteractiveMode } from 'commons/services/ParkingSpace/ParkingSpaceMapService';

type Props = {
  center: Position;
  config: Config;
  markers: Array<Marker>;
  styleOverwrite?: CSSProperties;
  zoom: number;
};

const ICON_MAP = new Map([
  ['address', '/static/img/map/circles/circle_address.png'],
  ['new_address', '/static/img/map/circles/circle_ok.png'],
  ['position', '/static/img/map/circles/circle_position.png'],
]);

const { _tg } = window.loadTranslations(__filename);

const ControlDialogueMap = ({
  center,
  config,
  markers,
  styleOverwrite,
  zoom,
}: Props): JSX.Element => {
  const TRANSLATION_MAP = new Map([
    ['address', _tg('address')],
    ['new_address', _tg('new_address')],
    ['position', _tg('field.control.position')],
  ]);

  const refMap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refMap?.current) {
      void services.mapService.init(
        MapId.LAPI_REVIEW_CHANGE_ADDRESS,
        config,
        refMap
      );
    }
  }, [refMap]);

  useEffect(() => {
    services.mapService.get(MapId.LAPI_REVIEW_CHANGE_ADDRESS)?.clear?.map();

    const markersToAdd: Array<MarkerWrapper> = markers.map(marker => {
      return {
        id: marker.id || '',
        type: DrawingType.CONTROL_DETAIL,
        point: {
          latitude: marker.position.lat,
          longitude: marker.position.lng,
        },
        title: TRANSLATION_MAP.get(marker.status || '') || '',
        symbol:
          ICON_MAP.get(marker.status || '') ||
          '/static/img/map/circles/circle_position.png',
      };
    });
    services.mapService
      .get(MapId.LAPI_REVIEW_CHANGE_ADDRESS)
      ?.markers?.add(markersToAdd);
    services.mapService.get(MapId.LAPI_REVIEW_CHANGE_ADDRESS)?.center?.set({
      latitude: center.lat,
      longitude: center.lng,
    });

    services.mapService.get(MapId.LAPI_REVIEW_CHANGE_ADDRESS)?.zoom?.set(zoom);

    services.zoning.drawZonesOnMap(MapId.LAPI_REVIEW_CHANGE_ADDRESS);
    services.parkingSpaceMap.setInteractiveMode(InteractiveMode.SELECT);

    return () =>
      services.parkingSpaceMap.setInteractiveMode(InteractiveMode.NONE);
  }, [center, markers, zoom]);

  return (
    <div
      ref={refMap}
      style={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '15px',
        ...styleOverwrite,
      }}
    />
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return { config };
})(ControlDialogueMap);
