import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';

const { _t } = window.loadTranslations(__filename);

const STYLE_CHECKBOX: CSSProperties = {
  marginTop: 20,
  width: 150,
  float: 'left',
};

const STYLE_CHECKBOX_ICON: CSSProperties = {
  marginRight: 8,
};

type CommentInputProps = {
  canSendComment: boolean;
  commentText: string;
  setCommentText: (commentText: string) => void;
  shouldCloseAlert: boolean;
  setShouldCloseAlert: (shouldCloseAlert: boolean) => void;
};

const CommentInput = ({
  commentText,
  setCommentText,
  shouldCloseAlert,
  setShouldCloseAlert,
  canSendComment,
}: CommentInputProps): JSX.Element => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    setCommentText(newValue);
  };

  return (
    <>
      <TextField
        name="commentTextField"
        autoComplete="off"
        autoFocus
        placeholder={_t('element.writeCommentHere')}
        value={commentText}
        style={{ width: '70%' }}
        onChange={handleChange}
      />
      <Checkbox
        style={STYLE_CHECKBOX}
        iconStyle={STYLE_CHECKBOX_ICON}
        checked={shouldCloseAlert}
        onCheck={() => setShouldCloseAlert(!shouldCloseAlert)}
        title={_t('element.closeAlert')}
        label={_t('element.closeAlert')}
        disabled={!canSendComment}
      />
    </>
  );
};

export default CommentInput;
