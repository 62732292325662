import React from 'react';
import { MenuItem, SelectField } from 'material-ui';

import { ParkingOrientationList } from '@cvfm-front/tefps-types';
import { FilterBarSection } from '@cvfm-front/tefps-ui';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { FilterBarSelectLabelStyle } from 'styles/FilterBarSelectStyle';
import { MapId } from 'commons/services/MapService';

const { _tg } = window.loadTranslations(__filename);

function ParkingSpaceFilterByOrientationSection(): JSX.Element {
  const orientations = useWatcher(
    services.parkingSpaceApi.watchOrientationsFilter,
    new Set()
  );

  return (
    <>
      <FilterBarSection
        title={_tg('filterByOrientation')}
        className="filter-bar-section__title__zero-margin-bottom"
        fields={[
          <SelectField
            labelStyle={FilterBarSelectLabelStyle}
            value={Array.from(orientations)}
            multiple
          >
            {ParkingOrientationList.map(orientation => (
              <MenuItem
                key={orientation}
                value={orientation}
                primaryText={_tg(`ParkingOrientation.${orientation}`)}
                onClick={async () => {
                  services.parkingSpaceApi.toggleOrientationFilter(orientation);
                  await services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
                }}
              />
            ))}
          </SelectField>,
        ]}
        actions={[]}
      />
    </>
  );
}

export default ParkingSpaceFilterByOrientationSection;
