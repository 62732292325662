import {
  GlobalSettings,
  PriceModulationDTO,
  PricingDTO,
  PricingPolicyDTO,
} from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

export const DEFAULT_FPS_REDUCTION: PriceModulationDTO = {
  percentage: 0,
  amount: 0,
  hours: 0,
};

const computeFpsReduction = (
  formFpsReduction: PriceModulationDTO | null | undefined
): PriceModulationDTO | null | undefined => {
  if (
    formFpsReduction &&
    (formFpsReduction.percentage >= 0 || formFpsReduction.amount >= 0)
  ) {
    return {
      percentage: formFpsReduction.percentage,
      amount: formFpsReduction.amount * 100,
      hours: formFpsReduction.hours,
    };
  }
  return null;
};

const uncomputeFpsReduction = (
  realFpsReduction: PriceModulationDTO | null | undefined
): PriceModulationDTO | null | undefined => ({
  percentage: realFpsReduction ? realFpsReduction.percentage : 0,
  amount: realFpsReduction ? realFpsReduction.amount / 100 : 0,
  hours: realFpsReduction ? realFpsReduction.hours : 0,
});

export const getGlobalSettings = (pricing: PricingDTO): GlobalSettings => ({
  ...pricing,
  fpsReduction: uncomputeFpsReduction(pricing.fpsReduction),
  fpsFixedPrice: pricing.fpsFixedPrice ? pricing.fpsFixedPrice / 100 : 0,
});

export const mergeGlobalSettings = (
  pricing: PricingDTO | null | undefined,
  globalSettings: GlobalSettings
): PricingDTO =>
  ({
    ...pricing,
    ...globalSettings,
    fpsReduction: computeFpsReduction(globalSettings.fpsReduction),
    fpsFixedPrice: globalSettings.fpsFixedPrice,
  } as PricingDTO);

export const getPricingPolicies = (
  pricing: PricingDTO
): Array<PricingPolicyDTO> => {
  if (pricing.pricingPolicies) {
    return pricing.pricingPolicies.map(policy => ({
      ...policy,
      fpsReduction: policy.fpsReduction
        ? uncomputeFpsReduction(policy.fpsReduction)
        : null,
    }));
  }
  return [];
};

export const mergePricingPolicies = (
  pricing: PricingDTO | null | undefined,
  pricingPolicies: Array<PricingPolicyDTO>
): PricingDTO =>
  ({
    ...pricing,
    pricingPolicies: pricingPolicies.map(policy => ({
      ...policy,
      fpsReduction: computeFpsReduction(policy.fpsReduction),
    })),
  } as PricingDTO);

/**
 * Returns a positive price with a maximum 2 digits for the decimal part
 */
export const correctPrice = (price: number): number =>
  Math.max(0, Math.round(price * 100) / 100);

/**
 * The returned duration is a positive integer
 */
export const correctDuration = (duration: number): number =>
  Math.max(0, Math.ceil(duration));

/**
 * Returns a percentage betwwen 0 and 100
 */
export const correctPercentage = (percentage: number): number =>
  Math.min(Math.max(0, percentage), 100);

export const DAYS_OFF = (): Array<{
  id: string;
  name: string;
}> => [
  { id: 'NOUVEL_AN', name: _tg('field.daysOff.newYear') },
  {
    id: 'JEUDI_SAINT',
    name: _tg('field.daysOff.holyThursday'),
  },
  { id: 'PAQUES', name: _tg('field.daysOff.easter') },
  { id: 'LUNDI_DE_PAQUES', name: _tg('field.daysOff.easterMonday') },
  {
    id: 'FETE_TRAVAIL',
    name: _tg('field.daysOff.work'),
  },
  { id: 'ARMISTICE_1945', name: _tg('field.daysOff.victory1945') },
  { id: 'ASCENSION', name: _tg('field.daysOff.ascension') },
  {
    id: 'LUNDI_DE_PENTECOTE',
    name: _tg('field.daysOff.whitMonday'),
  },
  { id: 'FETE_NATIONALE', name: _tg('field.daysOff.nationalHolyday') },
  { id: 'ASSOMPTION', name: _tg('field.daysOff.assumption') },
  {
    id: 'TOUSSAINT',
    name: _tg('field.daysOff.allSaintsDay'),
  },
  { id: 'ARMISTICE_1918', name: _tg('field.daysOff.peace1918') },
  { id: 'NOEL', name: _tg('field.daysOff.christmas') },
  {
    id: 'SAINT_ETIENNE',
    name: _tg('field.daysOff.saintEtienne'),
  },
];
