import React, { Component, CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import { red500 } from 'material-ui/styles/colors';
import moment, { Duration } from 'moment';

const { _tg } = window.loadTranslations(__filename);

const INLINE_STYLE: CSSProperties = {
  width: 60,
};

const INPUT_STYLE: CSSProperties = {
  textAlign: 'right',
};

const INPUT_STYLE_ERROR: CSSProperties = {
  ...INPUT_STYLE,
  color: red500,
};

type DurationFieldProps = {
  index: number;
  duration: Duration;
  previousDuration: Duration;
  nextDuration: Duration;
  changeDuration: (index: number, duration: Duration) => void;
  disabled: boolean;
};

type DurationFieldState = {
  error: string | null | undefined;
};

const isDurationValid = ({
  duration,
  previousDuration,
  nextDuration,
}: {
  duration: Duration;
  previousDuration: Duration;
  nextDuration: Duration;
}) => {
  if (
    duration?.asMinutes() < previousDuration?.asMinutes() ||
    duration?.asMinutes() > nextDuration?.asMinutes()
  ) {
    return _tg('tefps.pricing.pricing.errors.durationsShouldBeIncreasing');
  }
  return undefined;
};

class DurationField extends Component<DurationFieldProps, DurationFieldState> {
  constructor(props: DurationFieldProps) {
    super(props);
    this.state = this.computeState(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    duration,
    previousDuration,
    nextDuration,
  }: DurationFieldProps): void {
    this.setState(
      this.computeState({ duration, previousDuration, nextDuration })
    );
  }

  computeState = ({
    duration,
    previousDuration,
    nextDuration,
  }: {
    duration: Duration;
    previousDuration: Duration;
    nextDuration: Duration;
  }): {
    error: string | undefined;
  } => {
    return {
      error: isDurationValid({ duration, previousDuration, nextDuration }),
    };
  };

  changeMonths = (_event: React.MouseEvent<any>, value: string): void => {
    const months = Number(value);
    const { duration, changeDuration, index } = this.props;
    const newDuration = moment.duration({
      minutes: duration.minutes(),
      hours: duration.hours(),
      days: duration.days(),
      months,
    });
    changeDuration(index, newDuration);
  };

  changeYears = (_event: React.MouseEvent<any>, value: string): void => {
    const years = Number(value);
    const { duration, changeDuration, index } = this.props;
    const newDuration = moment.duration({
      minutes: duration.minutes(),
      hours: duration.hours(),
      days: duration.days(),
      months: duration.months(),
      years,
    });
    changeDuration(index, newDuration);
  };

  changeDays = (_event: React.MouseEvent<any>, value: string): void => {
    const days = Number(value);
    const { duration, changeDuration, index } = this.props;
    const newDuration = moment.duration({
      minutes: duration.minutes(),
      hours: duration.hours(),
      days,
      months: duration.months(),
      years: duration.years(),
    });
    changeDuration(index, newDuration);
  };

  changeHours = (_event: React.MouseEvent<any>, value: string): void => {
    const hours = Number(value);
    const { duration, changeDuration, index } = this.props;
    const newDuration = moment.duration({
      minutes: duration.minutes(),
      hours,
      days: duration.days(),
      months: duration.months(),
      years: duration.years(),
    });
    changeDuration(index, newDuration);
  };

  changeMinutes = (_event: React.MouseEvent<any>, value: string): void => {
    const minutes = Number(value);
    const { duration, changeDuration, index } = this.props;
    const newDuration = moment.duration({
      minutes,
      hours: duration.hours(),
      days: duration.days(),
      months: duration.months(),
      years: duration.years(),
    });
    changeDuration(index, newDuration);
  };

  render(): JSX.Element {
    const { error } = this.state;
    const { duration, disabled } = this.props;
    const inputStyle = error ? INPUT_STYLE_ERROR : INPUT_STYLE;

    return (
      <div>
        <TextField
          name="years"
          value={duration.years()}
          onChange={this.changeYears}
          type="number"
          min={0}
          style={INLINE_STYLE}
          inputStyle={inputStyle}
          underlineShow={false}
          disabled={disabled}
        />
        {_tg('field.date.year_shortened_uppercase')}
        <TextField
          name="months"
          value={duration.months()}
          onChange={this.changeMonths}
          type="number"
          min={0}
          max={11}
          style={INLINE_STYLE}
          inputStyle={inputStyle}
          underlineShow={false}
          disabled={disabled}
        />
        {_tg('field.date.month_shortened_uppercase')}
        <TextField
          name="days"
          value={duration.days()}
          onChange={this.changeDays}
          type="number"
          min={0}
          style={INLINE_STYLE}
          inputStyle={inputStyle}
          underlineShow={false}
          disabled={disabled}
        />
        {_tg('field.date.day_shortened_uppercase')}
        <TextField
          name="hours"
          value={duration.hours()}
          onChange={this.changeHours}
          type="number"
          min={0}
          max={24}
          style={INLINE_STYLE}
          inputStyle={inputStyle}
          underlineShow={false}
          disabled={disabled}
        />
        {_tg('field.date.hour_shortened')}
        <TextField
          name="min"
          value={duration.minutes()}
          onChange={this.changeMinutes}
          type="number"
          min={0}
          max={60}
          style={INLINE_STYLE}
          inputStyle={inputStyle}
          underlineShow={false}
          disabled={disabled}
        />
        {_tg('field.date.min_shortened')}
      </div>
    );
  }
}

export default DurationField;
