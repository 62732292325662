import React, { CSSProperties } from 'react';
import IconButton from 'material-ui/IconButton';
import ErrorIcon from 'material-ui/svg-icons/alert/error-outline';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';

import { BKG_LIGHT_BLUE, TXT_BLACK } from 'theme';
import Logout from 'tefps/LapiReview/LapiSideBar/components/Logout';
import TEFPSButton from 'tefps/LapiReview/LapiSideBar/components/TEFPSControlButton';

const BUTTONS_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const CENTER_COTNAINER_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const ERROR_ROW_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '80px',
};

const ERROR_ICON_STYLE: CSSProperties = {
  width: '80px',
  height: '80px',
};
const ERROR_TEXT_STYLE: CSSProperties = {
  fontSize: '22px',
  marginLeft: 20,
  marginRight: 10,
};

const REFRESH_ICON_STYLE: CSSProperties = {
  width: '120px',
  height: '120px',
  fill: BKG_LIGHT_BLUE,
};

const REFRESH_BUTTON_STYLE: CSSProperties = { width: '120px', height: '120px' };

type Props = {
  refresh: () => void;
};

const ErrorPage = ({ refresh }: Props) => {
  const errorText = "Il n'y a pas de contrôles à afficher";

  return (
    <>
      <div style={BUTTONS_STYLE}>
        <Logout size="m" />
        <TEFPSButton size="m" />
      </div>
      <div style={CENTER_COTNAINER_STYLE}>
        <div style={ERROR_ROW_STYLE}>
          <ErrorIcon style={ERROR_ICON_STYLE} />
          <span style={ERROR_TEXT_STYLE}> {errorText}</span>
        </div>
        <IconButton
          tooltip="Recharger la page"
          onClick={refresh}
          iconStyle={REFRESH_ICON_STYLE}
          style={REFRESH_BUTTON_STYLE}
        >
          <RefreshIcon />
        </IconButton>
      </div>
    </>
  );
};

export default ErrorPage;
