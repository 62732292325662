import cloneDeep from 'lodash.clonedeep';

import { NotificationService } from '@cvfm-front/commons-services';
import { Watcher } from '@cvfm-front/commons-utils';
import { getBillById, putBill } from 'api/cvfm-parking/BillApi';
import { Bill } from '@cvfm-front/tefps-types';

import BillVersionPatchesGenerator from './BillVersionPatchesGenerator';
import { BILL_PLACEHOLDER } from './BillPlaceholder';

const BillService = () => {
  const {
    watchValue: watchBill,
    setValue: setBill,
    getValue: getBill,
  } = Watcher<Bill>(BILL_PLACEHOLDER);
  const {
    watchValue: watchEditableBill,
    setValue: setEditableBill,
    getValue: getEditableBill,
  } = Watcher<Bill>(BILL_PLACEHOLDER);

  const fetchBill = async (billId: string) => {
    try {
      const bill = await getBillById(billId);
      setBill(bill);
      setEditableBill(cloneDeep(bill));
      return Promise.resolve();
    } catch (e) {
      NotificationService.pushNotification({
        id: 'bill-fetch',
        message: `Facture ${billId} introuvable`,
        type: 'error',
      });
      return Promise.reject();
    }
  };

  const setField = (onChangeCallback: (bill: Bill) => void) => {
    const editableBill = getEditableBill();

    onChangeCallback(editableBill);

    setEditableBill({ ...editableBill }); // We need to change the reference of the object to trigger Watcher callbacks
  };

  const submitChanges = async () => {
    const bill = getBill();
    const editableBill = getEditableBill();
    const patches = BillVersionPatchesGenerator(bill, editableBill);

    try {
      const patchedBill = await putBill(editableBill.billId, patches);

      NotificationService.pushNotification({
        id: 'bill-put',
        message: 'Enregistré avec succès',
        type: 'success',
      });

      setBill(cloneDeep(patchedBill));
      setEditableBill(cloneDeep(patchedBill));
    } catch (e) {
      NotificationService.pushNotification({
        id: 'bill-put',
        message: 'Erreur lors de la mise à jour',
        type: 'error',
      });
    }
  };

  const autoCompleteFromEAutoIndex = (input: string) => {
    const editableBill = getEditableBill();
    const lastIndexVersion = editableBill.versions.length - 1;
    const lines = input.split('\n');
    let nextLineCallback: any;

    lines.forEach(line => {
      if (nextLineCallback) {
        nextLineCallback(editableBill, line);
        nextLineCallback = null;
        return;
      }

      switch (line) {
        case 'Entreprise / organisme :':
          nextLineCallback = (bill: Bill, value: string) => {
            bill.versions[lastIndexVersion].offender.companyName = value;
          };
          return;
        case 'Rue :':
          nextLineCallback = (bill: Bill, value: string) => {
            bill.versions[lastIndexVersion].offender.address.streetName = value;
          };
          return;
        case 'Localité :':
          nextLineCallback = (bill: Bill, value: string) => {
            bill.versions[
              lastIndexVersion
            ].offender.address.addressLocality = value;
          };
          return;
        case 'NPA :':
          nextLineCallback = (bill: Bill, value: string) => {
            bill.versions[lastIndexVersion].offender.address.postalCode = value;
          };
          return;
        case 'Prénom :':
          nextLineCallback = (bill: Bill, value: string) => {
            bill.versions[lastIndexVersion].offender.givenName = value;
          };
          break;
        case 'Nom de famille :':
          nextLineCallback = (bill: Bill, value: string) => {
            bill.versions[lastIndexVersion].offender.familyName = value;
          };
          break;
        default:
          nextLineCallback = null;
      }
    });

    setEditableBill({ ...editableBill });
  };

  const autoCompleteFromInfoCar = (input: string) => {
    const editableBill = getEditableBill();
    const lastIndexVersion = editableBill.versions.length - 1;
    const lines = input.split('\n');

    lines.forEach(line => {
      const [key, value] = line.split('\t');

      const lastSpaceIndex = value.trim().lastIndexOf(' ');
      const [postalCode, locality] = value.trim().split(/ (.*)/);

      switch (key.trim()) {
        case 'Nom':
          editableBill.versions[
            lastIndexVersion
          ].offender.familyName = value.trim();
          break;
        case 'Prénom':
          editableBill.versions[
            lastIndexVersion
          ].offender.givenName = value.trim();
          break;
        case 'Adresse':
          editableBill.versions[
            lastIndexVersion
          ].offender.address.streetName = value.trim().slice(0, lastSpaceIndex);
          editableBill.versions[
            lastIndexVersion
          ].offender.address.streetNumber = value
            .trim()
            .slice(lastSpaceIndex + 1);
          break;
        case 'No postal et lieu':
          editableBill.versions[
            lastIndexVersion
          ].offender.address.addressLocality = locality;
          editableBill.versions[
            lastIndexVersion
          ].offender.address.postalCode = postalCode;
          break;
        default:
          break;
      }
    });
    if (
      editableBill.versions[lastIndexVersion].offender.givenName === '' &&
      editableBill.versions[lastIndexVersion].offender.familyName !== ''
    ) {
      editableBill.versions[lastIndexVersion].offender.companyName =
        editableBill.versions[lastIndexVersion].offender.familyName;
      editableBill.versions[lastIndexVersion].offender.familyName = '';
    }
    setEditableBill({ ...editableBill });
  };

  /* INPUT EXEMPLE
   *
   * Plaque - Vert agricole Normale
   * FR
   * 5349
   * NomSiffert-Zollet Hans-Peter
   * AdresseHolenacher 3
   * Ville3182 Ueberstorf
   */
  const autoCompleteFromOCNOrVS = (input: string) => {
    const editableBill = getEditableBill();
    const lastIndexVersion = editableBill.versions.length - 1;
    const lines = input.split('\n');

    lines.forEach(line => {
      const key = /^Nom|^Adresse|^Ville/.exec(line);
      if (key) {
        const [, value] = line.split(key[0]);

        const lastSpaceIndex = value.trim().lastIndexOf(' ');
        const [postalCode, locality] = value.trim().split(/ (.*)/);

        switch (key[0].trim()) {
          case 'Nom':
            editableBill.versions[
              lastIndexVersion
            ].offender.familyName = value.trim().slice(0, lastSpaceIndex);
            editableBill.versions[
              lastIndexVersion
            ].offender.givenName = value.trim().slice(lastSpaceIndex + 1);
            break;
          case 'Adresse':
            editableBill.versions[
              lastIndexVersion
            ].offender.address.streetName = value
              .trim()
              .slice(0, lastSpaceIndex);
            editableBill.versions[
              lastIndexVersion
            ].offender.address.streetNumber = value
              .trim()
              .slice(lastSpaceIndex + 1);
            break;
          case 'Ville':
            editableBill.versions[
              lastIndexVersion
            ].offender.address.addressLocality = locality;
            editableBill.versions[
              lastIndexVersion
            ].offender.address.postalCode = postalCode;
            break;
          default:
            break;
        }
      }
    });

    setEditableBill({ ...editableBill });
  };

  const getCantonIdFromPlate = (plate: string): string => {
    return plate.trim().slice(0, 2);
  };

  const getCantonServiceFromPlate = (
    plate: string
  ):
    | { label: string; url: string; autoComplete: (input: string) => void }
    | undefined => {
    const IdPlate = getCantonIdFromPlate(plate);
    switch (IdPlate) {
      case 'UR':
      case 'SZ':
      case 'GL':
      case 'SQ':
      case 'BS':
      case 'BL':
      case 'AR':
      case 'AI':
      case 'SG':
      case 'GR':
      case 'TG':
        return {
          label: 'eautoindex.ch',
          url: 'https://www.eautoindex.ch/search',
          autoComplete: autoCompleteFromEAutoIndex,
        };
      case 'VD':
        return {
          label: 'infocar.ch',
          url:
            'https://www.infocar.ch/Login.aspx?ReturnUrl=%2fSuchen%2fKontrollschild%2fSuchenKontrollschild.aspx',
          autoComplete: autoCompleteFromInfoCar,
        };
      case 'FR':
        return {
          label: 'ocn.ch',
          url:
            'https://www.ocn.ch/fr/conduire/plaques-et-carte-grise/auto-index-trouver-un-detenteur-ou-une-detentrice-de-vehicule',
          autoComplete: autoCompleteFromOCNOrVS,
        };
      case 'VS':
        return {
          label: 'vs.ch',
          url:
            'https://www.vs.ch/web/scn/recherche-d-un-detenteur-de-plaques-vs',
          autoComplete: autoCompleteFromOCNOrVS,
        };
      default:
        return undefined;
    }
  };

  return {
    fetchBill,
    watchBill,
    setField,
    submitChanges,
    watchEditableBill,
    getCantonServiceFromPlate,
  };
};

export default BillService();
