import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import BoButton from 'facade/BoButton';
import { BKG_LIGHT, BKG_LIGHT_BLUE } from 'theme';
import ExemptionReasonModal from 'tefps/LapiReview/Action/ExemptionReasonModal';
import { ExemptionReason, getConfigState } from 'config/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { exemptLapiReview } from 'api/lapiReview';
import { LapiReviewDTO } from 'api/lapiReview/types';
import { uploadFileAndReturnUrn } from 'api/mediaupload';
import { PatchObject } from 'api/commonTypes';
import { patchControl } from 'api/control';

import { registerQualityControlDecision } from './helpers';

const STYLE_BUTTON: CSSProperties = { width: '150px', margin: '10px' };

type ReduxStateProps = {
  exemptionReasonsConfigurations: Array<ExemptionReason> | null | undefined;
  improveLapiImagesEnabled: boolean;
};

type Props = ReduxStateProps & {
  lapiReview: LapiReviewDTO;
  fetchNew: Function;
  qualityControlMode: boolean;
  contextImages: Array<File>;
};

const uploadControlMedias = (
  lapiReview: LapiReviewDTO,
  mediaUpload: string,
  controlImages: Array<File>
) => {
  return controlImages.map(file => {
    return new Promise<PatchObject<unknown>>((resolve, reject) => {
      uploadFileAndReturnUrn(mediaUpload, file)
        .then(urn => {
          const existingFile =
            file && lapiReview.medias.find(m => m.name === file.name);

          const controlMediaPatch = {
            op: 'replace',
            path: '/medias',
            value: {
              url: urn,
              name: file ? file.name : uuidv4(),
              controlMediaType: 'CONTEXT',
              position: existingFile?.position,
            },
          };
          resolve(controlMediaPatch);
        })
        .catch(() => {
          const rejectError = new Error(
            `Erreur lors de l'envoi des images du contrôle ${lapiReview.controlId}`
          );
          rejectError.name = 'ERR_UPLOAD_CONTROL';
          reject(rejectError);
        });
    });
  });
};

const NFPS = ({
  lapiReview,
  exemptionReasonsConfigurations,
  fetchNew,
  qualityControlMode,
  contextImages,
  improveLapiImagesEnabled,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const setMessage = useSnackbar();

  const patchReplaceControlMedias = (
    controlId: string,
    controlPatches: Array<PatchObject<unknown>>
  ) => {
    return new Promise<void>((resolve, reject) => {
      patchControl(controlId, controlPatches)
        .then(() => {
          resolve();
        })
        .catch(() => {
          const rejectError = new Error(
            `Erreur lors de la sauvegarde des images du controle ${controlId}`
          );
          rejectError.name = 'ERR_PATCH_CONTROL';
          reject(rejectError);
        });
    });
  };

  const exemptControl = async (
    comment: string,
    exemptionReasonKey: string,
    tags: Set<string>
  ) => {
    const exemptionDTO = {
      controlId: lapiReview.controlId,
      exemptionReason: exemptionReasonKey,
      vehicleBrand: lapiReview.vehicleBrand,
      vehicleModel: lapiReview.vehicleModel,
      vehicleCategory: lapiReview.vehicleCategory,
      licensePlate: lapiReview.licensePlate,
      statementAddress: lapiReview.statementAddress,
      comment,
      tags: Array.from(tags),
    };
    try {
      if (qualityControlMode) {
        await registerQualityControlDecision(
          lapiReview,
          'EXEMPTION',
          exemptionReasonKey,
          Array.from(tags)
        );
      } else {
        const controlResponse = await exemptLapiReview(exemptionDTO);

        if (controlResponse.mediaUpload && improveLapiImagesEnabled) {
          Promise.all(
            uploadControlMedias(
              lapiReview,
              controlResponse.mediaUpload,
              contextImages
            )
          )
            .then((controlPatches: Array<PatchObject<unknown>>) => {
              return patchReplaceControlMedias(
                lapiReview.controlId,
                controlPatches
              );
            })
            .catch((error: Error) => {
              throw error;
            });
        }
      }
      fetchNew();
      setMessage('Le contrôle est placé en NFPS');
    } catch (e) {
      setMessage('Erreur lors de la mise à jour du contrôle');
    }
  };

  const onConfirm = (
    comment: string,
    exemptionReasonKey: string,
    tags: Set<string>
  ) => {
    void exemptControl(comment, exemptionReasonKey, tags);
    setOpen(false);
  };

  return (
    <div>
      <BoButton
        label="NFPS"
        backgroundColor={BKG_LIGHT_BLUE}
        labelColor={BKG_LIGHT}
        onClick={() => setOpen(true)}
        style={STYLE_BUTTON}
        disabled={!exemptionReasonsConfigurations}
      />
      <ExemptionReasonModal
        open={open}
        onConfirm={onConfirm}
        onCancel={() => setOpen(false)}
        qualityControlMode={qualityControlMode}
        controlId={lapiReview.controlId}
      />
    </div>
  );
};

export default connect(
  (state): ReduxStateProps => {
    const {
      exemptionReasonsConfigurations,
      lapiReviewConfigurationDTO: { improveLapiImagesEnabled },
    } = getConfigState(state);
    return { exemptionReasonsConfigurations, improveLapiImagesEnabled };
  }
)(NFPS);
