import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, FlatButton, TextField } from 'material-ui';
import HuePicker from 'react-color/lib/components/hue/Hue';
import { ColorResult } from 'react-color';

import usePatrolZoneToEdit from 'commons/hooks/usePatrolZoneToEdit';
import services from 'commons/services';
import { PatrolZoneDTO } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';

const { _tg } = window.loadTranslations(__filename);

const PatrolZoneEditModal = (): JSX.Element => {
  const patrolZoneToEdit = usePatrolZoneToEdit();
  const [patrolZone, setPatrolZone] = useState<PatrolZoneDTO | null>(null);

  useEffect(() => {
    if (patrolZoneToEdit) {
      setPatrolZone(patrolZoneToEdit);
    } else {
      setPatrolZone(null);
    }
  }, [patrolZoneToEdit]);

  const cancel = useCallback((): void => {
    services.patrolZoneService.setPatrolZoneToEdit(null);
  }, []);

  const deletePatrolZone = useCallback((): void => {
    if (patrolZoneToEdit) {
      services.patrolZoneService.deletePatrolZone(patrolZoneToEdit);
    }
    services.patrolZoneService.setPatrolZoneToEdit(null);
  }, [patrolZoneToEdit]);

  const update = useCallback((): void => {
    if (patrolZone) {
      services.patrolZoneService.changePatrolZone(patrolZone);
    }
    services.patrolZoneService.setPatrolZoneToEdit(null);
  }, [patrolZone]);

  // does not display modal at all to prevent typing issues
  if (!patrolZone) {
    return <></>;
  }

  const actions = [
    <FlatButton label={_tg('action.cancel')} onClick={cancel} />,
    <FlatButton
      label={_tg('action.delete')}
      secondary
      onClick={deletePatrolZone}
    />,
    <FlatButton label={_tg('action.update')} primary onClick={update} />,
  ];

  return (
    <Dialog
      actions={actions}
      title={_tg('action.edit')}
      open={!!patrolZoneToEdit}
      autoScrollBodyContent
    >
      <Flex flexDirection="column">
        <TextField
          fullWidth
          name="name"
          floatingLabelText={_tg('field.name')}
          onChange={(_e, value) => {
            setPatrolZone({ ...patrolZone, name: value });
          }}
          value={patrolZone.name}
        />
        <TextField
          fullWidth
          name="description"
          floatingLabelText={_tg('field.description')}
          onChange={(_e, value) => {
            setPatrolZone({ ...patrolZone, description: value });
          }}
          value={patrolZone.description}
        />
        <div style={{ marginTop: 14 }}>
          {_tg('commons.color')}
          <br />
          <br />
          <HuePicker
            color={patrolZone.color}
            width="100%"
            onChangeComplete={(color: ColorResult) => {
              setPatrolZone({ ...patrolZone, color: color.hex });
            }}
          />
        </div>
      </Flex>
    </Dialog>
  );
};

export default PatrolZoneEditModal;
