import React, { useCallback, useContext, useState } from 'react';

import ConfirmModal from 'commons/ConfirmModal';
import useWatcher from 'commons/hooks/useWatcher';

import FpsExportTypeModal from '../Fps/Detail/FpsExportTypeModal';

import { PdfExportContext } from './PdfExportContext';

type Props = {
  numberOfPdf: number;
};

const { _tg } = window.loadTranslations();

const PdfExportConfirmationModal = ({ numberOfPdf }: Props) => {
  const service = useContext(PdfExportContext);
  const isModalOpen = useWatcher(service.watchIsConfirmationModalOpen, false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onCancel = useCallback(
    () => service.setIsConfirmationModalOpen(false),
    []
  );
  const onConfirm = useCallback((withPictures: boolean) => {
    service.postToApiPdfExport(withPictures);
  }, []);

  const onNext = () => {
    onCancel();
    setIsOpen(true);
  };

  return (
    <>
      <ConfirmModal
        open={isModalOpen}
        title={_tg(`tefps.PdfExport.PdfExportConfirmationModal.title`)}
        message={_tg(`tefps.PdfExport.PdfExportConfirmationModal.message`, {
          numberOfPdf,
        })}
        onCancel={onCancel}
        onConfirm={onNext}
      />
      <FpsExportTypeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onExport={onConfirm}
      />
    </>
  );
};

export default PdfExportConfirmationModal;
