import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';

import { PriceModulationDTO } from '@cvfm-front/tefps-types';
import NumberField from 'commons/NumberField';

import { correctDuration, correctPercentage, correctPrice } from '../helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TEXT_FIELD: CSSProperties = { textAlign: 'center' };

type PriceModulationFieldProps = {
  priceModulation: PriceModulationDTO;
  updatePriceModulation: (priceModulation: PriceModulationDTO) => void;
  disabled: boolean;
  styleTitle?: Record<string, any>;
  styleField?: Record<string, any>;
};

class PriceModulationField extends React.Component<PriceModulationFieldProps> {
  changeFpsReducedAmount = (newValue: number): void => {
    const { priceModulation, updatePriceModulation } = this.props;
    updatePriceModulation({
      ...priceModulation,
      percentage: 0,
      amount: correctPrice(Number(newValue)),
    });
  };

  changeFpsReducedPercentage = (
    _event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { priceModulation, updatePriceModulation } = this.props;
    updatePriceModulation({
      ...priceModulation,
      percentage: correctPercentage(Number(newValue)),
      amount: 0,
    });
  };

  changeFpsReducedDuration = (
    _event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { priceModulation, updatePriceModulation } = this.props;
    updatePriceModulation({
      ...priceModulation,
      hours: correctDuration(Number(newValue)), // Paiement rapide pendant 5 jours max
    });
  };

  render(): JSX.Element {
    const { priceModulation, disabled, styleTitle, styleField } = this.props;

    return (
      <div>
        <span style={styleTitle}>
          {_tg('tefps.filters.fps.reducedDatetimeUpdateStatuses.label')} :
        </span>
        {_tg('field.date.from')}
        <TextField
          name="percentage"
          title="%"
          underlineShow={false}
          value={priceModulation.percentage}
          onChange={this.changeFpsReducedPercentage}
          type="number"
          style={styleField}
          inputStyle={STYLE_TEXT_FIELD}
          disabled={disabled}
        />
        % {_tg('commons.logic.or')}
        <NumberField
          name="amount"
          title={_tg('field.payment.currency')}
          underlineShow={false}
          value={priceModulation.amount}
          onChange={this.changeFpsReducedAmount}
          type="number"
          style={styleField}
          inputStyle={STYLE_TEXT_FIELD}
          disabled={disabled}
        />
        {_tg('field.payment.currency')} {_tg('field.date.during')}
        <TextField
          name="heures"
          title={_tg('field.date.hours')}
          underlineShow={false}
          value={priceModulation.hours}
          onChange={this.changeFpsReducedDuration}
          type="number"
          style={styleField}
          inputStyle={STYLE_TEXT_FIELD}
          disabled={disabled}
        />
        {_tg('field.date.hour_shortened')}
      </div>
    );
  }
}

export default PriceModulationField;
