import React, { CSSProperties } from 'react';

const STYLE_WRAPPER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: 5,
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 15,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

const STYLE_DATA: CSSProperties = {
  fontSize: 15,
  fontFamily: 'Roboto',
  textAlign: 'center',
  marginLeft: 10,
};

type Props = {
  title: string;
  data: string;
};

function SideBarDataLine({ title, data }: Props) {
  return (
    <div style={STYLE_WRAPPER}>
      <span style={STYLE_TITLE}>{title} :</span>
      <span style={STYLE_DATA}>{data}</span>
    </div>
  );
}

export default SideBarDataLine;
