import { AlertSearchFilterDTO } from 'api/planner/types';

import { AlertFilterStore } from './types';
import { INITIAL_STORE } from './reducer';
import { setAlertFilters, setAlertHits } from './actions';

export const mapAlertStoreToProps = ({
  alertFilters,
}: {
  alertFilters: AlertFilterStore;
}): { alertFilters: AlertSearchFilterDTO; hits: number } => ({
  alertFilters: alertFilters.filters,
  hits: alertFilters.hits,
});

export const mapAlertActionToProps = (dispatch: any) => ({
  setAlertFilters: (filters: AlertSearchFilterDTO) => {
    dispatch(setAlertFilters(filters));
  },
  setAlertHits: (hits: number) => {
    dispatch(setAlertHits(hits));
  },
  clearAlertFilters: () => {
    dispatch(setAlertFilters(INITIAL_STORE.filters));
  },
});
