import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import { AlertCommentAdditionDTO, AlertOverviewDTO } from 'api/planner/types';
import { addNewComment } from 'api/planner/index';

import CommentList from './CommentList';
import CommentInput from './CommentInput';
import ContentWithActions from './ContentWithActions';

const { _tg } = window.loadTranslations();

type CommentsModalProps = {
  open: boolean;
  handleClose: (alertClosing: boolean) => void;
  alertToComment: AlertOverviewDTO;
  updateAlertToComment: (
    alertToComment: AlertOverviewDTO | null | undefined
  ) => void;
};

const CommentsModal = ({
  open,
  handleClose,
  alertToComment: { alertId, comments },
  updateAlertToComment,
}: CommentsModalProps): JSX.Element => {
  const [commentText, setCommentText] = useState('');
  const canSendComment = commentText !== '';

  const [shouldCloseAlert, setShouldCloseAlert] = useState(false);

  const postAndRefresh = async (comment: string) => {
    // Prepare DTO for request
    const dto: AlertCommentAdditionDTO = {
      alertCommentDTO: {
        content: comment,
      },
      alertClosing: shouldCloseAlert,
    };

    // Send request
    const alerToComment = await addNewComment(alertId, dto);
    setCommentText('');
    updateAlertToComment(alerToComment);
  };

  const handlePost = async () => {
    await postAndRefresh(commentText);
  };

  return (
    <Dialog
      title={_tg('field.comment_plural')}
      data-index={comments}
      /* show */ open={open}
      onRequestClose={handleClose}
      actions={[
        <FlatButton
          label={_tg('action.cancel')}
          onClick={() => handleClose(true)}
        />,
        <FlatButton
          label={_tg('action.send')}
          primary
          onClick={handlePost}
          disabled={!canSendComment}
        />,
      ]}
    >
      <ContentWithActions
        content={<CommentList comments={comments} />}
        actions={
          <CommentInput
            canSendComment={canSendComment}
            commentText={commentText}
            setCommentText={setCommentText}
            shouldCloseAlert={shouldCloseAlert}
            setShouldCloseAlert={setShouldCloseAlert}
          />
        }
      />
    </Dialog>
  );
};

export default CommentsModal;
