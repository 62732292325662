import React, { CSSProperties } from 'react';
import _cloneDeep from 'lodash.clonedeep';
import IconButton from 'material-ui/IconButton';
import AddNew from 'material-ui/svg-icons/content/add-box';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars';

import { BKG_CYAN_SELECTED } from 'theme';
import { ItemIdName } from 'api/commonTypes';
import {
  PricingConfiguration,
  TimeIntervalCriteria,
  ZoningDTO,
  PriceModulationDTO,
  PricingPolicyDTO,
} from '@cvfm-front/tefps-types';

import { STYLE_TITLE } from '../theme';

import DnDContainer from './DnDContainer';
import AddPolicy from './AddPolicy';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER_HEADER: CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};

type PricingPoliciesProps = {
  zoning: ZoningDTO | null | undefined;
  profiles: Array<ItemIdName>;
  policies: Array<PricingPolicyDTO>;
  updatePolicies: Function;
  defaultChargedPeriod: TimeIntervalCriteria;
  defaultFpsReduction: PriceModulationDTO | null | undefined;
  disabled: boolean;
  cityPricingConfiguration: PricingConfiguration;
};

type PricingPoliciesState = {
  modifyPolicy: boolean;
  editedPolicy: PricingPolicyDTO | null | undefined;
  savable: boolean;
  order: Array<string> | null | undefined;
};

const INITIAL_STATE: PricingPoliciesState = {
  modifyPolicy: false,
  editedPolicy: null,
  savable: false,
  order: null,
};

class PricingPolicies extends React.Component<
  PricingPoliciesProps,
  PricingPoliciesState
> {
  constructor(props: PricingPoliciesProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  closePolicyEditing = (): void =>
    this.setState({ modifyPolicy: false, editedPolicy: null });

  newPolicy = (): void => this.setState({ modifyPolicy: true });

  editPolicy = (id: string): void => {
    const { policies } = this.props;
    this.setState({
      modifyPolicy: true,
      editedPolicy: policies.find(p => p.id === id),
    });
  };

  deletePolicy = (id: string): void => {
    const { policies, updatePolicies } = this.props;
    const policiesClone = policies.filter(p => p.id !== id);
    updatePolicies(policiesClone);
  };

  generateName = (name: string, number: number): string => `${name}(${number})`;

  duplicatePolicy = (id: string): void => {
    const { policies, updatePolicies } = this.props;
    const policyToDuplicate = policies.find(p => p.id === id);
    if (policyToDuplicate !== undefined) {
      const policyDuplicated = _cloneDeep(policyToDuplicate);
      let duplicationNumber = 0;
      policyDuplicated.name = this.generateName(
        policyToDuplicate.name,
        duplicationNumber
      );
      do {
        duplicationNumber += 1;
        policyDuplicated.name = this.generateName(
          policyToDuplicate.name,
          duplicationNumber
        );
      } while (policies.some(p => p.name === policyDuplicated.name));
      policyDuplicated.id = Date.now().toString();
      policies.push(policyDuplicated);
      updatePolicies(policies);
    }
  };

  /** Reorder policies following a drag&drop action */
  reorder = (orderedPolicies: Array<string>): void => {
    const { updatePolicies, disabled } = this.props;
    if (!disabled) updatePolicies(orderedPolicies);
  };

  savePolicies = (policies: Array<PricingPolicyDTO>): void => {
    const { updatePolicies } = this.props;
    updatePolicies(policies);
    this.closePolicyEditing();
  };

  saveEditedPolicy = (oldId: string, policy: PricingPolicyDTO): void => {
    const { policies } = this.props;
    const idx = policies.findIndex(p => p.id === oldId);
    const policiesClone = _cloneDeep(policies);
    policiesClone[idx] = policy;

    // Update CombinedPolicyIdGrid if id changes
    if (policy.id !== oldId) {
      policiesClone
        .filter(pol => pol.combinedPolicyIdGrid === oldId)
        .forEach(pol => {
          pol.combinedPolicyIdGrid = policy.id;
        });
    }

    this.savePolicies(policiesClone);
  };

  saveNewPolicy = (policy: PricingPolicyDTO): void => {
    const { policies } = this.props;
    this.savePolicies([...policies, policy]);
  };

  render(): JSX.Element {
    const {
      zoning,
      profiles,
      policies,
      defaultChargedPeriod,
      defaultFpsReduction,
      disabled,
      cityPricingConfiguration,
    } = this.props;
    const { modifyPolicy, editedPolicy } = this.state;

    return (
      <div
        style={{ display: 'flex', flex: 1, flexWrap: 'wrap', width: '100%' }}
      >
        <div style={STYLE_CONTAINER_HEADER}>
          <div style={{ ...STYLE_TITLE, width: '80%' }}>
            {_tg('tefps.pricing.pricing.label')}
          </div>
          {!disabled && (
            <div>
              <IconButton
                tooltip={_tg('tefps.pricing.pricing.newPolicy')}
                style={{ padding: 0 }}
                iconStyle={{ color: BKG_CYAN_SELECTED, height: 50, width: 50 }}
                onClick={this.newPolicy}
              >
                <AddNew />
              </IconButton>
            </div>
          )}
        </div>
        {modifyPolicy && (
          <AddPolicy
            isOpen
            close={this.closePolicyEditing}
            zoning={zoning}
            profiles={profiles}
            create={this.saveNewPolicy}
            edit={this.saveEditedPolicy}
            editedPolicy={editedPolicy}
            defaultChargedPeriod={defaultChargedPeriod}
            defaultFpsReduction={defaultFpsReduction}
            currentPolicies={policies}
            disabled={disabled}
            cityPricingConfiguration={cityPricingConfiguration}
          />
        )}
        <br />
        <div style={{ display: 'flex', width: '100%', height: 214 }}>
          <AutoSizer>
            {({ height, width }) => (
              <Scrollbars style={{ width, height }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '98%',
                    marginBottom: 6,
                  }}
                >
                  <DnDContainer
                    policies={policies}
                    deletePolicy={this.deletePolicy}
                    editPolicy={this.editPolicy}
                    duplicatePolicy={this.duplicatePolicy}
                    reorder={this.reorder}
                    disabled={disabled}
                  />
                </div>
              </Scrollbars>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default PricingPolicies;
