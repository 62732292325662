import React from 'react';

import {
  BoxWithTitle,
  Flex,
  LabelValue,
  TimeLineBox,
} from '@cvfm-front/commons-ui';
import { Bill, BillVersion } from '@cvfm-front/tefps-types';
import { formatDatetime } from 'commons/Date';
import PictureLink from 'commons/PictureLink';

import { formatSwissPrice } from '../../../commons/Price';

interface BillVersionDisplayerProps {
  bill: Bill;
  version: BillVersion;
}

const BillVersionDisplayer = ({
  bill,
  version,
}: BillVersionDisplayerProps): JSX.Element => {
  return (
    <>
      <TimeLineBox title="CRÉATION">
        <Flex gap={64} flexDirection="row" justifyContent="space-between">
          <BoxWithTitle title="Infos" secondary width100>
            <Flex gap={32} flexDirection="column">
              <Flex
                gap={32}
                flexDirection="row"
                justifyContent="space-between"
                width100
              >
                <LabelValue
                  label="DATE D'ENTRÉE"
                  value={formatDatetime({
                    datetime: bill.entryStatementDatetime,
                    withTime: false,
                  })}
                  flexDirection="column"
                  width100
                />
                <LabelValue
                  label="HEURE D'ENTRÉE"
                  value={formatDatetime({
                    datetime: bill.entryStatementDatetime,
                    withDate: false,
                  })}
                  flexDirection="column"
                  width100
                />
              </Flex>
              <Flex
                gap={32}
                flexDirection="row"
                width100
                justifyContent="space-between"
              >
                <LabelValue
                  label="DATE DE SORTIE"
                  value={formatDatetime({
                    datetime: bill.exitStatementDatetime,
                    withTime: false,
                  })}
                  flexDirection="column"
                  width100
                />
                <LabelValue
                  label="HEURE DE SORTIE"
                  value={formatDatetime({
                    datetime: bill.exitStatementDatetime,
                    withDate: false,
                  })}
                  flexDirection="column"
                  width100
                />
              </Flex>
            </Flex>
          </BoxWithTitle>
          <BoxWithTitle title="Photos" secondary width100>
            <Flex gap={32}>
              <PictureLink mediaUrn={bill.entryContextUrn} name="Entry" />
              <PictureLink mediaUrn={bill.exitContextUrn} name="Exit" />
            </Flex>
          </BoxWithTitle>
        </Flex>
      </TimeLineBox>
      {version.payment.length > 0 &&
        version.payment.map(payment => {
          return (
            <TimeLineBox title="PAIEMENT">
              <Flex gap={64} flexDirection="row" justifyContent="space-between">
                <BoxWithTitle title="" secondary width100>
                  <Flex gap={32} flexDirection="column">
                    <LabelValue
                      label="DATE DE PAIEMENT"
                      value={formatDatetime({
                        datetime: payment.paymentDatetime,
                      })}
                      flexDirection="column"
                      width100
                    />
                  </Flex>
                </BoxWithTitle>
                <BoxWithTitle title="" secondary width100>
                  <Flex gap={32} flexDirection="column">
                    <LabelValue
                      label="PRIX"
                      value={formatSwissPrice(payment.paymentAmount)}
                      flexDirection="column"
                      width100
                    />
                  </Flex>
                </BoxWithTitle>
              </Flex>
            </TimeLineBox>
          );
        })}
    </>
  );
};

export default BillVersionDisplayer;
