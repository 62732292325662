import { point, Point, Polygon, polygon, Position } from '@turf/helpers';
import intersect from '@turf/intersect';
import midpoint from '@turf/midpoint';

import {
  PointDTO,
  PolygonDTO,
  ZoneDTO,
  ZoningDTO,
} from '@cvfm-front/tefps-types';
import { Coordinates } from 'api/commonTypes';

const googleMapColorPreset = [
  'black',
  'brown',
  'green',
  'purple',
  'yellow',
  'blue',
  'gray',
  'orange',
  'red',
  'white',
];

// google map static require format 0x000000 or preset
const formatColor = (colorString: string) => {
  if (googleMapColorPreset.includes(colorString)) {
    return colorString;
  }
  const newColor = colorString.replace('#', '');
  const matcher = /^(.){3}$/.exec(newColor);
  if (!matcher) {
    return `0x${newColor}33`;
  }
  return `0x${matcher[1]}${matcher[1]}${matcher[2]}${matcher[2]}${matcher[3]}${matcher[3]}33 `;
};

export const computeColor = (zoning: ZoningDTO): Map<string, string> => {
  const colorMap: Map<string, string> = new Map();
  zoning.zones.forEach((zone: ZoneDTO) =>
    zone.polygonIds.forEach((zoneId: string) =>
      colorMap.set(zoneId, formatColor(zone.color))
    )
  );
  return colorMap;
};

const degToRad = (deg: number) => {
  return (Math.PI * deg) / 180;
};

const radToDeg = (rad: number) => {
  return (180 * rad) / Math.PI;
};

// Earth radius at a given latitude, according to the WGS-84 ellipsoid [m]
const WGS84EarthRadius = (lat: number) => {
  // Semi-axes of WGS-84 geoidal reference
  const WGS84A = 6378137.0;
  const WGS84B = 6356752.3;

  const An = WGS84A * WGS84A * Math.cos(lat);
  const Bn = WGS84B * WGS84B * Math.sin(lat);
  const Ad = WGS84A * Math.cos(lat);
  const Bd = WGS84B * Math.sin(lat);
  return Math.sqrt((An * An + Bn * Bn) / (Ad * Ad + Bd * Bd));
};

const boundingBox = (latDeg: number, longDeg: number, halfSideInKm: number) => {
  const lat = degToRad(latDeg);
  const lon = degToRad(longDeg);
  const halfSide = 1000 * halfSideInKm;
  const radius = WGS84EarthRadius(lat);
  const pradius = radius * Math.cos(lat);
  const latMin = radToDeg(lat - halfSide / radius);
  const latMax = radToDeg(lat + halfSide / radius);
  const lonMin = radToDeg(lon - halfSide / pradius);
  const lonMax = radToDeg(lon + halfSide / pradius);

  return polygon([
    [
      [latMin, lonMin],
      [latMax, lonMin],
      [latMax, lonMax],
      [latMin, lonMax],
      [latMin, lonMin],
    ],
  ]);
};

// Convert our polygonDTO data to turf geojson like polygon
const buildTurfPolygonFromPolygonDTO = (polygonDTO: PolygonDTO): Polygon => {
  try {
    return polygon([
      polygonDTO.points.map((polygonPoint: PointDTO) => {
        return [polygonPoint.lat, polygonPoint.lon];
      }),
    ]).geometry;
  } catch (e) {
    // some polygons are not well built to avoid crash we return null polygon
    return polygon([
      [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ],
    ]).geometry;
  }
};

// Coordinates are way to precise 7 digit is more than enough
const approximateCoordinates = (
  polygons: Array<PolygonDTO>
): Array<PolygonDTO> => {
  return polygons.map(oldPolygon => {
    return {
      ...oldPolygon,
      points: oldPolygon.points.map(polygonPoint => {
        return {
          lat: Number.parseFloat(polygonPoint.lat.toFixed(7)),
          lon: Number.parseFloat(polygonPoint.lon.toFixed(7)),
        };
      }),
    };
  });
};

// Build google query with patern : path=color:0x0000ff|weight:5|fillcolor:0x0000ff|40.737102,-73.990318|40.749825,-73.987963|40.752946,-73.987384|40.755823,-73.986397
export const buildPolygonsPaths = (
  zoning: ZoningDTO | null | undefined,
  coords: Coordinates
): string => {
  if (!zoning) {
    return '';
  }
  //  Restrict the visual zone
  const square = boundingBox(coords.latitude, coords.longitude, 0.15);
  //  City [zoneId,Color] Map
  const colorMap = computeColor(zoning);
  // Simplify coordinates
  const approximatedPolygons = approximateCoordinates(zoning.polygons);

  let query = '';
  approximatedPolygons.forEach((polygonDTO: PolygonDTO) => {
    let intersection;
    try {
      intersection = intersect(
        buildTurfPolygonFromPolygonDTO(polygonDTO),
        square
      );
    } catch (e) {
      intersection = null;
    }

    if (intersection !== null) {
      query += `&path=color:${colorMap.get(polygonDTO.id) ||
        ''}|weight:1|fillcolor:${colorMap.get(polygonDTO.id) || ''}`;
      if (intersection.geometry.type === 'Polygon') {
        intersection.geometry.coordinates.forEach((coordinates: Position[]) => {
          coordinates.forEach(coord => {
            query += `|${coord[0]},${coord[1]}`;
          });
        });
      }
      if (intersection.geometry.type === 'MultiPolygon') {
        intersection.geometry.coordinates.forEach(
          (coordinates: Position[][]) => {
            coordinates.forEach((polygons: Position[]) => {
              polygons.forEach((coord: Position) => {
                query += `|${coord[0]},${coord[1]}`;
              });
            });
          }
        );
      }
    }
  });
  return query;
};

export const getPositionMarkerUrl = (url: string): string => {
  const match: Array<string> | null | undefined = /:\/\/(.[^/:]+).*/.exec(url);

  if (match !== null && match[1] !== null) {
    const domain = match[1];

    if (
      domain.includes('localhost') ||
      domain.includes('agent.tefps-demo.fr')
    ) {
      return 'http://agent.tefps-demo.fr/static/img/map/circles/circle_position.png';
    }
    return `https://${domain}/static/img/map/circles/circle_position.png`;
  }
  return '';
};

export const getAddressMarkerUrl = (url: string): string => {
  const match: Array<string> | null | undefined = /:\/\/(.[^/:]+).*/.exec(url);

  if (match !== null && match[1] !== null) {
    const domain = match[1];

    if (
      domain.includes('localhost') ||
      domain.includes('agent.tefps-demo.fr')
    ) {
      return 'http://agent.tefps-demo.fr/static/img/map/circles/circle_address.png';
    }
    return `https://${domain}/static/img/map/circles/circle_address.png`;
  }
  return '';
};

export const getMidPoint = (
  point1: Coordinates,
  point2: Coordinates
): Point | null => {
  const turfPoint1 = point([point1.latitude, point1.longitude]);
  const turfPoint2 = point([point2.latitude, point2.longitude]);

  return midpoint(turfPoint1, turfPoint2).geometry;
};
