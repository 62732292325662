import React from 'react';
import { connect } from 'react-redux';

import { FETCH_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import Zoning from 'commons/ZoningComponents';
import { getConfigState } from 'config/duck';
import { getApiState, InternalApiState } from 'api/duck';

export type SectorsProps = {
  enableParkingMeter: boolean;
  organizationFilterEnabled: boolean;
};

function SectorsConfiguration({
  enableParkingMeter,
  organizationFilterEnabled,
}: SectorsProps) {
  return (
    <Zoning
      fetchConfigs={FETCH_ZONING_CONFIG}
      enableParkingMeter={enableParkingMeter}
      organizationFilterEnabled={organizationFilterEnabled}
    />
  );
}

const mapStateToProps = (state: InternalApiState) => {
  const { enableParkingMeter, organizationFilterEnabled } = getConfigState(
    state
  );
  const { userInfo } = getApiState(state);
  const enableParkingMeterForUser =
    enableParkingMeter &&
    userInfo &&
    userInfo.rights.includes('PARKING_METER_READ');

  return {
    enableParkingMeter: enableParkingMeterForUser,
    organizationFilterEnabled,
  };
};

export default connect(mapStateToProps)(SectorsConfiguration);
