import React, { CSSProperties } from 'react';
import { ColorResult, HuePicker } from 'react-color';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import { validateMandatoryInput } from 'commons/Validators';
import { RoutesGroupDTO } from 'api/planner/types';
import { BKG_LIGHT_BLUE, STYLE_ERROR_WRAPPER } from 'theme';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

type Props = {
  editType: string;
  routesGroup: RoutesGroupDTO | null | undefined;
  close: () => any;
  save: (body: unknown) => void;
};

type State = {
  error: string | null | undefined;
  name: string;
  color: string;
  id: string;
  errors: {
    [key: string]: string | null | undefined;
  };
};

const INITIAL_STATE = {
  error: null,
  name: '',
  color: '#ff0000',
  errors: {},
};

const computeFromProps = (props: Props): State => {
  const { routesGroup } = props;
  if (routesGroup) {
    return {
      error: null,
      errors: {},
      ...routesGroup,
    };
  }
  return { ...INITIAL_STATE, id: Date.now().toString() };
};

class AddRoutesGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = computeFromProps(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    this.setState(computeFromProps(nextProps));
  }

  onClose = (): void => {
    const { close } = this.props;
    close();
    this.setState({ ...INITIAL_STATE });
  };

  onClickValidate = (): void => {
    const { save, routesGroup } = this.props;
    const { name, color, id } = this.state;
    const body = {
      name,
      color,
      id,
      routeTemplateIds: routesGroup ? routesGroup.routeTemplateIds : null,
    };

    const errors = {
      name: validateMandatoryInput(name),
    };

    if (!Object.values(errors).some(v => !!v)) {
      try {
        save(body);
        this.setState({ error: null, errors: {} });
      } catch (error) {
        this.setState({ error: (error as Error).message });
      }
    }
    this.setState({ errors });
  };

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>, name: string): void =>
    this.setState({ name });
  onChangeId = (e: React.ChangeEvent<HTMLInputElement>, id: string): void =>
    this.setState({ id });

  handleChangeColor = (color: ColorResult): void => {
    this.setState({ color: color.hex });
  };

  render(): React.ReactNode {
    const { editType } = this.props;
    const { name, color, error, id, errors } = this.state;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label={editType === 'EDIT' ? _tg('action.edit') : _tg('action.add')}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ];

    return (
      <Dialog
        actions={actions}
        title={
          editType === 'ADD'
            ? _t('action.addRouteGroup')
            : _t('action.modifyRouteGroup')
        }
        open={editType === 'ADD' || editType === 'EDIT'}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
      >
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={id}
            disabled={editType === 'EDIT'}
            onChange={this.onChangeId}
            floatingLabelText={_t('element.groupId')}
            errorText={errors.id}
          />
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={name}
            onChange={this.onChangeName}
            floatingLabelText={_t('element.groupName')}
            errorText={errors.name}
          />
        </div>
        <div style={{ marginTop: 14 }}>
          {_t('element.groupColor')}
          <br />
          <br />
          <HuePicker
            color={color}
            width="100%"
            onChangeComplete={this.handleChangeColor}
          />
        </div>
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default AddRoutesGroup;
