import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import Panel from 'tefps/LapiReview/components/Panel';
import ControlAddress from 'tefps/LapiReview/Geolocalisation/components/ControlAddress';
import Legend from 'tefps/LapiReview/Geolocalisation/components/Legend';
import { ControlAddressType, Coordinates } from 'api/commonTypes';
import useCurrentLapiReview from 'commons/hooks/useCurrentLapiReview';
import services from 'commons/services';
import ControlDetailMap from 'tefps/Control/Detail/ControlDetailMap';
import useCurrentControlDTO from 'commons/hooks/useCurrentControlDTO';

const MAP_WITH_LEGEND: CSSProperties = {
  display: 'flex',
  width: '100%',
};

type GeolocalisationProps = {
  controlPosition: Coordinates;
  controlAddress: Coordinates;
  changeAddress: (newAddress: ControlAddressType) => void;
  darkMode: boolean;
};

const mapStyle = { height: 400, width: 640, marginTop: 20 };

const Geolocalisation = ({
  controlPosition,
  controlAddress,
  changeAddress,
  darkMode,
}: GeolocalisationProps): React.ReactElement<any> => {
  const controlDTO = useCurrentControlDTO();
  const lapiReview = useCurrentLapiReview();
  const [reload, setReload] = useState(0);

  if (!controlDTO) {
    return <></>;
  }

  const controlDirectionPresent: boolean =
    controlDTO.metadata.directionLongitude !== undefined &&
    controlDTO.metadata.directionLatitude !== undefined;
  const controlProjection: Coordinates | undefined =
    controlDTO.lastVersion.projection?.projectedPoint ?? undefined;

  useEffect(() => void services.zoning.init(), []);

  // TODO fix temporaire pour forcer le reload de la controlMap à la fermeture de la modale de changement d'addresse
  const onClose = (): void => {
    setReload(Math.random());
  };

  // TODO fix temporaire pour forcer le reload de la controlMap à la fermeture de la modale de changement d'addresse
  const onChangeAddress = (newAddress: ControlAddressType): void => {
    changeAddress(newAddress);
    setReload(Math.random());
  };

  return (
    <Panel name="Géolocalisation">
      <ControlAddress
        address={lapiReview.statementAddress}
        changeAddress={onChangeAddress}
        onClose={onClose}
        darkMode={darkMode}
      />
      <div style={MAP_WITH_LEGEND}>
        <div style={mapStyle}>
          <ControlDetailMap
            control={controlDTO}
            styleOverwrite={{ height: '100%' }}
            reload={reload}
          />
        </div>
        <Legend
          controlAddressCoordinates={controlAddress}
          controlPositionCoordinates={controlPosition}
          controlDirectionPresent={controlDirectionPresent}
          controlProjection={controlProjection}
        />
      </div>
    </Panel>
  );
};

export default Geolocalisation;
