import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import SelectField from 'material-ui/SelectField';
import Checkbox from 'material-ui/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import { BKG_LIGHT_BLUE } from 'theme';
import { ItemIdName } from 'api/commonTypes';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  marginTop: 8,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

type AddPricingProps = {
  open: boolean;
  existingPricings: Array<ItemIdName>;
  close: () => any;
  save: (name: string, copiedPricingId: string | null | undefined) => any;
};

type AddPricingState = {
  name: string;
  copiedPricingId: string | null | undefined;
};

const INITIAL_STATE = {
  name: '',
  copiedPricingId: null,
};

export default class AddPricing extends React.Component<
  AddPricingProps,
  AddPricingState
> {
  constructor(props: AddPricingProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  onClickValidate = (): void => {
    const { save } = this.props;
    const { name, copiedPricingId } = this.state;
    if (name && name.trim() !== '') {
      this.close();
      save(name, copiedPricingId);
    }
  };

  close = (): void => {
    this.setState(INITIAL_STATE);
    this.props.close();
  };

  changeCopiedPricingId = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    copiedPricingId: string
  ): void => {
    this.setState({ copiedPricingId });
  };

  changeName = (
    _e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ): void => {
    this.setState({ name });
  };

  enableCopy = (
    _event: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ): void => {
    const { existingPricings } = this.props;
    this.setState({
      copiedPricingId:
        isInputChecked && existingPricings.length > 0
          ? this.props.existingPricings[0].id
          : null,
    });
  };

  render(): JSX.Element {
    const { open, existingPricings } = this.props;
    const { name, copiedPricingId } = this.state;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.close}
      />,
      <BoButton
        key={2}
        label={_tg('action.create')}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ];

    return (
      <Dialog
        actions={actions}
        title={_tg('tefps.pricing.pricing.addPricing.createPricing')}
        open={open}
        onRequestClose={this.close}
        titleStyle={STYLE_TITLE}
      >
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={name}
            onChange={this.changeName}
            floatingLabelText={_tg(
              'tefps.pricing.pricing.addPricing.pricingName'
            )}
            errorText={
              (!name || name.trim() === '') &&
              _tg('feedback.error.mandatoryField')
            }
            style={{ marginBottom: 12 }}
          />
          <div style={{ width: '60%' }}>
            <Checkbox
              checked={copiedPricingId != null}
              onCheck={this.enableCopy}
              label={_tg('tefps.pricing.pricing.addPricing.clonePricing')}
            />
            <SelectField
              floatingLabelText={_tg(
                'tefps.pricing.pricing.addPricing.pricingToClone'
              )}
              value={copiedPricingId}
              onChange={this.changeCopiedPricingId}
              disabled={copiedPricingId == null}
            >
              {existingPricings.map(choice => (
                <MenuItem
                  key={choice.id}
                  value={choice.id}
                  primaryText={choice.name}
                />
              ))}
            </SelectField>
          </div>
        </div>
      </Dialog>
    );
  }
}
