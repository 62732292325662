import React, { CSSProperties } from 'react';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import ConfirmAction from 'commons/ConfirmAction';
import { RouteTemplateDTO } from 'api/planner/types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  routeTemplate: RouteTemplateDTO;
  selectedRouteTemplateId: string | null | undefined;
  editRouteTemplate: (routeTemplate: RouteTemplateDTO) => any;
  editRoutePath: (routeTemplateId: string | null | undefined) => any;
  deleteRouteTemplate: (routeTemplate: RouteTemplateDTO) => any;
};

type State = {
  isOpen: boolean;
};

const STYLE_CHECKBOXES: CSSProperties = {
  margin: '0px 15px 0px 0px',
  display: 'inline-block',
  width: 20,
  height: 20,
};

const STYLE_ACTIONS: CSSProperties = {
  margin: '0px 0px 0px 15px',
  width: 20,
  height: 20,
  cursor: 'pointer',
  paddingBottom: 2,
};

const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  width: '49%',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflow: 'hidden',
  paddingBottom: 2,
};

class RouteTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  onConfirmDelete = (): void => {
    const { routeTemplate, deleteRouteTemplate } = this.props;
    deleteRouteTemplate(routeTemplate);
    this.setState({ isOpen: false });
  };

  onConfirmDeleteCancel = (): void => {
    this.setState({ isOpen: false });
  };

  deleteRouteTemplate = (): void => {
    this.setState({ isOpen: true });
  };

  editRouteTemplate = (): void => {
    const { routeTemplate, editRouteTemplate } = this.props;
    editRouteTemplate(routeTemplate);
  };

  editRoutePath = (): void => {
    const {
      routeTemplate,
      editRoutePath,
      selectedRouteTemplateId,
    } = this.props;
    const id =
      selectedRouteTemplateId === routeTemplate.id ? null : routeTemplate.id;
    editRoutePath(id);
  };

  render(): React.ReactNode {
    const { routeTemplate, selectedRouteTemplateId } = this.props;
    const { isOpen } = this.state;
    return (
      <div style={{ height: '30px' }}>
        <Checkbox
          style={{ ...STYLE_CHECKBOXES }}
          onClick={this.editRoutePath}
          checked={selectedRouteTemplateId === routeTemplate.id}
          iconStyle={{ fill: '#ddd' }}
        />
        <span style={STYLE_NAME}>{routeTemplate.name}</span>
        <span title={_tg('action.modify')}>
          <Edit
            style={{ color: '#ddd', ...STYLE_ACTIONS }}
            onClick={this.editRouteTemplate}
          />
        </span>
        <span title={_tg('action.delete')}>
          <ConfirmAction
            action={this.onConfirmDelete}
            isOpen={isOpen}
            onClose={this.onConfirmDeleteCancel}
            message={_t('element.routeTemplateMessage')}
            enabled
          >
            <IconButton
              onClick={this.deleteRouteTemplate}
              iconStyle={{
                color: '#aaa',
                width: 20,
                height: 20,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ConfirmAction>
        </span>
      </div>
    );
  }
}

export default RouteTemplate;
