import * as React from 'react';
import { connect } from 'react-redux';
import { IconButton } from 'material-ui';
import Info from 'material-ui/svg-icons/action/info';

import { TXT_GREYBLUE } from 'theme';
import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import RadioButtons from 'commons/SidebarV2/Components/RadioButtons';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import SimpleDate from 'commons/SidebarV2/Components/SimpleDate';
import Times from 'commons/SidebarV2/Components/Times';
import { fetchOrganizationsFromTeams } from 'api/organizations';
import { fetchTeamsForOrganizations } from 'api/cvfm-core-directory/team';
import { fetchMatchingAgents } from 'api/accounts';
import { AgentIdAndNameDTO, GpsStatus } from '@cvfm-front/tefps-types';
import Toggle from 'commons/SidebarV2/Components/Toggle';
import { Flex } from '@cvfm-front/commons-ui';

import { MapFilters } from './types';
import { getPingFiltersState, initialFilters, pingFiltersChange } from './duck';
import { displayOptions } from './utils';

import './PingFilters.css';

const { _t, _tg } = window.loadTranslations(__filename);

const translateDisplayTypeOptions = () => {
  return [
    { value: displayOptions.CURRENT, label: _t('element.displayType.CURRENT') },
    {
      value: displayOptions.HISTORY,
      label: _t('element.displayType.HISTORY'),
    },
  ];
};

type OwnProps = {
  displayGpsStatusAsColors: boolean;
  displayPatrolZone: boolean;
  setDisplayGpsStatusAsColors: (value: boolean) => void;
  setDisplayPatrolZone: (value: boolean) => void;
};

type ReduxStateProps = {
  filters: MapFilters;
};

type ReduxDispatchProps = {
  updateFilters: (newFilters: MapFilters) => void;
};

type Props = OwnProps & ReduxStateProps & ReduxDispatchProps;

type State = {
  organizationOptions: Array<{ value: string; label: string }>;
  teamOptions: Array<{ value: string; label: string }>;
  agentSearchOptions: Array<{ id: string; name: string }>;
  showGpsStatusLegend: boolean;
};

const INITIAL_STATE = {
  organizationOptions: [],
  teamOptions: [],
  agentSearchOptions: [],
  showGpsStatusLegend: false,
};

class PingFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount(): void {
    void this.fetchOrganizationsAndBuildOptions();
  }

  componentWillUnmount(): void {
    this.resetFilters();
  }

  onChange = (id: string, value: unknown): void => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  onChangeOrganizations = (id: string, value: any): void => {
    // Update team list based on selected organizations
    this.onChange(id, value);
    void this.fetchTeamsAndBuildOptions(value);
  };

  fetchOrganizationsAndBuildOptions = async (): Promise<void> => {
    const { filters } = this.props;
    const organizations = (await fetchOrganizationsFromTeams()) || [];
    const organizationIds = new Set<string>();
    const organizationOptions = organizations.map(organization => {
      if (filters.organizationIds.has(organization.organizationId)) {
        organizationIds.add(organization.organizationId);
      }
      return {
        value: organization.organizationId,
        label: organization.name,
      };
    });

    organizationOptions.sort((a, b) => a.label.localeCompare(b.label));

    this.setState({ organizationOptions });
    this.onChange('organizationIds', organizationIds);

    void this.fetchTeamsAndBuildOptions(organizationIds);
  };

  fetchTeamsAndBuildOptions = async (
    organizationIds: Set<string>
  ): Promise<void> => {
    const { filters } = this.props;
    const teams = (await fetchTeamsForOrganizations(organizationIds)) || [];
    const teamOptions = teams.map(team => ({
      value: team.teamId,
      label: team.name,
    }));

    teamOptions.sort((a, b) => a.label.localeCompare(b.label));

    this.setState({ teamOptions });
    this.selectFirstTeamAvailable(filters.teamId, teamOptions);
  };

  selectFirstTeamAvailable = (
    selected: string | null | undefined,
    teamOptions: Array<{ value: string; label: string }>
  ): void => {
    // Select first team available if team previously selected is no longer available
    if (
      teamOptions.length > 0 &&
      (!selected || !teamOptions.map(team => team.value).includes(selected))
    ) {
      this.onChange('teamId', teamOptions[0].value);
    }
  };

  fetchAgentsAndBuildOptions = async (
    agentFilter: string | null | undefined
  ): Promise<void> => {
    let agents: AgentIdAndNameDTO[] = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const agentSearchOptions = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    this.setState({ agentSearchOptions });
  };

  resetFilters = (): void => {
    const { updateFilters } = this.props;
    const { teamOptions } = this.state;
    const filters = initialFilters();
    if (teamOptions.length > 0) {
      filters.teamId = teamOptions[0].value;
    }
    updateFilters(filters);
  };

  render(): React.ReactNode {
    const {
      filters,
      displayGpsStatusAsColors,
      displayPatrolZone,
      setDisplayGpsStatusAsColors,
      setDisplayPatrolZone,
    } = this.props;
    const {
      organizationOptions,
      teamOptions,
      agentSearchOptions,
      showGpsStatusLegend,
    } = this.state;

    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        <RadioButtons
          id="displayType"
          title={_t('element.sidebar.display')}
          options={translateDisplayTypeOptions()}
          selected={filters.displayType}
          onChange={this.onChange}
          iconStyle={{ color: TXT_GREYBLUE }}
          labelStyle={{ color: TXT_GREYBLUE }}
        />
        <Autocomplete
          id="agentId"
          title={_tg('field.agent.idOrName')}
          options={agentSearchOptions}
          onChange={this.onChange}
          onAutocomplete={this.fetchAgentsAndBuildOptions}
          search={filters.agentId}
        />
        {organizationOptions.length > 1 && (
          <Checkboxes
            id="organizationIds"
            title={_t('element.sidebar.authorities')}
            options={organizationOptions}
            filters={filters.organizationIds}
            onChange={this.onChangeOrganizations}
            faceting={null}
          />
        )}
        {(organizationOptions.length > 1 || teamOptions.length > 1) && (
          <RadioButtons
            id="teamId"
            title={_tg('field.organisation.team_plural')}
            options={teamOptions}
            selected={filters.teamId}
            onChange={this.onChange}
            iconStyle={{ color: TXT_GREYBLUE }}
            labelStyle={{ color: TXT_GREYBLUE }}
          />
        )}

        <Toggle
          id="displaySectors"
          title={_tg('field.ping.overlapSectors')}
          value={displayPatrolZone}
          onChange={(_id, value) => setDisplayPatrolZone(value)}
        />
        {filters.displayType === displayOptions.HISTORY && (
          <>
            <Checkboxes
              id="lapi"
              title={_tg('field.ping.lapi')}
              options={[
                { value: 'true', label: 'Oui' },
                { value: 'false', label: 'Non' },
              ]}
              filters={filters.lapi}
              onChange={this.onChange}
            />
            <div>
              <SimpleDate
                id="datetime"
                title={_tg('field.date.date')}
                date={filters.datetime}
                onChange={this.onChange}
              />
              <Times
                id="times"
                title={_t('element.sidebar.times')}
                times={filters.times}
                onChange={this.onChange}
              />
            </div>
          </>
        )}
        <Checkboxes
          id="gpsStatuses"
          title={_tg('field.ping.gpsStatus.status')}
          options={[
            { value: GpsStatus.OK, label: _tg('field.ping.gpsStatus.OK') },
            {
              value: GpsStatus.FREEZE_AND_JUMP,
              label: _tg('field.ping.gpsStatus.KO'),
            },
            {
              value: GpsStatus.UNKNOWN,
              label: _tg('field.ping.gpsStatus.UNKNOWN'),
            },
          ]}
          filters={filters.gpsStatuses}
          onChange={this.onChange}
          faceting={null}
        />
        {showGpsStatusLegend && (
          <Flex
            flexDirection="column"
            className="ping-filters__tooltip__description"
          >
            {displayGpsStatusAsColors ? (
              <>
                <p>{_tg('field.ping.colorsDescription.gpsStatus.OK')}</p>
                <p>{_tg('field.ping.colorsDescription.gpsStatus.UNKNOWN')}</p>
                <p>{_tg('field.ping.colorsDescription.gpsStatus.KO')}</p>
              </>
            ) : (
              <>
                <p>{_tg('field.ping.colorsDescription.legacy.current')}</p>
                <p>{_tg('field.ping.colorsDescription.legacy.historic')}</p>
              </>
            )}
          </Flex>
        )}
        <Flex
          className="ping-filters__tooltip__holder"
          alignItems="end"
          justifyContent="space-between"
        >
          <Toggle
            id="displayGpsStatusAsColors"
            title={_tg('field.ping.gpsStatus.displayStatus')}
            value={displayGpsStatusAsColors}
            onChange={(_id, value) => setDisplayGpsStatusAsColors(value)}
            styleBlock={{ marginTop: 0 }}
          />
          <IconButton
            style={{
              padding: 0,
              marginLeft: 12,
              height: '30px',
              width: '30px',
            }}
            iconStyle={{ height: 30, width: 30 }}
            onClick={() =>
              this.setState(oldState => ({
                ...oldState,
                showGpsStatusLegend: !oldState.showGpsStatusLegend,
              }))
            }
          >
            <Info color="white" />
          </IconButton>
        </Flex>
      </Sidebar>
    );
  }
}

export default connect(
  (state): ReduxStateProps => {
    const filters = getPingFiltersState(state);
    return {
      filters,
    };
  },
  (dispatch): ReduxDispatchProps => ({
    updateFilters: (filters: MapFilters) =>
      dispatch(pingFiltersChange(filters)),
  })
)(PingFilters);
