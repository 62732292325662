import React, { useCallback, useContext } from 'react';
import { v4 } from 'uuid';

import { FpsFiltersParam } from '@cvfm-front/tefps-types';
import BoButton from 'facade/BoButton';

import { PdfExportContext } from './PdfExportContext';

type Props = {
  search: FpsFiltersParam;
};

const { _tg } = window.loadTranslations();

const PdfExportButton = ({ search }: Props) => {
  const service = useContext(PdfExportContext);
  const onButtonClick = useCallback(() => {
    service.preparePdfExportCreation(v4(), search);
  }, [search]);

  return (
    <BoButton
      label={_tg(`tefps.PdfExport.PdfExportButton.label`)}
      onClick={onButtonClick}
      style={{ marginLeft: 20 }}
    />
  );
};

export default PdfExportButton;
