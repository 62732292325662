import React, { CSSProperties, useEffect, useState } from 'react';
import AutoComplete from 'material-ui/AutoComplete';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

const LABEL_STYLE: CSSProperties = {
  marginRight: 20,
  whiteSpace: 'nowrap',
};

const LIST_STYLE: CSSProperties = {
  overflowY: 'scroll',
  maxHeight: '300px',
};

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 20,
  height: 30,
};

type Props = {
  label: string | null | undefined;
  initialInput: string | undefined | null;
  style: CSSProperties;
  dataSource: Array<Record<string, any>>;
  dataSourceConfig: { text: string; value: string };
  handleNewRequest: (input: string) => void;
  disabled: boolean;
  muiTheme?: MuiTheme;
};

function AutoCompleteVehicles({
  label,
  initialInput,
  style,
  dataSource,
  dataSourceConfig,
  handleNewRequest,
  disabled = true,
  muiTheme,
}: Props) {
  const [searchText, setSearchText] = useState<string | null>(null);

  useEffect(() => {
    setSearchText(initialInput?.toUpperCase() || '');
  }, [initialInput]);

  return (
    <div style={ROW_STYLE}>
      <label style={{ ...LABEL_STYLE, color: muiTheme?.textField?.textColor }}>
        {label}
      </label>
      <AutoComplete
        id={`autocomplete-${label}`}
        searchText={searchText || ''}
        onUpdateInput={handleNewRequest}
        dataSource={dataSource}
        filter={(input: string, key: string) => key.includes(input)}
        dataSourceConfig={dataSourceConfig}
        openOnFocus
        fullWidth
        listStyle={LIST_STYLE}
        underlineShow={false}
        textFieldStyle={style}
        disabled={disabled}
      />
    </div>
  );
}

export default muiThemeable()(AutoCompleteVehicles);
