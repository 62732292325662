import * as React from 'react';
import { IndexRange } from 'react-virtualized';
import { Link } from 'react-router-dom';

import { BillEsPrivateDTO } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import './BillTablePage.css';

import { formatSwissPrice } from 'commons/Price';

import { translateEsBillStatus } from './BillStatusTranslator';

const { _t } = window.loadTranslations(__filename);

type BillTableProps = {
  pagedBills: Array<BillEsPrivateDTO>;
  totalBills: number;
  sortIndex: number;
  sortAscending: boolean;
  onSort: (colIndex: number, ascending: boolean, field: string) => void;
  onRange: (fromIndex: number) => Promise<void>;
};

const BillTable = ({
  pagedBills: bills,
  totalBills: billsCount,
  sortIndex,
  sortAscending,
  onSort,
  onRange,
}: BillTableProps): JSX.Element => {
  function handleTableSort(
    colIndex: number,
    ascending?: boolean,
    sortField?: string
  ) {
    if (ascending !== undefined && ascending !== null && sortField) {
      onSort(colIndex, ascending, sortField);
    }
  }

  function handleTableRange(range: IndexRange): Promise<void> {
    return onRange(range.startIndex);
  }

  const getHeaderCols = () => [
    {
      label: _t('table.header.billId'),
      width: 150,
      grow: 1,
      sortId: 'billId',
      onSort: false,
    },
    {
      label: _t('table.header.plate'),
      width: 70,
      grow: 1,
      sortId: 'plate',
      onSort: false,
    },
    {
      label: _t('table.header.price'),
      width: 150,
      grow: 1,
      sortId: 'price',
      onSort: true,
    },
    {
      label: _t('table.header.creationDatetime'),
      width: 95,
      grow: 1,
      sortId: 'creationDatetime',
      onSort: true,
    },
    {
      label: _t('table.header.status'),
      width: 70,
      grow: 1,
      sortId: 'status',
      onSort: true,
    },
    {
      label: _t('table.header.entryStatementDatetime'),
      width: 95,
      grow: 1,
      sortId: 'entryStatementDatetime',
      onSort: false,
    },
    {
      label: _t('table.header.exitStatementDatetime'),
      width: 95,
      grow: 1,
      sortId: 'exitStatementDatetime',
      onSort: false,
    },
  ];

  const renderStatusCell = (bill: BillEsPrivateDTO): JSX.Element => {
    const status = translateEsBillStatus(bill.status);
    return (
      <span id={`${bill.billId}-status`} className="bill-table-row">
        {status}
      </span>
    );
  };

  const renderDatetimeFieldCell = (
    bill: BillEsPrivateDTO,
    field: string
  ): JSX.Element => {
    const isoDatetimeStrToRender = bill[field] as string;
    const isoDatetimeToRender = new Date(isoDatetimeStrToRender);

    return (
      <span id={`${bill.billId}-${field}`} className="bill-table-row">
        {isoDatetimeToRender.toLocaleString()}
      </span>
    );
  };

  const renderTextFieldCell = (
    bill: BillEsPrivateDTO,
    field: string
  ): JSX.Element => {
    return (
      <Link to={`/parking/bill/${bill.billId}`}>
        <span id={`${bill.billId}-${field}`} className="bill-table-row">
          {bill[field] as string}
        </span>
      </Link>
    );
  };

  const renderPriceFieldCell = (
    bill: BillEsPrivateDTO,
    field: string
  ): JSX.Element => {
    return (
      <Link to={`/parking/bill/${bill.billId}`}>
        <span id={`${bill.billId}-${field}`} className="bill-table-row">
          {formatSwissPrice(bill[field])}
        </span>
      </Link>
    );
  };

  const renderRow = (bill: BillEsPrivateDTO) => {
    return [
      renderTextFieldCell(bill, 'billId'),
      renderTextFieldCell(bill, 'plate'),
      renderPriceFieldCell(bill, 'price'),
      renderDatetimeFieldCell(bill, 'creationDatetime'),
      renderStatusCell(bill),
      renderDatetimeFieldCell(bill, 'entryStatementDatetime'),
      renderDatetimeFieldCell(bill, 'exitStatementDatetime'),
    ];
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={bills}
      remoteRowCount={billsCount}
      loadMoreRows={handleTableRange}
      onSort={handleTableSort}
      colSorted={sortIndex}
      sortOrder={sortAscending}
    />
  );
};

export default BillTable;
