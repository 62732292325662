import { EsBillStatus } from '@cvfm-front/tefps-types';

const { _t } = window.loadTranslations(__filename);

export function translateEsBillStatus(status: EsBillStatus): string {
  let statusTranslation;
  switch (status) {
    case EsBillStatus.AWAITING_OFFENDER:
      statusTranslation = _t('status.AWAITING_OFFENDER');
      break;
    case EsBillStatus.AWAITING_PAYMENT:
      statusTranslation = _t('status.AWAITING_PAYMENT');
      break;
    case EsBillStatus.PAID:
      statusTranslation = _t('status.PAID');
      break;
    case EsBillStatus.ABANDONED:
      statusTranslation = _t('status.ABANDONED');
      break;
    default:
      statusTranslation = _t('status.UNKNOWN_ERROR');
  }
  return statusTranslation;
}
