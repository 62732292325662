import * as React from 'react';

import { signGoogleMapUrl, BASE_GOOGLE_MAPS_URL } from 'api/url-signer';
import {
  PolylinePath,
  RoutesGroupDTO,
  RouteTemplateDTO,
} from 'api/planner/types';

import { polylinesToPathes } from './helpers';

type Position = {
  latitude: number; // eslint-disable-line react/no-unused-prop-types
  longitude: number; // eslint-disable-line react/no-unused-prop-types
};

type MapProps = {
  style: Record<string, any>;
  center: Position;
  selectedRouteTemplateId: string | null | undefined;
  routeTemplates: Array<RouteTemplateDTO>;
  routesGroups: Array<RoutesGroupDTO>;
  onChange: () => void;
  findColor: (routeTemplateId: string) => string;
  editRoutePath: (routeTemplateId: string) => void;
  hiddenRoutesGroupIds: Array<string>;
};

type MapState = {
  map: google.maps.Map | null | undefined;
  currentInfoWindow: google.maps.InfoWindow | null | undefined;
};

declare global {
  interface Window {
    initMap: any;
  }
}

class RoutesMap extends React.Component<MapProps, MapState> {
  map: google.maps.Map | null | undefined = null;
  currentInfoWindow: google.maps.InfoWindow | null | undefined = null;
  refMap: HTMLDivElement | undefined = undefined;
  polylines: Array<google.maps.Polyline> = [];

  componentDidMount(): void {
    // Connect the initMap() function within this class to the global window context,
    // so Google Maps can invoke it
    window.initMap = this.initMap;
    // Asynchronously load the Google Maps script, passing in the callback reference
    void this.loadJS(
      `${BASE_GOOGLE_MAPS_URL}/maps/api/js?callback=initMap&libraries=geometry`
    );
  }

  shouldComponentUpdate(nextProps: MapProps): boolean {
    const { selectedRouteTemplateId, hiddenRoutesGroupIds } = this.props;
    if (hiddenRoutesGroupIds !== nextProps.hiddenRoutesGroupIds) {
      return true;
    }
    if (nextProps.selectedRouteTemplateId === selectedRouteTemplateId) {
      return false;
    }
    this.polylines.forEach(polyline => {
      if (polyline.routeTemplateId === selectedRouteTemplateId) {
        polyline.setOptions({
          editable: false,
          strokeOpacity: 0.6,
          // fillOpacity: 0.25,
        });
      }
      if (polyline.routeTemplateId === nextProps.selectedRouteTemplateId) {
        polyline.setOptions({
          editable: true,
          strokeOpacity: 0.9,
          // fillOpacity: 0.75,
        });
      }
    });
    return true;
  }

  componentDidUpdate(prevProps: MapProps): void {
    const { hiddenRoutesGroupIds } = this.props;
    if (hiddenRoutesGroupIds !== prevProps.hiddenRoutesGroupIds) {
      this.redraw();
    }
  }

  initMap = (): void => {
    const { center } = this.props;
    const { refMap } = this;

    if (!refMap) {
      return;
    }

    this.map = new google.maps.Map(refMap, {
      zoom: 13,
      center: { lat: center.latitude, lng: center.longitude },
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });
    this.drawPolylines();

    if (!this.map) {
      return;
    }

    google.maps.event.addListener(
      this.map,
      'dblclick',
      (event: { latLng: { lat: () => number; lng: () => number } }) => {
        const { selectedRouteTemplateId, findColor, onChange } = this.props;
        // La variable hasAPath permet de désactiver la possibilité de pouvoir
        // créer une parcours avec plusieurs segments. Sans cette variable chaque
        // parcours pourra avoir plusieurs segments.
        const hasAPath = this.polylines.find(
          p => p.routeTemplateId === selectedRouteTemplateId
        );

        if (selectedRouteTemplateId && !hasAPath) {
          // eslint-disable-next-line
        const clicked = { lat: event.latLng.lat() as number, lon: event.latLng.lng() as number };
          const points = [
            new google.maps.LatLng(clicked.lat, clicked.lon),
            new google.maps.LatLng(clicked.lat + 0.001, clicked.lon + 0.001),
          ];
          const color = findColor(selectedRouteTemplateId);

          this.addPolyline(true, points, selectedRouteTemplateId, color, true);
          onChange();
        }
      }
    );
  };

  addPolyline = (
    selected: boolean,
    coords: Array<google.maps.LatLng>,
    routeTemplateId: string,
    color: string,
    visible: boolean
  ): void => {
    const { editRoutePath, onChange } = this.props;
    const polyline = new google.maps.Polyline({
      routeTemplateId,
      path: coords,
      draggable: false,
      editable: selected,
      strokeColor: color,
      strokeOpacity: selected ? 0.9 : 0.6,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: selected ? 0.75 : 0.25,
      visible,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          },
          offset: '100%',
        },
      ],
    });

    if (this.map) {
      polyline.setMap(this.map);
    }

    this.polylines.push(polyline);
    // Add action onClick on the polygon
    google.maps.event.addListener(polyline, 'click', () =>
      editRoutePath(polyline.routeTemplateId)
    );
    google.maps.event.addListener(polyline.getPath(), 'insert_at', () =>
      onChange()
    );
    google.maps.event.addListener(polyline.getPath(), 'set_at', () =>
      onChange()
    );
    google.maps.event.addListener(polyline.getPath(), 'remove_at', () => {
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close();
      }
      onChange();
    });
  };

  redraw = (): void => {
    this.removeAllPolylines();
    this.drawPolylines();
  };

  saveMap = (): PolylinePath[] => {
    return polylinesToPathes(this.polylines);
  };

  loadJS = async (src: string): Promise<void> => {
    const signedUrl = await signGoogleMapUrl(src);
    const script = window.document.createElement('script');
    script.src = BASE_GOOGLE_MAPS_URL + signedUrl.url;
    script.async = true;
    window.document.body.appendChild(script);
  };

  createRefMap = (node: HTMLDivElement): void => {
    this.refMap = node;
  };

  drawPolyline = (
    data: RouteTemplateDTO,
    color: string,
    visible: boolean
  ): void => {
    const { selectedRouteTemplateId } = this.props;
    // Polygon Coordinates
    if (data && data.paths) {
      data.paths.forEach(path => {
        const coords = path.points.map(
          p => new google.maps.LatLng(p.latitude, p.longitude)
        );
        // Styling & Controls
        const isSelected = data.id === selectedRouteTemplateId;
        this.addPolyline(isSelected, coords, path.id, color, visible);
      });
    }
  };

  drawPolylines = (): void => {
    const { routeTemplates, routesGroups, hiddenRoutesGroupIds } = this.props;
    routeTemplates.forEach(routeTemplate => {
      const relatedGroup = routesGroups.find(
        group =>
          group.routeTemplateIds.includes(routeTemplate.id) &&
          !hiddenRoutesGroupIds.includes(group.id)
      );
      if (relatedGroup) {
        this.drawPolyline(routeTemplate, relatedGroup.color, true);
      }
    });
  };

  removeAllPolylines = (): void => {
    this.polylines.map(p => p.setMap(null));
    this.polylines = [];
  };

  render(): React.ReactNode {
    const { style } = this.props;

    return (
      <span>
        <div ref={this.createRefMap} style={style} />
      </span>
    );
  }
}

export default RoutesMap;
