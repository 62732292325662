import { Column } from 'commons/Exporter';

const { _t } = window.loadTranslations(__filename);

export const BILL_EXPORT_COLS: Column[] = [
  { key: 'billId', label: _t('field.billId'), checked: true },
  {
    key: 'creationDatetime',
    label: _t('field.creationDatetime'),
    checked: true,
  },
  { key: 'price', label: _t('field.price'), checked: true },
  {
    key: 'status',
    label: _t('field.status'),
    checked: true,
  },
  {
    key: 'plate',
    label: _t('field.plate'),
    checked: true,
  },
  {
    key: 'vehicleModel',
    label: _t('field.vehicleModel'),
    checked: true,
  },
  {
    key: 'vehicleCategory',
    label: _t('field.vehicleCategory'),
    checked: true,
  },
  {
    key: 'entryStatementDatetime',
    label: _t('field.entryStatementDatetime'),
    checked: true,
  },
  {
    key: 'exitStatementDatetime',
    label: _t('field.exitStatementDatetime'),
    checked: true,
  },
];
