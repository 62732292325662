import { LapiReviewMediaDTO, LapiReviewImage } from 'api/lapiReview/types';
import { uploadFileAndReturnUrn } from 'api/mediaupload';
import { PatchObject } from 'api/commonTypes';
import { fetchControl, patchControl } from 'api/control';

export const filterContextImages = (
  useImproved: boolean,
  medias: Array<LapiReviewMediaDTO>
): Array<LapiReviewMediaDTO> => {
  const expectedType = useImproved ? 'CONTEXT_IMPROVED' : 'CONTEXT';
  return medias
    .filter(media => media.type === 'CONTEXT_HIK')
    .concat(medias.filter(media => media.type === expectedType));
};

export const replaceControlImage = (
  controlId: string,
  replaceImage: LapiReviewImage
): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetchControl(controlId)
      .then(control => {
        if (
          control &&
          control.mediaUpload &&
          replaceImage &&
          replaceImage.file
        ) {
          return uploadFileAndReturnUrn(control.mediaUpload, replaceImage.file);
        }
        return Promise.reject(404);
      })
      .then(urn => {
        const controlMediaPatch = {
          op: 'replace',
          path: '/medias',
          value: {
            url: urn,
            name: replaceImage.name,
            controlMediaType: replaceImage.type,
            position: replaceImage.position,
          },
        };
        return controlMediaPatch;
      })
      .then((controlPatch: PatchObject<unknown>) => {
        return patchControl(controlId, [controlPatch]);
      })
      .then(() => {
        resolve(`Le control ${controlId} a été mis jour`);
      })
      .catch(() => {
        const rejectError = new Error(
          `Erreur lors de la mise à jour du controle ${controlId}`
        );
        rejectError.name = 'ERR_REPLACE_CONTROL';
        reject(rejectError);
      });
  });
};
