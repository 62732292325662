import React, { CSSProperties } from 'react';
import Delete from 'material-ui/svg-icons/content/clear';
import moment, { Duration } from 'moment';

import { STYLE_ADD_BUTTON } from 'tefps/Pricing/Policies/theme';
import { PricingGridEntry } from '@cvfm-front/tefps-types';
import { Flex, Text } from '@cvfm-front/commons-ui';

import PricingField from './PricingField';
import DurationField from './DurationField';

const { _tg } = window.loadTranslations(__filename);

export const MAX_TICKET_PRICE = 100000; // 1000€
const MIN_TICKET_PRICE = 0;
const PRICE_STEP = 10; // centimes
const MAX_DURATION = moment.duration('P12M'); // 12 mois
const DEFAULT_DURATION = moment.duration('PT10M'); // 10 minutes

const STYLE_GRID: CSSProperties = {
  border: '1px solid #CCC',
  borderRadius: 5,
  marginRight: 6,
  padding: 10,
  width: '96%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const STYLE_TITLE: CSSProperties = {
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  color: '#000',
  fontSize: '1em',
  borderBottom: '1px solid #CCC',
  paddingTop: 8,
  paddingBottom: 8,
};

const initializeState = (
  pricingGrid: { [key: string]: PricingGridEntry },
  parkingMeterDurationId?: number
) => {
  if (pricingGrid) {
    return {
      cumulatedDurationsAndPrices: Object.entries(pricingGrid).map(
        ([durationPeriod, pricingGridEntry]) => ({
          duration: moment.duration(durationPeriod),
          price: Number(pricingGridEntry.price),
          computeExactHourlyEnd: pricingGridEntry.computeExactHourlyEnd,
          extendFreeWeekDays: pricingGridEntry.extendFreeWeekDays,
          extendDaysOff: pricingGridEntry.extendDaysOff,
          extendOffPeriods: pricingGridEntry.extendOffPeriods,
        })
      ),
      pmDefaultDurationId: parkingMeterDurationId,
    };
  }
  return { cumulatedDurationsAndPrices: [] };
};

type PricingGridState = {
  cumulatedDurationsAndPrices: Array<{
    duration: Duration;
    price: number;
    computeExactHourlyEnd: boolean;
    extendFreeWeekDays: boolean;
    extendDaysOff: boolean;
    extendOffPeriods: boolean;
  }>;
  pmDefaultDurationId?: number;
};

type PricingGridProps = {
  pricingGrid: {
    [key: string]: PricingGridEntry;
  };
  hasErrors: (value: boolean) => void;
  disabled: boolean;
  parkingMetersEnabled: boolean;
  parkingMeterDurationId?: number;
};

class PricingGrid extends React.Component<PricingGridProps, PricingGridState> {
  constructor(props: PricingGridProps) {
    super(props);
    this.state = initializeState(
      props.pricingGrid,
      props.parkingMeterDurationId
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: PricingGridProps): void {
    const { pricingGrid, parkingMeterDurationId } = newProps;
    const { pricingGrid: oldPricingGrid } = this.props;
    if (pricingGrid !== oldPricingGrid) {
      this.setState(initializeState(pricingGrid, parkingMeterDurationId));
    }
  }

  getPricingGrid = (): Record<
    string,
    {
      price: number;
      computeExactHourlyEnd: boolean;
      extendFreeWeekDays: boolean;
    }
  > => {
    const pricingGrid = {};
    const { cumulatedDurationsAndPrices } = this.state;
    cumulatedDurationsAndPrices.forEach(row => {
      pricingGrid[row.duration.toISOString()] = {
        price: row.price,
        computeExactHourlyEnd: row.computeExactHourlyEnd,
        extendFreeWeekDays: row.extendFreeWeekDays,
        extendDaysOff: row.extendDaysOff,
        extendOffPeriods: row.extendOffPeriods,
      };
    });
    return pricingGrid;
  };

  getParkingMeterDurationId = (): number | undefined => {
    const { pmDefaultDurationId } = this.state;
    return pmDefaultDurationId;
  };

  validateGrid = (
    cumulatedDurationsAndPrices: Array<{ duration: Duration; price: number }>
  ): void => {
    const erroneous = cumulatedDurationsAndPrices.some((row, i) => {
      const previous = cumulatedDurationsAndPrices[i - 1] || {
        duration: moment.duration('PT0M'),
        price: 0,
      };
      const next = cumulatedDurationsAndPrices[i + 1] || {
        duration: MAX_DURATION,
        price: MAX_TICKET_PRICE,
      };
      const isPriceInvalid =
        row.price < previous.price || row.price > next.price;
      const isDurationInvalid =
        row.duration.asMinutes() < previous.duration.asMinutes() ||
        row.duration.asMinutes() > next.duration.asMinutes();
      return isPriceInvalid || isDurationInvalid;
    });
    const { hasErrors } = this.props;
    hasErrors(erroneous);
  };

  deleteRow = (event: React.MouseEvent<any>): void => {
    const { cumulatedDurationsAndPrices, pmDefaultDurationId } = this.state;
    const index = Number(event.currentTarget.dataset.idx);
    const newcumulatedDurationsAndPrices = cumulatedDurationsAndPrices.filter(
      (p, idx) => idx !== index
    );
    const newDefaultDurationId =
      !pmDefaultDurationId || index === pmDefaultDurationId
        ? undefined
        : pmDefaultDurationId + -(index < pmDefaultDurationId);

    this.setState({
      cumulatedDurationsAndPrices: newcumulatedDurationsAndPrices,
      pmDefaultDurationId: newDefaultDurationId,
    });
  };

  addRow = (): void => {
    const { cumulatedDurationsAndPrices } = this.state;
    const len = cumulatedDurationsAndPrices.length;
    const defaultDuration =
      len >= 1
        ? moment
            .duration(cumulatedDurationsAndPrices[len - 1].duration)
            .add(DEFAULT_DURATION)
        : DEFAULT_DURATION;
    const defaultPrice =
      len - 1 >= 0
        ? Math.min(
            cumulatedDurationsAndPrices[len - 1].price + PRICE_STEP,
            MAX_TICKET_PRICE
          )
        : MIN_TICKET_PRICE;

    this.setState({
      cumulatedDurationsAndPrices: [
        ...cumulatedDurationsAndPrices,
        {
          duration: defaultDuration,
          price: defaultPrice,
          computeExactHourlyEnd: false,
          extendFreeWeekDays: false,
          extendDaysOff: false,
          extendOffPeriods: false,
        },
      ],
    });
  };

  changeDuration = (index: number, duration: Duration): void => {
    const cumulatedDurationsAndPrices = [
      ...this.state.cumulatedDurationsAndPrices,
    ];
    cumulatedDurationsAndPrices[index] = {
      duration,
      price: cumulatedDurationsAndPrices[index].price,
      computeExactHourlyEnd:
        cumulatedDurationsAndPrices[index].computeExactHourlyEnd,
      extendFreeWeekDays: cumulatedDurationsAndPrices[index].extendFreeWeekDays,
      extendDaysOff: cumulatedDurationsAndPrices[index].extendDaysOff,
      extendOffPeriods: cumulatedDurationsAndPrices[index].extendOffPeriods,
    };
    this.setState({ cumulatedDurationsAndPrices });
    this.validateGrid(cumulatedDurationsAndPrices);
  };

  changePrice = (index: number, price: number): void => {
    const cumulatedDurationsAndPrices = [
      ...this.state.cumulatedDurationsAndPrices,
    ];
    cumulatedDurationsAndPrices[index] = {
      duration: cumulatedDurationsAndPrices[index].duration,
      price,
      computeExactHourlyEnd:
        cumulatedDurationsAndPrices[index].computeExactHourlyEnd,
      extendFreeWeekDays: cumulatedDurationsAndPrices[index].extendFreeWeekDays,
      extendDaysOff: cumulatedDurationsAndPrices[index].extendDaysOff,
      extendOffPeriods: cumulatedDurationsAndPrices[index].extendOffPeriods,
    };
    this.setState({ cumulatedDurationsAndPrices });
    this.validateGrid(cumulatedDurationsAndPrices);
  };

  onChangePmDefaultDuration = (event: React.FormEvent<any>): void => {
    this.setState({ pmDefaultDurationId: Number(event.currentTarget.value) });
  };

  onChangeComputeExactHourlyEnd = (index: number): void => {
    const cumulatedDurationsAndPrices = [
      ...this.state.cumulatedDurationsAndPrices,
    ];
    cumulatedDurationsAndPrices[index] = {
      duration: cumulatedDurationsAndPrices[index].duration,
      price: cumulatedDurationsAndPrices[index].price,
      computeExactHourlyEnd: !cumulatedDurationsAndPrices[index]
        .computeExactHourlyEnd,
      extendFreeWeekDays: cumulatedDurationsAndPrices[index].extendFreeWeekDays,
      extendDaysOff: cumulatedDurationsAndPrices[index].extendDaysOff,
      extendOffPeriods: cumulatedDurationsAndPrices[index].extendOffPeriods,
    };
    this.setState({ cumulatedDurationsAndPrices });
  };

  isLongDuration = (duration: Duration): boolean => {
    return duration.asDays() >= 1;
  };

  onChangeComputeExtendFreeWeekDays = (index: number): void => {
    const cumulatedDurationsAndPrices = [
      ...this.state.cumulatedDurationsAndPrices,
    ];
    cumulatedDurationsAndPrices[index] = {
      duration: cumulatedDurationsAndPrices[index].duration,
      price: cumulatedDurationsAndPrices[index].price,
      computeExactHourlyEnd:
        cumulatedDurationsAndPrices[index].computeExactHourlyEnd,
      extendFreeWeekDays: !cumulatedDurationsAndPrices[index]
        .extendFreeWeekDays,
      extendDaysOff: cumulatedDurationsAndPrices[index].extendDaysOff,
      extendOffPeriods: cumulatedDurationsAndPrices[index].extendOffPeriods,
    };
    this.setState({ cumulatedDurationsAndPrices });
  };

  onChangeComputeExtendFreeDaysOff = (index: number): void => {
    const cumulatedDurationsAndPrices = [
      ...this.state.cumulatedDurationsAndPrices,
    ];
    cumulatedDurationsAndPrices[index] = {
      ...cumulatedDurationsAndPrices[index],
      extendDaysOff: !cumulatedDurationsAndPrices[index].extendDaysOff,
    };
    this.setState({ cumulatedDurationsAndPrices });
  };

  onChangeComputeExtendFreeoffPeriods = (index: number): void => {
    const cumulatedDurationsAndPrices = [
      ...this.state.cumulatedDurationsAndPrices,
    ];
    cumulatedDurationsAndPrices[index] = {
      ...cumulatedDurationsAndPrices[index],
      extendOffPeriods: !cumulatedDurationsAndPrices[index].extendOffPeriods,
    };
    this.setState({ cumulatedDurationsAndPrices });
  };

  render(): JSX.Element {
    const { cumulatedDurationsAndPrices, pmDefaultDurationId } = this.state;
    const { disabled, parkingMetersEnabled } = this.props;

    return (
      <div style={STYLE_GRID}>
        <table style={{ width: '96%' }}>
          <tbody>
            <tr style={{ borderBottom: '1px solid #ccc', textAlign: 'center' }}>
              {!disabled && <td style={{ width: 40 }} />}
              <td style={STYLE_TITLE}>
                {_tg('tefps.pricing.pricing.pricingGrid.totalDuration')}
              </td>
              <td style={STYLE_TITLE}>
                {_tg('tefps.pricing.pricing.pricingGrid.cumuledPrice')}
              </td>
              <td style={STYLE_TITLE}>
                {_tg('tefps.pricing.pricing.pricingGrid.computeExactHourlyEnd')}
              </td>
              <td style={STYLE_TITLE}>
                {_tg('tefps.pricing.pricing.pricingGrid.extendFreeDays')}
              </td>
              {parkingMetersEnabled && (
                <td style={STYLE_TITLE}>
                  {_tg('tefps.pricing.pricing.pricingGrid.pmDefaultDuration')}
                </td>
              )}
            </tr>
            {cumulatedDurationsAndPrices.map((row, i) => {
              const previous = cumulatedDurationsAndPrices[i - 1];
              const next = cumulatedDurationsAndPrices[i + 1];
              return (
                <tr style={{ textAlign: 'center' }} key={i}>
                  {!disabled && (
                    <td style={{ width: 40 }}>
                      <span>
                        <Delete
                          cursor="pointer"
                          style={{ color: 'grey' }}
                          data-idx={i}
                          onClick={this.deleteRow}
                        />
                      </span>
                    </td>
                  )}
                  <td>
                    <DurationField
                      index={i}
                      duration={row.duration}
                      previousDuration={previous && previous.duration}
                      nextDuration={next && next.duration}
                      changeDuration={this.changeDuration}
                      disabled={disabled}
                    />
                  </td>
                  <td>
                    <PricingField
                      price={row.price}
                      previousPrice={previous && previous.price}
                      nextPrice={next && next.price}
                      index={i}
                      changePrice={this.changePrice}
                      disabled={disabled}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={row.computeExactHourlyEnd}
                      onChange={() => this.onChangeComputeExactHourlyEnd(i)}
                      disabled={disabled || !this.isLongDuration(row.duration)}
                    />
                  </td>
                  <td>
                    <Flex justifyContent="space-evenly">
                      <Flex flexDirection="column">
                        <Text>
                          {_tg(
                            'tefps.pricing.pricing.pricingGrid.extendFreeWeekDays'
                          )}
                        </Text>
                        <input
                          type="checkbox"
                          checked={row.extendFreeWeekDays}
                          onChange={() =>
                            this.onChangeComputeExtendFreeWeekDays(i)
                          }
                          disabled={
                            disabled || !this.isLongDuration(row.duration)
                          }
                          style={{ display: 'flex' }}
                        />
                      </Flex>
                      <Flex flexDirection="column">
                        <Text>
                          {_tg(
                            'tefps.pricing.pricing.pricingGrid.extendFreeDaysOff'
                          )}
                        </Text>
                        <input
                          type="checkbox"
                          checked={row.extendDaysOff}
                          onChange={() =>
                            this.onChangeComputeExtendFreeDaysOff(i)
                          }
                          disabled={
                            disabled || !this.isLongDuration(row.duration)
                          }
                        />
                      </Flex>
                      <Flex flexDirection="column">
                        <Text>
                          {_tg(
                            'tefps.pricing.pricing.pricingGrid.extendFreeOffPeriods'
                          )}
                        </Text>
                        <input
                          type="checkbox"
                          checked={row.extendOffPeriods}
                          onChange={() =>
                            this.onChangeComputeExtendFreeoffPeriods(i)
                          }
                          disabled={
                            disabled || !this.isLongDuration(row.duration)
                          }
                        />
                      </Flex>
                    </Flex>
                  </td>
                  {parkingMetersEnabled && (
                    <td>
                      <input
                        type="radio"
                        value={i}
                        checked={pmDefaultDurationId === i}
                        onChange={this.onChangePmDefaultDuration}
                        disabled={disabled}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
            {!disabled && (
              <tr>
                <td colSpan={3}>
                  <div
                    style={{ ...STYLE_ADD_BUTTON, marginTop: 12 }}
                    onClick={this.addRow}
                  >
                    +
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PricingGrid;
