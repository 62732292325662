import { CSSProperties } from 'react';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';

import {
  BKG_ERROR_RED,
  BKG_GREY,
  BKG_GREY_LIGHT,
  BKG_LIGHT,
  BKG_LIGHT_BLUE,
  BKG_ORANGE,
  TXT_BLACK,
  TXT_GREYBLUE,
} from 'theme';

export const SHADOW: CSSProperties = {
  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
};

export const STYLE_CONTENT: CSSProperties = {
  flex: 1,
  overflow: 'hidden',
  height: '100%',
  width: '100%',
};

export const STORAGE_LAPI_DARK_MODE = 'cvfm.lapiReview.darkMode';

const customlightTheme: __MaterialUI.Styles.MuiTheme = {
  paper: {
    backgroundColor: BKG_GREY_LIGHT,
  },
  palette: {
    canvasColor: BKG_GREY,
    secondaryTextColor: BKG_LIGHT_BLUE,
  },
  table: {
    backgroundColor: BKG_GREY,
  },
  textField: {
    textColor: TXT_BLACK,
    backgroundColor: BKG_GREY,
  },
};

export const DARK = '#121212';
const DARK_LIGHT = '#363636';
const SOLARIZED_DARK = '#242424';

const customDarkTheme: __MaterialUI.Styles.MuiTheme = {
  paper: {
    backgroundColor: SOLARIZED_DARK,
  },
  palette: {
    canvasColor: DARK_LIGHT,
    secondaryTextColor: '#107306',
  },
  table: {
    backgroundColor: DARK_LIGHT,
  },
  textField: {
    backgroundColor: DARK_LIGHT,
    disabledTextColor: TXT_GREYBLUE,
  },
  raisedButton: {
    color: DARK_LIGHT,
  },
  drawer: {
    color: SOLARIZED_DARK,
  },
  dropDownMenu: {
    accentColor: SOLARIZED_DARK,
  },
  menu: {
    backgroundColor: SOLARIZED_DARK,
  },
  inkBar: {
    backgroundColor: SOLARIZED_DARK,
  },
};

export const lightTheme: __MaterialUI.Styles.MuiTheme = getMuiTheme(
  lightBaseTheme,
  customlightTheme
);
export const darkTheme: __MaterialUI.Styles.MuiTheme = getMuiTheme(
  darkBaseTheme,
  customDarkTheme
);
