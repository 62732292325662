import { arrayToOption } from '@cvfm-front/commons-types';
import { mapLabelToTraduction } from '@cvfm-front/commons-utils';
import { ParkingOrientationList } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

export const parkingTypeOptions = arrayToOption(
  ParkingOrientationList,
  mapLabelToTraduction(ParkingOrientationList, _tg, 'ParkingOrientation')
);
