import React, { CSSProperties } from 'react';
import Paper from 'material-ui/Paper';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_LIGHT_BLUE } from 'theme';
import { SHADOW } from 'tefps/LapiReview/theme';

type PanelProps = {
  name: string;
  children?: Array<Record<string, any>> | Record<string, any>;
  style?: Record<string, any>;
  muiTheme?: MuiTheme;
};

const STYLE_CONTAINER: CSSProperties = {
  ...SHADOW,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 20,
};

const SEPARATOR_STYLE: CSSProperties = {
  width: '100%',
  marginBottom: 10,
};

const Panel = ({ name, children, style, muiTheme }: PanelProps) => {
  return (
    <Paper style={{ ...STYLE_CONTAINER, ...style }}>
      <SeparatorWithTitle
        style={SEPARATOR_STYLE}
        title={name}
        color={BKG_LIGHT_BLUE}
        titleSize={15}
        textColor={muiTheme?.textField?.textColor}
      />
      {children}
    </Paper>
  );
};

export default muiThemeable()(Panel);
