import React, { CSSProperties, useState } from 'react';
import { Dialog, TextField } from 'material-ui';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';

import { getConfigState } from 'config/duck';
import { BKG_LIGHT_BLUE } from 'theme';
import { LapiReviewConfigurationDTO } from 'api/lapiReviewConfiguration/types';

import TagsList from './TagsList';

const DIALOG_STYLE_SINGLE_COLUMN: CSSProperties = {
  width: 'auto',
  minWidth: '27%',
  maxWidth: '30%',
  minHeight: '25%',
  maxHeight: '90%',
};

type ReduxStateProps = {
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
};

type ComplexModalProps = ReduxStateProps & {
  open: boolean;
  onCancel: () => void;
  onConfirm: (comment: string, tags: Set<string>) => void;
};

const ComplexModal = ({
  open,
  onCancel,
  onConfirm,
  lapiReviewConfigurationDTO,
}: ComplexModalProps) => {
  const [comment, setComment] = useState<string>('');
  const [tagsSelected, setTagsSelected] = useState<Set<string>>(new Set());

  const handleCancel = () => {
    setComment('');
    setTagsSelected(new Set());
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm(comment, tagsSelected);
    setComment('');
    setTagsSelected(new Set());
  };

  const actions = [
    <FlatButton label="Annuler" secondary onClick={handleCancel} />,
    <FlatButton
      label="Confirmer"
      labelStyle={{ color: BKG_LIGHT_BLUE }}
      primary
      keyboardFocused
      onClick={handleConfirm}
    />,
  ];

  return (
    <Dialog
      title="Qualifications du contrôle"
      actions={actions}
      modal
      open={open}
      contentStyle={DIALOG_STYLE_SINGLE_COLUMN}
      autoScrollBodyContent
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <TagsList
            lapiReviewConfigurationDTO={lapiReviewConfigurationDTO}
            tagsSelected={tagsSelected}
            setTagsSelected={setTagsSelected}
          />
          <TextField
            id="lapi-fps-confirm--col-third-text-field"
            floatingLabelText="Commentaire"
            floatingLabelFixed
            value={comment}
            multiLine
            onChange={(e: React.FormEvent<unknown>, text: string) =>
              setComment(text)
            }
            fullWidth
            maxlength="200"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default connect(
  (state): ReduxStateProps => {
    const { lapiReviewConfigurationDTO } = getConfigState(state);
    return { lapiReviewConfigurationDTO };
  }
)(ComplexModal);
