import React, { CSSProperties } from 'react';
import Paper from 'material-ui/Paper';
import flow from 'lodash.flow';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import Duplicate from 'material-ui/svg-icons/content/content-copy';
import Delete from 'material-ui/svg-icons/content/clear';
import { DragSource, DropTarget } from 'react-dnd';

import { BKG_CYAN } from 'theme';
import ConfirmAction from 'commons/ConfirmAction';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  marginBottom: '-8px', // marge négative pour permettre un chevauchement partiel des disques numérotant les politiques
  cursor: 'move',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flex: 1,
};

const GREY_BORDER = '#CACACA';
const RED_DELETE = '#DF586E';

const STYLE_CIRCLE: CSSProperties = {
  height: 48,
  width: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: BKG_CYAN,
  fontSize: 24,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

const STYLE_NAME: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  paddingBottom: 2,
  borderBottom: `2px solid ${BKG_CYAN}`,
};

const STYLE_ACTION: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  border: `1px solid ${GREY_BORDER}`,
  borderRadius: 5,
  marginBottom: 8,
  marginLeft: 10,
};

const STYLE_DELETE: CSSProperties = { padding: '0px 4px 0px 4px' };

const STYLE_EDIT: CSSProperties = {
  ...STYLE_DELETE,
  borderRight: `1px solid ${GREY_BORDER}`,
};

type DndProps = {
  connectDragSource: (element: JSX.Element) => JSX.Element;
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  isDragging: boolean;
};

type OwnProps = {
  moveItem: Function; // eslint-disable-line react/no-unused-prop-types
  findIndexFromId: Function; // eslint-disable-line react/no-unused-prop-types
  id: string;
  name: string;
  idx: number;
  edit: Function;
  remove: Function;
  duplicate: Function;
  deletionEnabled: boolean;
};

type Props = OwnProps & DndProps;

const itemSource = {
  beginDrag(props: Props) {
    return {
      id: props.id,
      originalIndex: props.findIndexFromId(props.id),
    };
  },

  endDrag(props: Props, monitor: any) {
    const { id: droppedId, originalIndex } = monitor.getItem();
    props.moveItem(droppedId, originalIndex, true);
  },
};

const itemTarget = {
  canDrop() {
    return false;
  },

  hover(props: Props, monitor: any) {
    const { id: draggedId } = monitor.getItem();
    const { id: overId } = props;

    if (draggedId !== overId) {
      const overIndex = props.findIndexFromId(overId);
      props.moveItem(draggedId, overIndex, false);
    }
  },
};

class DnDItem extends React.Component<Props> {
  edit = () => this.props.edit(this.props.id);
  remove = () => this.props.remove(this.props.id);
  duplicate = () => this.props.duplicate(this.props.id);

  render(): JSX.Element {
    const {
      name,
      isDragging,
      connectDragSource,
      connectDropTarget,
      idx,
      deletionEnabled,
    } = this.props;
    const opacity = isDragging ? 0 : 1;
    const zI = Math.round(1000 / (idx + 1));

    if (!deletionEnabled) {
      return (
        <div
          style={{ ...STYLE_CONTAINER, cursor: 'pointer', opacity, zIndex: zI }}
          onClick={this.edit}
        >
          <Paper style={{ ...STYLE_CIRCLE, zIndex: zI }} zDepth={1} circle>
            <div>{idx}</div>
          </Paper>
          <div style={STYLE_NAME}>{name}</div>
        </div>
      );
    }

    return connectDragSource(
      connectDropTarget(
        <div style={{ ...STYLE_CONTAINER, opacity, zIndex: zI }}>
          <Paper style={{ ...STYLE_CIRCLE, zIndex: zI }} zDepth={1} circle>
            <div>{idx}</div>
          </Paper>
          <div style={STYLE_NAME}>{name}</div>
          <div style={STYLE_ACTION}>
            <span title={_tg('action.modify')} style={STYLE_EDIT}>
              <Edit
                cursor="pointer"
                style={{ color: BKG_CYAN }}
                onClick={this.edit}
              />
            </span>
            <span title={_tg('action.duplicate')} style={STYLE_EDIT}>
              <Duplicate
                cursor="pointer"
                style={{ color: BKG_CYAN }}
                onClick={this.duplicate}
              />
            </span>
            <span title={_tg('action.delete')} style={STYLE_DELETE}>
              <ConfirmAction
                action={this.remove}
                message={_tg('tefps.pricing.pricing.deletePolicyConfirmation')}
                enabled
              >
                <Delete cursor="pointer" style={{ color: RED_DELETE }} />
              </ConfirmAction>
            </span>
          </div>
        </div>
      )
    );
  }
}

export default flow(
  DragSource<OwnProps>('item', itemSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget('item', itemTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(DnDItem);
