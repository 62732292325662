import React, { useEffect, useState } from 'react';

import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { fetchAgents } from 'api/accounts';
import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import { containsIdNameOrLogin } from '../../../../Administration/Agents/List/utils';

const { _t } = window.loadTranslations(__filename);

type Props = {
  onQualityAgentSelect: (e: string | null | undefined) => void;
};

function QualityControlAgentFilter({
  onQualityAgentSelect,
}: Props): JSX.Element {
  const [reviewAgentSearchOptions, setReviewAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [agents, setAgents] = useState<AgentAccountLightDTO[]>([]);
  const [search, setSearch] = useState<string>('');
  const setMessage = useSnackbar();

  function onSelectAgentToFilter(id: string, value: any) {
    onQualityAgentSelect(value === '' ? null : value);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async function updateMatchingAgentsList(idNameLogin: string) {
    let filteredAgents: Array<{ id: string; fullName: string }> = [];
    if (idNameLogin) {
      filteredAgents = (agents || [])
        .filter(agent => containsIdNameOrLogin(idNameLogin, agent))
        .map(agent => ({
          id: agent.agentId,
          fullName: `${agent.firstName} ${agent.lastName}`,
        }));
    }

    const agentSearchOptions = filteredAgents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    setReviewAgentSearchOptions(agentSearchOptions);
    setSearch(idNameLogin);
  }

  async function fetchAgentsList() {
    try {
      const agentListResponse = await fetchAgents('fps');
      // On tri les agents par ordre alphabétique une fois au chargement
      const agentsSorted = agentListResponse.sort(
        (a, b) =>
          a.lastName.localeCompare(b.lastName) ||
          a.firstName.localeCompare(b.firstName)
      );
      setAgents(agentsState => [...agentsState, ...agentsSorted]);
    } catch (err) {
      setMessage(err ? err.message : err);
    }
  }

  useEffect(() => {
    void fetchAgentsList();
  }, []);

  return (
    <Autocomplete
      id="agentIdName"
      title={_t('element.autocompleteAgent.title')}
      options={reviewAgentSearchOptions}
      onChange={onSelectAgentToFilter}
      onAutocomplete={updateMatchingAgentsList}
      search={search}
      secondaryColor
    />
  );
}

export default QualityControlAgentFilter;
