import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Add from 'material-ui/svg-icons/content/add-box';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import BoButton from 'facade/BoButton';
import Sidebar from 'commons/SidebarV2';
import { getApiState } from 'api/duck';
import { STYLE_TITLE_BORDER, TXT_GREY } from 'theme';
import { RoutesGroupDTO, RouteTemplateDTO } from 'api/planner/types';

import RoutesGroup from './RoutesGroup';
import AddRoutesGroup from './AddRoutesGroup';
import RouteTemplate from './RouteTemplate';
import AddRouteTemplate from './AddRouteTemplate';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_BLOCK_TITLE: CSSProperties = {
  fontSize: 15,
  marginBottom: 20,
  marginTop: 8,
  fontWeight: 'bold',
  display: 'flex',
  color: TXT_GREY,
};

const STYLE_ROUTE_TITLE: CSSProperties = {
  ...STYLE_BLOCK_TITLE,
  marginTop: 40,
  marginBottom: 0,
};

const ADD_STYLE: CSSProperties = {
  color: 'white',
  marginLeft: 0,
  width: 60,
  height: 30,
  cursor: 'pointer',
};

type Props = {
  routesGroups: Array<RoutesGroupDTO>;
  routeTemplates: Array<RouteTemplateDTO>;
  saveRoutesGroup: (group: RoutesGroupDTO, flag: boolean) => void;
  addOrEditRoute: (template: RouteTemplateDTO, groupdIds: string[]) => void;
  deleteRoutesGroup: (selectedGroup: RoutesGroupDTO) => void;
  deleteRouteTemplate: (routeTemplate: RouteTemplateDTO) => void;
  saveRouteTemplates: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selectedRouteTemplateId: string | null | undefined;
  editRoutePath: (routeTemplateId: string | null | undefined) => any;
  hasChanged: boolean;
  showRoutesGroup: (routesGroupId: string) => void;
  hideRoutesGroup: (routesGroupId: string) => void;
  canWriteRoutesGroup: boolean;
  canWriteRouteTemplate: boolean;
};

type State = {
  editType: 'ADD' | 'EDIT' | 'NONE';
  editRouteTemplateType: string;
  selectedGroup: RoutesGroupDTO | null | undefined;
  selectedRouteTemplate: RouteTemplateDTO | null | undefined;
  groupId: string | null | undefined;
};

class RoutesSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editType: 'NONE',
      editRouteTemplateType: 'NONE',
      selectedGroup: null,
      selectedRouteTemplate: null,
      groupId: null,
    };
  }

  onChangeGroup = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    groupId: string
  ) => this.setState({ groupId });

  newRoutesGroup = () =>
    this.setState({ editType: 'ADD', selectedGroup: null });

  newRouteTemplate = () =>
    this.setState({
      editRouteTemplateType: 'ADD',
      selectedRouteTemplate: null,
    });

  close = () =>
    this.setState({
      editType: 'NONE',
      editRouteTemplateType: 'NONE',
      selectedGroup: null,
    });

  saveRoutesGroupStub = (selectedGroup: RoutesGroupDTO) => {
    const { saveRoutesGroup } = this.props;
    saveRoutesGroup(selectedGroup, true);
    this.setState({ editType: 'NONE', selectedGroup: null });
  };

  saveRouteTemplate = (
    routeTemplate: RouteTemplateDTO,
    groupIds: Array<string>
  ) => {
    const { addOrEditRoute } = this.props;
    addOrEditRoute(routeTemplate, groupIds);
    this.setState({
      editRouteTemplateType: 'NONE',
      selectedRouteTemplate: null,
    });
  };

  deleteRoutesGroupStub = (selectedGroup: RoutesGroupDTO) => {
    const { deleteRoutesGroup } = this.props;
    deleteRoutesGroup(selectedGroup);
  };

  deleteRouteTemplateStub = (routeTemplate: RouteTemplateDTO) => {
    const { deleteRouteTemplate } = this.props;
    deleteRouteTemplate(routeTemplate);
  };

  edit = (selectedGroup: RoutesGroupDTO) => {
    this.setState({ editType: 'EDIT', selectedGroup });
  };

  editRouteTemplate = (selectedRouteTemplate: RouteTemplateDTO) => {
    this.setState({ editRouteTemplateType: 'EDIT', selectedRouteTemplate });
  };

  render() {
    const {
      editType,
      editRouteTemplateType,
      selectedGroup,
      selectedRouteTemplate,
      groupId,
    } = this.state;
    const {
      routesGroups,
      routeTemplates,
      editRoutePath,
      selectedRouteTemplateId,
      hasChanged,
      showRoutesGroup,
      hideRoutesGroup,
      canWriteRoutesGroup,
      canWriteRouteTemplate,
      saveRouteTemplates,
    } = this.props;

    const selectFieldGroups = [{ id: 'Tous', name: 'Tous' }, ...routesGroups];

    let displayRouteTemplates = routeTemplates;
    if (groupId && groupId !== 'Tous') {
      const findRoutesGroup = routesGroups.find(group => group.id === groupId);
      if (findRoutesGroup) {
        displayRouteTemplates = displayRouteTemplates.filter(route =>
          findRoutesGroup.routeTemplateIds.includes(route.id)
        );
      }
    }

    return (
      <Sidebar>
        <div style={STYLE_BLOCK_TITLE}>
          {_t('element.sidebar.routesGroup')}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <div style={{ marginBottom: 10 }}>
          {routesGroups.map(routesGroup => (
            <RoutesGroup
              key={`route-group-${routesGroup.id}`}
              routesGroup={routesGroup}
              edit={this.edit}
              deleteRoutesGroup={this.deleteRoutesGroupStub}
              showRoutesGroup={showRoutesGroup}
              hideRoutesGroup={hideRoutesGroup}
            />
          ))}
        </div>
        {canWriteRoutesGroup && (
          <div style={{ marginBottom: 10 }}>
            <span
              title={_t('element.sidebar.createRoutes')}
              style={{ right: 0 }}
            >
              <Add style={ADD_STYLE} onClick={this.newRoutesGroup} />
            </span>
            <AddRoutesGroup
              editType={editType}
              routesGroup={selectedGroup}
              close={this.close}
              save={this.saveRoutesGroupStub}
            />
          </div>
        )}

        <div style={STYLE_ROUTE_TITLE}>
          {_t('element.sidebar.routes')}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <SelectField
          value={groupId}
          style={{ width: '100%' }}
          onChange={this.onChangeGroup}
          floatingLabelText={_t('element.sidebar.routesGroup')}
          floatingLabelStyle={{ color: '#aaa' }}
          labelStyle={{ color: '#fff', fontSize: 14 }}
        >
          {selectFieldGroups.map(({ id, name }) => (
            <MenuItem key={id} value={id} primaryText={name || id} />
          ))}
        </SelectField>
        <div style={{ marginBottom: 10 }}>
          {displayRouteTemplates.map(routeTemplate => (
            <RouteTemplate
              key={`route-template-${routeTemplate.id}`}
              routeTemplate={routeTemplate}
              editRouteTemplate={this.editRouteTemplate}
              selectedRouteTemplateId={selectedRouteTemplateId}
              deleteRouteTemplate={this.deleteRouteTemplateStub}
              editRoutePath={editRoutePath}
            />
          ))}
        </div>
        {canWriteRouteTemplate && (
          <div style={{ marginBottom: 10 }}>
            <span
              title={_t('element.sidebar.createRoute')}
              style={{ right: 0 }}
            >
              <Add style={ADD_STYLE} onClick={this.newRouteTemplate} />
            </span>
            <AddRouteTemplate
              editType={editRouteTemplateType}
              routeTemplate={selectedRouteTemplate}
              routesGroups={routesGroups}
              close={this.close}
              saveRouteTemplate={this.saveRouteTemplate}
            />
            <BoButton
              key={2}
              label={_tg('action.save_1')}
              primary
              fullWidth
              onClick={saveRouteTemplates}
              disabled={!hasChanged}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  const canWriteRoutesGroup = userInfo
    ? userInfo.rights.includes('ROUTES_GROUP_WRITE')
    : null;
  const canWriteRouteTemplate = userInfo
    ? userInfo.rights.includes('ROUTE_TEMPLATE_WRITE')
    : null;
  return {
    canWriteRoutesGroup,
    canWriteRouteTemplate,
  };
})(RoutesSidebar);
