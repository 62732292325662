import React, { CSSProperties } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';

import { loadEmbedDashboardAccess } from 'api/observatory';
import { KeyLabel } from '@cvfm-front/commons-types';

import { SupersetDashboardFilters } from '../../api/observatory/types';
import { exportIndicator1D } from '../../api/backoffice';

const { _tg } = window.loadTranslations(__filename);

export const STYLE_OBS_TEXT: CSSProperties = {
  color: 'black',
  backgroundColor: 'transparent',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  minWidth: 135,
  justifyContent: 'center',
  borderRadius: 4,
  gap: 10,
  border: '2px solid rgb(218,173,255, 0.2)',
};

export const STYLE_OBS_TEXT_SELECTED: CSSProperties = {
  ...STYLE_OBS_TEXT,
  color: '#792FDA',
  backgroundColor: '#EAE2FF',
};

export enum TemporalUnit {
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

// We consider that controlMetrics are purged after 3 years
export const METRICS_LIFETIME = 3;

type FunctionErrorHandler = (error: string | null) => void;

export const loadEmbeddedDashboard = (
  filters: SupersetDashboardFilters,
  dashboardRef: React.RefObject<HTMLDivElement>,
  setError: FunctionErrorHandler
) => {
  loadEmbedDashboardAccess(filters)
    .then(response => {
      setError(null);
      embedDashboard({
        id: filters.dashboardId,
        supersetDomain: response.supersetProxyUrl,
        mountPoint: dashboardRef.current!,
        fetchGuestToken: () => Promise.resolve(response.guestToken),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: false,
          filters: {
            visible: true,
            expanded: false,
          },
          urlParams: {
            native_filters_key: response.filterKey
              ? response.filterKey
              : undefined,
            standalone: '1',
            show_filters: '0',
            show_native_filters: '0',
          },
        },
      });
    })
    .catch(e => {
      setError(e.message);
    });
};

export const getWeekDateRange = (weekNumber: number, year: number): string => {
  const formatDate = (date: Date): string =>
    date.toLocaleDateString(window.i18next.language, {
      day: 'numeric',
      month: 'short',
    });

  const firstDayOfYear = new Date(year, 0, 1);
  const firstWeekStart = new Date(
    firstDayOfYear.getTime() +
      (8 - (firstDayOfYear.getDay() || 7)) * 24 * 60 * 60 * 1000
  );
  const weekStartDate = new Date(
    firstWeekStart.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
  );
  const weekEndDate = new Date(
    weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
  );
  return `${_tg('field.date.fromAlternative')} ${formatDate(
    weekStartDate
  )} ${_tg('field.date.toAlternative')} ${formatDate(weekEndDate)}`;
};

export const getCurrentMonth = (): number => {
  return new Date().getMonth();
};

export const getCurrentWeek = (): number => {
  const date = new Date();
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const daysSinceStartOfYear =
    (date.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000);
  const weekOffset = startOfYear.getDay();
  return Math.ceil((daysSinceStartOfYear + weekOffset) / 7) - 1;
};

export const periodOptions = (period: TemporalUnit): KeyLabel[] | string[] => {
  switch (period) {
    case TemporalUnit.YEAR: {
      const currentYear = new Date().getFullYear();
      const years = [];
      let year = currentYear - METRICS_LIFETIME;
      while (year <= currentYear) {
        years.push({ key: `${year}`, label: `${year}` });
        year += 1;
      }
      return years.sort((a, b) => parseInt(b.key, 10) - parseInt(a.key, 10));
    }
    case TemporalUnit.QUARTER: {
      return Array.from({ length: 4 }, (_, i) => ({
        key: `${i + 1}`,
        label: `${_tg('commons.quarter')} ${(i + 1).toString()}`,
      }));
    }
    case TemporalUnit.WEEK: {
      return Array.from(
        { length: 52 },
        (_, i) => `${_tg('commons.weekAbbreviation')}-${i + 1}`
      );
    }
    case TemporalUnit.MONTH: {
      const date = new Date();
      const months: string[] = [];
      let month = 0;
      while (month < 12) {
        date.setMonth(month);
        const monthTemp = date.toLocaleDateString(window.i18next.language, {
          month: 'long',
        });
        months.push(monthTemp.charAt(0).toUpperCase() + monthTemp.slice(1));
        month += 1;
      }
      return months;
    }
    default:
      return [];
  }
};

export const DropDownArrow = ({ selected }: { selected: boolean }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.1207 9.29055L12.0007 13.1705L15.8807 9.29055C16.2707 8.90055 16.9007 8.90055 17.2907 9.29055C17.6807 9.68055 17.6807 10.3105 17.2907 10.7005L12.7007 15.2905C12.3107 15.6805 11.6807 15.6805 11.2907 15.2905L6.7007 10.7005C6.3107 10.3105 6.3107 9.68055 6.7007 9.29055C7.0907 8.91055 7.7307 8.90055 8.1207 9.29055Z"
      fill={selected ? '#792FDA' : 'black'}
    />
  </svg>
);

export const Arrow = ({ direction = 'back' }: { direction: string }) => {
  let transform = 'scaleX(-1)';
  if (direction === 'next') {
    transform = 'scaleX(-1)';
  } else if (direction === 'back') {
    transform = 'scaleX(1)';
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform,
      }}
    >
      <path
        d="M14.7105 6.71047C14.3205 6.32047 13.6905 6.32047 13.3005 6.71047L8.71047 11.3005C8.32047 11.6905 8.32047 12.3205 8.71047 12.7105L13.3005 17.3005C13.6905 17.6905 14.3205 17.6905 14.7105 17.3005C15.1005 16.9105 15.1005 16.2805 14.7105 15.8905L10.8305 12.0005L14.7105 8.12047C15.1005 7.73047 15.0905 7.09047 14.7105 6.71047Z"
        fill="#131313"
      />
    </svg>
  );
};

export const computeDateInterval = (year: number, quarter?: number) => {
  let startDate;
  let endDate;

  switch (quarter) {
    case 1:
      startDate = new Date(year, 0, 1);
      endDate = new Date(year, 3, 0, 23, 59, 59);
      break;
    case 2:
      startDate = new Date(year, 3, 1);
      endDate = new Date(year, 6, 0, 23, 59, 59);
      break;
    case 3:
      startDate = new Date(year, 6, 1);
      endDate = new Date(year, 9, 0, 23, 59, 59);
      break;
    case 4:
      startDate = new Date(year, 9, 1);
      endDate = new Date(year, 12, 0, 23, 59, 59);
      break;
    default:
      throw new Error('Invalid quarter');
  }

  const today = new Date();
  if (endDate > today) {
    endDate = today;
  }

  return { startDate, endDate };
};

export const export1D = async (
  filters: SupersetDashboardFilters,
  agentId: string
): Promise<void> => {
  const { year } = filters;
  const { quarter } = filters;

  const { startDate, endDate } = computeDateInterval(year, quarter);

  const { organizationId } = filters;

  const request = {
    from: startDate,
    to: endDate,
    organizationId: organizationId || '', // when exporting 1D KPI, an organization should be selected
    agentId,
  };
  await exportIndicator1D(request);
};

export const matchesCurrentPeriod = (
  filters: SupersetDashboardFilters,
  period: TemporalUnit
) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  if (period === TemporalUnit.YEAR) {
    return filters.year === currentYear;
  }
  if (period === TemporalUnit.QUARTER) {
    const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
    return filters.quarter === currentQuarter;
  }
  return false;
};
