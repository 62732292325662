import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { isEqual } from 'lodash';

import { ControlSubscriber } from 'api/control/types';
import * as INNER_THEME from 'tefps/LapiReview/TicketInformation/theme';
import DateComponent from 'commons/Date';
import { LightLapiZone } from 'api/pricing/types';
import { TXT_GREEN_RGAA, TXT_RED_RGGAA } from 'theme';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  subscriptions?: Array<ControlSubscriber> | null | undefined;
  subscriptionPlanMap: Map<string, string> | undefined;
  zonesMap: Map<string, LightLapiZone>;
  relevantSubscription?: ControlSubscriber;
  isForControl: boolean;
  controlZone: string;
  controlDate: string;
  controlPricingCategory: string | undefined;
  darkMode: boolean;
};

const RightsRow = ({
  subscriptions,
  subscriptionPlanMap,
  zonesMap,
  relevantSubscription,
  isForControl,
  controlDate,
  controlZone,
  controlPricingCategory,
  darkMode,
}: Props) => {
  function highlightUsefullSubscription(subscription: ControlSubscriber) {
    const highLight = isEqual(subscription, relevantSubscription);
    return highLight
      ? { ...INNER_THEME.rowStyle, color: TXT_GREEN_RGAA }
      : INNER_THEME.rowStyle;
  }

  function returnCellWithInvalidStyleTheme(bool: boolean) {
    return bool
      ? { ...INNER_THEME.cellsStyle, color: TXT_RED_RGGAA }
      : INNER_THEME.cellsStyle;
  }

  function highLightInvalidStartDateTime(startdatime: string) {
    const dateTime = new Date(controlDate).getTime();
    const startTime = new Date(startdatime).getTime();

    return returnCellWithInvalidStyleTheme(dateTime < startTime);
  }

  function highLightInvalidEndDateTime(endDatetime: string) {
    const dateTime = new Date(controlDate).getTime();
    const endTime = new Date(endDatetime).getTime();

    return returnCellWithInvalidStyleTheme(dateTime > endTime);
  }

  function highLightInvalidZone(validityAreas: string[]) {
    return returnCellWithInvalidStyleTheme(
      !validityAreas.includes(controlZone)
    );
  }

  function highLightInvalidPricingCategory(
    princingCategory: string | null | undefined
  ) {
    if (princingCategory || controlPricingCategory) {
      return INNER_THEME.cellsStyle;
    }
    return returnCellWithInvalidStyleTheme(
      princingCategory !== controlPricingCategory
    );
  }

  const sortedSubscriptions:
    | Array<ControlSubscriber>
    | null
    | undefined = useMemo(
    () =>
      subscriptions?.sort(
        (a, b) =>
          new Date(a.startOfValidityDatetime).getTime() -
          new Date(b.startOfValidityDatetime).getTime()
      ),
    [subscriptions]
  );

  return (
    <div
      style={
        isForControl ? INNER_THEME.bodyControlWrapper : INNER_THEME.bodyWrapper
      }
    >
      {!!sortedSubscriptions && sortedSubscriptions.length > 0 ? (
        <Table style={INNER_THEME.tableStyle}>
          <TableHeader
            adjustForCheckbox={false}
            enableSelectAll={false}
            displaySelectAll={false}
          >
            <TableRow selectable={false} style={INNER_THEME.commonRowStyle}>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.start')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.end')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.area')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.lastCellStyle}>
                {_tg('commons.category')}
              </TableRowColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} style={INNER_THEME.bodyWrapper}>
            {sortedSubscriptions.map((right: ControlSubscriber, index) => {
              return (
                <TableRow
                  key={index}
                  selectable={false}
                  style={
                    highlightUsefullSubscription(right) &&
                    (darkMode ? INNER_THEME.darkMode : INNER_THEME.rowStyle)
                  }
                >
                  <TableRowColumn
                    style={
                      INNER_THEME.cellsStyle &&
                      highLightInvalidStartDateTime(
                        right.startOfValidityDatetime
                      )
                    }
                  >
                    <DateComponent
                      datetime={right.startOfValidityDatetime}
                      withYear
                      withAlternativeSeparator
                    />
                  </TableRowColumn>
                  <TableRowColumn
                    style={
                      INNER_THEME.cellsStyle &&
                      highLightInvalidEndDateTime(right.endOfValidityDatetime)
                    }
                  >
                    <DateComponent
                      datetime={right.endOfValidityDatetime}
                      withYear
                      withAlternativeSeparator
                    />
                  </TableRowColumn>
                  <TableRowColumn
                    style={
                      INNER_THEME.cellsStyle &&
                      highLightInvalidZone(right.validityAreas)
                    }
                  >
                    {right.validityAreas.map((area: string, index: number) => {
                      return ` ${zonesMap.get(area)?.name}${
                        index === right.validityAreas.length - 1 ? '' : ','
                      }`;
                    })}
                  </TableRowColumn>
                  <TableRowColumn
                    style={
                      (subscriptionPlanMap
                        ? highLightInvalidPricingCategory(
                            subscriptionPlanMap.get(right.subscriptionPlanId)
                          )
                        : highLightInvalidPricingCategory(
                            right.subscriptionPlanId
                          )) && INNER_THEME.lastCellStyle
                    }
                  >
                    {subscriptionPlanMap
                      ? subscriptionPlanMap.get(right.subscriptionPlanId)
                      : right.subscriptionPlanId}
                  </TableRowColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Table style={INNER_THEME.tableStyle}>
          <TableBody displayRowCheckbox={false} style={INNER_THEME.bodyWrapper}>
            <TableRow selectable={false} style={INNER_THEME.emptyRowStyle}>
              <TableRowColumn style={INNER_THEME.emptyCellsTable}>
                {_tg('tefps.lapiReview.ticketInformation.noRight')}
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default RightsRow;
