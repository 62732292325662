import React from 'react';

type Props = {
  content: any;
  actions: any;
};

const ContentWithActions = ({ content, actions }: Props): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        width: '100%',
        height: '600px',
        backgroundColor: 'white',
        padding: 32,
      }}
    >
      <div style={{ flex: 1, overflow: 'auto' }}>{content}</div>
      <div style={{ display: 'flex' }}>{actions}</div>
    </div>
  );
};

export default ContentWithActions;
