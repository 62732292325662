import React, { CSSProperties } from 'react';
import { Dialog } from 'material-ui';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import IconButton from 'material-ui/IconButton';

import { BKG_LIGHT_BLUE } from '../../theme';

import Logout from './LapiSideBar/components/Logout';
import TEFPSButton from './LapiSideBar/components/TEFPSControlButton';

import './LapiReviewErrorModal.css';

const REFRESH_ICON_STYLE: CSSProperties = {
  width: '120px',
  height: '120px',
  fill: BKG_LIGHT_BLUE,
};

const REFRESH_BUTTON_STYLE: CSSProperties = { width: '120px', height: '120px' };

type Props = {
  open: boolean;
  handleRefresh: () => void;
};

function LapiReviewErrorModal({ open, handleRefresh }: Props): JSX.Element {
  const actions = [<Logout size="m" />, <TEFPSButton size="m" />];
  return (
    <Dialog
      title="Il n'y a pas de contrôles à afficher"
      modal
      open={open}
      actions={actions}
      actionsContainerClassName="dialog__actions"
      contentClassName="dialog__content"
    >
      <div className="dialog__reload__container">
        <IconButton
          tooltip="Recharger la page"
          onClick={handleRefresh}
          iconStyle={REFRESH_ICON_STYLE}
          style={REFRESH_BUTTON_STYLE}
        >
          <RefreshIcon />
        </IconButton>
      </div>
    </Dialog>
  );
}

export default LapiReviewErrorModal;
