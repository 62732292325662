import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MenuItem, SelectField } from 'material-ui';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

import {
  TreatmentAgentCounterDTO,
  VehicleCategory,
} from '@cvfm-front/tefps-types';
import SidebarDataLine from 'tefps/LapiReview/LapiSideBar/components/SidebarDataLine';
import { getApiState, InternalApiState } from 'api/duck';
import { fetchTodayReviewAgentStat } from 'api/cvfm-core-control/StatisticsApi';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';

const { _t } = window.loadTranslations(__filename);

const STYLE_WRAPPER: CSSProperties = {
  display: 'block',
  marginLeft: 12,
};

const initialCounterState: TreatmentAgentCounterDTO = {
  nbComplex: 0,
  nbExemption: 0,
  nbFps: 0,
  nbTreated: 0,
  nbReviewed: 0,
};

type Props = {
  sideBarOpen: boolean;
  agentId: string | undefined;
  complexCaseEnabled: boolean;
  muiTheme?: MuiTheme;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
};

function ReviewAgentCounter({
  agentId,
  sideBarOpen,
  complexCaseEnabled,
  muiTheme,
  authorizedVehicleCategories,
}: Props) {
  const hasVehicleTypes =
    authorizedVehicleCategories && authorizedVehicleCategories.length > 0;

  const [agentStats, setAgentStats] = useState<TreatmentAgentCounterDTO>(
    initialCounterState
  );
  const [vehicleType, setVehicleType] = useState<VehicleCategory>(
    'CAR' as VehicleCategory
  );

  async function updateTodayStats() {
    if (agentId) {
      const newCounter = await fetchTodayReviewAgentStat(agentId, vehicleType);
      if (!complexCaseEnabled) {
        newCounter.nbTreated = newCounter.nbFps + newCounter.nbExemption;
      }
      setAgentStats(newCounter);
    }
  }

  function onChangeVehicleCategory(
    e: any,
    index: number,
    value: VehicleCategory
  ) {
    setVehicleType(value);
  }

  useEffect(() => {
    if (sideBarOpen) {
      updateTodayStats();
    }
  }, [sideBarOpen, vehicleType]);

  return (
    <div style={STYLE_WRAPPER}>
      {hasVehicleTypes && (
        <SelectField
          floatingLabelText="Type du véhicule"
          onChange={onChangeVehicleCategory}
          value={vehicleType}
          style={{
            backgroundColor: muiTheme?.paper?.backgroundColor,
          }}
        >
          {Object.entries(VehicleCategory)
            .filter(vehicle =>
              authorizedVehicleCategories.find(
                authorized => authorized.vehicleCategory === vehicle[0]
              )
            )
            .map(vehicle => (
              <MenuItem
                value={vehicle[0]}
                primaryText={vehicle[1]}
                style={{ fontWeight: 'bold' }}
              />
            ))}
        </SelectField>
      )}
      <SidebarDataLine
        title={_t('element.nbFps')}
        data={`${agentStats.nbFps}`}
      />
      <SidebarDataLine
        title={_t('element.nbExemption')}
        data={`${agentStats.nbExemption}`}
      />
      {complexCaseEnabled && (
        <SidebarDataLine
          title={_t('element.nbComplex')}
          data={`${agentStats.nbComplex}`}
        />
      )}
      {hasVehicleTypes && (
        <SidebarDataLine
          title={_t('element.nbReviewed')}
          data={`${agentStats.nbReviewed}`}
        />
      )}
      <SidebarDataLine
        title={_t('element.nbTreated')}
        data={`${agentStats.nbTreated}`}
      />
    </div>
  );
}

function mapStateToProps(state: InternalApiState) {
  const { authorizedVehicleCategories } = getConfigState(state);
  authorizedVehicleCategories.sort((a, b) => a.priority - b.priority);

  const { userInfo } = getApiState(state);
  const agentId = userInfo?.agentId;
  return {
    agentId,
    authorizedVehicleCategories,
  };
}

export default muiThemeable()(connect(mapStateToProps)(ReviewAgentCounter));
