import { Checkbox } from 'material-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ControlAddressType, Coordinates } from 'api/commonTypes';
import useCurrentLapiReview from 'commons/hooks/useCurrentLapiReview';
import useZoning from 'commons/hooks/useZoning';
import services from 'commons/services';
import {
  CoreAddress,
  EsCityParkingSpaceDTO,
  FnmsLocation,
} from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';
import useCurrentControlDTO from 'commons/hooks/useCurrentControlDTO';

import ControlDialogueMap from './ControlDialogueMap';

const { _t } = window.loadTranslations(__filename);

type RecomputeControlPositionProps = {
  address: ControlAddressType;
  handleZoneChange: (
    location: FnmsLocation | null,
    zoneId: string,
    patrolZoneId: string | null
  ) => void;
  newParkingSpace: EsCityParkingSpaceDTO | null;
};

function RecomputeControlPosition({
  address,
  handleZoneChange,
  newParkingSpace,
}: RecomputeControlPositionProps): JSX.Element {
  const lapiReview = useCurrentLapiReview();
  const controlDTO = useCurrentControlDTO();
  const zoning = useZoning();
  const [shouldRecomputeZone, setShouldRecomputeZone] = useState(false);
  const [newZoneId, setNewZoneId] = useState(lapiReview.zoneId);
  const [
    newAddressCoordinate,
    setNewAddressCoordinate,
  ] = useState<Coordinates | null>(null);
  const toggleShouldRecomputeZone = useCallback(() => {
    setShouldRecomputeZone(!shouldRecomputeZone);
  }, [shouldRecomputeZone]);
  const [markers, center] = useMemo(
    () =>
      services.lapiReview.computeMarkersAndCenter(
        shouldRecomputeZone ? newAddressCoordinate : null
      ),
    [lapiReview, newAddressCoordinate, shouldRecomputeZone]
  );

  useEffect(() => {
    void (async () => {
      // If a new parkingSpace is selected, cancel the auto change
      if (newParkingSpace !== null) {
        return;
      }
      if (!shouldRecomputeZone) {
        handleZoneChange(
          null,
          lapiReview.zoneId,
          controlDTO.lastVersion.patrolZoneId ?? null
        );
        setNewZoneId(lapiReview.zoneId);
        return;
      }

      const coreAddress: CoreAddress = {
        ...address,
        streetNumber: address.streetNumber || '',
        locality: address.addressLocality,
      };
      const location = await services.geocodingApi.geocode(coreAddress);
      const zones = await services.zoning.findZone(
        location.latitude,
        location.longitude
      );

      const patrolZone = await services.lapiReview.findPatrolZoneWithLatLon(
        location.latitude,
        location.longitude
      );

      const zoneId = zones.length > 0 ? zones[0].zoneId : lapiReview.zoneId;

      const patrolZoneId = patrolZone.patrolZoneId ?? null;

      handleZoneChange(location, zoneId, patrolZoneId);
      setNewAddressCoordinate(location);
      setNewZoneId(zoneId);
    })();
  }, [address, shouldRecomputeZone]);

  if (zoning === null) {
    return <></>;
  }

  return (
    <Flex flexDirection="column" gap={8}>
      <Checkbox
        label={_t('recomputeZone')}
        checked={shouldRecomputeZone}
        onCheck={toggleShouldRecomputeZone}
      />
      <ControlDialogueMap
        zoom={18}
        markers={markers}
        center={center}
        styleOverwrite={{ width: '500px', height: '250px' }}
      />
      {shouldRecomputeZone && (
        <span>
          {_t('previousZone', {
            zoneName: services.zoning.getZoneNameFromId(lapiReview.zoneId),
          })}
        </span>
      )}
      {shouldRecomputeZone && (
        <span>
          {_t('newZone', {
            zoneName: services.zoning.getZoneNameFromId(newZoneId),
          })}
        </span>
      )}
    </Flex>
  );
}

export default RecomputeControlPosition;
