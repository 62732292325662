import { createContext } from 'react';

import { Watcher } from '@cvfm-front/commons-utils';
import { apiGet, apiPost, getHostname } from 'api/helpers';
import { FpsFiltersParam } from '@cvfm-front/tefps-types';

import { PdfExport, PdfExportService } from './types';

const PDF_EXPORT_API_ROOT =
  '/api/proxy/documents/api/v1/city/{cityId}/pdfexport';

function PdfExportServiceFactory(): PdfExportService {
  // Watchers
  const {
    setValue: setIsConfirmationModalOpen,
    watchValue: watchIsConfirmationModalOpen,
  } = Watcher(false);
  const { setValue: setPdfExports, watchValue: watchPdfExports } = Watcher<
    PdfExport[]
  >([]);
  const { setValue: setHasExports, watchValue: watchHasExport } = Watcher<
    boolean
  >(false);
  const {
    setValue: setNumberOfExportDone,
    watchValue: watchNumberOfExportDone,
  } = Watcher<number>(0);

  // Private
  let search = {} as FpsFiltersParam;
  let newPdfExportId = '';

  const getFromApiPdfExports: PdfExportService['getFromApiPdfExports'] = async () => {
    const listOfPdfExport = await apiGet<PdfExport[]>(PDF_EXPORT_API_ROOT);
    // Newest first
    listOfPdfExport.sort((a, b) =>
      b.creationDatetime.localeCompare(a.creationDatetime)
    );
    const hasExports = listOfPdfExport.length > 0;
    const numberOfDone = listOfPdfExport.filter(x => x.status === 'DONE')
      .length;

    // Propagate changes
    setPdfExports(listOfPdfExport);
    setHasExports(hasExports);
    setNumberOfExportDone(numberOfDone);

    // Auto refresh processing exports
    const hasProcessingExports =
      listOfPdfExport.filter(x => x.status !== 'DONE' && x.status !== 'ERROR')
        .length > 0;
    if (hasProcessingExports) {
      // This will refresh exports when at least one export is processing
      // Not optimal, but do the work.
      // TODO: Use websocket for realtime update ?
      setTimeout(() => {
        getFromApiPdfExports();
      }, 1000);
    }
  };

  const preparePdfExportCreation: PdfExportService['preparePdfExportCreation'] = (
    pdfExportId,
    searchRequest
  ) => {
    newPdfExportId = pdfExportId;
    search = searchRequest;
    setIsConfirmationModalOpen(true);
  };

  const postToApiPdfExport: PdfExportService['postToApiPdfExport'] = async (
    withPictures: boolean
  ) => {
    await apiPost<PdfExport>(
      `${PDF_EXPORT_API_ROOT}/${newPdfExportId}?exportWithPictures=${withPictures}`,
      search
    );
    setIsConfirmationModalOpen(false);
    await getFromApiPdfExports(); // We update stored exports
  };

  const getFromApiPdfExportArchive: PdfExportService['getFromApiPdfExportArchive'] = id => {
    try {
      // Create a link and set the URL using `createObjectURL`
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = `${getHostname().slice(
        0,
        -1
      )}${PDF_EXPORT_API_ROOT}/${id}/archive`;

      // It needs to be added to the DOM so it can be clicked
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  const init: PdfExportService['init'] = async () => {
    try {
      await getFromApiPdfExports();
    } catch (e) {
      // Can fail on init if user does not have permission
      console.error(e);
    }
  };

  return {
    init,
    setIsConfirmationModalOpen,
    watchIsConfirmationModalOpen,
    watchPdfExports,
    watchHasExport,
    watchNumberOfExportDone,
    getFromApiPdfExports,
    postToApiPdfExport,
    preparePdfExportCreation,
    getFromApiPdfExportArchive,
  };
}

export const PdfExportContext = createContext<PdfExportService>(
  PdfExportServiceFactory()
);
