import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import Hidden from 'material-ui/svg-icons/action/visibility-off';
import Visible from 'material-ui/svg-icons/action/visibility';
import { CircularProgress, Dialog } from 'material-ui';
import { connect } from 'react-redux';

import { OpenFileExplorer } from '@cvfm-front/commons-utils';
import { FilterBarSection } from '@cvfm-front/tefps-ui';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import BoButton from 'facade/BoButton';
import { Flex, Title } from '@cvfm-front/commons-ui';
import { EsCityParkingSpaceDTOFactory } from '@cvfm-front/tefps-types';
import { getApiState } from 'api/duck';
import { EditionType } from 'commons/services/ParkingSpace/ParkingSpaceEditionService';
import { MapId } from 'commons/services/MapService';

import ParkingSpaceChangeRegimeModal from './ParkingSpaceChangeRegimeModal';
import ParkingSpaceEditionModal from './ParkingSpaceEditionModal';
import ParkingSpaceImportModal from './ParkingSpaceImportModal';
import ParkingSpaceAutocompleteId from './ParkingSpaceAutocompleteId';
import ParkingSpaceFilterByRegimeSection from './ParkingSpaceFilterByRegimeSection';
import ParkingSpaceFilterByOrientationSection from './ParkingSpaceFilterByOrientationSection';
import ParkingSpaceAutocompleteAddress from './ParkingSpaceAutocompleteAddress';

const { _tg } = window.loadTranslations(__filename);

const STYLE_VISIBILITY: CSSProperties = {
  margin: '0px 8px 0px 0px',
  width: 16,
  height: 16,
  cursor: 'pointer',
  paddingBottom: 2,
};

interface ParkingSpaceFilterBar {
  cityId: string;
  canEdit: boolean;
}

const ParkingSpaceFilterBarSection = ({
  cityId,
  canEdit,
}: ParkingSpaceFilterBar) => {
  const [showConfirmImportModal, setShowConfirmInportModal] = useState<boolean>(
    false
  );
  const [
    showConfirmRecomputeZoningModal,
    setShowConfirmRecomputeZoningModal,
  ] = useState<boolean>(false);
  const [
    showConfirmRecomputeAddressesModal,
    setShowConfirmRecomputeAddressesModal,
  ] = useState<boolean>(false);
  const [showOperationUnderwayModal, setShowOperationUnderwayModal] = useState<
    boolean
  >(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  /*
   * Watchers
   */
  const displayZone = useWatcher(
    services.parkingSpaceMap.watchDisplayZone,
    false
  );

  /*
   * Callbacks
   */
  const handleImport = useCallback(() => {
    OpenFileExplorer('application/json,application/geo+json', file => {
      setSelectedFile(file);
      setShowConfirmInportModal(true);
    });
  }, []);

  const handleConfirmImport = useCallback(() => {
    if (selectedFile) {
      void services.parkingSpaceApi.importGson(selectedFile);
      setSelectedFile(null);
    }
    setShowConfirmInportModal(false);
  }, [selectedFile]);

  const handleCancelImport = useCallback(() => {
    setShowConfirmInportModal(false);
    setSelectedFile(null);
  }, []);

  const handleConfirmRecomputeZoning = useCallback(async () => {
    setIsUpdating(true);
    await services.parkingSpaceApi.recomputeZones(cityId);
    services.parkingSpaceMap.closeSelection(MapId.PARKING_SPACE);
    setTimeout(() => {
      void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
    }, 1000);
    setShowConfirmRecomputeZoningModal(false);
    setIsUpdating(false);
  }, [cityId]);

  const handleCancelRecomputeZoning = useCallback(() => {
    setShowConfirmRecomputeZoningModal(false);
  }, []);

  const handleConfirmRecomputeAddresses = useCallback(() => {
    setIsUpdating(true);
    setShowConfirmRecomputeAddressesModal(false);
    void services.parkingSpaceApi.recomputeAddresses(cityId);
    setShowOperationUnderwayModal(true);
    services.parkingSpaceMap.closeSelection(MapId.PARKING_SPACE);
    setTimeout(() => {
      void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
    }, 1000);
    setIsUpdating(false);
  }, [cityId]);

  const handleCancelRecomputeAddresses = useCallback(() => {
    setShowConfirmRecomputeAddressesModal(false);
  }, []);

  const handleExport = useCallback(
    () => services.parkingSpaceApi.exportGson(),
    []
  );

  const handleAddParkingSpace = useCallback(() => {
    const newParkingSpace = EsCityParkingSpaceDTOFactory();
    const center = services.mapService.get(MapId.PARKING_SPACE)?.center?.get();
    newParkingSpace.latLong.latitude = center?.latitude ?? 0;
    newParkingSpace.latLong.longitude = center?.longitude ?? 0;
    services.parkingSpaceCreation.addParkingSpace(newParkingSpace);
    services.parkingSpaceEdition.startEdition(
      newParkingSpace,
      EditionType.CREATION
    );
  }, []);

  const handleNeutralize = useCallback(() => {
    services.parkingSpaceChangeRegime.setIsSelecting(true);
  }, []);

  const handleRecomputeZones = useCallback(() => {
    setShowConfirmRecomputeZoningModal(true);
  }, []);

  const handleRecomputeAddresses = useCallback(() => {
    setShowConfirmRecomputeAddressesModal(true);
  }, []);

  const onCheck = useCallback(() => {
    services.parkingSpaceMap.setDisplayZone(MapId.PARKING_SPACE, !displayZone);
    void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
  }, [displayZone]);

  useEffect(() => {
    /*
     * getters
     */
    const getZoning = async (): Promise<void> => {
      try {
        await services.zoning.init();
      } catch (e) {
        // NOP
      }
    };

    void getZoning();
  }, []);

  const confirmationModaleActions = [
    <BoButton
      label={_tg('action.confirm')}
      onClick={handleConfirmImport}
      primary
      style={{ marginRight: 10 }}
    />,
    <BoButton label={_tg('cancel')} onClick={handleCancelImport} secondary />,
  ];

  const confirmationRecomputeZoningModaleActions = [
    <BoButton
      label={_tg('action.confirm')}
      onClick={handleConfirmRecomputeZoning}
      primary
      style={{ marginRight: 10 }}
      disabled={isUpdating}
    />,
    <BoButton
      label={_tg('cancel')}
      onClick={handleCancelRecomputeZoning}
      secondary
      disabled={isUpdating}
    />,
  ];

  const confirmationRecomputeAddressesModaleActions = [
    <BoButton
      label={_tg('action.confirm')}
      onClick={handleConfirmRecomputeAddresses}
      primary
      style={{ marginRight: 10 }}
      disabled={isUpdating}
    />,
    <BoButton
      label={_tg('cancel')}
      onClick={handleCancelRecomputeAddresses}
      secondary
      disabled={isUpdating}
    />,
  ];

  const confirmationOperationUnderwayModalActions = [
    <BoButton
      label={_tg('action.confirm')}
      onClick={() => setShowOperationUnderwayModal(false)}
      primary
      style={{ marginRight: 10 }}
      disabled={isUpdating}
    />,
  ];

  return (
    <>
      <Flex flexDirection="row">
        {displayZone ? (
          <span title={_tg('action.hideZone')}>
            <Hidden
              style={{ color: 'red', ...STYLE_VISIBILITY }}
              onClick={onCheck}
            />
          </span>
        ) : (
          <span title={_tg('action.displayZone')}>
            <Visible
              style={{ color: 'greenyellow', ...STYLE_VISIBILITY }}
              onClick={onCheck}
            />
          </span>
        )}
        {displayZone ? (
          <span>{`${_tg('action.hideZone')}`}</span>
        ) : (
          <span>{`${_tg('action.displayZone')}`}</span>
        )}
      </Flex>
      <ParkingSpaceAutocompleteId />
      <ParkingSpaceAutocompleteAddress />
      <ParkingSpaceFilterByRegimeSection />
      <ParkingSpaceFilterByOrientationSection />
      <FilterBarSection
        title={_tg('actions')}
        actions={[
          <BoButton
            fullWidth
            label={_tg('addParkingSpace')}
            onClick={handleAddParkingSpace}
            disabled={!canEdit}
          />,
          <BoButton
            primary
            fullWidth
            label={_tg('multipleSelection')}
            onClick={handleNeutralize}
            disabled={!canEdit}
          />,
        ]}
      />
      <FilterBarSection
        title={`${_tg('import')} | ${_tg('export')}`}
        actions={[
          <BoButton
            fullWidth
            label={_tg('importGeoJson')}
            onClick={handleImport}
            disabled={!canEdit}
          />,
          <BoButton
            fullWidth
            label={_tg('exportGeoJson')}
            onClick={handleExport}
            disabled={!canEdit}
          />,
        ]}
      />
      <FilterBarSection
        title={_tg('rightGroups.ZONING')}
        actions={[
          <BoButton
            fullWidth
            label={_tg('recomputeZoningForParkingSpace')}
            onClick={handleRecomputeZones}
            disabled={!canEdit}
          />,
          <BoButton
            fullWidth
            label={_tg('recomputeAddressesForParkingSpace')}
            onClick={handleRecomputeAddresses}
            disabled={!canEdit}
          />,
        ]}
      />
      <Dialog open={showConfirmImportModal} actions={confirmationModaleActions}>
        <Flex flexDirection="column" gap={8}>
          <Title>{_tg('commons.careful')}</Title>
          <p>{_tg('warningImportParkingSpace')}</p>
        </Flex>
      </Dialog>
      <Dialog
        open={showConfirmRecomputeZoningModal}
        actions={confirmationRecomputeZoningModaleActions}
      >
        {!isUpdating && (
          <Flex flexDirection="column" gap={8}>
            <Title>{_tg('commons.careful')}</Title>
            <p>{_tg('recomputeZoningForParkingSpaceConfirmModale')}</p>
          </Flex>
        )}
        {isUpdating && (
          <Flex justifyContent="center">
            <CircularProgress />
          </Flex>
        )}
      </Dialog>
      <Dialog
        open={showConfirmRecomputeAddressesModal}
        actions={confirmationRecomputeAddressesModaleActions}
      >
        {!isUpdating && (
          <Flex flexDirection="column" gap={8}>
            <Title>{_tg('commons.careful')}</Title>
            <p>{_tg('recomputeAddressesForParkingSpaceConfirmModal')}</p>
          </Flex>
        )}
        {isUpdating && (
          <Flex justifyContent="center">
            <CircularProgress />
          </Flex>
        )}
      </Dialog>
      <Dialog
        open={showOperationUnderwayModal}
        actions={confirmationOperationUnderwayModalActions}
      >
        {!isUpdating && (
          <Flex flexDirection="column" gap={8}>
            <Title>{_tg('commons.careful')}</Title>
            <p>{_tg('recomputeAddressesForParkingSpaceUnderwayModal')}</p>
          </Flex>
        )}
        {isUpdating && (
          <Flex justifyContent="center">
            <CircularProgress />
          </Flex>
        )}
      </Dialog>
      <ParkingSpaceEditionModal />
      <ParkingSpaceChangeRegimeModal />
      <ParkingSpaceImportModal />
    </>
  );
};

export default connect(state => {
  const { userInfo, cityId } = getApiState(state);
  return {
    cityId,
    canEdit: userInfo && userInfo.rights.includes('PARKING_SPACE_WRITE'),
  };
})(ParkingSpaceFilterBarSection);
