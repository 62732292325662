import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _isEqual from 'lodash.isequal';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import WarningIcon from 'material-ui/svg-icons/alert/warning';

import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import {
  AuthorizedVehicleCategory,
  fetchConfig,
  getConfigState,
} from 'config/duck';
import {
  createPricing,
  deletePricing,
  fetchActivePricing,
  fetchPricing,
  fetchPricings,
  fetchZoning,
  savePricing,
  setPricingActive,
  fetchProducts,
} from 'api/pricing';
import { getApiState, InternalApiState } from 'api/duck';
import { BKG_CYAN, TXT_BLACK, TXT_RED } from 'theme';
import Content from 'commons/Content';
import { ItemIdName } from 'api/commonTypes';
import { toZoneforMap } from 'commons/ZoningComponents/helpers';
import { FETCH_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import {
  PricingConfiguration,
  ZoningDTO,
  GlobalSettings,
  PricingDTO,
  PricingPolicyDTO,
} from '@cvfm-front/tefps-types';

import TariffMap from './components/TariffMap';
import GlobalConfiguration from './components/GlobalConfiguration';
import PricingPolicies from './components/PricingPolicies';
import MenuBar from './components/MenuBar';
import Simulation from './components/Simulation';
import {
  getGlobalSettings,
  getPricingPolicies,
  mergeGlobalSettings,
  mergePricingPolicies,
} from './helpers';
import { BRD_GREY, STYLE_TITLE } from './theme';

const { _tg } = window.loadTranslations(__filename);

const MARGIN_WIDTH = 70;

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  color: TXT_BLACK,
  margin: '24px auto',
  fontFamily: 'Roboto',
};

const STYLE_MODULE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 5,
  padding: 12,
  border: `1px solid ${BRD_GREY}`,
  backgroundColor: 'white',
};

const STYLE_CONTAINER_LEFT: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: '55%',
};

const STYLE_CONTAINER_RIGHT: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '45%',
  height: '100%',
};

const STYLE_CONTAINER_MAP: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
};

const STYLE_MAP: CSSProperties = {
  borderRadius: 5,
  marginTop: 10,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
};

const buildSortedPricingList = (mapOfPricings: { [key: string]: string }) => {
  const listOfLightPricingDTO = Object.keys(mapOfPricings).map(id => ({
    id,
    name: mapOfPricings[id],
  }));
  return listOfLightPricingDTO
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ id, name }));
};

const fetchSafeActivePricing = (): Promise<PricingDTO | undefined> =>
  fetchActivePricing().catch((reason): undefined => {
    if ((reason as { status: number }).status === 404) {
      // If 404, we set the active pricing to undefined
      return undefined;
    }
    throw reason;
  });

type ReduxStateProps = {
  activePricingId: string | null | undefined;
  authorizedVehicleCategories: AuthorizedVehicleCategory[];
  canWritePricing: boolean;
  canAdminPricingDailyPeriod: boolean;
  pricingConfiguration: PricingConfiguration;
  location: {
    latitude: number;
    longitude: number;
  };
};

type ReduxDispatchProps = {
  reloadConfig: () => void;
};

type PoliciesProps = ReduxStateProps & ReduxDispatchProps;

type PoliciesState = {
  zoning: ZoningDTO | undefined;
  profiles: Array<ItemIdName>;
  pricing: PricingDTO | undefined;
  pricingList: Array<ItemIdName>;
  fetchedPricing: PricingDTO | undefined;
  error: { message: string; json?: Record<string, any> } | undefined;
  canNotModifyActivePricing: boolean;
  secondConfirmationRequired: boolean;
  savable: boolean;
};

const INITIAL_STATE: PoliciesState = {
  zoning: undefined,
  profiles: [],
  pricingList: [],
  pricing: undefined,
  fetchedPricing: undefined,
  error: undefined,
  canNotModifyActivePricing: false,
  secondConfirmationRequired: false,
  savable: false,
};

const DEFAULT_GLOB_SETTINGS: GlobalSettings = {
  surfaceModulation: null,
  ecoModulation: null,
  dailyChargedPeriod: { start: '08:00', end: '18:00' },
  dailyBreaks: [],
  fpsReduction: null,
  fpsProfileDependent: true,
  fpsPriceFixed: false,
  fixedMinutesDuration: 0,
  fpsFixedPrice: 0,
  globalFreeDaysOff: [],
  globalFreeDays: [],
  specialDaysRange: [],
};

class Policies extends React.Component<PoliciesProps, PoliciesState> {
  map = null;
  state: PoliciesState = INITIAL_STATE;

  componentDidMount() {
    this.initPageData();
  }

  setPricingActive = async () => {
    this.closeSecondConfirmation();
    try {
      if (this.state.pricing) {
        await setPricingActive(this.state.pricing.id);
        this.props.reloadConfig();
        this.initPageData();
      }
    } catch (error) {
      const canNotModifyActivePricing =
        error.json &&
        error.json.message &&
        error.json.message.includes(
          'tarification active ne peut pas être modifiée'
        );
      this.setState({ error, canNotModifyActivePricing });
    }
  };

  setPricingActiveWithCheckSecondConfirmation = async () => {
    // Check right to edit pricing in daily period and daily period
    const activePricing = await fetchSafeActivePricing();

    if (activePricing) {
      const now = moment();
      const start = moment(activePricing.dailyChargedPeriod.start, 'HH:mm');
      const end = moment(activePricing.dailyChargedPeriod.end, 'HH:mm');
      const isDailyPeriod = start.isBefore(now) && end.isAfter(now);
      const { canAdminPricingDailyPeriod } = this.props;
      // Ask for second confirmation if required
      if (canAdminPricingDailyPeriod && isDailyPeriod) {
        this.setState({ secondConfirmationRequired: true });
        return;
      }
    }

    void this.setPricingActive();
  };

  closeSecondConfirmation = () => {
    this.setState({ secondConfirmationRequired: false });
  };

  createRefMap = (node: any) => {
    this.map = node;
  };

  initPageData = async () => {
    try {
      const zoning = await fetchZoning(FETCH_ZONING_CONFIG);
      const mapOfPricings = await fetchPricings();
      const pricingList = buildSortedPricingList(mapOfPricings);
      const profiles: Array<ItemIdName> = await fetchProducts();

      const pricing = await fetchSafeActivePricing();
      this.setState({
        error: undefined,
        zoning,
        pricingList,
        profiles,
        pricing,
        fetchedPricing: pricing,
        savable: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  removePricing = async () => {
    const { pricing } = this.state;
    if (!pricing) return;
    await deletePricing(pricing.id);
    await this.initPageData();
  };

  newPricing = async (
    pricingName: string,
    copiedPricingId: string | null | undefined
  ) => {
    const { activePricingId, reloadConfig } = this.props;
    try {
      const creationResponse = await createPricing(
        pricingName,
        copiedPricingId || ''
      );

      const isFirstPricing = !activePricingId || activePricingId === 'default';
      if (isFirstPricing) {
        reloadConfig();
      }

      const pricing = await fetchPricing(creationResponse.pricingId);
      const mapOfPricings = await fetchPricings();
      const pricingList = buildSortedPricingList(mapOfPricings);
      this.setState({
        error: undefined,
        pricing,
        pricingList,
        fetchedPricing: pricing,
        savable: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  handleCloseErrorModal = () =>
    this.setState({ error: undefined, canNotModifyActivePricing: false });

  fetchPricingData = async (editedPricingId: string | null | undefined) => {
    try {
      const pricing = editedPricingId
        ? await fetchPricing(editedPricingId)
        : await fetchSafeActivePricing();
      if (pricing) {
        this.setState({
          error: undefined,
          pricing,
          fetchedPricing: pricing,
          savable: false,
        });
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  changePricing = (editedPricingId: string) => {
    void this.fetchPricingData(editedPricingId);
  };

  updateGlobalSettings = (globalSettings: GlobalSettings) => {
    const { pricing, fetchedPricing } = this.state;
    const pricingNew = mergeGlobalSettings(pricing, globalSettings);
    this.setState({
      pricing: pricingNew,
      savable: !_isEqual(fetchedPricing, pricingNew),
    });
  };

  updatePolicies = (policies: Array<PricingPolicyDTO>) => {
    const { fetchedPricing, pricing } = this.state;
    const initialPolicies = fetchedPricing
      ? fetchedPricing.pricingPolicies
      : null;
    const pricingNew = mergePricingPolicies(pricing, policies);
    this.setState({
      pricing: pricingNew,
      savable: !_isEqual(initialPolicies, policies),
    });
  };

  saveAllPricing = async () => {
    const { pricing } = this.state;
    try {
      await savePricing(pricing);
      const id = pricing ? pricing.id : 'active';
      await this.fetchPricingData(id);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const {
      zoning,
      pricing,
      profiles,
      savable,
      secondConfirmationRequired,
      error,
      pricingList,
      canNotModifyActivePricing,
    } = this.state;
    const {
      activePricingId,
      canWritePricing,
      pricingConfiguration,
      authorizedVehicleCategories,
    } = this.props;

    if ((error && !canNotModifyActivePricing) || !this.props.location) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('tefps.pricing.pricing.errors.noZoning.message')}
              error={
                error || {
                  message: _tg('tefps.pricing.pricing.errors.noZoning.error'),
                }
              }
            />
          </FlexCenter>
        </Content>
      );
    }

    const { latitude, longitude } = this.props.location;

    if (pricingList.length === 0) {
      return (
        <Content>
          <MenuBar
            savable={savable}
            saveAll={this.saveAllPricing}
            changePricing={this.changePricing}
            pricingList={pricingList}
            currentId={null}
            activePricingId={activePricingId}
            createPricing={this.newPricing}
            setPricingActive={this.setPricingActiveWithCheckSecondConfirmation}
            isAllowed={canWritePricing}
          />
        </Content>
      );
    }

    if (!zoning) {
      return (
        <Content>
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }

    const settings = pricing
      ? getGlobalSettings(pricing)
      : DEFAULT_GLOB_SETTINGS;
    const pricingPolicies = pricing ? getPricingPolicies(pricing) : undefined;

    return (
      <Content>
        <MenuBar
          pricingList={pricingList}
          currentId={pricing?.id}
          activePricingId={activePricingId}
          savable={savable}
          saveAll={this.saveAllPricing}
          changePricing={this.changePricing}
          createPricing={this.newPricing}
          removePricing={this.removePricing}
          setPricingActive={this.setPricingActiveWithCheckSecondConfirmation}
          isAllowed={canWritePricing}
        />
        {!!pricing && !!pricingPolicies && (
          <div style={STYLE_CONTAINER}>
            <div style={STYLE_CONTAINER_LEFT}>
              <div style={STYLE_CONTAINER_MAP}>
                <div
                  style={{
                    margin: `0px 24px 24px ${MARGIN_WIDTH}px`,
                    height: '80%',
                  }}
                >
                  <div style={STYLE_TITLE}>
                    {_tg('tefps.pricing.pricing.policyVisualisation')}
                  </div>
                  <div style={STYLE_MAP}>
                    {!!zoning && (
                      <TariffMap
                        ref={this.createRefMap}
                        center={{ lat: latitude, lng: longitude }}
                        profiles={profiles}
                        authorizedVehicleCategories={
                          authorizedVehicleCategories
                        }
                        zones={toZoneforMap(zoning)}
                        pricingId={pricing.id}
                        displayVehicleCategory={pricingPolicies.some(
                          policy => !!policy.vehicleCategories.length
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  ...STYLE_MODULE,
                  margin: `0px 24px 24px ${MARGIN_WIDTH}px`,
                  height: '30%',
                }}
              >
                <Simulation
                  price={settings.fpsFixedPrice || 0}
                  reducedPrice={
                    settings.fpsReduction ? settings.fpsReduction.amount : 0
                  }
                />
              </div>
            </div>
            <div style={STYLE_CONTAINER_RIGHT}>
              <div
                style={{
                  ...STYLE_MODULE,
                  margin: `32px ${MARGIN_WIDTH}px 24px 24px`,
                  height: '50%',
                }}
              >
                <GlobalConfiguration
                  settings={pricing}
                  updateSettings={this.updateGlobalSettings}
                  reloadPricing={this.changePricing}
                  disabled={!canWritePricing || activePricingId === pricing.id}
                  pricingConfiguration={pricingConfiguration}
                />
              </div>
              <div
                style={{
                  ...STYLE_MODULE,
                  margin: `12px ${MARGIN_WIDTH}px 24px 24px`,
                  height: '50%',
                }}
              >
                <PricingPolicies
                  policies={pricingPolicies}
                  zoning={zoning}
                  profiles={profiles || []}
                  updatePolicies={this.updatePolicies}
                  defaultChargedPeriod={settings.dailyChargedPeriod}
                  defaultFpsReduction={settings.fpsReduction}
                  disabled={
                    !(canWritePricing && activePricingId !== pricing.id)
                  }
                  cityPricingConfiguration={pricingConfiguration}
                />
              </div>
            </div>
          </div>
        )}
        {error && canNotModifyActivePricing && (
          <Dialog
            title={_tg('tefps.pricing.pricing.errors.impossibleAction.title')}
            actions={[
              <FlatButton
                label={_tg('action.ok')}
                primary
                keyboardFocused
                onClick={this.handleCloseErrorModal}
              />,
            ]}
            open
            modal={false}
            onRequestClose={this.handleCloseErrorModal}
          >
            <span>
              {_tg('tefps.pricing.pricing.errors.impossibleAction.message')}
            </span>
          </Dialog>
        )}
        {secondConfirmationRequired && (
          <Dialog
            title={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: TXT_RED,
                }}
              >
                <WarningIcon style={{ marginRight: 5, color: TXT_RED }} />
                {_tg('commons.careful')}
              </div>
            }
            actions={[
              <FlatButton
                label={_tg('action.cancel')}
                primary
                keyboardFocused
                onClick={this.closeSecondConfirmation}
              />,
              <FlatButton
                label={_tg('action.ok')}
                primary
                onClick={this.setPricingActive}
              />,
            ]}
            open
            modal={false}
            onRequestClose={this.closeSecondConfirmation}
          >
            <span>
              {_tg('tefps.pricing.pricing.errors.changingActive.message')}
            </span>
          </Dialog>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state: InternalApiState): ReduxStateProps => {
  const {
    activePricing: activePricingId,
    location,
    pricingConfiguration,
    authorizedVehicleCategories,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    activePricingId,
    authorizedVehicleCategories,
    pricingConfiguration,
    location,
    canWritePricing: !!(userInfo && userInfo.rights.includes('PRICING_WRITE')),
    canAdminPricingDailyPeriod: !!(
      userInfo && userInfo.rights.includes('PRICING_ADMIN_DAILY_PERIOD')
    ),
  };
};

const mapDispatchToProps = (dispatch: any): any => ({
  reloadConfig: () => dispatch(fetchConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
