import React, { CSSProperties, useState } from 'react';
import Drawer from 'material-ui/Drawer';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import ForwardIcon from 'material-ui/svg-icons/navigation/arrow-forward';
import Toggle from 'material-ui/Toggle';

import BoButton from 'facade/BoButton';
import Logout from 'tefps/LapiReview/LapiSideBar/components/Logout';
import TEFPSButton from 'tefps/LapiReview/LapiSideBar/components/TEFPSControlButton';
import { QualityControlStatistics } from 'api/lapiReviewConfiguration/types';
import { QualityControlFilterDTO } from 'api/lapiReview/types';
import { firstDateOfCurrentMonth } from 'commons/Utils/dateUtil';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import ReviewAgentCounter from 'tefps/LapiReview/LapiSideBar/components/ReviewAgentCounter';

import { DatepickerClass } from '../../../commons/DateFilter';

import ControlSearch from './components/ControlSearch';
import ControlHistory from './components/ControlHistory';
import QualityControlStatisticsComponent from './components/QualityControlStatisticsComponent';
import QualityControlAgentFilter from './components/QualityControlAgentFilter';

const STYLE_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 20,
  marginTop: 16,
  height: 36,
};

const TOP_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const STYLE_TOGGLE: CSSProperties = {
  paddingLeft: '12px',
  paddingBottom: '12px',
  paddingTop: '12px',
  width: '85%',
};

const STYLE_BUTTON: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  flexFlow: 'column',
};

const STYLE_SIDE_BAR: CSSProperties = {
  ...STYLE_CONTAINER,
  height: '100%',
  justifyContent: 'space-between',
};

type Props = {
  userName: string;
  complexCaseEnabled: boolean;
  searchLapiByIdWithRebuild: (controlId: string) => void;
  lapiReviewId: string;
  searchLapiById: (controlId: string) => void;
  qualityControlMode: boolean;
  qualityStatistics: QualityControlStatistics;
  onQualityAgentSelect: (e: string) => void;
  qualityControlFilter: QualityControlFilterDTO;
  setQualityControlFilter: React.Dispatch<
    React.SetStateAction<QualityControlFilterDTO>
  >;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode: boolean;
};

const LapiSideBar = ({
  userName,
  complexCaseEnabled,
  searchLapiByIdWithRebuild,
  lapiReviewId,
  searchLapiById,
  qualityControlMode,
  qualityStatistics,
  onQualityAgentSelect,
  qualityControlFilter,
  setQualityControlFilter,
  setDarkMode,
  darkMode,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const firstDayOfMonth: Date = firstDateOfCurrentMonth();
  const today: Date = new Date();

  const handleToggle = () => {
    setOpen(!open);
  };

  function onChangeFilterDate(id: string, filterDate: FilterDate): void {
    setQualityControlFilter((filter: QualityControlFilterDTO) => {
      return {
        ...filter,
        from: filterDate.from,
        to: filterDate.to,
      };
    });
  }

  function toggleDarkMode() {
    setDarkMode(prev => !prev);
  }

  return (
    <>
      <div style={STYLE_ROW}>
        {!open && (
          <BoButton
            label={userName}
            labelPosition="before"
            onClick={handleToggle}
            icon={<SettingsIcon />}
          />
        )}
      </div>
      <Drawer open={open} openSecondary width={350}>
        <div style={STYLE_SIDE_BAR}>
          <div style={STYLE_CONTAINER}>
            <div style={TOP_STYLE}>
              <Logout />
              <TEFPSButton />
            </div>
            <div style={STYLE_BUTTON}>
              <BoButton
                fullWidth
                label="Cacher la barre"
                onClick={handleToggle}
                labelPosition="before"
                icon={<ForwardIcon />}
              />
            </div>
            <Toggle
              label="Mode sombre"
              style={STYLE_TOGGLE}
              toggled={darkMode}
              onToggle={toggleDarkMode}
            />
            {!qualityControlMode && (
              <ReviewAgentCounter
                sideBarOpen={open}
                complexCaseEnabled={complexCaseEnabled}
              />
            )}
            {qualityControlMode && (
              <>
                <QualityControlStatisticsComponent
                  qualityStatistics={qualityStatistics}
                  agentFiltered={!!qualityControlFilter.agentId}
                />
                <QualityControlAgentFilter
                  onQualityAgentSelect={onQualityAgentSelect}
                />
                <Dates
                  id="qualityDatepicker"
                  datePickerClass={DatepickerClass.lapiSidebar}
                  direction="column"
                  title="Dates de revue"
                  onChange={onChangeFilterDate}
                  dates={qualityControlFilter as FilterDate}
                  minDate={firstDayOfMonth}
                  maxDate={today}
                  secondaryColor
                />
              </>
            )}
          </div>
          {!qualityControlMode && (
            <>
              <ControlHistory
                lapiReviewId={lapiReviewId}
                searchLapiById={searchLapiById}
              />
              <ControlSearch
                searchLapiByIdWithRebuild={searchLapiByIdWithRebuild}
              />
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default LapiSideBar;
