import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { PatrolZoneDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';
import { Config, getConfigState } from 'config/duck';
import {
  DrawingType,
  MapId,
  PolygonWrapper,
} from 'commons/services/MapService';

type Props = {
  config: Config;
  patrolZones: Array<PatrolZoneDTO>;
};

const PatrolZoneMap = ({ config, patrolZones }: Props): JSX.Element => {
  const refMap = useRef<HTMLDivElement>(null);
  const [lastEditedPolygonId, setLastEditedPolygonId] = useState<string | null>(
    null
  );

  useEffect(() => {
    services.mapService.init(MapId.PATROL_ZONE, config, refMap);
    return () => services.mapService.free(MapId.PATROL_ZONE);
  }, []);

  const updatePatrolZones = (polygon: google.maps.Polygon): void => {
    const [patrolZoneId, index] = polygon.id.split('/');
    setLastEditedPolygonId(polygon.id);
    const patrolZoneToChange = patrolZones.find(
      p => p.patrolZoneId === patrolZoneId
    );
    if (patrolZoneToChange) {
      patrolZoneToChange.points[parseInt(index, 10)] = polygon
        .getPath()
        .getArray()
        .map(point => ({
          latitude: point.lat(),
          longitude: point.lng(),
        }));
      services.patrolZoneService.changePatrolZone(patrolZoneToChange);
    }
  };

  useEffect(() => {
    const mapWrapper = services.mapService.get(MapId.PATROL_ZONE);
    mapWrapper?.clear?.map();
    mapWrapper?.polygons?.add(
      patrolZones.reduce(
        (acc, p) =>
          acc.concat(
            p.points.map((points, index) => ({
              id: `${p.patrolZoneId}/${index}`,
              name: p.name,
              type: DrawingType.PATROL_ZONE,
              points,
              color: p.color,
              zIndex: acc.length + index,
              visible: true,
              editable: lastEditedPolygonId === `${p.patrolZoneId}/${index}`,
              canBeEdited: true,
              onRemove: () => {
                p.points.splice(index, 1);
                services.patrolZoneService.changePatrolZone(p);
              },
              pathListeners: new Map([
                ['insert_at', updatePatrolZones],
                ['set_at', updatePatrolZones],
                ['remove_at', updatePatrolZones],
              ]),
            }))
          ),
        [] as Array<PolygonWrapper>
      )
    );
  }, [patrolZones]);

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      ref={refMap}
    />
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return { config };
})(PatrolZoneMap);
