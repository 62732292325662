import Accessible from 'material-ui/svg-icons/action/accessible';
import Alarm from 'material-ui/svg-icons/action/alarm';
import Code from 'material-ui/svg-icons/action/code';
import Visibility from 'material-ui/svg-icons/action/visibility';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import Cached from 'material-ui/svg-icons/action/cached';
import Feedback from 'material-ui/svg-icons/action/feedback';
import Delete from 'material-ui/svg-icons/action/delete';
import ZoomIn from 'material-ui/svg-icons/action/zoom-in';
import ZoomOut from 'material-ui/svg-icons/action/zoom-out';
import Settings from 'material-ui/svg-icons/action/settings';
import SupervisorAccount from 'material-ui/svg-icons/action/supervisor-account';
import Translate from 'material-ui/svg-icons/action/translate';
import HourglassEmpty from 'material-ui/svg-icons/action/hourglass-empty';
import Update from 'material-ui/svg-icons/action/update';
import PlaylistAddCheck from 'material-ui/svg-icons/av/playlist-add-check';
import Error from 'material-ui/svg-icons/alert/error';
import Warning from 'material-ui/svg-icons/alert/warning';
import LocationOn from 'material-ui/svg-icons/communication/location-on';
import LocationOff from 'material-ui/svg-icons/communication/location-off';
import BorderColor from 'material-ui/svg-icons/editor/border-color';
import Attachment from 'material-ui/svg-icons/file/attachment';
import Brightness4 from 'material-ui/svg-icons/image/brightness-4';
import Brightness5 from 'material-ui/svg-icons/image/brightness-5';
import Brightness6 from 'material-ui/svg-icons/image/brightness-6';
import Brightness7 from 'material-ui/svg-icons/image/brightness-7';
import CropFree from 'material-ui/svg-icons/image/crop-free';
import BlurOn from 'material-ui/svg-icons/image/blur-on';
import PhotoCamera from 'material-ui/svg-icons/image/photo-camera';
import Image from 'material-ui/svg-icons/image/image';
import DirectionsWalk from 'material-ui/svg-icons/maps/directions-walk';
import AirlineSeatReclineNormal from 'material-ui/svg-icons/notification/airline-seat-recline-normal';
import Power from 'material-ui/svg-icons/notification/power';
import BusinessCenter from 'material-ui/svg-icons/places/business-center';
import Person from 'material-ui/svg-icons/social/person';
import Star from 'material-ui/svg-icons/toggle/star';

import { patchControl } from 'api/control';
import { LapiReviewDTO } from 'api/lapiReview/types';

export const REASON_ICONS = {
  accessible: Accessible,
  alarm: Alarm,
  default: Code,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  cached: Cached,
  feedback: Feedback,
  delete: Delete,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
  settings: Settings,
  supervisorAccount: SupervisorAccount,
  translate: Translate,
  hourglassEmpty: HourglassEmpty,
  update: Update,
  playlistAddCheck: PlaylistAddCheck,
  error: Error,
  warning: Warning,
  locationOn: LocationOn,
  locationOff: LocationOff,
  borderColor: BorderColor,
  attachment: Attachment,
  brightness4: Brightness4,
  brightness5: Brightness5,
  brightness6: Brightness6,
  brightness7: Brightness7,
  cropFree: CropFree,
  blurOn: BlurOn,
  photoCamera: PhotoCamera,
  image: Image,
  directionsWalk: DirectionsWalk,
  airlineSeatReclineNormal: AirlineSeatReclineNormal,
  power: Power,
  businessCenter: BusinessCenter,
  person: Person,
  star: Star,
};

export async function registerQualityControlDecision(
  lapiReview: LapiReviewDTO,
  qualityDecision: string,
  qualityReason: string | null,
  tags: Array<string>
): Promise<void> {
  await patchControl(lapiReview.controlId, [
    {
      op: 'add',
      path: '/qualityControl',
      value: {
        decision: qualityDecision,
        exemptionReason: qualityReason,
        vehicleBrand: lapiReview.vehicleBrand,
        vehicleModel: lapiReview.vehicleModel,
        vehicleCategory: lapiReview.vehicleCategory,
        licensePlate: lapiReview.licensePlate,
        statementAddress: lapiReview.statementAddress,
        tags,
      },
    },
  ]);
}
