import React, { useCallback } from 'react';
import { Dialog } from 'material-ui';

import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { GenericFormV2 } from '@cvfm-front/commons-ui';
import { ChangeRegimeFactory } from 'commons/services/ParkingSpace/ParkingSpaceChangeRegimeService';
import {
  ParkingSpaceRegime,
  ParkingSpaceRegimeList,
} from '@cvfm-front/tefps-types';
import { FormFieldTypeWithTitleV2 } from '@cvfm-front/commons-types';
import { arrayToOption } from '@cvfm-front/commons-types/build/formsV2/SelectOptionTypeV2';
import { mapLabelToTraduction } from '@cvfm-front/commons-utils';
import { getLocale } from 'commons/Utils/localeUtils';
import { MapId } from 'commons/services/MapService';

const { _tg } = window.loadTranslations(__filename);

const ParkingSpaceChangeRegimeModal = (): JSX.Element => {
  /*
   * Watchers
   */
  const isChangingRegime = useWatcher(
    services.parkingSpaceChangeRegime.watchIsChangingRegime,
    false
  );
  const request = useWatcher(
    services.parkingSpaceChangeRegime.watchRequest,
    ChangeRegimeFactory()
  );

  /*
   * Callbacks
   */
  const handleOnClose = useCallback(() => {
    services.parkingSpaceChangeRegime.setIsChangingRegime(false);
  }, []);

  const handleSave = useCallback(async () => {
    await services.parkingSpaceChangeRegime.saveToAPI();
    // We wait to be sure the document is indexed for next refresh
    setTimeout(() => {
      void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
    }, 1000);
  }, []);

  const formFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: _tg('newRegime'),
    },
    {
      type: 'select',
      label: _tg('regime'),
      value: request.parkingRegime,
      selectOptions: arrayToOption(
        ParkingSpaceRegimeList,
        mapLabelToTraduction(ParkingSpaceRegimeList, _tg, 'ParkingSpaceRegime')
      ),
      onValueChange: v => {
        services.parkingSpaceChangeRegime.setRequest({
          ...request,
          parkingRegime: v as ParkingSpaceRegime,
        });
      },
      required: true,
    },
    {
      type: 'date',
      label: _tg('startDate'),
      language: getLocale(),
      value: new Date(request.startDatetime).toString(),
      onValueChange: v => {
        services.parkingSpaceChangeRegime.setRequest({
          ...request,
          startDatetime: v,
        });
      },
      required: true,
    },
    {
      type: 'date',
      label: _tg('endDate'),
      language: getLocale(),
      value: request.endDatetime
        ? new Date(request.endDatetime).toString()
        : '',
      onValueChange: v => {
        services.parkingSpaceChangeRegime.setRequest({
          ...request,
          startDatetime: v,
        });
      },
    },
  ];

  return (
    <Dialog open={isChangingRegime} onRequestClose={handleOnClose}>
      <GenericFormV2
        fields={formFields}
        onSubmitCallback={handleSave}
        submitLabel={_tg('save')}
        onCancelCallback={handleOnClose}
        cancelLabel={_tg('close')}
      />
    </Dialog>
  );
};

export default ParkingSpaceChangeRegimeModal;
