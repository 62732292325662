import { connect } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';

import { GpsStatus, PatrolZoneDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';
import { Config, getConfigState } from 'config/duck';
import {
  CircleWrapper,
  DeckGLArrowWrapper,
  DeckGLMultipleArrowsWrapper,
  DrawingType,
  MapId,
  PolygonWrapper,
} from 'commons/services/MapService';
import { AgentPingHistoryDTO, PingPointDTO } from 'api/planner/types';
import { fetchPingDetails } from 'api/planner';
import FakeInputBlock from 'commons/FakeInputBlock';

import { getPingFiltersState } from './duck';
import { MapFilters } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  config: Config;
  patrolZones: Array<PatrolZoneDTO>;
  displayPatrolZone: boolean;
  currentPings: Array<PingPointDTO>;
  historyPings: Array<AgentPingHistoryDTO>;
  displayGpsStatusAsColors: boolean;
  filters: MapFilters;
};

const colorFromGpsStatus = (gpsStatus: GpsStatus): [number, number, number] => {
  switch (gpsStatus) {
    case GpsStatus.OK:
      return [52, 144, 30]; // '#349a1d'; // green
    case GpsStatus.UNKNOWN:
      return [30, 84, 16]; // '#1d5410'; // dark green
    case GpsStatus.FREEZE:
    case GpsStatus.JUMP:
    case GpsStatus.FREEZE_AND_JUMP:
      return [249, 49, 49]; // '#f93131'; // red
    default:
      return [150, 150, 150]; // '#999';
  }
};

const PingsMapV2 = ({
  config,
  patrolZones,
  displayPatrolZone,
  currentPings,
  historyPings,
  displayGpsStatusAsColors,
  filters,
}: Props): JSX.Element => {
  const refMap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    services.mapService.init(MapId.PING_MAP, config, refMap);
    return () => services.mapService.free(MapId.PING_MAP);
  }, []);

  const renderPingWindow = async (pingId: string): Promise<string> => {
    let pingDetails = null;
    let error = null;
    try {
      pingDetails = await fetchPingDetails(pingId, filters.teamId || null);
    } catch (err) {
      error = (err as Error).message;
    }

    let gpsStatusString = '';
    switch (pingDetails?.gpsStatus) {
      case GpsStatus.OK:
        gpsStatusString = _tg('field.ping.gpsStatus.OK');
        break;
      case GpsStatus.FREEZE:
        gpsStatusString = _tg('field.ping.gpsStatus.FREEZE');
        break;
      case GpsStatus.JUMP:
        gpsStatusString = _tg('field.ping.gpsStatus.JUMP');
        break;
      case GpsStatus.FREEZE_AND_JUMP:
        gpsStatusString = _tg('field.ping.gpsStatus.FREEZE_AND_JUMP');
        break;
      case GpsStatus.UNKNOWN:
      default:
        gpsStatusString = _tg('field.ping.gpsStatus.UNKNOWN');
    }

    return ReactDOMServer.renderToString(
      <div>
        {pingDetails && (
          <div>
            <FakeInputBlock
              inputStyle={{ wordBreak: 'normal' }}
              title={_tg('field.organisation.team')}
              value={pingDetails.teamName}
            />
            <FakeInputBlock
              inputStyle={{ wordBreak: 'normal' }}
              title={_tg('field.agent.agent')}
              value={pingDetails.agentName}
            />
            {pingDetails.lapi && (
              <>
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_tg('field.ping.lapiCameraLeftEnabled')}
                  value={pingDetails.lapiCameraLeftEnabled ? 'OK' : 'KO'}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_tg('field.ping.lapiCameraRightEnabled')}
                  value={pingDetails.lapiCameraRightEnabled ? 'OK' : 'KO'}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_tg('field.ping.lapiReferent')}
                  value={pingDetails.lapiReferent ? 'OK' : 'KO'}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_tg('field.ping.gpsStatus.status')}
                  value={gpsStatusString}
                />
              </>
            )}
          </div>
        )}
        {error && (
          <div>
            <p style={{ fontWeight: 'bold' }}>
              {_t('feedback.error.fetchPingDetails')}
            </p>
            <p>{error}</p>
          </div>
        )}
      </div>
    );
  };

  const convertHistoryToArrowWrapper = (
    pings: AgentPingHistoryDTO[]
  ): DeckGLMultipleArrowsWrapper => {
    const data: Array<DeckGLArrowWrapper> = [];

    pings.forEach(agentHistory => {
      const points = agentHistory.pingHistory;
      for (let i = 0; i < points.length - 1; i += 1) {
        const startPoint = points[i];
        const endPoint = points[i + 1];

        data.push({
          start: {
            latitude: startPoint.location.latitude,
            longitude: startPoint.location.longitude,
          },
          end: {
            latitude: endPoint.location.latitude,
            longitude: endPoint.location.longitude,
          },
          color: [150, 150, 150],
          width: 1,
        });
      }
    });

    return {
      id: 'historyPingArrows',
      type: DrawingType.HISTORY_PING,
      arrows: data,
      minDistanceForArrow: 10,
      maxNumberOfLinesBeforeArrow: 1,
    };
  };

  useEffect(() => {
    const mapWrapper = services.mapService.get(MapId.PING_MAP);
    mapWrapper?.clear?.map();

    // Gestions des Patrol Zones
    mapWrapper?.polygons?.add(
      patrolZones.reduce(
        (acc, p) =>
          acc.concat(
            p.points.map((points, index) => ({
              id: `${p.patrolZoneId}/${index}`,
              name: p.name,
              type: DrawingType.PATROL_ZONE,
              points,
              color: p.color,
              zIndex: acc.length + index,
              visible: displayPatrolZone,
              editable: false,
              canBeEdited: false,
            }))
          ),
        [] as Array<PolygonWrapper>
      )
    );

    // Création des Pings Actuels
    const currentCircles: Array<CircleWrapper> = currentPings.map(point => ({
      id: point.pingId,
      point: {
        latitude: point.location.latitude,
        longitude: point.location.longitude,
      },
      title: `Ping: ${point.pingId}`,
      radius: 10,
      color: displayGpsStatusAsColors
        ? colorFromGpsStatus(point.gpsStatus)
        : [255, 0, 0],
    }));
    mapWrapper?.circles?.set({
      id: 'currentPings',
      type: DrawingType.PING,
      circles: currentCircles,
      infoWindowContentGenerator: (circleWrapper: CircleWrapper) =>
        renderPingWindow(circleWrapper.id),
    });

    // Création des Pings Historiques
    const historyCircles: Array<CircleWrapper> = historyPings
      .map(agentPingHistory =>
        agentPingHistory.pingHistory.map(
          point =>
            ({
              id: point.pingId,
              point: {
                latitude: point.location.latitude,
                longitude: point.location.longitude,
              },
              title: `Ping: ${point.pingId}`,
              radius: 3,
              color: displayGpsStatusAsColors
                ? colorFromGpsStatus(point.gpsStatus)
                : [255, 0, 0], // indexToRGB(index), // TODO
            } as CircleWrapper)
        )
      )
      .reduce((acc, val) => acc.concat(val), []);
    mapWrapper?.circles?.set({
      id: 'historyPings',
      type: DrawingType.HISTORY_PING,
      circles: historyCircles,
      infoWindowContentGenerator: (circleWrapper: CircleWrapper) =>
        renderPingWindow(circleWrapper.id),
    });

    // Gestion des Flèches Historiques
    const arrowWrapper = convertHistoryToArrowWrapper(historyPings);
    mapWrapper?.deckGlArrows?.set(arrowWrapper);
  }, [displayPatrolZone, currentPings, historyPings, displayGpsStatusAsColors]);

  return (
    <div
      ref={refMap}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    />
  );
};

export default connect(state => {
  const config = getConfigState(state);
  const filters = getPingFiltersState(state);
  return { config, filters };
})(PingsMapV2);
