import React, { CSSProperties } from 'react';

import { QualityControlStatistics } from 'api/lapiReviewConfiguration/types';
import SidebarDataLine from 'tefps/LapiReview/LapiSideBar/components/SidebarDataLine';

const { _t } = window.loadTranslations(__filename);

const STYLE_WRAPPER: CSSProperties = {
  display: 'block',
  marginLeft: 5,
};

type Props = {
  qualityStatistics: QualityControlStatistics;
  agentFiltered: boolean;
};

function QualityControlStatisticsComponent({
  qualityStatistics,
  agentFiltered,
}: Props) {
  return (
    <div style={STYLE_WRAPPER}>
      <SidebarDataLine
        title={_t('element.totalMonthVerifications')}
        data={`${qualityStatistics.totalMonthVerifications}`}
      />
      <SidebarDataLine
        title={_t('element.cityProgressPercentage')}
        data={`${qualityStatistics.cityProgressPercentage} %`}
      />
      <SidebarDataLine
        title={_t('element.averageVerificationsPerReviewAgent')}
        data={`${qualityStatistics.averageVerificationsPerReviewAgent}`}
      />
      {agentFiltered && (
        <SidebarDataLine
          title={_t('element.verificationsOnFilteredAgent')}
          data={`${qualityStatistics.verificationsOnFilteredAgent}`}
        />
      )}
    </div>
  );
}

export default QualityControlStatisticsComponent;
