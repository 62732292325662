import React, { CSSProperties } from 'react';

import { STYLE_ADD_BUTTON, STYLE_CRITERIA_CONTAINER } from '../theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_MENU_ITEM: CSSProperties = {
  fontFamily: 'Roboto',
  textAlign: 'center',
  borderTop: '1px solid #ccc',
  padding: 6,
  color: 'black',
  width: '100%',
  cursor: 'pointer',
};

const STYLE_MENU_ITEM_DISABLED: CSSProperties = {
  ...STYLE_MENU_ITEM,
  cursor: 'not-allowed',
  opacity: 0.3,
  color: 'grey',
};

const CriteriaMenuItem = ({
  name,
  tooltip,
  disabled,
  criteriaKey,
  chooseCriteria,
}: {
  name: string;
  tooltip: string;
  disabled: boolean | null | undefined;
  criteriaKey: string;
  chooseCriteria: (key: string) => void;
}) => (
  <div
    title={
      disabled
        ? _tg('tefps.pricing.pricing.timeCriteriaMenu.criteriaAlreadyExists')
        : tooltip
    }
    style={disabled ? STYLE_MENU_ITEM_DISABLED : STYLE_MENU_ITEM}
    onClick={() => chooseCriteria(criteriaKey)}
  >
    {name}
  </div>
);

type TimeCriteriaMenuProps = {
  dayOffCriteriaExists: boolean | null | undefined;
  dayOfWeekCriteriaExists: boolean | null | undefined;
  addCriteria: (criteriaKey: string) => any;
};

type TimeCriteriaMenuState = {
  open: boolean;
};

class TimeCriteriaMenu extends React.Component<
  TimeCriteriaMenuProps,
  TimeCriteriaMenuState
> {
  constructor(props: TimeCriteriaMenuProps) {
    super(props);
    this.state = { open: false };
  }

  chooseCriteria = (criteriaKey: string): void => {
    const { addCriteria } = this.props;
    this.setState({ open: false });
    addCriteria(criteriaKey);
  };

  openMenu = (): void => this.setState({ open: true });

  render(): JSX.Element {
    const { dayOffCriteriaExists, dayOfWeekCriteriaExists } = this.props;
    const { open } = this.state;

    if (!open) {
      return (
        <div style={STYLE_ADD_BUTTON} onClick={this.openMenu}>
          +
        </div>
      );
    }

    return (
      <div style={STYLE_CRITERIA_CONTAINER}>
        <div style={{ marginBottom: 4 }}>
          {_tg('tefps.pricing.pricing.timeCriteriaMenu.chooseCriteria')}
        </div>
        <CriteriaMenuItem
          name={_tg(
            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.titleShort'
          )}
          tooltip={_tg(
            'tefps.pricing.pricing.timeCriteriaMenu.dayOfWeekWherePolicyApplies'
          )}
          disabled={dayOfWeekCriteriaExists}
          criteriaKey="dayOfWeekCriteria"
          chooseCriteria={this.chooseCriteria}
        />
        <CriteriaMenuItem
          name={_tg(
            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.titleShort'
          )}
          tooltip={_tg(
            'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.title'
          )}
          disabled={dayOffCriteriaExists}
          criteriaKey="dayOffCriteria"
          chooseCriteria={this.chooseCriteria}
        />
        <CriteriaMenuItem
          name={_tg('tefps.pricing.pricing.timeCriteriaMenu.dateRange')}
          tooltip={_tg(
            'tefps.pricing.pricing.timeCriteriaMenu.dateRangeTooltip'
          )}
          disabled={false}
          criteriaKey="dateIntervalCriteria"
          chooseCriteria={this.chooseCriteria}
        />
        <CriteriaMenuItem
          name={_tg('tefps.pricing.pricing.timeCriteriaMenu.timeRange')}
          tooltip={_tg(
            'tefps.pricing.pricing.timeCriteriaMenu.timeRangeTooltip'
          )}
          disabled={false}
          criteriaKey="timeIntervalCriteria"
          chooseCriteria={this.chooseCriteria}
        />
        <CriteriaMenuItem
          name={_tg('tefps.pricing.pricing.timeCriteriaMenu.specialDays')}
          tooltip={_tg(
            'tefps.pricing.pricing.timeCriteriaMenu.specialDaysTooltip'
          )}
          disabled={false}
          criteriaKey="specialDayCriteria"
          chooseCriteria={this.chooseCriteria}
        />
      </div>
    );
  }
}

export default TimeCriteriaMenu;
