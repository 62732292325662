import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';

import { getConfigState } from 'config/duck';
import { Address } from 'api/commonTypes';
import {
  BrandModel,
  LapiReviewDTO,
  LapiReviewImage,
  PreloadLapiReviewDTO,
  QualityControlFilterDTO,
  VehicleBrandDTO,
} from 'api/lapiReview/types';
import { LightLapiZone } from 'api/pricing/types';
import { QualityControlStatistics } from 'api/lapiReviewConfiguration/types';
import { FETCH_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { fetchZoning } from 'api/pricing';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { ZoningDTO } from '@cvfm-front/tefps-types';

import ContextImages from './ContextImages/ContextImages';
import PhotoInformation from './PhotoInformation/PhotoInformation';
import TicketInformation from './TicketInformation/TicketInformation';
import VehicleInformation from './VehicleInformation/VehicleInformation';
import LapiSideBar from './LapiSideBar/LapiSideBar';
import Action from './Action/Action';
import Geolocalisation from './Geolocalisation/Geolocalisation';
import AlreadyTreatedInformation from './AlreadyTreatedInformation';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

const STYLE_COLUMN: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: '1',
  padding: '20px',
};

const STYLE_TOP_RIGHT: CSSProperties = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  paddingLeft: '10px',
  justifyContent: 'space-between',
  alignItems: 'end',
};

type Props = {
  complexCaseEnabled: boolean;
  tagsEnabled: boolean;
  improveLapiImagesEnabled: boolean;
  preLoadLapi: PreloadLapiReviewDTO;
  handleManualBlurring: (
    arg0: Array<LapiReviewImage>,
    arg1: boolean,
    arg2: number
  ) => void;
  zonesMap: Map<string, LightLapiZone>;
  changeBrand: (arg0: string | null | undefined) => void;
  changeModel: (arg0: string | null | undefined) => void;
  changeAddress: (arg0: Address) => void;
  changeCategory: (arg0: string | null | undefined) => void;
  userName: string;
  fetchNew: () => void;
  vehiclesList: Array<VehicleBrandDTO>;
  brandModelsMap: Map<string, Array<BrandModel>>;
  changePlate: (newValue: string) => Promise<void>;
  brandProvided: boolean;
  subscriptionPlanMap: Map<string, string> | undefined;
  searchLapiById: (controlId: string) => void;
  searchLapiByIdWithRebuild: (controlId: string) => void;
  lockTreatment: boolean;
  clearHistoryAndHandleTimer: () => void;
  isLapiFetchedFromControlList: boolean;
  qualityControlMode: boolean;
  qualityStatistics: QualityControlStatistics;
  onQualityAgentSelect: (e: string) => void;
  qualityControlFilter: QualityControlFilterDTO;
  setQualityControlFilter: (filter: QualityControlFilterDTO) => void;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode: boolean;
  selectImage: (index: number, checker: boolean) => void;
  selectedImages: Set<number>;
};

function View({
  preLoadLapi: {
    lapiReview,
    contextImages,
    contextImprovedImages,
    plateImages,
    fpsPrice,
  },
  complexCaseEnabled,
  tagsEnabled,
  improveLapiImagesEnabled,
  zonesMap,
  handleManualBlurring,
  changeBrand,
  changeModel,
  changeAddress,
  changeCategory,
  userName,
  fetchNew,
  vehiclesList,
  brandModelsMap,
  brandProvided,
  subscriptionPlanMap,
  changePlate,
  searchLapiById,
  searchLapiByIdWithRebuild,
  lockTreatment,
  isLapiFetchedFromControlList,
  clearHistoryAndHandleTimer,
  qualityControlMode,
  qualityStatistics,
  onQualityAgentSelect,
  qualityControlFilter,
  setQualityControlFilter,
  setDarkMode,
  darkMode,
  selectImage,
  selectedImages,
}: Props) {
  const [zoning, setZoning] = useState<ZoningDTO | null | undefined>(null);
  const [useImagesImproved, setUseImagesImproved] = useState<boolean>(
    improveLapiImagesEnabled
  );
  const [initialLapiReview, setInitialLapiReview] = useState<LapiReviewDTO>(
    _cloneDeep(lapiReview)
  );
  const setMessage = useSnackbar();

  const contextImagesLocal = useImagesImproved
    ? contextImprovedImages
    : contextImages;

  const contextImagesFiles = contextImagesLocal
    .filter(img => img && img.file)
    .map(img => img.file);

  const handleUseImprovedImages = () => {
    setUseImagesImproved(!useImagesImproved);
  };

  const fetchCityZoning = async () => {
    try {
      const cityZoning = await fetchZoning(FETCH_ZONING_CONFIG);
      setZoning(cityZoning);
    } catch (e) {
      setMessage('Erreur lors de la récupération des zones');
    }
  };

  useEffect(() => {
    void fetchCityZoning();
    setInitialLapiReview(_cloneDeep(lapiReview));
  }, []);

  // Maj des données initiales du lapireview, devrait p-e être sur le composant du dessus
  useEffect(() => {
    setInitialLapiReview(_cloneDeep(lapiReview));
  }, [lapiReview]);

  return (
    <div style={STYLE_CONTENT}>
      <div style={STYLE_COLUMN}>
        <ContextImages
          controlId={lapiReview.controlId}
          contextImages={contextImagesLocal}
          imagesAreImproved={useImagesImproved}
          improveLapiImagesEnabled={improveLapiImagesEnabled}
          lockTreatment={lockTreatment}
          selectImage={selectImage}
          selectedImages={selectedImages}
          handleManualBlurring={handleManualBlurring}
          handleUseImprovedImages={handleUseImprovedImages}
        />
        <PhotoInformation
          datetime={lapiReview.statementDatetime}
          zoneName={zonesMap.get(lapiReview.zoneId)?.name}
          complex={lapiReview.complex}
          complexTreatmentAgent={lapiReview.complexTreatmentAgent}
          controlId={lapiReview.controlId}
          terminalId={lapiReview.terminalId}
          controlAgentName={lapiReview.controlAgent.name}
          controlStatus={lapiReview.controlStatus}
          reviewReason={lapiReview.reviewReason}
          qualityControlMode={qualityControlMode}
        />
        {lockTreatment && !qualityControlMode && (
          <AlreadyTreatedInformation lapiReview={lapiReview} />
        )}
        <TicketInformation
          parkingRights={lapiReview.parkingRights}
          subscriptions={lapiReview.subscriptions}
          subscriptionPlanMap={subscriptionPlanMap}
          fpsPrice={fpsPrice}
          zonesMap={zonesMap}
          isForControl={false}
          controlDate={lapiReview.statementDatetime}
          controlZone={lapiReview.zoneId}
          controlPricingCategory={
            lapiReview.relavantParkingRightPricingCategory
          }
          darkMode={darkMode}
        />
      </div>
      <div style={STYLE_COLUMN}>
        <div style={{ display: 'flex' }}>
          <VehicleInformation
            plateImage={plateImages[0]}
            lapiReview={lapiReview}
            initialLapiReview={initialLapiReview}
            vehiclesList={vehiclesList}
            brandModelsMap={brandModelsMap}
            changeBrand={changeBrand}
            changeModel={changeModel}
            changeVehicleCategory={changeCategory}
            changePlate={changePlate}
            lockTreatment={lockTreatment && !qualityControlMode}
          />
          <div style={STYLE_TOP_RIGHT}>
            <LapiSideBar
              userName={userName}
              complexCaseEnabled={complexCaseEnabled}
              searchLapiByIdWithRebuild={searchLapiByIdWithRebuild}
              lapiReviewId={lapiReview.controlId}
              searchLapiById={searchLapiById}
              qualityControlMode={qualityControlMode}
              qualityStatistics={qualityStatistics}
              onQualityAgentSelect={onQualityAgentSelect}
              qualityControlFilter={qualityControlFilter}
              setQualityControlFilter={setQualityControlFilter}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <Action
              complexCaseEnabled={complexCaseEnabled}
              tagsEnabled={tagsEnabled}
              lapiReview={lapiReview}
              fetchNew={fetchNew}
              clearHistoryAndHandleTimer={clearHistoryAndHandleTimer}
              plateImages={plateImages}
              contextImages={contextImagesFiles}
              selectedImages={selectedImages}
              brandProvided={brandProvided}
              fpsPrice={fpsPrice}
              controlZone={zonesMap.get(lapiReview.zoneId)}
              lockTreatment={lockTreatment}
              isLapiFetchedFromControlList={isLapiFetchedFromControlList}
              qualityControlMode={qualityControlMode}
              zoning={zoning}
            />
          </div>
        </div>
        {zoning && (
          <Geolocalisation
            controlPosition={{
              longitude: lapiReview.controlLongitude,
              latitude: lapiReview.controlLatitude,
            }}
            controlAddress={{
              longitude: lapiReview.addressLongitude,
              latitude: lapiReview.addressLatitude,
            }}
            changeAddress={changeAddress}
            darkMode={darkMode}
          />
        )}
      </div>
    </div>
  );
}

export default connect(state => {
  const { lapiReviewConfigurationDTO } = getConfigState(state);
  const { improveLapiImagesEnabled, tagsEnabled } = lapiReviewConfigurationDTO;
  return { improveLapiImagesEnabled, tagsEnabled };
})(View);
