import React, { CSSProperties, useEffect } from 'react';
import { connect } from 'react-redux';

import { getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';
import services from 'commons/services';
import usePatrolZones from 'commons/hooks/usePatrolZones';
import usePatrolZoneError from 'commons/hooks/usePatrolZoneError';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import PatrolZoneSidebar from './PatrolZoneSidebar';
import PatrolZoneMap from './PatrolZoneMap';
import PatrolZoneEditModal from './PatrolZoneEditModal';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_MAP_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

const PatrolZone = (): JSX.Element => {
  const patrolZones = usePatrolZones();
  const error = usePatrolZoneError();
  const setMessage = useSnackbar();

  useEffect(() => {
    void services.patrolZoneService.init();
  }, []);

  useEffect(() => {
    setMessage(error || '');
  }, [error]);

  if (patrolZones === null) {
    return <></>;
  }

  return (
    <div style={STYLE_CONTENT}>
      <PatrolZoneEditModal />
      <PatrolZoneSidebar />
      <div style={STYLE_MAP_WRAPPER}>
        <PatrolZoneMap patrolZones={patrolZones} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: InternalApiState) => {
  const { location } = getConfigState(state);
  return {
    location,
  };
};

export default connect(mapStateToProps)(PatrolZone);
