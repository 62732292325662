import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog, IconButton } from 'material-ui';
import AddNew from 'material-ui/svg-icons/content/add-box';
import RemoveIcon from 'material-ui/svg-icons/content/remove';
import InfoIcon from 'material-ui/svg-icons/action/info';
import moment from 'moment';

import BoButton from 'facade/BoButton';
import {
  PricingConfiguration,
  PricingDTO,
  SpecialDaysRange,
} from '@cvfm-front/tefps-types';
import { BKG_CYAN_SELECTED, BRD_GREY, ICON_RED } from 'theme';
import { Select, Title } from '@cvfm-front/commons-ui';
import {
  ExtendedFilterDate,
  FilterDate,
} from 'commons/SidebarV2/Components/Dates';
import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import { saveSpecialDays } from 'api/pricing';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import { formatDatetimeFromString } from '../../../../commons/Utils/dateUtil';

const { _tg } = window.loadTranslations(__filename);

const STYLE_MODULE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 5,
  padding: 12,
  border: `1px solid ${BRD_GREY}`,
  backgroundColor: 'white',
};

type SpecialDaysRangeModalProps = {
  onClose: () => void;
  open: boolean;
  disabled: boolean;
  pricingConfiguration: PricingConfiguration;
  pricingDTO: PricingDTO;
  reloadPricing: (pricingId: string) => void;
};

export default function SpecialDaysRangeModal({
  open,
  disabled,
  onClose,
  pricingConfiguration,
  pricingDTO,
  reloadPricing,
}: SpecialDaysRangeModalProps): JSX.Element {
  const [selectedDay, setSelectedDay] = useState<string>(
    pricingConfiguration.specialDays[0].id
  );
  const [dateRange, setDateRange] = useState<FilterDate>({
    from: new Date(),
    to: new Date(),
  });
  const [specialDayEntries, setSpecialDaysEntries] = useState<{
    [key: string]: ExtendedFilterDate[];
  }>({});

  const setMessage = useSnackbar();

  useEffect(() => {
    setSpecialDaysEntries(
      pricingDTO.specialDaysRange.reduce(
        (acc, entry) => {
          if (acc[entry.specialDayKey]) {
            acc[entry.specialDayKey].push({
              from: new Date(entry.dateRange.from),
              to: new Date(entry.dateRange.to),
              dateRangeAbortedAt: entry.dateRangeAbortedAt,
            });
          } else {
            acc[entry.specialDayKey] = [
              {
                from: new Date(entry.dateRange.from),
                to: new Date(entry.dateRange.to),
                dateRangeAbortedAt: entry.dateRangeAbortedAt,
              },
            ];
          }
          return acc;
        },
        {} as {
          [key: string]: ExtendedFilterDate[];
        }
      )
    );
  }, [pricingDTO.specialDaysRange]);

  async function onSave() {
    try {
      await saveSpecialDays(
        pricingDTO.id,
        Object.keys(specialDayEntries).reduce((acc, key) => {
          specialDayEntries[key].forEach(value =>
            acc.push({
              specialDayKey: key,
              dateRange: {
                from: value.from?.toISOString() || '',
                to: value.to?.toISOString() || '',
              },
            })
          );
          return acc;
        }, [] as SpecialDaysRange)
      );
      onClose();
      reloadPricing(pricingDTO.id);
    } catch (err) {
      setMessage(err);
    }
  }

  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      key={0}
      label={_tg('action.save_1')}
      onClick={onSave}
      primary
    />,
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={disabled ? _tg('action.close') : _tg('action.cancel')}
      onClick={onClose}
    />,
  ];

  function addRangeToSettings() {
    const specialDaysRange = { ...specialDayEntries };
    if (specialDaysRange[selectedDay]) {
      specialDaysRange[selectedDay].push(dateRange);
    } else {
      specialDaysRange[selectedDay] = [dateRange];
    }
    setSpecialDaysEntries(specialDaysRange);
  }

  function removeEntry(specialDayKey: string, dateToRemove: FilterDate) {
    const spde = { ...specialDayEntries };
    spde[specialDayKey] = spde[specialDayKey].filter(
      range => range.from !== dateToRemove.from || range.to !== dateToRemove.to
    );
    setSpecialDaysEntries(spde);
  }

  function renderSpecialDayInput(key: string): JSX.Element {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Title>
          {pricingConfiguration.specialDays.find(spd => spd.id === key)?.label}
        </Title>
        {specialDayEntries[key].map(datesForEntry => (
          <div
            key={`${datesForEntry.from} ${datesForEntry.to}`}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <DateFilter
              dates={datesForEntry}
              datepickerClass={DatepickerClass.filter}
              styleText={{}}
              onChange={({ to, from }) =>
                setDateRange({
                  from: from || undefined,
                  to: to && from && from > to ? from : to || undefined,
                })
              }
              minDate={dateRange.from}
              disabled
            />
            {datesForEntry.dateRangeAbortedAt ? (
              <IconButton
                tooltip={`${_tg(
                  'field.interrupted'
                )} : ${formatDatetimeFromString(
                  datesForEntry.dateRangeAbortedAt
                )}`}
                style={{ marginTop: 7 }}
              >
                <InfoIcon />
              </IconButton>
            ) : (
              <IconButton
                tooltip={_tg('tefps.pricing.pricing.specialDays.removeDays')}
                style={{ marginTop: 7 }}
                iconStyle={{
                  color: ICON_RED,
                }}
                disabled={
                  !moment(datesForEntry.to)
                    .startOf('days')
                    .isSameOrAfter(moment().startOf('days'))
                }
                onClick={() => removeEntry(key, datesForEntry)}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    );
  }

  function exludedDatesFromRange(dateRangeToExclude: FilterDate): Date[] {
    if (!dateRangeToExclude.from || !dateRangeToExclude.to) {
      return [];
    }
    const datesInRange: Date[] = [];
    const now = moment(dateRangeToExclude.from);
    const limit = moment(dateRangeToExclude.to);
    while (!now.isAfter(limit)) {
      datesInRange.push(now.toDate());
      now.add(1, 'day');
    }
    return datesInRange;
  }

  return (
    <Dialog open={open} actions={actions} autoScrollBodyContent>
      <div style={STYLE_MODULE}>
        <Title>{_tg('tefps.pricing.pricing.specialDays.addRangeToADay')}</Title>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Select
            options={pricingConfiguration.specialDays.map(spd => {
              return { label: spd.label, value: spd.id };
            })}
            value={selectedDay}
            onChange={value => setSelectedDay(value)}
          />
          <DateFilter
            dates={dateRange}
            datepickerClass={DatepickerClass.filter}
            styleText={{}}
            onChange={({ to, from }) =>
              setDateRange({
                from: from || undefined,
                to: to && from && from > to ? from : to || undefined,
              })
            }
            minDate={new Date()}
            excludedDates={specialDayEntries[selectedDay]?.reduce(
              (acc, dateRangeToExclude) => [
                ...acc,
                ...exludedDatesFromRange({ ...dateRangeToExclude }),
              ],
              [] as Date[]
            )}
          />
          <IconButton
            tooltip={_tg('tefps.pricing.pricing.specialDays.addRange')}
            style={{ marginTop: 7 }}
            iconStyle={{ color: BKG_CYAN_SELECTED }}
            onClick={addRangeToSettings}
          >
            <AddNew />
          </IconButton>
        </div>
      </div>
      <div style={STYLE_MODULE}>
        {Object.keys(specialDayEntries).map(key => renderSpecialDayInput(key))}
      </div>
    </Dialog>
  );
}
