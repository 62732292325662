import React, { CSSProperties, useState } from 'react';
import IconButton from 'material-ui/IconButton';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

import { Address, ControlAddressType } from 'api/commonTypes';
import AddressRenderer from 'commons/Address/AddressRenderer';
import { TXT_BLACK } from 'theme';

import ControlAddressModal from './ControlAddressModal';

const { _tg } = window.loadTranslations(__filename);

const ADDRESS_STYLE: CSSProperties = {
  margin: '0% 2% 0% 2%',
  display: 'flex',
  padding: '10px',
};

const CONTAINER_STYLE: CSSProperties = {
  width: '100%',
};

const TITLE_CONTAINER_STYLE: CSSProperties = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1px 2% 0% 2%',
};

const TITLE_STYLE: CSSProperties = {
  flex: '1 1 auto',
  color: TXT_BLACK,
};

type ControlAddressProps = {
  address: ControlAddressType;
  changeAddress: (newAddress: ControlAddressType) => void;
  onClose: () => void;
  muiTheme?: MuiTheme;
  darkMode: boolean;
};

const ControlAddress = ({
  address,
  changeAddress,
  muiTheme,
  darkMode,
  onClose,
}: ControlAddressProps): React.ReactElement<any> => {
  const [open, setOpen] = useState<boolean>(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    onClose();
  };

  const saveAndCloseModal = (newAddress: Address) => {
    setOpen(false);
    changeAddress(newAddress);
  };

  return (
    <div style={CONTAINER_STYLE}>
      <div style={TITLE_CONTAINER_STYLE}>
        <span style={{ ...TITLE_STYLE, color: muiTheme?.textField?.textColor }}>
          {_tg(
            'tefps.lapiReview.geolocalisation.components.controlAddress.text'
          )}
        </span>
        <IconButton onClick={openModal} touch>
          <EditIcon />
        </IconButton>
      </div>
      <AddressRenderer
        style={{
          ...ADDRESS_STYLE,
        }}
        address={address}
        itemStyle={{ marginLeft: 10 }}
      />
      <ControlAddressModal
        open={open}
        onCancel={closeModal}
        onConfirm={saveAndCloseModal}
        address={address}
        darkMode={darkMode}
      />
    </div>
  );
};

export default muiThemeable()(ControlAddress);
