import moment from 'moment';

import { GpsStatus } from '@cvfm-front/tefps-types';
import { PingMapSearchRequest } from 'api/planner/types';

import { MapFilters, PingDisplayType } from './types';

export const displayOptions: {
  [k: string]: PingDisplayType;
} = {
  CURRENT: 'CURRENT',
  HISTORY: 'HISTORY',
};

export const filtersToRequest = (filters: MapFilters): PingMapSearchRequest => {
  const {
    organizationIds,
    teamId,
    agentId,
    datetime,
    times,
    lapi,
    referent,
    hasRouteInstance,
    gpsStatuses,
  } = filters;

  // we use FREEZE_AND_JUMP as KO
  const gpsStatusesToSend = Array.from(gpsStatuses);
  if (gpsStatuses.has(GpsStatus.FREEZE_AND_JUMP)) {
    gpsStatusesToSend.push(GpsStatus.FREEZE);
    gpsStatusesToSend.push(GpsStatus.JUMP);
  }

  const lapiValue =
    lapi.size >= 2 ? 'true' : (lapi.values().next().value as string);
  // agent filter has priority over organization/team filters
  return {
    organizationIds:
      organizationIds && !agentId ? Array.from(organizationIds) : [],
    teamIds: teamId && !agentId ? [teamId] : [],
    agentIds: agentId ? [agentId] : [],
    datetime: datetime ? moment(datetime).toISOString() : null,
    startTime: times ? times.from : null,
    endTime: times ? times.to : null,
    lapi: lapiValue,
    referent,
    hasRouteInstance,
    gpsStatuses: gpsStatusesToSend,
  };
};
