import React from 'react';

import BoButton from 'facade/BoButton';

type Props = {
  cantonLabel: string;
  cantonUrl: string;
  plate: string;
};

const CantonButton = ({
  cantonLabel,
  cantonUrl,
  plate,
}: Props): JSX.Element => {
  return (
    <BoButton
      secondary
      label={cantonLabel}
      onClick={() => {
        // On copie la plaque sans l'indicateur de canton dans le clipBoard pour que l'agent
        // n'ait qu'à le coller.
        void navigator.clipboard
          .writeText(plate.replace(/\D+/, ''))
          .then(() => {
            const newTab = window.open(cantonUrl, '_blank');
            if (newTab) {
              newTab.focus();
            }
          });
      }}
    />
  );
};

export default CantonButton;
