import {
  LapiReviewDTO,
  LapiReviewImage,
  PreloadLapiReviewDTO,
} from 'api/lapiReview/types';
import { FnmsCityFpsPriceDTO } from 'api/pricing/types';
import { fetchControl } from 'api/lapiReview';
import { getFpsPrice } from 'api/pricing';
import { filterContextImages } from 'tefps/LapiReview/ContextImages/utils';
import { filterPlatePicture } from 'tefps/LapiReview/VehicleInformation/utils';

export const fetchLapiReviewFpsPrice = async (
  lapiReview: LapiReviewDTO
): Promise<FnmsCityFpsPriceDTO> => {
  return new Promise((resolve, reject) => {
    const statementDatetimeDate = new Date(lapiReview.statementDatetime);
    const statementDatetimeQuery = statementDatetimeDate.toISOString();

    const vehicleQuery = {
      brand: lapiReview.vehicleBrand,
      model: lapiReview.vehicleBrand,
      vehicleCategory:
        lapiReview.vehicleCategory || lapiReview.presumedVehicleType,
    };

    const parkingRightsInReviewZone = lapiReview.parkingRights.filter(
      parkingRight => {
        return (
          parkingRight.hasValidPricingCategory &&
          (parkingRight.validityAreas.includes(lapiReview.zoneId) ||
            parkingRight.zoneId === lapiReview.zoneId)
        );
      }
    );
    const parkingRightsQuery = parkingRightsInReviewZone.map(t => ({
      cityId: '',
      parkId: null,
      ticketId: null,
      zoneId: t.zoneId,
      type: t.type,
      rightPrice: t.rightPrice,
      startDatetime: t.startDatetime,
      endDatetime: t.endDatetime,
    }));

    getFpsPrice(
      lapiReview.zoneId,
      statementDatetimeQuery,
      lapiReview.licensePlate,
      vehicleQuery,
      parkingRightsQuery
    )
      .then(fpsPriceResult => {
        resolve(fpsPriceResult);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchLapiReviewContextImages = (
  review: LapiReviewDTO,
  useImproved: boolean
): Promise<Array<LapiReviewImage>> => {
  const imgPromises = filterContextImages(useImproved, review.medias).map(
    media => {
      return new Promise<LapiReviewImage>((resolve, reject) => {
        fetch(media.contentUrl, { mode: 'cors', method: 'GET' })
          .then(res => res.blob())
          .then(blob => {
            const lapiContextImage: LapiReviewImage = {
              file: new File([blob], media.name, { type: blob.type }),
              name: media.name,
              type: media.type,
              position: media.position,
            };
            resolve(lapiContextImage);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  );
  return Promise.all(imgPromises);
};

const fetchLapiReviewPlateImages = (
  review: LapiReviewDTO
): Promise<Array<File>> => {
  const platePromises = filterPlatePicture(review.medias).map(media => {
    return new Promise<File>((resolve, reject) => {
      fetch(media.contentUrl, { mode: 'cors', method: 'GET' })
        .then(res => res.blob())
        .then(blob => {
          const plateFile = new File([blob], media.name, { type: blob.type });
          resolve(plateFile);
        })
        .catch(error => {
          reject(error);
        });
    });
  });
  return Promise.all(platePromises);
};

type LapiReviewPreloadedImages = {
  contextImages: Array<LapiReviewImage>;
  contextImagesImproved: Array<LapiReviewImage>;
  plateImages: Array<File>;
};

const fetchLapiReviewImages = (
  lapiReview: LapiReviewDTO
): Promise<LapiReviewPreloadedImages> => {
  return new Promise((resolve, reject) => {
    // Fetch all async
    const contextImgsPromise = fetchLapiReviewContextImages(lapiReview, false);
    const contextImgsImprovedPromise = fetchLapiReviewContextImages(
      lapiReview,
      true
    );
    const plateImgsPromise = fetchLapiReviewPlateImages(lapiReview);

    // Declare result container
    const fetchedImages = {} as LapiReviewPreloadedImages;
    // Process results, with waiting if needed
    contextImgsPromise
      .then(contextImgs => {
        fetchedImages.contextImages = contextImgs;
        return contextImgsImprovedPromise;
      })
      .then(contextImgsImproved => {
        fetchedImages.contextImagesImproved = contextImgsImproved;
        return plateImgsPromise;
      })
      .then(plateImgs => {
        fetchedImages.plateImages = plateImgs;
        resolve(fetchedImages);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchLapiReviewPreloadFromLapiReview = (
  lapiReview: LapiReviewDTO
): Promise<PreloadLapiReviewDTO> => {
  return new Promise((resolve, reject) => {
    const preloadLapiReview = {} as PreloadLapiReviewDTO;
    preloadLapiReview.lapiReview = lapiReview;

    fetchLapiReviewFpsPrice(lapiReview)
      .then(fpsPrice => {
        preloadLapiReview.fpsPrice = fpsPrice;
        return fetchLapiReviewImages(preloadLapiReview.lapiReview);
      })
      .then(lapiReviewPreloadedImages => {
        preloadLapiReview.contextImages =
          lapiReviewPreloadedImages.contextImages;
        preloadLapiReview.contextImprovedImages =
          lapiReviewPreloadedImages.contextImagesImproved;
        preloadLapiReview.plateImages = lapiReviewPreloadedImages.plateImages;
        resolve(preloadLapiReview);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchLapiReviewPreload = (): Promise<PreloadLapiReviewDTO> => {
  return new Promise((resolve, reject) => {
    fetchControl()
      .then(lapiReview => {
        return fetchLapiReviewPreloadFromLapiReview(lapiReview);
      })
      .then(preloadLapiReview => {
        resolve(preloadLapiReview);
      })
      .catch(error => {
        reject(error);
      });
  });
};
