import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';
import WarningIcon from 'material-ui/svg-icons/alert/warning';
import ActionIcon from 'material-ui/svg-icons/action/info';

import Panel from 'tefps/LapiReview/components/Panel';
import {
  BrandModel,
  LapiReviewDTO,
  VehicleBrandDTO,
} from 'api/lapiReview/types';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';
import { SivConfiguration } from '@cvfm-front/tefps-types';
import BoButton from 'facade/BoButton';
import { ICON_YELLOW, ICON_BLUE } from 'theme';

import AutoCompleteVehicles from './component/AutoCompleteVehicles';
import Plate from './component/Plate';
import VehicleTypeSelector from './component/VehicleTypeSelector';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  plateImage: File;
  lapiReview: LapiReviewDTO;
  initialLapiReview: LapiReviewDTO;
  vehiclesList: Array<VehicleBrandDTO>;
  brandModelsMap: Map<string, Array<BrandModel>>;
  changeBrand: (input: string | null) => void;
  changeModel: (input: string | null) => void;
  changeVehicleCategory: (category: string) => void;
  changePlate: (newValue: string) => Promise<void>;
  lockTreatment: boolean;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  sivConfiguration: SivConfiguration;
};

const INPUT_STYLE: CSSProperties = {
  width: '100%',
  height: '100%',
  border: 0,
  fontSize: 16,
};

const MAIN_CONTAINER_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

const SIV_INFORMATION_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 20,
};

const STYLE_ICON: CSSProperties = {
  width: 35,
  height: 35,
  margin: '2px 10px 2px 0',
};

const DATA_BRAND_CONFIG = {
  text: 'brand',
  value: 'brand',
};

const DATA_MODEL_CONFIG = {
  text: 'name',
  value: 'name',
};

/**
 * Compare vehicle information between initial lapi value and current state
 * Used to display a warning
 */
function hasVehicleInformationChanged(
  currentLapiReview: LapiReviewDTO,
  initialLapiReview: LapiReviewDTO
): boolean {
  return (
    currentLapiReview.vehicleCategory !== initialLapiReview.vehicleCategory ||
    currentLapiReview.vehicleBrand !== initialLapiReview.vehicleBrand ||
    currentLapiReview.vehicleModel !== initialLapiReview.vehicleModel
  );
}

/**
 * Show diff between current/initial lapireview information
 */
function computeVehicleChanges(
  currentLapiReview: LapiReviewDTO,
  initialLapiReview: LapiReviewDTO
): string {
  let res = '';

  if (currentLapiReview.vehicleBrand !== initialLapiReview.vehicleBrand) {
    res += `Marque: ${initialLapiReview.vehicleBrand || ''}`;
  }
  if (currentLapiReview.vehicleModel !== initialLapiReview.vehicleModel) {
    if (res !== '') res += ', ';
    res += `Modèle: ${initialLapiReview.vehicleModel || ''}`;
  }
  if (currentLapiReview.vehicleCategory !== initialLapiReview.vehicleCategory) {
    if (res !== '') res += ', ';
    res += `Catégorie: ${initialLapiReview.vehicleCategory || ''}`;
  }

  return res;
}

function VehicleInformation({
  plateImage,
  lapiReview,
  initialLapiReview,
  vehiclesList,
  brandModelsMap,
  changeBrand,
  changeModel,
  changeVehicleCategory,
  changePlate,
  lockTreatment,
  authorizedVehicleCategories,
  sivConfiguration,
}: Props): JSX.Element {
  const [disableModelField, setDisableModelField] = useState(true);
  const [brandModels, setBrandModels] = useState(new Array<BrandModel>());

  const plateUrl = URL.createObjectURL(plateImage);

  function validateRequest(request: string | undefined | null) {
    return !(!request || request.trim() === '');
  }

  const handleBrandChange = (input: string | null) => {
    if (validateRequest(input)) {
      setDisableModelField(false);
      changeBrand(input);
    } else {
      changeBrand(null);
    }
    const models = brandModelsMap.get(input || '');
    setBrandModels(models || []);
  };

  const handleModelChange = (input: string) => {
    if (validateRequest(input)) {
      changeModel(input);
    } else {
      changeModel(null);
    }
  };

  const handleVehicleCategoryChange = (category: string) => {
    changeVehicleCategory(category);
  };

  function reinitVehicleInformation() {
    changeModel(initialLapiReview.vehicleModel || null);
    changeBrand(initialLapiReview.vehicleBrand || null);
    if (initialLapiReview.vehicleCategory) {
      changeVehicleCategory(initialLapiReview.vehicleCategory);
    }
  }

  return (
    <Panel style={{ flex: '2' }} name="Information Véhicule">
      <div style={MAIN_CONTAINER_STYLE}>
        <Plate
          plateUrl={plateUrl}
          lapiReview={lapiReview}
          changePlate={changePlate}
          lockTreatment={lockTreatment}
        />
        {sivConfiguration.enableControlSiv && (
          <div style={SIV_INFORMATION_STYLE}>
            <div style={{ width: 45 }}>
              <ActionIcon style={STYLE_ICON} color={ICON_BLUE} />
            </div>
            <div>{_tg('tefps.lapiReview.vehicleInformation.siv')}</div>
          </div>
        )}
        <AutoCompleteVehicles
          label="Marque :"
          initialInput={lapiReview.vehicleBrand}
          style={INPUT_STYLE}
          dataSource={vehiclesList}
          dataSourceConfig={DATA_BRAND_CONFIG}
          handleNewRequest={handleBrandChange}
          disabled={lockTreatment}
        />
        <AutoCompleteVehicles
          label="Modèle :"
          initialInput={lapiReview.vehicleModel}
          style={INPUT_STYLE}
          dataSource={brandModels}
          dataSourceConfig={DATA_MODEL_CONFIG}
          handleNewRequest={handleModelChange}
          disabled={disableModelField && lockTreatment}
        />
        {authorizedVehicleCategories &&
          authorizedVehicleCategories.length > 0 && (
            <VehicleTypeSelector
              selectedVehicleCategory={
                lapiReview.vehicleCategory || lapiReview.presumedVehicleType
              }
              handleNewRequest={handleVehicleCategoryChange}
              authorizedVehicleCategories={authorizedVehicleCategories}
            />
          )}
        {sivConfiguration.enableControlSiv &&
          hasVehicleInformationChanged(lapiReview, initialLapiReview) && (
            <div style={SIV_INFORMATION_STYLE}>
              <div style={{ width: 45 }}>
                <WarningIcon style={STYLE_ICON} color={ICON_YELLOW} />
              </div>
              <div>
                {_tg('tefps.lapiReview.vehicleInformation.changes', {
                  changes: computeVehicleChanges(lapiReview, initialLapiReview),
                })}
              </div>
              <div>
                <BoButton
                  label={_tg('tefps.lapiReview.vehicleInformation.reinitLabel')}
                  onClick={reinitVehicleInformation}
                />
              </div>
            </div>
          )}
      </div>
    </Panel>
  );
}

export default connect(state => {
  const { authorizedVehicleCategories, sivConfiguration } = getConfigState(
    state
  );
  authorizedVehicleCategories.sort((a, b) => a.priority - b.priority);
  return {
    authorizedVehicleCategories,
    sivConfiguration,
  };
})(VehicleInformation);
