import React, { useEffect, useState } from 'react';

import { BoxWithTitle, Flex, GenericFormV2 } from '@cvfm-front/commons-ui';
import {
  SelectFieldTypeV2,
  TextFieldTypeV2,
  TitleFieldTypeV2,
} from '@cvfm-front/commons-types';
import { Bill } from '@cvfm-front/tefps-types';

import BillDetailService from './BillDetailService';
import { BILL_PLACEHOLDER } from './BillPlaceholder';
import BillDetailAutoComplete from './BillDetailAutoComplete';
import CantonButton from './CantonButton';

interface BillVersionProps {
  plate: string;
  submitCallback?: () => void;
}

const BillDetailForm = ({
  plate,
  submitCallback,
}: BillVersionProps): JSX.Element => {
  const [editableBill, setEditableBill] = useState<Bill>(BILL_PLACEHOLDER);
  const cantonService = BillDetailService.getCantonServiceFromPlate(plate);

  useEffect(() => BillDetailService.watchEditableBill(setEditableBill), []);

  const lastVersionIndex = editableBill.versions.length - 1;
  const lastVersion = editableBill.versions[lastVersionIndex];

  return (
    <BoxWithTitle title="Edition de la facture">
      {cantonService && (
        <Flex flexDirection="column">
          <CantonButton
            cantonLabel={cantonService.label}
            cantonUrl={cantonService.url}
            plate={plate}
          />
          <BillDetailAutoComplete
            label={cantonService.label}
            autoComplete={cantonService.autoComplete}
          />
        </Flex>
      )}
      <GenericFormV2
        submitLabel="Enregistrer"
        fields={[
          {
            type: 'title',
            label: 'Identité',
          } as TitleFieldTypeV2,
          {
            type: 'select',
            label: 'Civilité',
            value: lastVersion.offender.gender || 'MALE',
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[lastVersionIndex].offender.gender = value as
                  | 'MALE'
                  | 'FEMALE';
              }),
            selectOptions: [
              { label: 'Homme', value: 'MALE' },
              { label: 'Femme', value: 'FEMALE' },
            ],
          } as SelectFieldTypeV2,
          {
            type: 'text',
            label: 'Prénom',
            value: lastVersion.offender.givenName,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[lastVersionIndex].offender.givenName = value;
              }),
          } as TextFieldTypeV2,
          {
            type: 'text',
            label: 'Nom',
            value: lastVersion.offender.familyName,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[lastVersionIndex].offender.familyName = value;
              }),
          } as TextFieldTypeV2,
          {
            type: 'text',
            label: 'Entreprise',
            value: lastVersion.offender.companyName,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[lastVersionIndex].offender.companyName = value;
              }),
            required: false,
          } as TextFieldTypeV2,
          {
            type: 'title',
            label: 'Adresse',
          } as TitleFieldTypeV2,
          {
            type: 'text',
            label: 'Code postal',
            value: lastVersion.offender.address.postalCode,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[
                  lastVersionIndex
                ].offender.address.postalCode = value;
              }),
          } as TextFieldTypeV2,
          {
            type: 'text',
            label: 'Ville',
            value: lastVersion.offender.address.addressLocality,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[
                  lastVersionIndex
                ].offender.address.addressLocality = value;
              }),
          } as TextFieldTypeV2,
          {
            type: 'text',
            label: 'Nom de rue',
            value: lastVersion.offender.address.streetName,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[
                  lastVersionIndex
                ].offender.address.streetName = value;
              }),
          } as TextFieldTypeV2,
          {
            type: 'text',
            label: 'Numéro de rue',
            value: lastVersion.offender.address.streetNumber,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[
                  lastVersionIndex
                ].offender.address.streetNumber = value;
              }),
          } as TextFieldTypeV2,
          {
            type: 'title',
            label: 'Facture',
          } as TitleFieldTypeV2,
          {
            type: 'text',
            label: 'Montant (en centimes)',
            value: lastVersion.price.toString(),
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[lastVersionIndex].price = parseInt(value, 10);
              }),
          } as TextFieldTypeV2,
          {
            type: 'title',
            label: 'Commentaire',
          } as TitleFieldTypeV2,
          {
            type: 'text',
            label: 'Commentaire',
            value: lastVersion.comment,
            onValueChange: value =>
              BillDetailService.setField(bill => {
                bill.versions[lastVersionIndex].comment = value;
              }),
            multiline: true,
            rows: 5,
            fullWidth: true,
          } as TextFieldTypeV2,
        ]}
        onSubmitCallback={() => {
          void BillDetailService.submitChanges();

          if (submitCallback) {
            submitCallback();
          }
        }}
      />
    </BoxWithTitle>
  );
};

export default BillDetailForm;
