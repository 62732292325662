import React from 'react';

import BoButton from 'facade/BoButton';
import { BoxWithTitle, Flex, LabelValue } from '@cvfm-front/commons-ui';
import { formatDatetime } from 'commons/Date';
import { Bill } from '@cvfm-front/tefps-types';
import { formatSwissPrice } from 'commons/Price';

interface BillDetailInformationProps {
  bill: Bill;
  setDialog: (e: boolean) => void;
}

const BillDetailInformation = ({
  bill,
  setDialog,
}: BillDetailInformationProps): JSX.Element => {
  const lastVersionIndex = bill.versions.length - 1;
  const lastVersion = bill.versions[lastVersionIndex];

  return (
    <BoxWithTitle title="Facture" subtitle="Informations générales">
      <Flex gap={64} flexDirection="column">
        <Flex gap={64} flexDirection="row" justifyContent="space-between">
          <LabelValue
            label="Identifiant"
            value={bill.id}
            flexDirection="column"
            width100
          />
          <Flex justifyContent="space-between" width100>
            <Flex gap={32}>
              <LabelValue
                label="Date"
                value={formatDatetime({
                  datetime: lastVersion.timestamp,
                  withTime: false,
                })}
                flexDirection="column"
              />
              <LabelValue
                label="Heure"
                value={formatDatetime({
                  datetime: lastVersion.timestamp,
                  withYear: false,
                  withDate: false,
                })}
                flexDirection="column"
              />
            </Flex>
            <LabelValue
              label="Montant"
              value={formatSwissPrice(lastVersion.price)}
              flexDirection="column"
            />
          </Flex>
        </Flex>

        <Flex gap={64} flexDirection="row" justifyContent="space-between">
          <BoxWithTitle title="Identité" border secondary width100>
            <Flex gap={32} flexDirection="column">
              <LabelValue
                label="NOM"
                value={lastVersion.offender.familyName || '-'}
                flexDirection="column"
              />
              <LabelValue
                label="PRENOM"
                value={lastVersion.offender.givenName || '-'}
                flexDirection="column"
              />
              <LabelValue
                label="ENTREPRISE"
                value={lastVersion.offender.companyName || '-'}
                flexDirection="column"
              />
              <LabelValue
                label="ADRESSE"
                value={
                  `${lastVersion.offender.address.streetName} ${lastVersion.offender.address.streetNumber}` ||
                  '-'
                }
                flexDirection="column"
              />
              <Flex gap={32} flexDirection="row">
                <LabelValue
                  label="CODE POSTAL"
                  value={lastVersion.offender.address.postalCode || '-'}
                  flexDirection="column"
                  width100
                />
                <LabelValue
                  label="VILLE"
                  value={lastVersion.offender.address.addressLocality || '-'}
                  flexDirection="column"
                  width100
                />
                <LabelValue
                  label="PAYS"
                  value={lastVersion.offender.address.addressCountry || '-'}
                  flexDirection="column"
                  width100
                />
              </Flex>
            </Flex>
          </BoxWithTitle>
          <BoxWithTitle title="Véhicule" border secondary width100>
            <Flex gap={32} flexDirection="column">
              <LabelValue
                label="PLAQUE D'IMMATRICULATION"
                value={bill.plate.plate}
                flexDirection="column"
              />
              <LabelValue
                label="MARQUE"
                value={bill.vehicle.brand || '-'}
                flexDirection="column"
              />
              <LabelValue
                label="MODÈLE"
                value={bill.vehicle.model || '-'}
                flexDirection="column"
              />
              <LabelValue
                label="PAYS D'IMMATRICULATION"
                value={bill.plate.plateCountry || '-'}
                flexDirection="column"
              />
            </Flex>
          </BoxWithTitle>
        </Flex>

        <LabelValue
          label="COMMENTAIRE"
          value={lastVersion.comment || '-'}
          flexDirection="column"
        />

        <BoButton
          primary
          label="EDITER LA FACTURE"
          onClick={() => setDialog(true)}
          style={{ alignSelf: 'flex-end', borderRadius: '8px' }}
          buttonStyle={{ borderRadius: '8px' }}
        />
      </Flex>
    </BoxWithTitle>
  );
};

export default BillDetailInformation;
