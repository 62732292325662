import { CSSProperties } from 'react';

import { DARK_LIGHT } from 'theme';

export const panelStyle: CSSProperties = {
  minHeight: '20em',
  display: 'block',
};

export const bodyWrapper: CSSProperties = {
  width: '100%',
  maxHeight: '5.5em',
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const bodyControlWrapper: CSSProperties = {
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const tableStyle: CSSProperties = {
  width: '100%',
};

export const headStyle: CSSProperties = {
  height: '2em',
  marginTop: '25px',
  fontWeight: 'bold',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const commonRowStyle: CSSProperties = {
  height: '1em',
};

export const rowStyle: CSSProperties = {
  ...commonRowStyle,
};

export const emptyRowStyle: CSSProperties = {
  height: '3em',
};

export const commonCellStyle: CSSProperties = {
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  wordBreak: 'normal',
  height: '2em',
};

export const lastCellStyle: CSSProperties = {
  width: '30%',
  ...commonCellStyle,
};

export const cellsStyle: CSSProperties = {
  width: '16.7%',
  ...commonCellStyle,
};

export const emptyCellsTable: CSSProperties = {
  fontWeight: 'bold',
  ...commonCellStyle,
};

export const darkMode: CSSProperties = {
  backgroundColor: DARK_LIGHT,
  ...rowStyle,
};
