import { $Shape } from 'utility-types';
import * as React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

import { AlertSearchFilterDTO } from 'api/planner/types';
import { fetchTeams } from 'api/cvfm-core-directory/team';
import { fetchMatchingAgents } from 'api/accounts';
import SidebarV2 from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Dates from 'commons/SidebarV2/Components/Dates';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import { CheckboxOption } from 'commons/SidebarV2/types';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { AgentIdAndNameDTO } from '@cvfm-front/tefps-types';

import { mapAlertActionToProps, mapAlertStoreToProps } from './ducks/mappers';

const { _t, _tg } = window.loadTranslations(__filename);

type ReduxActionProps = {
  // Store variables
  hits: number;

  // Actions
  alertFilters: AlertSearchFilterDTO;
};

type ReduxDispatchProps = {
  setAlertFilters: (filters: AlertSearchFilterDTO) => void;
  clearAlertFilters: () => void;
};

type OwnProps = {
  override?: $Shape<AlertSearchFilterDTO>;
  withFilters: boolean;
};

type AlertFilterProps = ReduxActionProps & ReduxDispatchProps & OwnProps;

type AlertFilterState = {
  teamOptions: CheckboxOption[];
  agentSearchOptions: Array<{ id: string; name: string }>;
};

class AlertFilter extends React.Component<AlertFilterProps, AlertFilterState> {
  constructor(props: AlertFilterProps) {
    super(props);
    this.state = { teamOptions: [], agentSearchOptions: [] };
  }

  componentDidMount(): void {
    void this.loadTeamOptions();
  }

  loadTeamOptions = async () => {
    const teamList = await fetchTeams();
    const teamOptions = teamList.map(el => ({
      value: el.teamId,
      label: el.name,
    }));
    this.setState({ teamOptions });
  };

  fetchAgentsAndGenerateOptions = async (
    agentFilter: string | null | undefined
  ) => {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const agentSearchOptions = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    this.setState({ agentSearchOptions });
  };

  render() {
    const {
      alertFilters,
      setAlertFilters,
      clearAlertFilters,
      override,
      hits,
      withFilters,
    } = this.props;
    const { teamOptions, agentSearchOptions } = this.state;

    if (!withFilters) {
      return <SidebarV2 />;
    }

    // Duplicate filters into a new object, we don't want to mutate redux object
    // This is to compare the redux object with the overrided one
    const filters: AlertSearchFilterDTO = {
      ...alertFilters,
      ...override,
    };

    // Apply the override to the redux store
    // the compare is needed to prevent a refresh infinite loop
    if (!isEqual(filters, alertFilters)) {
      setAlertFilters(filters);
    }

    const onChangeAgentFilter = (id: string, value: string) =>
      setAlertFilters({
        ...filters,
        agentIds: value === '' ? new Set<string>() : new Set([value]),
      });

    const onFilterChange = (key: string, value: unknown) =>
      setAlertFilters({ ...filters, [key]: value });

    const fields = [
      <Dates
        id="alertStartDate"
        key="alter-filter-startDate"
        title={_tg('field.date.beginning')}
        dates={filters.alertStartDate}
        onChange={onFilterChange}
      />,
      <Dates
        id="alertEndDate"
        key="alter-filter-endDate"
        title={_tg('field.date.end')}
        dates={filters.alertEndDate}
        onChange={onFilterChange}
      />,
      <Autocomplete
        id="agentId"
        key="alter-filter-agentId"
        title={_tg('field.agent.idOrName')}
        options={agentSearchOptions}
        onChange={onChangeAgentFilter}
        onAutocomplete={this.fetchAgentsAndGenerateOptions}
        search={Array.from(filters.agentIds)[0] || ''}
      />,
      <Checkboxes
        id="teamIds"
        key="alter-filter-teamIds"
        title={_t('element.field.teams')}
        options={teamOptions}
        filters={filters.teamIds}
        faceting={null}
        onChange={onFilterChange}
      />,
      <Checkboxes
        id="alertNatures"
        key="alter-filter-alertNatures"
        title={_t('element.field.nature')}
        options={[
          {
            value: 'PATH',
            label: _t('element.field.PATH'),
          },
          { value: 'INACTIVITY', label: _t('element.field.INACTIVITY') },
          { value: 'GPS_STATUS', label: _tg('field.ping.gpsStatus.status') },
        ]}
        filters={filters.alertNatures}
        faceting={null}
        onChange={onFilterChange}
      />,
      <Checkboxes
        id="alertStatuses"
        key="alter-filter-alertStatuses"
        title={_t('element.field.status')}
        options={[
          { value: 'OPENED', label: _t('element.field.OPENED') },
          { value: 'CLOSED', label: _t('element.field.CLOSED') },
        ]}
        filters={filters.alertStatuses}
        faceting={null}
        onChange={onFilterChange}
      />,
    ];

    // No need to display forced field inside filter bar
    const forcedKeys = Object.keys(override || {});
    const changeableFields = fields.filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      elem => !forcedKeys.includes(elem.props.id)
    );

    // Display only changeable fields
    return (
      <SidebarV2>
        <BarTitle resetFilters={clearAlertFilters} />
        <HitsCounter hits={hits} />
        {changeableFields}
      </SidebarV2>
    );
  }
}

export default connect<ReduxActionProps, ReduxDispatchProps, OwnProps>(
  mapAlertStoreToProps,
  mapAlertActionToProps
)(AlertFilter);
