import React, { CSSProperties, useState } from 'react';
import TextField from 'material-ui/TextField';
import { validate as uuidValidate } from 'uuid';

import BoButton from 'facade/BoButton';

const { _tg } = window.loadTranslations(__filename);

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: 20,
  marginRight: 20,
  marginTop: 30,
  marginBottom: 10,
  fontSize: 15,
};

const SEARCH_STYLE: CSSProperties = {
  marginLeft: 20,
};

type Props = {
  searchLapiByIdWithRebuild: (controlId: string) => void;
};

function ControlSearch({ searchLapiByIdWithRebuild }: Props) {
  const [controlId, setControlId] = useState('');

  function handleClick() {
    searchLapiByIdWithRebuild(controlId);
  }

  function handleChangeField(e: React.FormEvent<{}>, newValue: string) {
    setControlId(newValue);
  }

  return (
    <div>
      <div style={STYLE_HEADER}>
        <span>
          {_tg(
            'tefps.lapiReview.lapiSideBar.components.controlSearch.searchControl'
          )}
        </span>
      </div>
      <TextField
        id="lapiControlSearch"
        onChange={handleChangeField}
        placeholder={_tg(
          'tefps.lapiReview.lapiSideBar.components.controlSearch.controlId'
        )}
        value={controlId}
        errorText=""
        style={SEARCH_STYLE}
      />
      <BoButton
        label={_tg(
          'tefps.lapiReview.lapiSideBar.components.controlSearch.search'
        )}
        onClick={handleClick}
        fullWidth
        labelPosition="before"
        disabled={!uuidValidate(controlId)}
      />
    </div>
  );
}

export default ControlSearch;
