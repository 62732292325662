import React, { CSSProperties } from 'react';
import moment from 'moment';
import TextField from 'material-ui/TextField';

import { BKG_GREY } from 'theme';
import RemovableCriteria from 'commons/RemovableCriteria';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  marginRight: 10,
  textAlign: 'center',
};

const INLINE_STYLE: CSSProperties = {
  height: 24,
  border: `1px solid ${BKG_GREY}`,
  borderRadius: 5,
  lineHeight: '20px',
  width: 80,
};

const TIME_REGEX = /([01]\d|2[0-3]):([0-5]\d)$/;

const validateConsistency = (
  start: string,
  end: string,
  endOnNextDay: boolean | null | undefined
) => {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');
  if (endOnNextDay) {
    const todayStart = startTime.clone();
    const tomorrowEnd = endTime
      .clone()
      .add(1, 'day')
      .subtract(1, 's'); // enlever 1s permet de gérer le cas d'une période de 24h
    return Math.abs(todayStart.diff(tomorrowEnd, 'day')) < 1
      ? undefined
      : _tg('tefps.pricing.pricing.errors.timeIntervalMaxADay');
  }
  return startTime.isBefore(endTime)
    ? undefined
    : _tg('tefps.pricing.pricing.errors.periodNotConsistent');
};

const validateTimeField = (time: string) => {
  if (!time || !TIME_REGEX.test(time)) {
    return 'format hh:mm';
  }
  return undefined;
};

type ContentType = {
  from: string | undefined;
  to: string | undefined;
  idx?: number;
  errors: {
    [key: string]: string | null | undefined;
  };
  onFromChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) => void;
  onToChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) => void;
  disabled: boolean;
};

const Content = ({
  from,
  to,
  idx,
  errors,
  onFromChange,
  onToChange,
  disabled,
}: ContentType) => (
  <span>
    <span style={{ ...STYLE_TEXT, marginLeft: 10 }}>
      {_tg('field.date.from')}
    </span>
    <TextField
      name="from"
      underlineShow={false}
      inputStyle={STYLE_TEXT}
      value={from}
      onChange={onFromChange}
      type="time"
      style={INLINE_STYLE}
      data-idx={idx}
      errorText={errors.from}
      disabled={disabled}
    />
    <span style={{ ...STYLE_TEXT, marginLeft: 10 }}>
      {_tg('field.date.to')}
    </span>
    <TextField
      name="to"
      underlineShow={false}
      inputStyle={STYLE_TEXT}
      value={to}
      onChange={onToChange}
      type="time"
      style={INLINE_STYLE}
      data-idx={idx}
      errorText={errors.to}
      disabled={disabled}
    />
  </span>
);

type Props = {
  from: string;
  to: string;
  idx?: number;
  remove?: (event: any) => void;
  onChange?: Function;
  disabled: boolean;
  endOnNextDay?: boolean;
};

type State = {
  from: string | undefined;
  to: string | undefined;
  errors: {
    [key: string]: string | null | undefined;
  };
};

/**
 * A component to choose a time interval
 */
export default class TimeInterval extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { from: props.from, to: props.to, errors: {} };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { from, to, endOnNextDay } = nextProps;
    const errorConsistency = validateConsistency(from, to, endOnNextDay);
    this.setState({
      from: nextProps.from,
      to: nextProps.to,
      errors: { to: errorConsistency },
    });
  }

  onFromChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { onChange, to, endOnNextDay } = this.props;
    const from = newValue;
    const errorConsistency = validateConsistency(from, to, endOnNextDay);
    const errorFormat = validateTimeField(from);
    if (!errorFormat && !errorConsistency) {
      if (onChange) {
        onChange(from, to, event.target.dataset.idx);
      }
    } else {
      const error = errorFormat || errorConsistency;
      this.setState({ from, to, errors: { from: error } });
    }
  };

  onToChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { onChange, from, endOnNextDay } = this.props;
    const to = newValue;
    const errorConsistency = validateConsistency(from, to, endOnNextDay);
    const errorFormat = validateTimeField(to);
    if (!errorFormat && !errorConsistency) {
      if (onChange) {
        onChange(from, to, event.target.dataset.idx);
      }
    } else {
      const error = errorFormat || errorConsistency;
      this.setState({ from, to, errors: { to: error } });
    }
  };

  render(): JSX.Element {
    const { idx, remove, disabled } = this.props;
    const { from, to, errors } = this.state;

    if (!disabled && remove) {
      return (
        <RemovableCriteria deleteFunc={remove} index={idx}>
          <Content
            from={from}
            to={to}
            idx={idx}
            errors={errors}
            onFromChange={this.onFromChange}
            onToChange={this.onToChange}
            disabled={false}
          />
        </RemovableCriteria>
      );
    }

    return (
      <Content
        from={from}
        to={to}
        idx={idx}
        errors={errors}
        onFromChange={this.onFromChange}
        onToChange={this.onToChange}
        disabled={disabled}
      />
    );
  }
}
