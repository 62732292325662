import * as React from 'react';
import range from 'lodash.range';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

const { _tg } = window.loadTranslations(__filename);

type DayMonthPickerProps = {
  disabled: boolean;
  dayOfMonth: number;
  month: number;
  onChange: (day: number, month: number) => void;
};

class DayMonthPicker extends React.Component<DayMonthPickerProps> {
  MONTH_LIST: Array<{
    name: string;
    shortName: string;
    id: number;
    maxDaysNb: number;
  }> = [
    {
      name: _tg('field.date.monthList.january'),
      shortName: '01',
      id: 1,
      maxDaysNb: 31,
    },
    {
      name: _tg('field.date.monthList.february'),
      shortName: '02',
      id: 2,
      maxDaysNb: 29,
    },
    {
      name: _tg('field.date.monthList.mars'),
      shortName: '03',
      id: 3,
      maxDaysNb: 31,
    },
    {
      name: _tg('field.date.monthList.april'),
      shortName: '04',
      id: 4,
      maxDaysNb: 30,
    },
    {
      name: _tg('field.date.monthList.may'),
      shortName: '05',
      id: 5,
      maxDaysNb: 31,
    },
    {
      name: _tg('field.date.monthList.june'),
      shortName: '06',
      id: 6,
      maxDaysNb: 30,
    },
    {
      name: _tg('field.date.monthList.july'),
      shortName: '07',
      id: 7,
      maxDaysNb: 31,
    },
    {
      name: _tg('field.date.monthList.august'),
      shortName: '08',
      id: 8,
      maxDaysNb: 31,
    },
    {
      name: _tg('field.date.monthList.september'),
      shortName: '09',
      id: 9,
      maxDaysNb: 30,
    },
    {
      name: _tg('field.date.monthList.october'),
      shortName: '10',
      id: 10,
      maxDaysNb: 31,
    },
    {
      name: _tg('field.date.monthList.november'),
      shortName: '11',
      id: 11,
      maxDaysNb: 30,
    },
    {
      name: _tg('field.date.monthList.december'),
      shortName: '12',
      id: 12,
      maxDaysNb: 31,
    },
  ];

  findMonthData = (
    monthId: number
  ): {
    name: string;
    shortName: string;
    id: number;
    maxDaysNb: number;
  } => {
    return this.MONTH_LIST.find(m => m.id === monthId) || this.MONTH_LIST[0];
  };

  onChangeDay = (
    _event: React.ChangeEvent<HTMLElement>,
    _index: number,
    newDay: number
  ): void => {
    const { onChange, month } = this.props;
    onChange(newDay, month);
  };
  onChangeMonth = (
    _event: React.ChangeEvent<HTMLElement>,
    _index: number,
    newMonth: number
  ): void => {
    const { onChange, dayOfMonth } = this.props;
    const maxDaysInMonth = this.findMonthData(newMonth).maxDaysNb;
    const day = dayOfMonth > maxDaysInMonth ? maxDaysInMonth : dayOfMonth;
    onChange(day, newMonth);
  };

  render(): JSX.Element {
    const { dayOfMonth, month, disabled } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <SelectField
          value={dayOfMonth}
          autoWidth
          onChange={this.onChangeDay}
          style={{ width: 56 }}
          disabled={disabled}
          menuItemStyle={{ textAlign: 'center' }}
        >
          {range(1, this.findMonthData(month).maxDaysNb + 1).map(day => (
            <MenuItem key={day} value={day} primaryText={day} />
          ))}
        </SelectField>
        <SelectField
          value={month}
          autoWidth
          onChange={this.onChangeMonth}
          style={{ width: 56 }}
          disabled={disabled}
          menuItemStyle={{ textAlign: 'center' }}
        >
          {this.MONTH_LIST.map((
            { name, shortName, id } // On affiche le mois en numérique pour la compacité
          ) => (
            <MenuItem
              title={name}
              key={id}
              value={id}
              primaryText={shortName}
            />
          ))}
        </SelectField>
      </div>
    );
  }
}

export default DayMonthPicker;
