import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router';

import BoButton from 'facade/BoButton';
import { BKG_GREYBLUE, BKG_LIGHT, BKG_ORANGE, ICON_GREEN } from 'theme';
import Panel from 'tefps/LapiReview/components/Panel';
import NFPS from 'tefps/LapiReview/Action/NFPS';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { LapiComplexDTO, LapiReviewDTO } from 'api/lapiReview/types';
import { patchLapiReview } from 'api/lapiReview';
import FPS from 'tefps/LapiReview/Action/FPS';
import { FnmsCityFpsPriceDTO, LightLapiZone } from 'api/pricing/types';
import { VehicleCategory, ZoningDTO } from '@cvfm-front/tefps-types';
import { PatchObject } from '@cvfm-front/commons-types';

import Complex from './Complex';

const STYLE_BUTTON: CSSProperties = { width: '150px', margin: '10px' };

const STYLE_BUTTON_LABEL: CSSProperties = { padding: 0 };

const STYLE_BUTTON_EXPANDED: CSSProperties = {
  ...STYLE_BUTTON,
  height: 'auto',
};

type Props = {
  lapiReview: LapiReviewDTO;
  complexCaseEnabled: boolean;
  tagsEnabled: boolean;
  fetchNew: () => void;
  clearHistoryAndHandleTimer: () => void;
  plateImages: Array<File>;
  contextImages: Array<File>;
  selectedImages: Set<number>;
  brandProvided: boolean;
  fpsPrice: FnmsCityFpsPriceDTO | null | undefined;
  controlZone: LightLapiZone | undefined;
  lockTreatment: boolean;
  isLapiFetchedFromControlList: boolean;
  qualityControlMode: boolean;
  zoning?: ZoningDTO | null | undefined;
};

const Action = ({
  lapiReview,
  complexCaseEnabled,
  tagsEnabled,
  fetchNew,
  plateImages,
  contextImages,
  selectedImages,
  brandProvided,
  fpsPrice,
  controlZone,
  lockTreatment,
  isLapiFetchedFromControlList,
  clearHistoryAndHandleTimer,
  qualityControlMode,
  zoning,
}: Props): JSX.Element => {
  const setMessage = useSnackbar();
  const history = useHistory();

  const handleIsComplex = async () => {
    try {
      const patch: PatchObject<LapiComplexDTO> = {
        op: 'replace',
        path: '/isComplex',
        value: {
          vehicleType: lapiReview.vehicleCategory
            ? lapiReview.vehicleCategory
            : null,
          controlId: lapiReview.controlId,
        },
      };

      await patchLapiReview(lapiReview.controlId, [patch]);
      setMessage('Marqué comme cas complexe');
      fetchNew();
    } catch (e) {
      setMessage(e.json ? e.json.message : e);
    }
  };

  const handleReviewReasonValidation = async (): Promise<void> => {
    try {
      const patch = [
        {
          path: '/validateReviewReason',
          op: 'replace',
          value: true,
        },
      ];
      await patchLapiReview(lapiReview.controlId, patch);
      setMessage('Validé');
      fetchNew();
    } catch (e) {
      setMessage(e.json ? e.json.message : e);
    }
  };

  function handleNext() {
    clearHistoryAndHandleTimer();
  }

  function goBack() {
    history.goBack();
  }

  const getVehicleTypeLabel = (lapiReview: LapiReviewDTO): string => {
    if (lapiReview.reviewReason === 'vehicleType') {
      if (lapiReview.presumedVehicleType) {
        return VehicleCategory[lapiReview.presumedVehicleType];
      }
      if (lapiReview.relavantParkingRightPricingCategory) {
        return VehicleCategory[lapiReview.relavantParkingRightPricingCategory];
      }
    }
    return '';
  };

  const displayOkButton = (): boolean => {
    if (qualityControlMode) {
      return false;
    }

    if (!lapiReview.reviewReason) {
      return false;
    }

    if (lapiReview.controlStatus !== 'WAITING_REVIEW') {
      return false;
    }

    return true;
  };

  const unlockFps = (): boolean => {
    if (selectedImages.size === 0) {
      return false;
    }

    if (!brandProvided) {
      return false;
    }

    if (!fpsPrice || fpsPrice.finePrice <= 0) {
      return false;
    }

    if (
      lapiReview.controlStatus === 'WAITING_REVIEW' &&
      lapiReview.vehicleCategory &&
      lapiReview.presumedVehicleType &&
      lapiReview.vehicleCategory === lapiReview.presumedVehicleType
    ) {
      return false;
    }

    return true;
  };

  return (
    <Panel name="Action">
      {(!lockTreatment || qualityControlMode) && (
        <>
          {displayOkButton() && (
            <BoButton
              label={`OK vérification ${getVehicleTypeLabel(lapiReview)}`}
              backgroundColor={ICON_GREEN}
              labelColor={BKG_LIGHT}
              style={STYLE_BUTTON_EXPANDED}
              labelStyle={STYLE_BUTTON_LABEL}
              onClick={handleReviewReasonValidation}
              disabled={
                !!(
                  lapiReview.vehicleCategory &&
                  lapiReview.presumedVehicleType &&
                  lapiReview.vehicleCategory !== lapiReview.presumedVehicleType
                )
              }
            />
          )}
          <FPS
            lapiReview={lapiReview}
            fetchNew={fetchNew}
            contextImages={contextImages}
            selectedImages={selectedImages}
            plateImages={plateImages}
            unlock={unlockFps()}
            fpsPrice={fpsPrice}
            controlZone={controlZone}
            qualityControlMode={qualityControlMode}
            zoning={zoning}
          />
          <NFPS
            lapiReview={lapiReview}
            fetchNew={fetchNew}
            qualityControlMode={qualityControlMode}
            contextImages={contextImages}
          />
          {complexCaseEnabled &&
            !qualityControlMode &&
            (tagsEnabled ? (
              <Complex lapiReview={lapiReview} fetchNew={fetchNew} />
            ) : (
              <BoButton
                label="CAS COMPLEXE"
                backgroundColor={BKG_ORANGE}
                labelColor={BKG_LIGHT}
                style={STYLE_BUTTON}
                onClick={handleIsComplex}
              />
            ))}
        </>
      )}
      {lockTreatment &&
        !isLapiFetchedFromControlList &&
        !qualityControlMode && (
          <BoButton
            label="Reprendre la revue"
            backgroundColor={BKG_GREYBLUE}
            labelColor={BKG_LIGHT}
            style={STYLE_BUTTON_EXPANDED}
            onClick={handleNext}
          />
        )}
      {lockTreatment && isLapiFetchedFromControlList && !qualityControlMode && (
        <BoButton
          label="Retour"
          backgroundColor={BKG_GREYBLUE}
          labelColor={BKG_LIGHT}
          style={STYLE_BUTTON}
          onClick={goBack}
        />
      )}
    </Panel>
  );
};

export default Action;
