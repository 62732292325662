import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { isEqual } from 'lodash';

import * as INNER_THEME from 'tefps/LapiReview/TicketInformation/theme';
import DateComponent from 'commons/Date';
import Price from 'commons/Price';
import { BKG_LIGHT_BLUE, BKG_PINK, TXT_GREEN_RGAA, TXT_RED_RGGAA } from 'theme';
import { FnmsCityFpsPriceDTO, LightLapiZone } from 'api/pricing/types';
import { ControlParkingRightDTO } from 'api/tickets/types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  parkingRights?: Array<ControlParkingRightDTO> | null | undefined;
  fpsPrice: FnmsCityFpsPriceDTO | null | undefined;
  zonesMap: Map<string, LightLapiZone>;
  relevantParkingRight?: ControlParkingRightDTO;
  isForControl: boolean;
  controlZone: string;
  controlDate: string;
  controlPricingCategory: string | undefined;
  darkMode: boolean;
};

const TicketsRow = ({
  parkingRights,
  fpsPrice,
  zonesMap,
  relevantParkingRight,
  isForControl,
  controlDate,
  controlZone,
  controlPricingCategory,
  darkMode,
}: Props) => {
  function highlightUsefullTicket(ticket: ControlParkingRightDTO) {
    if (ticket.type === 'FINE')
      return { ...INNER_THEME.rowStyle, color: BKG_PINK };

    const highLight = fpsPrice?.significantRights?.some(
      right =>
        right.endDatetime === ticket.endDatetime &&
        right.startDatetime === ticket.startDatetime &&
        right.zoneId === ticket.zoneId
    );

    return highLight
      ? { ...INNER_THEME.rowStyle, color: BKG_LIGHT_BLUE }
      : INNER_THEME.rowStyle;
  }

  function highLightRelevantTicket(ticket: ControlParkingRightDTO) {
    return isEqual(ticket, relevantParkingRight)
      ? { ...INNER_THEME.rowStyle, color: TXT_GREEN_RGAA }
      : INNER_THEME.rowStyle;
  }

  function returnCellWithInvalidStyleTheme(bool: boolean) {
    return bool
      ? { ...INNER_THEME.cellsStyle, color: TXT_RED_RGGAA }
      : INNER_THEME.cellsStyle;
  }

  function highLightInvalidStartDateTime(startdatime: string) {
    const dateTime = new Date(controlDate).getTime();
    const startTime = new Date(startdatime).getTime();

    return returnCellWithInvalidStyleTheme(dateTime < startTime);
  }

  function highLightInvalidEndDateTime(endDatetime: string) {
    const dateTime = new Date(controlDate).getTime();
    const endTime = new Date(endDatetime).getTime();

    return returnCellWithInvalidStyleTheme(dateTime > endTime);
  }

  function highLightInvalidZone(zoneId: string) {
    return returnCellWithInvalidStyleTheme(zoneId !== controlZone);
  }

  function highLightInvalidPricingCategory(
    princingCategory: string | null | undefined
  ) {
    if (princingCategory || controlPricingCategory) {
      return INNER_THEME.cellsStyle;
    }
    return returnCellWithInvalidStyleTheme(
      princingCategory !== controlPricingCategory
    );
  }

  const sortedParkingRights:
    | Array<ControlParkingRightDTO>
    | null
    | undefined = useMemo(
    () =>
      parkingRights?.sort(
        (a, b) =>
          new Date(a.startDatetime).getTime() -
          new Date(b.startDatetime).getTime()
      ),
    [parkingRights]
  );
  return (
    <div
      style={
        isForControl ? INNER_THEME.bodyControlWrapper : INNER_THEME.bodyWrapper
      }
    >
      {!!sortedParkingRights && sortedParkingRights.length > 0 ? (
        <Table style={INNER_THEME.tableStyle}>
          <TableHeader
            adjustForCheckbox={false}
            enableSelectAll={false}
            displaySelectAll={false}
          >
            <TableRow selectable={false} style={INNER_THEME.commonRowStyle}>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.start')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.end')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.area')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.category')}
              </TableRowColumn>
              <TableRowColumn style={INNER_THEME.cellsStyle}>
                {_tg('commons.amount')}
              </TableRowColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} style={INNER_THEME.bodyWrapper}>
            {sortedParkingRights.map((ticket, index) => {
              return (
                <TableRow
                  key={index}
                  style={
                    darkMode
                      ? INNER_THEME.darkMode
                      : INNER_THEME.rowStyle &&
                        highlightUsefullTicket(ticket) &&
                        highLightRelevantTicket(ticket)
                  }
                >
                  <TableRowColumn
                    style={highLightInvalidStartDateTime(ticket.startDatetime)}
                  >
                    <DateComponent
                      datetime={ticket.startDatetime}
                      withYear
                      withAlternativeSeparator
                    />
                  </TableRowColumn>
                  <TableRowColumn
                    style={highLightInvalidEndDateTime(ticket.endDatetime)}
                  >
                    <DateComponent
                      datetime={ticket.endDatetime}
                      withYear
                      withAlternativeSeparator
                    />
                  </TableRowColumn>
                  <TableRowColumn style={highLightInvalidZone(ticket.zoneId)}>
                    {zonesMap.get(ticket.zoneId)?.name}
                  </TableRowColumn>
                  <TableRowColumn
                    style={highLightInvalidPricingCategory(
                      ticket.pricingCategory
                    )}
                  >
                    {ticket.pricingCategory || 'Visiteur'}
                  </TableRowColumn>
                  <TableRowColumn style={INNER_THEME.cellsStyle}>
                    <Price price={ticket.rightPrice} />
                  </TableRowColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Table style={INNER_THEME.tableStyle}>
          <TableBody displayRowCheckbox={false} style={INNER_THEME.bodyWrapper}>
            <TableRow selectable={false} style={INNER_THEME.emptyRowStyle}>
              <TableRowColumn style={INNER_THEME.emptyCellsTable}>
                {_tg('tefps.lapiReview.ticketInformation.noTicket')}
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default TicketsRow;
