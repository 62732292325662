import React from 'react';

import Exporter from 'commons/Exporter';
import { AlertFiltersParam } from 'api/planner/types';

const { _t } = window.loadTranslations(__filename);

export const translateExportCols = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  { key: 'alertId', label: _t('table.alertNumber'), checked: true },
  {
    key: 'nature',
    label: _t('table.alertNature'),
    checked: true,
  },
  {
    key: 'startDateTime',
    label: _t('table.alertOpeningDate'),
    checked: true,
  },
  {
    key: 'endDateTime',
    label: _t('table.alertClosingDate'),
    checked: true,
  },
  { key: 'status', label: _t('table.alertStatus') },
  {
    key: 'agentId',
    label: _t('table.agentId'),
  },
  { key: 'comments', label: _t('table.alertComments') },
  {
    key: 'creationDateTime',
    label: _t('table.alertCreationDate'),
  },
];

type AlertExporterProps = {
  filters: PagedQuery<AlertFiltersParam>;
  exportRequest: (
    filters: Record<string, any>,
    columns: { columns: any }
  ) => Promise<any>;
  disabled: boolean;
};

const AlertExporter = ({
  filters,
  exportRequest,
  disabled,
}: AlertExporterProps): JSX.Element => {
  return (
    <Exporter
      disabled={disabled}
      columns={translateExportCols()}
      type={_t('element.alerts')}
      filters={filters}
      fileExport={exportRequest}
    />
  );
};

export default AlertExporter;
