import { Bill, Person } from '@cvfm-front/tefps-types';
import { BillVersionPatchDTO } from 'api/cvfm-parking/type';

const isOffenderDifferent = (offender1: Person, offender2: Person): boolean => {
  return (
    offender1.email !== offender2.email ||
    offender1.gender !== offender2.gender ||
    offender1.givenName !== offender2.givenName ||
    offender1.familyName !== offender2.familyName ||
    offender1.maidenName !== offender2.maidenName ||
    offender1.companyName !== offender2.companyName ||
    offender1.address.postalCode !== offender2.address.postalCode ||
    offender1.address.streetName !== offender2.address.streetName ||
    offender1.address.streetType !== offender2.address.streetType ||
    offender1.address.addressRegion !== offender2.address.addressRegion ||
    offender1.address.addressCountry !== offender2.address.addressCountry ||
    offender1.address.addressLocality !== offender2.address.addressLocality ||
    offender1.address.addressSubRegion !== offender2.address.addressSubRegion ||
    offender1.honorificPrefix !== offender2.honorificPrefix
  );
};

const BillVersionPatchesGenerator = (
  bill: Bill,
  newBill: Bill
): BillVersionPatchDTO[] => {
  const billLastVersion = bill.versions[bill.versions.length - 1];
  const newBillLastVersion = newBill.versions[newBill.versions.length - 1];

  const patches: BillVersionPatchDTO[] = [];

  if (
    isOffenderDifferent(billLastVersion.offender, newBillLastVersion.offender)
  ) {
    patches.push({
      op: 'replace',
      path: '/person',
      value: newBillLastVersion.offender,
    });
  }

  if (billLastVersion.price !== newBillLastVersion.price) {
    patches.push({
      op: 'add',
      path: '/price',
      value: newBillLastVersion.price,
    });
  }

  if (billLastVersion.comment !== newBillLastVersion.comment) {
    patches.push({
      op: 'replace',
      path: '/comment',
      value: newBillLastVersion.comment,
    });
  }

  return patches;
};

export default BillVersionPatchesGenerator;
