import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Dialog } from 'material-ui';

import BoButton from 'facade/BoButton';
import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { Flex, Title } from '@cvfm-front/commons-ui';

const { _tg } = window.loadTranslations(__filename);

const ParkingSpaceImportModal = () => {
  /*
   * States
   */
  const [isOpen, setIsOpen] = useState(false);

  /*
   * Watchers
   */
  const isUploading = useWatcher(
    services.parkingSpaceApi.watchIsUploading,
    false
  );
  const hasError = useWatcher(
    services.parkingSpaceApi.watchHasUploadError,
    false
  );

  /*
   * Callbacks
   */
  const handleOnClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  /*
   * Effects
   */
  useEffect(() => {
    if (isUploading === true) {
      setIsOpen(true);
    }
  }, [isUploading]);

  /*
   * Actions
   */
  const actions = [
    <BoButton
      key="close"
      secondary
      label={_tg('close')}
      onClick={handleOnClose}
      disabled={isUploading}
    />,
  ];

  return (
    <Dialog open={isOpen} actions={actions}>
      {isUploading && (
        <Flex flexDirection="column" gap={8}>
          <Title>{_tg('parkingSpaceImportInProgress')}</Title>
          <p>{_tg('thisOperationCanTakeMinutes')}</p>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </Flex>
      )}
      {!isUploading && !hasError && <Title>{_tg('importSuccess')}</Title>}
      {!isUploading && hasError && <Title>{_tg('importFail')}</Title>}
    </Dialog>
  );
};

export default ParkingSpaceImportModal;
