import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from 'material-ui';
import { useParams } from 'react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Paper from 'material-ui/Paper';
import _cloneDeep from 'lodash.clonedeep';

import { InternalAgent } from 'api/auth/types';
import { getApiState, InternalApiState } from 'api/duck';
import {
  BrandModel,
  LapiReviewImage,
  PreloadLapiReviewDTO,
  QualityControlFilterDTO,
  VehicleBrandDTO,
} from 'api/lapiReview/types';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import {
  fetchBrandAndModelVehiclesList,
  fetchQualityControl,
  findControlByIdWithRebuild,
  findLapiReviewByControlId,
  patchLapiReview,
} from 'api/lapiReview';
import { replaceControlImage } from 'tefps/LapiReview/ContextImages/utils';
import FlexCenter from 'commons/FlexCenter';
import ErrorPage from 'tefps/LapiReview/components/ErrorPage';
import { findSubscriptionPlansDTO } from 'api/cvfm-core-subscription/subscriptionPlanPublic';
import { fetchZoning } from 'api/pricing';
import { Address } from 'api/commonTypes';
import {
  DARK,
  darkTheme,
  lightTheme,
  STORAGE_LAPI_DARK_MODE,
  STYLE_CONTENT,
} from 'tefps/LapiReview/theme';
import { FETCH_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { FnmsCityFpsPriceDTO, LightLapiZone } from 'api/pricing/types';
import { getConfigState } from 'config/duck';
import {
  LapiReviewConfigurationDTO,
  QualityControlStatistics,
} from 'api/lapiReviewConfiguration/types';
import services from 'commons/services';

import {
  fetchLapiReviewFpsPrice,
  fetchLapiReviewPreload,
  fetchLapiReviewPreloadFromLapiReview,
} from './lapiReviewPreloadFetcher';
import LapiReviewPreloadQueue from './lapiReviewPreloadQueue';
import View from './view';
import LapiReviewTimeoutModal from './lapiReviewTimeoutModal';
import { initialQualityFilter } from './helpers';
import LapiReviewErrorModal from './LapiReviewErrorModal';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  userInfo: InternalAgent;
  isSubscribersEnabled: boolean;
  qualityControlMode: boolean;
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
};

const LapiReview = ({
  userInfo,
  isSubscribersEnabled,
  lapiReviewConfigurationDTO,
  qualityControlMode = false,
}: Props) => {
  // No user info should not happen: blank page
  if (!userInfo) {
    return null;
  }

  /*
   * View State
   */
  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.getItem(STORAGE_LAPI_DARK_MODE) === 'true' || false
  );
  const setMessage = useSnackbar();
  // Init controlId provided by url params if any
  const [controlId, setControlId] = useState<string | undefined | null>(
    useParams<{ controlId: string }>().controlId
  );
  const [controlRebuild, setControlRebuild] = useState(
    controlId !== null && controlId !== undefined
  );
  const [selectedImages, setSelectedImages] = useState<Set<number>>(new Set());
  // The view shows this PreloadLapiReviewDTO, if any
  const [
    presentedLapi,
    setPresentedLapi,
  ] = useState<PreloadLapiReviewDTO | null>(null);
  // One boolean for each mode
  const [modeQualityControl, setModeQualityControl] = useState(
    qualityControlMode
  );
  const [modeHistory, setModeHistory] = useState(typeof controlId === 'string');
  const [modeReview, setModeReview] = useState(
    !modeQualityControl && !modeHistory
  );
  // current preload service
  const [
    preLoadQueue,
    setPreloadQueue,
  ] = useState<LapiReviewPreloadQueue | null>(null);
  // Boolean to keep track of timeout
  const [reviewHasTimedOut, setReviewHasTimedOut] = useState(false);

  /*
   * Configuration section
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [reviewLoading, setReviewLoading] = useState<boolean>(true);
  const [queueEnded, setQueueEnded] = useState<boolean>(false);

  const [zonesMap, setZonesMap] = useState(new Map<string, LightLapiZone>());
  const [zonesFetched, setZonesFetched] = useState<boolean>(false);
  const [vehiclesList, setVehiclesList] = useState<Array<VehicleBrandDTO>>([]);
  const [brandsFetched, setBrandsFetched] = useState<boolean>(false);
  const [brandModelsMap, setBrandModelsMap] = useState(
    new Map<string, Array<BrandModel>>()
  );
  const [subscriptionPlanMap, setSubscriptionPlanMap] = useState<
    Map<string, string>
  >();
  const [subscriptionPlanFetched, setSubscriptionPlanMapFetched] = useState(
    false
  );
  const preloadLen = lapiReviewConfigurationDTO.fetchStackLen;

  function setPresentedLapiAndUpdateMap(
    newPresentedLapi?: PreloadLapiReviewDTO,
    keepSelectedImages?: boolean
  ) {
    if (newPresentedLapi) {
      services.lapiReview.setCurrentLapiReview({
        ...newPresentedLapi.lapiReview,
      });
      setPresentedLapi(newPresentedLapi);
      if (!keepSelectedImages) {
        // Si au moins une image est de type CONTEXT_IMPROVED on est en mode contexte enrichit.
        // On ne sélectionne alors aucune image
        if (
          !newPresentedLapi.contextImages.some(f => f.type === 'CONTEXT_HIK')
        ) {
          setSelectedImages(
            new Set(
              newPresentedLapi.contextImages
                .filter(
                  f => f.type === 'CONTEXT' || f.type === 'CONTEXT_IMPROVED'
                )
                .map((_, index) => index)
            )
          );
        } else {
          setSelectedImages(new Set());
        }
      }
    }
  }

  function selectImage(index: number, checked: boolean): void {
    const images = _cloneDeep(selectedImages);
    if (checked === true) {
      images.add(index);
    } else if (images.has(index)) {
      images.delete(index);
    }
    setSelectedImages(images);
  }

  async function fetchSubscriptionPlans(): Promise<void> {
    try {
      const list = await findSubscriptionPlansDTO();
      const map = new Map();
      list.forEach(value => {
        map.set(value.subscriptionPlanId, value.name);
      });
      setSubscriptionPlanMap(map);
      setSubscriptionPlanMapFetched(true);
    } catch (e) {
      setMessage(_tg('feedback.error.fetchSubscription'));
    }
  }

  async function fetchZonesNames(): Promise<void> {
    try {
      const zoning = await fetchZoning(FETCH_ZONING_CONFIG);
      zoning.zones.forEach(zone =>
        zonesMap.set(zone.id, {
          name: zone.name,
          alias: zone.alias,
          virtual: zone.virtual,
        })
      );
      setZonesMap(zonesMap);
      setZonesFetched(true);
    } catch (e) {
      setMessage('Erreur lors de la récupération des zones');
    }
  }

  async function fetchVehiclesList(): Promise<void> {
    try {
      const brands = await fetchBrandAndModelVehiclesList();
      setVehiclesList(brands);
      brands.forEach(brand => brandModelsMap.set(brand.brand, brand.models));
      setBrandModelsMap(brandModelsMap);
      setBrandsFetched(true);
    } catch (e) {
      setMessage('Impossible de récupérer la liste des véhicules');
    }
  }

  /*
   * Review Mode section
   */
  function reviewFetcher(): Promise<PreloadLapiReviewDTO> {
    return fetchLapiReviewPreload();
  }

  const reviewPreloadQueue = new LapiReviewPreloadQueue(
    'Review Mode',
    reviewFetcher,
    preloadLen,
    true,
    true,
    true
  );

  function onPresentReviewSubscription(
    reviewToPresent: PreloadLapiReviewDTO
  ): void {
    setPresentedLapiAndUpdateMap(reviewToPresent);
    setReviewLoading(false);
    setQueueEnded(false);
  }

  function onNoAvailableReviewSubscription(): void {
    setPresentedLapiAndUpdateMap();
    setReviewLoading(false);
    setQueueEnded(true);
  }

  function onPresentedReviewTimeoutSubscription(): void {
    reviewPreloadQueue.preloadStop();
    setReviewHasTimedOut(true);
  }

  function onPresentedReviewTimeoutResume(): void {
    setPresentedLapiAndUpdateMap();
    setReviewHasTimedOut(false);
    if (preLoadQueue) {
      setReviewLoading(true);
      preLoadQueue.preloadClear();
      void preLoadQueue.preloadStart();
    }
  }

  // Register review callbacks defined above
  reviewPreloadQueue.onPresentReview(onPresentReviewSubscription);
  reviewPreloadQueue.onNoAvailableReview(onNoAvailableReviewSubscription);
  reviewPreloadQueue.onPresentedReviewTimeout(
    onPresentedReviewTimeoutSubscription
  );

  /*
   * Quality Mode section
   */
  const [qualityControlFilter, setQualityControlFilter] = useState<
    QualityControlFilterDTO
  >(initialQualityFilter());
  const [qualityStatistics, setQualityStatistics] = useState<
    QualityControlStatistics
  >({
    totalMonthVerifications: 0,
    cityProgressPercentage: 0,
    averageVerificationsPerReviewAgent: 0,
    verificationsOnFilteredAgent: 0,
  });

  function handleQualityControlStatistics(
    totalMonthVerifications: number,
    cityProgressPercentage: number,
    averageVerificationsPerReviewAgent: number,
    totalVerificationOnCurrentAgent: number,
    isInitialisation: boolean
  ) {
    if (qualityStatistics.totalMonthVerifications < totalMonthVerifications) {
      qualityStatistics.totalMonthVerifications = totalMonthVerifications;
    } else if (!isInitialisation) {
      qualityStatistics.totalMonthVerifications += 1;
    }
    if (qualityStatistics.cityProgressPercentage < cityProgressPercentage) {
      qualityStatistics.cityProgressPercentage = cityProgressPercentage;
    }
    if (
      qualityStatistics.verificationsOnFilteredAgent <
      totalVerificationOnCurrentAgent
    ) {
      qualityStatistics.verificationsOnFilteredAgent = totalVerificationOnCurrentAgent;
    } else if (!isInitialisation) {
      qualityStatistics.verificationsOnFilteredAgent += 1;
    }
    if (
      qualityStatistics.averageVerificationsPerReviewAgent <
      averageVerificationsPerReviewAgent
    ) {
      qualityStatistics.averageVerificationsPerReviewAgent = averageVerificationsPerReviewAgent;
    }
    setQualityStatistics(qualityStatistics);
  }

  function qualityFetcher(): Promise<PreloadLapiReviewDTO> {
    return new Promise((resolve, reject) => {
      const qualityReviewPromise = fetchQualityControl(qualityControlFilter);
      qualityReviewPromise
        .then(qualityReview => {
          handleQualityControlStatistics(
            qualityReview.totalMonthVerifications,
            qualityReview.cityProgressPercentage,
            qualityReview.averageVerificationsPerReviewAgent,
            qualityReview.totalVerificationOnCurrentAgent,
            false
          );
          return fetchLapiReviewPreloadFromLapiReview(
            qualityReview.nextControlToSupervise
          );
        })
        .then(preloadedQualityReview => {
          resolve(preloadedQualityReview);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  const qualityPreloadQueue = new LapiReviewPreloadQueue(
    'Quality Mode',
    qualityFetcher,
    1,
    false,
    false,
    false
  );

  function onPresentQualitySubscription(
    reviewToPresent: PreloadLapiReviewDTO
  ): void {
    setPresentedLapiAndUpdateMap(reviewToPresent);
    setReviewLoading(false);
    setQueueEnded(false);
  }

  function onNoAvailableQualitySubscription(): void {
    setPresentedLapiAndUpdateMap();
    setReviewLoading(false);
    setQueueEnded(true);
  }

  function handleQualityControlAgentFilter(agentId: string | undefined) {
    setQualityStatistics(statistics => {
      return {
        ...statistics,
        verificationsOnFilteredAgent: 0,
      };
    });
    setQualityControlFilter(filters => {
      return {
        ...filters,
        agentId,
      };
    });
  }

  // Register quality callbacks defined above
  qualityPreloadQueue.onPresentReview(onPresentQualitySubscription);
  qualityPreloadQueue.onNoAvailableReview(onNoAvailableQualitySubscription);

  /*
   * History Section
   */
  function handleSearchByIdErrors(err: any) {
    if (err.json.error === 'GenericBadRequest') {
      setMessage(
        `Le controle demandé est actuellement consulté par l'agent ${err.json.message}`
      );
    } else if (err.json.error === 'NonexistentEntityException') {
      setMessage(`Ce contrôle n'existe pas`);
      setModeHistory(false);
      setModeReview(!qualityControlMode);
    } else if (err.json.error === 'UnauthorizedOperation') {
      setMessage(`Le contrôle recherché n'est pas un contrôle LAPI`);
    } else {
      setMessage(`Erreur inconnue`);
    }
  }

  function historyFetcher(): Promise<PreloadLapiReviewDTO> {
    return new Promise((resolve, reject) => {
      let historyReviewPromise;
      if (controlRebuild && controlId) {
        historyReviewPromise = findControlByIdWithRebuild(controlId);
      } else if (controlId) {
        historyReviewPromise = findLapiReviewByControlId(controlId);
      } else {
        reject("L'historique n'est pas disponible sans controlId");
        return;
      }
      historyReviewPromise
        .then(historyReview => {
          return fetchLapiReviewPreloadFromLapiReview(historyReview);
        })
        .then(historyReviewPreloaded => {
          resolve(historyReviewPreloaded);
        })
        .catch(error => {
          handleSearchByIdErrors(error);
          reject(error);
        });
    });
  }

  const historyPreloadQueue = new LapiReviewPreloadQueue(
    'History Mode',
    historyFetcher,
    1,
    false,
    false,
    false
  );

  function onPresentHistorySubscription(
    reviewToPresent: PreloadLapiReviewDTO
  ): void {
    setPresentedLapiAndUpdateMap(reviewToPresent);
    setReviewLoading(false);
  }

  function onNoAvailableHistorySubscription(): void {
    setPresentedLapiAndUpdateMap();
    setReviewLoading(false);
  }

  // Register history callbacks defined above
  historyPreloadQueue.onPresentReview(onPresentHistorySubscription);
  historyPreloadQueue.onNoAvailableReview(onNoAvailableHistorySubscription);

  function searchLapiById(id: string): void {
    setControlRebuild(false);
    setControlId(id);
    setModeReview(false);
    setModeHistory(true);
    setReviewLoading(true);
  }

  function searchLapiByIdWithRebuild(id: string): void {
    setControlRebuild(true);
    setControlId(id);
    setModeReview(false);
    setModeHistory(true);
    setReviewLoading(true);
  }

  function exitHistoryMode(): void {
    setModeHistory(false);
    setModeReview(true);
    setControlId(null);
    setReviewLoading(true);
    historyPreloadQueue.preloadClear();
  }

  /*
   * Edit section
   */
  function changeBrand(newValue: string): void {
    if (presentedLapi) {
      const newPresented = { ...presentedLapi };
      newPresented.lapiReview.vehicleBrand = newValue;
      setPresentedLapi(newPresented);
    }
  }

  function changeModel(newValue: string): void {
    if (presentedLapi) {
      const newPresented = { ...presentedLapi };
      newPresented.lapiReview.vehicleModel = newValue;
      setPresentedLapi(newPresented);
    }
  }

  async function changeAddress(newAddress: Address): Promise<void> {
    if (presentedLapi) {
      const currentLapiReview = services.lapiReview.getCurrentLapiReview();
      const newPresented = { ...presentedLapi };
      newPresented.lapiReview.statementAddress = newAddress;
      newPresented.lapiReview.addressLongitude =
        currentLapiReview.addressLongitude;
      newPresented.lapiReview.addressLatitude =
        currentLapiReview.addressLatitude;
      newPresented.lapiReview.controlLatitude =
        currentLapiReview.controlLatitude;
      newPresented.lapiReview.controlLongitude =
        currentLapiReview.controlLongitude;
      newPresented.lapiReview.zoneId = currentLapiReview.zoneId;
      newPresented.lapiReview.controlStatus = currentLapiReview.controlStatus;
      const fpsPrice = await fetchLapiReviewFpsPrice(newPresented.lapiReview);
      newPresented.fpsPrice = fpsPrice; // Used for rendering UI
      if (newPresented.lapiReview.controlStatus === 'OK') {
        setMessage('Le contrôle sur la nouvelle adresse est valide');
        if (preLoadQueue) {
          preLoadQueue.triggerPresentNextReview();
        }
        return;
      }
      setPresentedLapiAndUpdateMap(newPresented, true);
    }
  }

  async function changeCategory(newValue: string) {
    if (presentedLapi) {
      const newPresented = { ...presentedLapi };
      newPresented.lapiReview.vehicleCategory = newValue;
      const fpsPrice = await fetchLapiReviewFpsPrice(newPresented.lapiReview);
      newPresented.fpsPrice = fpsPrice;
      setPresentedLapi(newPresented);
    }
  }

  async function changePlate(newValue: string) {
    if (presentedLapi) {
      // Type narrow down
      const newPresented = { ...presentedLapi };

      // Skip checks in qualityControlMode
      if (qualityControlMode && presentedLapi) {
        newPresented.lapiReview.licensePlate.plate = newValue;
        setPresentedLapi(newPresented);
        return;
      }

      // Patch, replay control, update
      try {
        const review = await patchLapiReview(
          newPresented.lapiReview.controlId,
          [
            {
              path: '/plate',
              op: 'replace',
              value: newValue,
            },
          ]
        );
        newPresented.lapiReview.licensePlate.plate = newValue;

        if (
          review.controlStatus === 'OK' ||
          review.controlStatus === 'EXEMPTION' ||
          (review.controlStatus === 'WAITING_REVIEW' &&
            review.reviewReason === 'pricingCategory')
        ) {
          setMessage('Le contrôle sur la nouvelle plaque est valide');
          if (preLoadQueue) {
            preLoadQueue.triggerPresentNextReview();
          }
        } else {
          try {
            const fpsPrice = await fetchLapiReviewFpsPrice(review);
            newPresented.lapiReview = review;
            newPresented.fpsPrice = fpsPrice;
            setPresentedLapi(newPresented);

            if (
              (review.subscriptions && review.subscriptions.length !== 0) ||
              (review.parkingRights && review.parkingRights.length !== 0)
            ) {
              setMessage('Des droits et/ou tickets ont été récupérés');
            }
          } catch (e) {
            setMessage(
              "Le prix du nouveaux Fps n'as pas réussi à être calculé"
            );
          }
        }
      } catch (e) {
        setMessage("La plaque et le contrôle n'ont put être mis à jour");
      }
    }
  }

  function handleManualBlurring(
    newContextImages: Array<LapiReviewImage>,
    useImproved: boolean,
    blurredIndex: number
  ) {
    if (presentedLapi) {
      const newPresented = { ...presentedLapi };
      if (useImproved) {
        newPresented.contextImprovedImages = newContextImages;
      } else {
        newPresented.contextImages = newContextImages;
      }

      // Trigger async control update
      void replaceControlImage(
        newPresented.lapiReview.controlId,
        newPresented.contextImages[blurredIndex]
      );
      // Update view
      setPresentedLapi(newPresented);
    }
  }

  /*
   * Managed view section
   */
  function handleRefresh(): void {
    setReviewLoading(true);

    if (modeQualityControl) {
      setQualityControlFilter(initialQualityFilter());
      if (preLoadQueue) {
        void preLoadQueue.preloadStart();
      } else {
        setPreloadQueue(qualityPreloadQueue);
        void qualityPreloadQueue.preloadStart();
      }
    } else if (modeHistory) {
      void historyPreloadQueue.preloadStart();
    } else if (modeReview) {
      if (preLoadQueue) {
        void preLoadQueue.preloadStart();
      } else {
        setPreloadQueue(reviewPreloadQueue);
        void reviewPreloadQueue.preloadStart();
      }
    }
  }

  function handleNextRequest(controlId?: string): void {
    // Prevent double triggers from async processes in child components
    if (
      controlId &&
      presentedLapi &&
      controlId !== presentedLapi.lapiReview.controlId
    ) {
      return;
    }

    if (preLoadQueue) {
      setReviewLoading(true);
      if (modeQualityControl) {
        preLoadQueue.preloadClear();
        void preLoadQueue.preloadStart();
        return;
      }

      if (modeReview) {
        preLoadQueue.triggerPresentNextReview();
      }
    }
  }

  function changeTheme() {
    setDarkMode(prevState => {
      localStorage.setItem(STORAGE_LAPI_DARK_MODE, JSON.stringify(!prevState));
      return !prevState;
    });
  }

  // Use effect for config loading
  useEffect(() => {
    void services.zoning.init();
    void fetchZonesNames();
    void fetchVehiclesList();
    if (isSubscribersEnabled) {
      void fetchSubscriptionPlans();
    }

    // Return a cleanup of queues that react calls when the component unloads
    return function cleanup() {
      reviewPreloadQueue.preloadClear();
      qualityPreloadQueue.preloadClear();
      historyPreloadQueue.preloadClear();
    };
  }, []);

  // Use effect on config changes
  // Triggered when configs fetched booleans update
  useEffect(() => {
    if (isSubscribersEnabled && !subscriptionPlanFetched) {
      // Not ready yet
      return;
    }
    if (zonesFetched && brandsFetched) {
      setInitialLoading(false);
    }
  }, [zonesFetched, brandsFetched, subscriptionPlanFetched]);

  // ReviewMode useEffect
  useEffect(() => {
    if (initialLoading) {
      return;
    }
    if (modeReview && preLoadQueue) {
      void preLoadQueue.preloadStart();
    } else if (modeReview) {
      setPreloadQueue(reviewPreloadQueue);
    }
  }, [initialLoading, modeReview, preLoadQueue]);

  // HistoryMode useEffect
  useEffect(() => {
    if (initialLoading) {
      return;
    }
    if (modeHistory && controlId) {
      historyPreloadQueue.preloadClear();
      void historyPreloadQueue.preloadStart();
    }
  }, [initialLoading, modeHistory, controlId]);

  // QualityControlMode useEffect
  useEffect(() => {
    if (initialLoading) {
      return;
    }
    setModeQualityControl(qualityControlMode);
    if (qualityControlMode) {
      setReviewLoading(true);
      // Clear queue when filtered an agent
      if (qualityControlFilter.agentId) {
        qualityPreloadQueue.preloadClear();
      }
      void qualityPreloadQueue.preloadStart();
      // Use quality queue
      setPreloadQueue(qualityPreloadQueue);
    }
  }, [initialLoading, qualityControlMode, qualityControlFilter]);

  if (initialLoading || (!presentedLapi && reviewLoading)) {
    return (
      <MuiThemeProvider
        muiTheme={getMuiTheme(darkMode ? darkTheme : lightTheme)}
      >
        <Paper
          style={{
            ...STYLE_CONTENT,
            backgroundColor: darkMode ? DARK : lightTheme.palette?.canvasColor,
          }}
        >
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Paper>
      </MuiThemeProvider>
    );
  }

  if (zonesFetched && brandsFetched && presentedLapi) {
    return (
      <>
        <MuiThemeProvider
          muiTheme={getMuiTheme(darkMode ? darkTheme : lightTheme)}
        >
          <Paper
            style={{
              ...STYLE_CONTENT,
              backgroundColor: darkMode
                ? DARK
                : lightTheme.palette?.canvasColor,
            }}
          >
            <View
              complexCaseEnabled={lapiReviewConfigurationDTO.complexCaseEnabled}
              preLoadLapi={presentedLapi}
              zonesMap={zonesMap}
              handleManualBlurring={handleManualBlurring}
              changeBrand={changeBrand}
              changeModel={changeModel}
              changeAddress={changeAddress}
              changeCategory={changeCategory}
              userName={userInfo.name}
              fetchNew={handleNextRequest}
              vehiclesList={vehiclesList}
              brandModelsMap={brandModelsMap}
              brandProvided={!!presentedLapi.lapiReview.vehicleBrand}
              subscriptionPlanMap={subscriptionPlanMap}
              changePlate={changePlate}
              searchLapiByIdWithRebuild={searchLapiByIdWithRebuild}
              searchLapiById={searchLapiById}
              lockTreatment={modeHistory}
              isLapiFetchedFromControlList={!!controlId && controlRebuild}
              clearHistoryAndHandleTimer={exitHistoryMode}
              qualityControlMode={qualityControlMode}
              qualityStatistics={qualityStatistics}
              onQualityAgentSelect={handleQualityControlAgentFilter}
              qualityControlFilter={qualityControlFilter}
              setQualityControlFilter={setQualityControlFilter}
              setDarkMode={changeTheme}
              darkMode={darkMode}
              selectImage={selectImage}
              selectedImages={selectedImages}
            />
            <LapiReviewTimeoutModal
              open={modeReview && reviewHasTimedOut}
              lapiReview={presentedLapi.lapiReview}
              onAcknowledge={onPresentedReviewTimeoutResume}
            />
            <LapiReviewErrorModal
              open={queueEnded}
              handleRefresh={handleRefresh}
            />
          </Paper>
        </MuiThemeProvider>
      </>
    );
  }

  return (
    <MuiThemeProvider muiTheme={getMuiTheme(darkMode ? darkTheme : lightTheme)}>
      <Paper
        style={{
          ...STYLE_CONTENT,
          backgroundColor: darkMode ? DARK : lightTheme.palette?.canvasColor,
        }}
      >
        <ErrorPage refresh={handleRefresh} />
      </Paper>
    </MuiThemeProvider>
  );
};

function mapStateToProps(state: InternalApiState) {
  const { userInfo } = getApiState(state);
  const {
    lapiReviewConfigurationDTO,
    modulesConfiguration: {
      subscribers: { enabled: isSubscribersEnabled },
    },
  } = getConfigState(state);
  return {
    userInfo,
    isSubscribersEnabled,
    lapiReviewConfigurationDTO,
  };
}

export default connect(mapStateToProps)(LapiReview);
