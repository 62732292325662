import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import BoButton from 'facade/BoButton';
import { BKG_GREEN, BKG_LIGHT } from 'theme';
import { formatCtsPrice } from 'commons/Price';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { LapiReviewDTO, FpsCreationResponse } from 'api/lapiReview/types';
import { findNextFpsId, createFps } from 'api/lapiReview';
import { FnmsCityFpsPriceDTO, LightLapiZone } from 'api/pricing/types';
import { uploadFileAndReturnUrn } from 'api/mediaupload';
import { patchFps } from 'api/fps';
import { FnmsLatestIdDTO } from 'api/fps/types';
import { PatchObject } from 'api/commonTypes';
import { getConfigState } from 'config/duck';
import OnUploadMoal from 'commons/OnUploadModal/OnUploadModal';
import { LapiReviewConfigurationDTO } from 'api/lapiReviewConfiguration/types';
import { ZoningDTO } from '@cvfm-front/tefps-types';

import ConfirmAction from '../../../commons/ConfirmAction';

import { registerQualityControlDecision } from './helpers';
import FPSConfirmationModal from './FPSConfirmationModal';

const STYLE_BUTTON: CSSProperties = { width: '150px', margin: '10px' };

type FPSProps = {
  lapiReview: LapiReviewDTO;
  fetchNew: Function;
  contextImages: Array<File>;
  selectedImages: Set<number>;
  plateImages: Array<File>;
  unlock: boolean;
  fpsPrice: FnmsCityFpsPriceDTO | null | undefined;
  controlZone: LightLapiZone | undefined;
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
  qualityControlMode: boolean;
  zoning?: ZoningDTO | null | undefined;
};

const FPS = ({
  lapiReview,
  fetchNew,
  plateImages,
  contextImages,
  selectedImages,
  unlock,
  fpsPrice,
  controlZone,
  lapiReviewConfigurationDTO,
  qualityControlMode,
  zoning,
}: FPSProps) => {
  const setMessage = useSnackbar();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [nextFpsId, setNextFpsId] = React.useState('');
  const [onUpload, setOnUpload] = React.useState<boolean>(false);
  const [showTestModal, setShowTestModal] = React.useState<boolean>(false);

  function setValidityZoneAndGetLapi() {
    if (controlZone && controlZone.virtual && controlZone.alias.length > 0) {
      return {
        ...lapiReview,
        zoneId: controlZone.alias[0],
        price: fpsPrice ?? undefined,
      };
    }
    return { ...lapiReview, price: fpsPrice ?? undefined };
  }

  const { _tg } = window.loadTranslations(__filename);

  const createFpsFromLapi = () => {
    return new Promise((resolve, reject) => {
      createFps(setValidityZoneAndGetLapi())
        .then((fpsCreationResponse: FpsCreationResponse) => {
          resolve(fpsCreationResponse);
        })
        .catch(error => {
          const rejectError = new Error(
            `Creation du FPS échouée: ${
              error.json ? error.json.message : error
            }`
          );
          rejectError.name = 'ERR_CREATE_FPS';
          reject(rejectError);
        });
    });
  };

  const uploadFpsMedias = (
    fpsId: string,
    mediaUpload: string,
    fpsImages: Array<File>
  ) => {
    return fpsImages.map(file => {
      return new Promise<PatchObject<unknown>>((resolve, reject) => {
        uploadFileAndReturnUrn(mediaUpload, file)
          .then(urn => {
            const fpsMediaPatch = {
              op: 'add',
              path: '/medias',
              value: {
                url: urn,
                name: file ? file.name : uuidv4(),
              },
            };
            resolve(fpsMediaPatch);
          })
          .catch(() => {
            const rejectError = new Error(
              `Erreur lors de l'envoi des images du fps ${fpsId}`
            );
            rejectError.name = 'ERR_UPLOAD_FPS';
            reject(rejectError);
          });
      });
    });
  };

  const patchAddFpsMedias = (
    fpsId: string,
    fpsPatches: Array<PatchObject<unknown>>
  ) => {
    return new Promise<void>((resolve, reject) => {
      patchFps(fpsId, fpsPatches)
        .then(() => {
          resolve();
        })
        .catch(() => {
          const rejectError = new Error(
            `Erreur lors de la sauvegarde des images du fps ${fpsId}`
          );
          rejectError.name = 'ERR_PATCH_FPS';
          reject(rejectError);
        });
    });
  };

  const errorFpsAsyncFlow = (error: Error) => {
    let errorMessage = null;
    switch (error.name) {
      case 'ERR_CREATE_FPS': {
        setOnUpload(false);
        errorMessage = error.message;
        break;
      }
      case 'ERR_TEST_MODE':
      case 'ERR_UPLOAD_FPS':
      case 'ERR_PATCH_FPS': {
        errorMessage = error.message;
        break;
      }
      default: {
        errorMessage = `Erreur à la création du FPS: ${error.message}`;
        break;
      }
    }
    setMessage(errorMessage);
  };

  const createFpsAsyncFlow = () => {
    let fpsId = '';
    let fpsMediaUpload = '';

    setOnUpload(true);
    createFpsFromLapi()
      .then((fpsCreationResponse: FpsCreationResponse) => {
        // Hide modal state first
        setOnUpload(false);
        // Can present a new review during the rest of the flow
        fetchNew(lapiReview.controlId);

        // Test mode: Interrupt normal flow
        if (!lapiReviewConfigurationDTO.lapiReviewFpsEnabled) {
          setShowTestModal(true);
          const testModeError = new Error('Test de la fonctionnalité FPS: OK');
          testModeError.name = 'ERR_TEST_MODE';
          throw testModeError;
        }
        // Store infos and notify success
        fpsId = fpsCreationResponse.fpsId;
        fpsMediaUpload = fpsCreationResponse.mediaUpload;
        setMessage(`Fps ${fpsId} créé.`);

        // Continue flow with background async uploads & patches
        const fpsImages = plateImages.concat(
          contextImages.filter((_file, index) => selectedImages.has(index))
        );
        return uploadFpsMedias(fpsId, fpsMediaUpload, fpsImages);
      })
      .then(fpsPatchesPromises => {
        // Get all patch objects after async uploads
        Promise.all(fpsPatchesPromises)
          .then(fpsPatches => {
            return patchAddFpsMedias(fpsId, fpsPatches);
          })
          .catch(error => {
            throw error;
          });
      })
      .catch((error: Error) => {
        errorFpsAsyncFlow(error);
      });
  };

  async function handleMode() {
    if (qualityControlMode) {
      await registerQualityControlDecision(
        lapiReview,
        'KO',
        null,
        lapiReview.tags
      );
      fetchNew();
      setMessage('Le contrôle est placé en FPS');
    } else {
      createFpsAsyncFlow();
    }
  }

  function onConfirmValidation(selectedTags: Set<string>, comment?: string) {
    lapiReview.comment = comment || '';
    lapiReview.tags = Array.from(selectedTags);
    setConfirmOpen(false);
    void handleMode();
  }

  function onCancelValidation() {
    setConfirmOpen(false);
  }

  function onFPSAction() {
    findNextFpsId(lapiReview.statementDatetime)
      .then((fnmsFpsId: FnmsLatestIdDTO) => {
        setNextFpsId(fnmsFpsId.latestId);
        setConfirmOpen(true);
      })
      .catch((error: any) => {
        const messageError = `Numératation du FPS échouée: ${
          error.json ? error.json.message : error
        }`;
        setMessage(messageError);
      });
  }

  let displayPrice = '';
  let ticketPrice = formatCtsPrice(0);
  if (fpsPrice) {
    displayPrice = formatCtsPrice(Math.max(fpsPrice.finePrice, 0));

    if (
      fpsPrice.significantRights &&
      fpsPrice.significantRights.length &&
      fpsPrice.significantRights[0].type === 'TICKET'
    ) {
      ticketPrice = formatCtsPrice(fpsPrice.significantRights[0].rightPrice);
    }
  }

  return (
    <>
      <BoButton
        label={`FPS ${displayPrice}`}
        backgroundColor={BKG_GREEN}
        labelColor={BKG_LIGHT}
        style={STYLE_BUTTON}
        onClick={onFPSAction}
        disabled={!unlock}
      />
      <FPSConfirmationModal
        open={confirmOpen}
        qualityControlMode={qualityControlMode}
        onCancel={onCancelValidation}
        onConfirm={onConfirmValidation}
        fpsId={nextFpsId.toString()}
        agentShortId={nextFpsId.toString().slice(-6, -3)}
        timeStamp={new Date(lapiReview.statementDatetime)}
        price={displayPrice}
        ticketPrice={ticketPrice}
        address={lapiReview.statementAddress}
        zone={controlZone}
        plateImage={plateImages[0]}
        lapiReview={lapiReview}
        vehicleBrand={lapiReview.vehicleBrand}
        vehicleModel={lapiReview.vehicleModel}
        licensePlate={lapiReview.licensePlate}
        comment={lapiReview.comment}
        zoning={zoning}
        lapiReviewConfigurationDTO={lapiReviewConfigurationDTO}
        contextImages={contextImages}
        selectedImages={selectedImages}
      />
      <OnUploadMoal
        open={onUpload}
        message="FPS en cours de création"
        title="Création du FPS"
      />
      <ConfirmAction
        dialogStyle={{ zIndex: 2000 }}
        action={() => setShowTestModal(false)}
        isOpen={showTestModal}
        onClose={() => setShowTestModal(false)}
        message={_tg(
          'Administration.Agents.Detail.helpers.element.fps.test_mode'
        )}
        enabled
      />
    </>
  );
};

export default connect(state => {
  const { lapiReviewConfigurationDTO } = getConfigState(state);
  return { lapiReviewConfigurationDTO };
})(FPS);
