import React from 'react';
import { Dialog } from 'material-ui';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';

import ClipBoardButton from 'commons/ClipBoardButton';
import { LapiReviewDTO } from 'api/lapiReview/types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  lapiReview: LapiReviewDTO;
  onAcknowledge: () => void;
};

function LapiReviewTimeoutModal({
  open,
  lapiReview,
  onAcknowledge,
}: Props): JSX.Element {
  const actions = [
    <FlatButton
      label={_tg('action.update')}
      keyboardFocused
      primary
      onClick={onAcknowledge}
    />,
  ];

  return (
    <Dialog
      title="Durée de présentation dépassée"
      actions={actions}
      modal
      open={open}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column wrap',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'flex-end',
            marginTop: '35px',
          }}
        >
          <TextField
            floatingLabelText={_tg('field.control.controlId')}
            value={lapiReview.controlId}
            disabled
          />
          <ClipBoardButton
            subject={"L'id du contrôle"}
            textToClip={lapiReview.controlId}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default LapiReviewTimeoutModal;
