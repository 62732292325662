import React, { CSSProperties } from 'react';
import { MenuItem, SelectField } from 'material-ui';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

import { VehicleCategory } from '@cvfm-front/tefps-types';
import { AuthorizedVehicleCategory } from 'config/duck';

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 20,
  height: 30,
};

type Props = {
  selectedVehicleCategory?: string;
  handleNewRequest: (input: string) => void;
  muiTheme?: MuiTheme;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
};

function VehicleTypeSelector({
  selectedVehicleCategory,
  handleNewRequest,
  muiTheme,
  authorizedVehicleCategories,
}: Props) {
  function onChangeVehicleCategory(
    _event: React.SyntheticEvent<HTMLElement>,
    _index: number,
    menuItemValue: string
  ) {
    handleNewRequest(menuItemValue);
  }

  return (
    <div style={ROW_STYLE}>
      <SelectField
        floatingLabelText="Type du véhicule"
        onChange={onChangeVehicleCategory}
        value={selectedVehicleCategory || Object.keys(VehicleCategory)[0]}
        style={{
          backgroundColor: muiTheme?.paper?.backgroundColor,
        }}
        floatingLabelStyle={{ color: muiTheme?.textField?.textColor }}
      >
        {Object.entries(VehicleCategory)
          .filter(vehicle =>
            authorizedVehicleCategories.find(
              authorized => authorized.vehicleCategory === vehicle[0]
            )
          )
          .map(vehicle => (
            <MenuItem
              value={vehicle[0]}
              primaryText={vehicle[1]}
              style={{ fontWeight: 'bold' }}
            />
          ))}
      </SelectField>
    </div>
  );
}

export default muiThemeable()(VehicleTypeSelector);
