import React, { CSSProperties } from 'react';
import SelectField from 'material-ui/SelectField';
import Toggle from 'material-ui/Toggle';
import MenuItem from 'material-ui/MenuItem';
import Delete from 'material-ui/svg-icons/action/delete';

import BoButton from 'facade/BoButton';
import ConfirmAction from 'commons/ConfirmAction';
import { ItemIdName } from 'api/commonTypes';

import { BRD_GREY } from '../theme';

import AddPricing from './AddPricing';

const { _tg } = window.loadTranslations(__filename);

const ACTIVE_TAG = ' [actif]';

const STYLE_MENU: CSSProperties = {
  display: 'flex',
  flex: 1,
  padding: 10,
  margin: '12px 69px 0px 69px',
  border: `1px solid ${BRD_GREY}`,
  borderRadius: 5,
  backgroundColor: 'white',
  justifyContent: 'space-between',
  height: '38px',
};

type MenuBarProps = {
  saveAll: React.MouseEventHandler<{}>;
  savable: boolean;
  createPricing: (
    name: string,
    copiedPricingId: string | null | undefined
  ) => any;
  pricingList: Array<ItemIdName>;
  currentId: string | null | undefined; // id of the pricing being edited
  changePricing: Function;
  removePricing?: () => any;
  activePricingId: string | null | undefined;
  setPricingActive: () => any;
  isAllowed: boolean;
};

type MenuBarState = {
  open: boolean;
};

/**
 * Barre de menu pour la gestion de la tarification
 */
class MenuBar extends React.Component<MenuBarProps, MenuBarState> {
  constructor(props: MenuBarProps) {
    super(props);
    this.state = { open: false };
  }

  changePricing = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    id: string
  ): void => this.props.changePricing(id);

  open = (): void => this.setState({ open: true });
  close = (): void => this.setState({ open: false });

  render(): JSX.Element {
    const {
      saveAll,
      savable,
      pricingList,
      currentId,
      createPricing,
      removePricing,
      activePricingId,
      isAllowed,
      setPricingActive,
    } = this.props;
    const { open } = this.state;
    const currentItem = pricingList.find(item => item.id === currentId);
    const name = currentItem ? currentItem.name : '';
    const isActivePricing = activePricingId === currentId;

    return (
      <div style={STYLE_MENU}>
        <div>
          <BoButton
            label={_tg('tefps.pricing.pricing.menuBar.addPricing')}
            onClick={this.open}
          />
        </div>
        <div>
          {!isActivePricing && (
            <BoButton
              label={_tg('action.save_1')}
              primary
              onClick={saveAll}
              disabled={!savable}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '-10px',
            width: '500px',
          }}
        >
          <ConfirmAction
            styleWrapper={{ verticalAlign: 'super' }}
            action={setPricingActive}
            message={_tg('tefps.pricing.pricing.menuBar.confirmActionMessage', {
              name,
            })}
            enabled={!isActivePricing && isAllowed}
          >
            <span
              title={
                isActivePricing
                  ? _tg('tefps.pricing.pricing.menuBar.activePricing')
                  : _tg('tefps.pricing.pricing.menuBar.activatePricing')
              }
            >
              <Toggle
                toggled={isActivePricing}
                style={{ width: 0, marginTop: 30, marginRight: 16 }}
              />
            </span>
          </ConfirmAction>
          <SelectField
            floatingLabelText={_tg(
              'tefps.pricing.pricing.menuBar.currentlyEditedPricing'
            )}
            value={currentId}
            onChange={this.changePricing}
            disabled={pricingList.length === 0}
            fullWidth
          >
            {pricingList.map(choice => (
              <MenuItem
                key={choice.id}
                value={choice.id}
                primaryText={
                  activePricingId === choice.id
                    ? choice.name + ACTIVE_TAG
                    : choice.name
                }
              />
            ))}
          </SelectField>
          {isAllowed && !isActivePricing && removePricing && (
            <ConfirmAction
              styleWrapper={{ verticalAlign: 'super' }}
              action={removePricing}
              message={_tg('tefps.pricing.pricing.menuBar.deleteConfirm', {
                name,
              })}
              enabled
            >
              <Delete
                style={{
                  color: '#aaa',
                  width: 30,
                  height: 30,
                  marginTop: 30,
                  verticalAlign: 'super',
                  cursor: 'pointer',
                  marginLeft: 10,
                }}
              />
            </ConfirmAction>
          )}
        </div>
        <AddPricing
          open={open}
          existingPricings={pricingList}
          close={this.close}
          save={createPricing}
        />
      </div>
    );
  }
}

export default MenuBar;
