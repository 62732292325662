import React from 'react';
import { DropTarget, DragDropContext as dragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import flow from 'lodash.flow';

import { PricingPolicyDTO } from '@cvfm-front/tefps-types';

import DnDPolicyItem from './DnDPolicyItem';

const itemTarget = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  drop() {},
};

type State = {
  items: Array<PricingPolicyDTO>;
};

type DndProps = {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
};

type OwnProps = {
  policies: Array<PricingPolicyDTO>;
  editPolicy: Function;
  duplicatePolicy: Function;
  deletePolicy: Function;
  reorder: Function;
  disabled: boolean;
};

type Props = DndProps & OwnProps;

class DnDContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { items: props.policies };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.setState({ items: newProps.policies });
  }

  moveItem = (id: string, atIndex: number, ended = false) => {
    const items = [...this.state.items];
    const index = this.findIndexFromId(id);
    items.splice(atIndex, 0, items.splice(index, 1)[0]);
    if (!this.props.disabled) {
      if (ended) {
        this.props.reorder(this.state.items);
      } else {
        this.setState({ items });
      }
    }
  };

  findIndexFromId = (id: string) =>
    this.state.items.findIndex(item => item.id === id);

  render(): JSX.Element {
    const {
      connectDropTarget,
      editPolicy,
      duplicatePolicy,
      deletePolicy,
      disabled,
    } = this.props;
    const { items } = this.state;
    return connectDropTarget(
      <div style={{ width: '96%', marginTop: 4 }}>
        {items.map((item, i) => (
          <DnDPolicyItem
            key={item.id}
            id={item.id}
            idx={i + 1}
            name={item.name}
            moveItem={this.moveItem}
            findIndexFromId={this.findIndexFromId}
            remove={deletePolicy}
            edit={editPolicy}
            duplicate={duplicatePolicy}
            deletionEnabled={!disabled}
          />
        ))}
      </div>
    );
  }
}

export default flow(
  DropTarget<OwnProps>('item', itemTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
  dragDropContext(HTML5Backend)
)(DnDContainer);

/*
Error:(73, 12) TS2769: No overload matches this call.
  Overload 1 of 2, '(props?: Props | undefined, context?: any): DndComponent<Props, any>', gave the following error.
    Type '{ key: string; id: string; idx: number; name: string; moveItem: (id: string, atIndex: number, ended?: boolean) => void; findIndexFromId: (id: string) => number; remove: Function; edit: Function; deletionEnabled: boolean; }' is missing the following properties from type 'Readonly<Props>': connectDragSource, connectDropTarget, isDragging
  Overload 2 of 2, '(props: Props, context?: any): Component<Props, any, any>', gave the following error.
    Type '{ key: string; id: string; idx: number; name: string; moveItem: (id: string, atIndex: number, ended?: boolean) => void; findIndexFromId: (id: string) => number; remove: Function; edit: Function; deletionEnabled: boolean; }' is missing the following properties from type 'Readonly<Props>': connectDragSource, connectDropTarget, isDragging
 */
