import React from 'react';
import { MenuItem } from 'material-ui';
import SelectField from 'material-ui/SelectField';

import { ParkingSpaceRegimeList } from '@cvfm-front/tefps-types';
import { FilterBarSection } from '@cvfm-front/tefps-ui';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { FilterBarSelectLabelStyle } from 'styles/FilterBarSelectStyle';
import { MapId } from 'commons/services/MapService';

const { _tg } = window.loadTranslations(__filename);

function ParkingSpaceFilterByRegimeSection(): JSX.Element {
  const regimes = useWatcher(
    services.parkingSpaceApi.watchRegimesFilter,
    new Set()
  );

  return (
    <FilterBarSection
      title={_tg('filterByRegime')}
      className="filter-bar-section__title__zero-margin-bottom"
      fields={[
        <SelectField
          labelStyle={FilterBarSelectLabelStyle}
          value={Array.from(regimes)}
          multiple
        >
          {ParkingSpaceRegimeList.map(regime => (
            <MenuItem
              key={regime}
              value={regime}
              primaryText={_tg(`ParkingSpaceRegime.${regime}`)}
              onClick={async () => {
                services.parkingSpaceApi.toggleRegimeFilter(regime);
                await services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
              }}
            />
          ))}
        </SelectField>,
      ]}
      actions={[]}
    />
  );
}

export default ParkingSpaceFilterByRegimeSection;
