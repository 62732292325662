import { Bill } from '@cvfm-front/tefps-types';

// This bill is displayed during loading time
export const BILL_PLACEHOLDER: Bill = {
  id: 'Chargement...',
  cityId: 'Chargement...',
  billId: 'Chargement...',
  creationDatetime: 'Chargement...',
  controlId: 'Chargement...',
  entryStatementDatetime: 'Chargement...',
  exitStatementDatetime: 'Chargement...',
  entryContextUrn: 'Chargement...',
  exitContextUrn: 'Chargement...',
  vehicle: {
    brand: 'Chargement...',
    model: 'Chargement...',
  },
  plate: {
    plate: 'Chargement...',
    plateCountry: 'Chargement...',
  },
  versions: [
    {
      price: 0,
      comment: 'Chargement...',
      offender: {
        gender: 'MALE',
        honorificPrefix: 'Chargement...',
        givenName: 'Chargement...',
        additionalName: 'Chargement...',
        maidenName: 'Chargement...',
        familyName: 'Chargement...',
        email: 'Chargement...',
        address: {
          streetNumber: 'Chargement...',
          streetNumberBis: 'Chargement...',
          streetType: 'Chargement...',
          streetName: 'Chargement...',
          specialPlace: 'Chargement...',
          postOfficeBoxNumber: 'Chargement...',
          postalCode: 'Chargement...',
          addressSubRegion: 'Chargement...',
          addressRegion: 'Chargement...',
          addressLocality: 'Chargement...',
          addressCountry: 'Chargement...',
        },
      },
      timestamp: '2022-01-01',
      agent: {
        agentId: 'Chargement...',
        name: 'Chargement...',
      },
      payment: [],
    },
  ],
};
