import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import Add from 'material-ui/svg-icons/content/add-circle-outline';
import Delete from 'material-ui/svg-icons/content/clear';
import _cloneDeep from 'lodash.clonedeep';
import { RaisedButton } from 'material-ui';

import { translateDaysOfWeek } from 'commons/Days';
import { BKG_CYAN, BKG_GREY } from 'theme';
import {
  PriceModulationDTO,
  PricingDTO,
  PricingConfiguration,
} from '@cvfm-front/tefps-types';
import MultiSelect from 'commons/MultiSelect';
import NumberField from 'commons/NumberField';

import {
  correctDuration,
  correctPrice,
  DAYS_OFF,
  DEFAULT_FPS_REDUCTION,
} from '../helpers';
import { STYLE_TITLE } from '../theme';

import TimeInterval from './TimeInterval';
import PriceModulationField from './PriceModulationField';
import SpecialDaysRangeModal from './SpecialDaysRangeModal';

const { _tg } = window.loadTranslations(__filename);

const STYLE_PARAMETER: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

const STYLE_BLOCK: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  margin: 10,
};

const STYLE_BUTTON: CSSProperties = {
  backgroundColor: `${BKG_CYAN}`,
  fontFamily: 'Roboto',
  color: 'black',
  textAlign: 'center',
};

const STYLE_FIELD: CSSProperties = {
  height: 24,
  width: 44,
  marginLeft: 4,
  fontSize: 12,
  border: `1px solid ${BKG_GREY}`,
  borderRadius: 5,
  marginRight: 5,
};

const STYLE_TEXT_FIELD: CSSProperties = { textAlign: 'center' };

const STYLE_CHECKBOX_CONTAINER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  marginTop: 4,
};

const STYLE_CHECKBOX: CSSProperties = {
  width: 26,
};

const STYLE_CHECKBOX_ICON: CSSProperties = {
  marginRight: 8,
};

const STYLE_CHECKBOX_LABEL: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: 12,
};

const STYLE_FLEX_CENTER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
};

type GlobalConfigurationProps = {
  settings: PricingDTO;
  reloadPricing: (pricingId: string) => void;
  updateSettings: Function;
  disabled: boolean;
  pricingConfiguration: PricingConfiguration;
};

type GlobalConfigurationState = {
  openSpecialDaysModal: boolean;
};

export default class GlobalConfiguration extends React.Component<
  GlobalConfigurationProps,
  GlobalConfigurationState
> {
  constructor(props: GlobalConfigurationProps) {
    super(props);
    this.state = { openSpecialDaysModal: false };
  }

  onCheck = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ): void => {
    const { settings, updateSettings } = this.props;
    const { field } = currentTarget.dataset;
    let checkboxes = {};
    if (field === 'fpsPriceFixed') {
      checkboxes = {
        fpsProfileDependent: isInputChecked
          ? false
          : settings.fpsProfileDependent,
        fpsPriceFixed: isInputChecked,
      };
    } else if (field === 'fpsIndexedOnTicket') {
      checkboxes = {
        fpsProfileDependent: isInputChecked
          ? settings.fpsProfileDependent
          : false,
        fpsPriceFixed: !isInputChecked,
      };
    } else if (field === 'fpsProfileDependent') {
      checkboxes = {
        fpsProfileDependent: settings.fpsPriceFixed ? false : isInputChecked,
      };
    }
    updateSettings({ ...settings, ...checkboxes });
  };

  updateFpsReduction = (fpsReduction: PriceModulationDTO): void => {
    const { settings, updateSettings } = this.props;
    updateSettings({
      ...settings,
      fpsReduction,
    });
  };

  changeFpsFixedPrice = (newValue: number): void => {
    const { settings, updateSettings } = this.props;
    updateSettings({
      ...settings,
      fpsFixedPrice: correctPrice(Number(newValue) * 100),
    });
  };

  changeFpsFixedDuration = (
    _event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { settings, updateSettings } = this.props;
    updateSettings({
      ...settings,
      fixedMinutesDuration: correctDuration(Number(newValue)),
    });
  };

  changeFreeDays = (criteriaName: string, selection: Array<string>): void => {
    const { settings, updateSettings } = this.props;
    updateSettings({ ...settings, [criteriaName]: selection });
  };

  changeDailyPeriod = (from: string, to: string): void => {
    const { settings, updateSettings } = this.props;
    updateSettings({
      ...settings,
      dailyChargedPeriod: { start: from, end: to },
    });
  };

  changeBreak = (
    from: string | null | undefined,
    to: string | null | undefined,
    idx: string
  ): void => {
    const settings = _cloneDeep(this.props.settings);
    const { updateSettings } = this.props;
    settings.dailyBreaks[idx] = { start: from, end: to };
    updateSettings(settings);
  };

  addBreak = (): void => {
    const { settings, updateSettings } = this.props;
    updateSettings({
      ...settings,
      dailyBreaks: [...settings.dailyBreaks, { start: '12:00', end: '14:00' }],
    });
  };

  deleteBreak = (event: React.MouseEvent<HTMLInputElement>): void => {
    const settings = _cloneDeep(this.props.settings);
    const { updateSettings, disabled } = this.props;
    settings.dailyBreaks.splice(
      parseInt(event.currentTarget.dataset.idx as string, 10),
      1
    );
    if (!disabled) updateSettings(settings);
  };

  saveDaysOff = (selection: Array<string>): void =>
    this.changeFreeDays('globalFreeDaysOff', selection);

  saveDaysOfWeek = (selection: Array<string>): void =>
    this.changeFreeDays('globalFreeDays', selection);

  openSpecialDaysModal = (): void => {
    this.setState({ openSpecialDaysModal: true });
  };

  render(): JSX.Element {
    const {
      settings,
      disabled,
      pricingConfiguration,
      reloadPricing,
    } = this.props;
    const { openSpecialDaysModal } = this.state;
    const fpsIndexedOnTicket = !settings.fpsPriceFixed;

    return (
      <div style={STYLE_BLOCK}>
        <div
          style={{
            ...STYLE_FLEX_CENTER,
            flex: 1,
            justifyContent: 'center',
            marginBottom: 16,
          }}
        >
          <div style={STYLE_TITLE}>
            {_tg('tefps.pricing.pricing.globalConfiguration.title')}
          </div>
        </div>

        <div style={{ ...STYLE_FLEX_CENTER, marginBottom: disabled ? 1 : 0 }}>
          <span style={STYLE_PARAMETER}>
            {_tg('tefps.pricing.pricing.policy.dailyChargedPeriod.label')}
          </span>
          <TimeInterval
            from={settings.dailyChargedPeriod.start}
            to={settings.dailyChargedPeriod.end}
            onChange={this.changeDailyPeriod}
            disabled={disabled}
          />
        </div>
        <div style={{ ...STYLE_FLEX_CENTER, marginBottom: 12 }}>
          <span style={STYLE_PARAMETER}>
            {_tg('tefps.pricing.pricing.policy.breaks.dailyBreak')}
          </span>
          {!disabled && (
            <IconButton
              tooltip={_tg('tefps.pricing.pricing.policy.breaks.newBreak')}
              iconStyle={{ width: 24 }}
              onClick={this.addBreak}
            >
              <Add />
            </IconButton>
          )}
          <br />
          {settings.dailyBreaks && (
            <div>
              {settings.dailyBreaks.map((pause, i) => (
                <div
                  key={i.toString() + pause.start}
                  style={{ ...STYLE_FLEX_CENTER, marginBottom: 8 }}
                >
                  <TimeInterval
                    idx={i}
                    from={pause.start}
                    to={pause.end}
                    onChange={this.changeBreak}
                    disabled={disabled}
                  />
                  {!disabled && (
                    <span title={_tg('action.delete')}>
                      <Delete
                        style={{ cursor: 'pointer' }}
                        data-idx={i}
                        onClick={this.deleteBreak}
                      />
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', marginBottom: 12, marginTop: 4 }}>
          <span style={STYLE_PARAMETER}>
            {_tg('tefps.pricing.pricing.policy.criterias.freeDays')}
          </span>
          <div style={{ width: '100%' }}>
            <MultiSelect
              items={DAYS_OFF()}
              checkedIds={settings.globalFreeDaysOff}
              title={_tg(
                'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.titleShort'
              )}
              itemNameSingular={_tg(
                'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.itemNameSingular'
              )}
              itemNamePlural={_tg(
                'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOffCriteria.itemNamePlural'
              )}
              save={this.saveDaysOff}
              disabled={disabled}
            />
            <MultiSelect
              items={translateDaysOfWeek()}
              checkedIds={settings.globalFreeDays}
              title={_tg(
                'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.titleShort'
              )}
              itemNameSingular={_tg(
                'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.itemNameSingular'
              )}
              itemNamePlural={_tg(
                'tefps.pricing.pricing.policy.criterias.temporalCriteria.dayOfWeekCriteria.itemNamePlural'
              )}
              save={this.saveDaysOfWeek}
              disabled={disabled}
            />
            {pricingConfiguration.specialDays &&
              pricingConfiguration.specialDays.length > 0 && (
                <RaisedButton
                  label={_tg(
                    'tefps.pricing.pricing.policy.criterias.temporalCriteria.specialDayCriteria.titleLong'
                  )}
                  labelStyle={{
                    fontSize: 12,
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                  onClick={this.openSpecialDaysModal}
                  style={{ ...STYLE_BUTTON, ...STYLE_FLEX_CENTER, fontSize: 8 }}
                />
              )}
          </div>
        </div>

        <div style={{ marginBottom: 12 }}>
          <PriceModulationField
            priceModulation={
              settings.fpsReduction
                ? {
                    ...settings.fpsReduction,
                    amount: settings.fpsReduction.amount / 100,
                  }
                : DEFAULT_FPS_REDUCTION
            }
            updatePriceModulation={this.updateFpsReduction}
            disabled={disabled}
            styleTitle={STYLE_PARAMETER}
            styleField={STYLE_FIELD}
          />
        </div>

        <div>
          <div style={STYLE_PARAMETER}>
            {_tg(
              'tefps.pricing.pricing.globalConfiguration.fpsComputationMethod'
            )}
          </div>
          <div style={STYLE_CHECKBOX_CONTAINER}>
            <Checkbox
              style={STYLE_CHECKBOX}
              iconStyle={STYLE_CHECKBOX_ICON}
              onCheck={this.onCheck}
              checked={fpsIndexedOnTicket}
              inputStyle={STYLE_TEXT_FIELD}
              data-field="fpsIndexedOnTicket"
              disabled={disabled}
            />
            <div style={STYLE_CHECKBOX_LABEL}>
              {_tg(
                'tefps.pricing.pricing.globalConfiguration.fpsIndexedOnTicket'
              )}
            </div>
          </div>
          <div style={STYLE_CHECKBOX_CONTAINER}>
            <Checkbox
              style={STYLE_CHECKBOX}
              iconStyle={STYLE_CHECKBOX_ICON}
              onCheck={this.onCheck}
              checked={settings.fpsPriceFixed}
              inputStyle={STYLE_TEXT_FIELD}
              data-field="fpsPriceFixed"
              disabled={disabled}
            />
            <div style={STYLE_CHECKBOX_LABEL}>
              {_tg('tefps.pricing.pricing.globalConfiguration.fixedPrice')}
            </div>
            <NumberField
              name="fixedPrice"
              title={_tg('field.payment.currency')}
              underlineShow={false}
              value={settings.fpsFixedPrice ? settings.fpsFixedPrice / 100 : 0}
              onChange={this.changeFpsFixedPrice}
              inputStyle={STYLE_TEXT_FIELD}
              type="number"
              style={STYLE_FIELD}
              disabled={disabled}
            />
            {_tg('tefps.pricing.pricing.globalConfiguration.for')}
            <TextField
              name="fixedDuration"
              title={_tg('field.date.min_plural')}
              underlineShow={false}
              value={settings.fixedMinutesDuration}
              onChange={this.changeFpsFixedDuration}
              inputStyle={STYLE_TEXT_FIELD}
              type="number"
              style={STYLE_FIELD}
              disabled={disabled}
            />
            {_tg('field.date.min_plural')}
          </div>
          <div style={STYLE_CHECKBOX_CONTAINER}>
            <Checkbox
              style={STYLE_CHECKBOX}
              iconStyle={STYLE_CHECKBOX_ICON}
              onCheck={this.onCheck}
              checked={settings.fpsProfileDependent}
              data-field="fpsProfileDependent"
              disabled={disabled}
            />
            <div style={STYLE_CHECKBOX_LABEL}>
              {_tg(
                'tefps.pricing.pricing.globalConfiguration.takingRightsIntoAccount'
              )}
            </div>
          </div>
        </div>
        {openSpecialDaysModal && (
          <SpecialDaysRangeModal
            open={openSpecialDaysModal}
            reloadPricing={reloadPricing}
            disabled={disabled}
            onClose={() => this.setState({ openSpecialDaysModal: false })}
            pricingDTO={settings}
            pricingConfiguration={pricingConfiguration}
          />
        )}
      </div>
    );
  }
}
