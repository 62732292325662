import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog, TextField } from 'material-ui';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { List, ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { ExemptionReason, getConfigState } from 'config/duck';
import { BKG_LIGHT_BLUE, TXT_RED, ICON_BLUE } from 'theme';
import { LapiReviewConfigurationDTO } from 'api/lapiReviewConfiguration/types';

import { REASON_ICONS } from './helpers';
import TagsList from './TagsList';

const { _t } = window.loadTranslations(__filename);

const DIALOG_STYLE: CSSProperties = {
  width: 'auto',
  minWidth: '30%',
  maxWidth: '90%',
  minHeight: '25%',
  maxHeight: '90%',
};

const SINGLE_COLUMN_THRESHOLD = 7;
const DIALOG_STYLE_SINGLE_COLUMN: CSSProperties = {
  width: 'auto',
  minWidth: '27%',
  maxWidth: '30%',
  minHeight: '25%',
  maxHeight: '90%',
};

const WARNING_STYLE: CSSProperties = {
  color: TXT_RED,
  flex: '0 0 auto',
};

const LIST_STYLE: CSSProperties = {
  display: 'flex',
  alignContent: 'space-evenly',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
};

type ReduxStateProps = {
  exemptionReasonsConfigurations: Array<ExemptionReason> | null | undefined;
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
};

type ExemptionReasonModalProps = ReduxStateProps & {
  open: boolean;
  qualityControlMode: boolean;
  controlId: string;
  onCancel: () => void;
  onConfirm: (
    comment: string,
    exemptionReasonKey: string,
    tags: Set<string>
  ) => void;
};

const ExemptionReasonModal = ({
  exemptionReasonsConfigurations,
  open,
  qualityControlMode,
  controlId,
  onCancel,
  onConfirm,
  lapiReviewConfigurationDTO,
}: ExemptionReasonModalProps) => {
  const [exemptionReasonKey, setExemptionReasonKey] = useState<
    string | null | undefined
  >(null);
  const [comment, setComment] = useState<string>('');
  const [tagsSelected, setTagsSelected] = useState<Set<string>>(new Set());

  const onItemListClick = (event: React.MouseEvent<HTMLElement>) => {
    setExemptionReasonKey(event.currentTarget.id);
  };

  const computeReasonStyle = (key: string) => {
    const style = {
      width: '512px',
      margin: '5px 5px',
      borderBottom: '1px solid lightgray',
    };
    if (key === exemptionReasonKey)
      return Object.assign(style, {
        backgroundColor: BKG_LIGHT_BLUE,
        color: 'white',
      });
    return style;
  };

  // exemptionReasonsConfigurations.filter because property filter is missing in null or undefined [1].
  // On ne peut pas ouvrir la modale si il ny'a pas de motifs d'exemption donc ok.
  const list = exemptionReasonsConfigurations?.filter(
    reason => !reason.hiddenLapi
  );

  // Depending on list size, we restrict to single column
  let dialogStyle = DIALOG_STYLE;
  if (list && list.length <= SINGLE_COLUMN_THRESHOLD) {
    dialogStyle = DIALOG_STYLE_SINGLE_COLUMN;
  }

  const handleCancel = () => {
    setComment('');
    setExemptionReasonKey(null);
    setTagsSelected(new Set());
    onCancel();
  };

  const handleConfirm = () => {
    if (exemptionReasonKey) {
      onConfirm(comment, exemptionReasonKey, tagsSelected);
      setExemptionReasonKey(null);
    }
  };

  const warningMessage = _t('element.warningMessage');

  const renderIcon = (iconKey: string | null | undefined) => {
    let icon = 'default';
    if (iconKey !== null && iconKey !== undefined) {
      icon = iconKey;
    }
    const IconComponent = REASON_ICONS[icon];
    return <IconComponent />;
  };

  const actions = [
    <FlatButton label="Annuler" secondary onClick={handleCancel} />,
    <FlatButton
      label="Confirmer"
      labelStyle={{ color: BKG_LIGHT_BLUE }}
      primary
      keyboardFocused
      onClick={handleConfirm}
    />,
  ];

  useEffect(() => {
    setExemptionReasonKey(null);
    setComment('');
    setTagsSelected(new Set());
  }, [controlId]);

  if (lapiReviewConfigurationDTO.tagsEnabled) {
    return (
      <Dialog
        title={
          <div style={{ display: 'flex' }}>
            <h3
              style={{
                padding: '24px 24px 20px',
                width: '70%',
              }}
            >
              {_t('element.choice')}
            </h3>
            <h3
              style={{
                padding: '24px 24px 20px',
                width: '30%',
              }}
            >
              {_t('element.qualifications')}
            </h3>
          </div>
        }
        actions={actions}
        modal
        open={open}
        contentStyle={DIALOG_STYLE}
        autoScrollBodyContent
      >
        <div style={{ display: 'flex' }}>
          <div style={{ width: '70%', paddingRight: '24px' }}>
            {!exemptionReasonKey && (
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <span style={WARNING_STYLE}>{warningMessage}</span>
              </div>
            )}
            <List style={LIST_STYLE}>
              {list?.map(reason => {
                return (
                  <ListItem
                    primaryText={reason.label}
                    id={reason.key}
                    key={reason.key}
                    onClick={onItemListClick}
                    style={computeReasonStyle(reason.key)}
                    leftAvatar={
                      <Avatar
                        icon={renderIcon(reason.icon)}
                        backgroundColor={ICON_BLUE}
                      />
                    }
                  />
                );
              })}
            </List>
          </div>
          <div
            style={{
              width: '30%',
              borderLeft: '2px solid',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingLeft: '24px',
            }}
          >
            <TagsList
              lapiReviewConfigurationDTO={lapiReviewConfigurationDTO}
              tagsSelected={tagsSelected}
              setTagsSelected={setTagsSelected}
            />
            {!qualityControlMode && (
              <TextField
                id="lapi-fps-confirm--col-third-text-field"
                floatingLabelText="Commentaire"
                floatingLabelFixed
                value={comment}
                multiLine
                onChange={(e: React.FormEvent<unknown>, text: string) =>
                  setComment(text)
                }
                fullWidth
                maxlength="200"
              />
            )}
          </div>
        </div>
      </Dialog>
    );
  }

  // else return the same element as before

  return (
    <Dialog
      title="Choix d'un motif d'exemption"
      actions={actions}
      modal
      open={open}
      contentStyle={dialogStyle}
      autoScrollBodyContent
    >
      {!exemptionReasonKey && (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <span style={WARNING_STYLE}>{warningMessage}</span>
        </div>
      )}
      <List style={LIST_STYLE}>
        {list?.map(reason => {
          return (
            <ListItem
              primaryText={reason.label}
              id={reason.key}
              key={reason.key}
              onClick={onItemListClick}
              style={computeReasonStyle(reason.key)}
              leftAvatar={
                <Avatar
                  icon={renderIcon(reason.icon)}
                  backgroundColor={ICON_BLUE}
                />
              }
            />
          );
        })}
      </List>
    </Dialog>
  );
};

export default connect(
  (state): ReduxStateProps => {
    const {
      exemptionReasonsConfigurations,
      lapiReviewConfigurationDTO,
    } = getConfigState(state);
    return { exemptionReasonsConfigurations, lapiReviewConfigurationDTO };
  }
)(ExemptionReasonModal);
