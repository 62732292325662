import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { getConfigState } from 'config/duck';

import BillTablePage from './BillTablePage';

type SubscriptionRouterProps = RouteComponentProps & {
  enabled: boolean;
};

const BillRouter = ({ match, enabled }: SubscriptionRouterProps) => {
  if (!enabled) {
    return (
      <div style={{ height: '100%' }}>
        <MissNotFound />
      </div>
    );
  }

  const baseRoute = match.url;

  return (
    <div style={{ height: '100%' }}>
      <Switch>
        <Redirect exact path={baseRoute} to={`${baseRoute}/list`} />
        <Route path={`${baseRoute}/list`} exact component={BillTablePage} />
        <MissNotFound />
      </Switch>
    </div>
  );
};

export default connect(state => {
  const {
    modulesConfiguration: {
      parking: { enabled },
    },
  } = getConfigState(state);
  return {
    enabled,
  };
})(BillRouter);
