import React, { CSSProperties, useEffect, useState } from 'react';
import { List, ListItem } from 'material-ui/List';
import CircularProgress from 'material-ui/CircularProgress';

import { LapiReviewHistoryDTO } from 'api/lapiReview/types';
import { fetchRecentHistory } from 'api/lapiReview';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';

const LIST_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto',
};

type Props = {
  lapiReviewId: string;
  searchLapiById: (controlId: string) => void;
};

function ControlHistory({ lapiReviewId, searchLapiById }: Props) {
  const [controlHistory, setControlHistory] = useState<
    Array<LapiReviewHistoryDTO>
  >();

  function handleSearch(event: React.MouseEvent<HTMLElement>) {
    searchLapiById(event.currentTarget.id);
  }

  async function initRecentHistory() {
    const history = await fetchRecentHistory();
    setControlHistory(history);
  }

  useEffect(() => {
    initRecentHistory();
  }, [lapiReviewId]);

  if (controlHistory) {
    return (
      <List style={LIST_STYLE}>
        {controlHistory?.map((history, index) => {
          return (
            <ListItem
              primaryText={`${index + 1} - ${history.plate}`}
              id={history.controlId}
              key={`${history.controlId}_${history.plate}`}
              onClick={handleSearch}
            />
          );
        })}
      </List>
    );
  }
  return (
    <Content>
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    </Content>
  );
}

export default ControlHistory;
