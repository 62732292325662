import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import { ExemptionReason, getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';
import { LapiReviewDTO } from 'api/lapiReview/types';
import DateClose from 'commons/DateClose/DateClose';

const { _tg } = window.loadTranslations(__filename);

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const BOLD_STYLE: CSSProperties = {
  fontWeight: 'bold',
};

type Props = {
  lapiReview: LapiReviewDTO;
  exemptionReasonsConfigurations: Array<ExemptionReason> | undefined;
};

function AlreadyTreatedInformation({
  lapiReview,
  exemptionReasonsConfigurations,
}: Props) {
  const reasonLabel = exemptionReasonsConfigurations?.find(
    reason => reason.key === lapiReview?.exemptionReason
  )?.label;
  const decision =
    lapiReview.controlStatus === 'EXEMPTION' ? 'exemption' : 'FPS';

  function isControlNotComplex(): boolean {
    return !!lapiReview.fpsId || !!lapiReview.exemptionReason;
  }

  function generateAdditionalInformation() {
    if (reasonLabel || lapiReview.fpsId) {
      return `(${reasonLabel ?? lapiReview.fpsId})`;
    }
    return '';
  }

  return (
    <p style={ROW_STYLE}>
      {_tg('tefps.lapiReview.alreadyTreatedInfo.index.text1')}
      <span style={BOLD_STYLE}>{lapiReview.lastReviewAgentName}</span>
      <DateClose
        datetime={lapiReview.treatmentDatetime}
        withYear={false}
        withAlternativeSeparator
        withThe
      />
      {isControlNotComplex() && (
        <>
          <Trans
            i18nKey={_tg('tefps.lapiReview.alreadyTreatedInfo.index.text2', {
              decision,
              generateAdditionalInformation: generateAdditionalInformation(),
            })}
            components={[<span style={BOLD_STYLE} />]}
          />
        </>
      )}
    </p>
  );
}

function mapStateToProps(state: InternalApiState) {
  const { exemptionReasonsConfigurations } = getConfigState(state);
  return { exemptionReasonsConfigurations };
}

export default connect(mapStateToProps)(AlreadyTreatedInformation);
