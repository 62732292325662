import { CSSProperties } from 'react';

import { BKG_CYAN, TXT_BLACK } from 'theme';

export const BRD_GREY = '#A9A9A9';

export const STYLE_ADD_BUTTON: CSSProperties = {
  borderRadius: 5,
  padding: 2,
  border: '1px dashed #ccc',
  fontFamily: 'Roboto',
  color: 'black',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: 26,
  cursor: 'pointer',
};

export const STYLE_TITLE: CSSProperties = {
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  textAlign: 'center',
  color: TXT_BLACK,
  fontSize: 22,
};

export const STYLE_CRITERIA_CONTAINER: CSSProperties = {
  borderRadius: 5,
  padding: 8,
  border: `1px solid ${BKG_CYAN}`,
  fontFamily: 'Roboto',
  color: 'black',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: 12,
};
