import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import AdvertisingModal from 'commons/AdvertisingModal';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import ServiceLoader from 'tefps/RecoursesV2/ServiceLoader';

import Routes from './Routes';
import Pings from './Pings';
import Alerts from './Alerts';
import PatrolZone from './PatrolZone';
import AbusiveParkingPage from './AbusiveParkingPage';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  width: '60%',
  margin: '0 auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: 30,
};

const STYLE_MODULE: CSSProperties = {
  width: 500,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  margin: 30,
  fontWeight: 'bold',
  fontSize: 35,
};

type PlannerHomeProps = {
  userInfo: InternalAgent;
  isPlannerEnabled: boolean;
  isLapiReviewEnabled: boolean;
  isQualityControlEnabled: boolean;
  canDetectAbusiveParkings: boolean;
};

const PlannerHome = ({
  userInfo,
  isPlannerEnabled,
  isLapiReviewEnabled,
  isQualityControlEnabled,
  canDetectAbusiveParkings,
}: PlannerHomeProps) => (
  <div style={{ height: '100%', fontFamily: 'Roboto' }}>
    <AdvertisingModal module="planner" />
    {!userInfo.rights.includes('BACKOFFICE_PLANNER') && (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message={_tg('feedback.error.fetchOperations')}
            error={{
              message: _tg('feedback.error.accessDenied'),
            }}
          />
        </FlexCenter>
      </Content>
    )}
    {userInfo.rights.includes('BACKOFFICE_PLANNER') && (
      <div>
        <div style={STYLE_CONTAINER}>
          <span style={STYLE_TITLE}>{_t('element.selectModule')}</span>
        </div>
        <div style={STYLE_CONTAINER}>
          {userInfo.rights.includes('ROUTE_READ') && isPlannerEnabled && (
            <Link to="/planner/routes" style={STYLE_MODULE}>
              {_t('element.route')}
            </Link>
          )}
          {userInfo.rights.includes('PATROL_ZONE_READ') && isPlannerEnabled && (
            <Link to="/planner/patrolZone" style={STYLE_MODULE}>
              {_t('element.patrolZone')}
            </Link>
          )}
          {userInfo.rights.includes('TEAM_READ') && isPlannerEnabled && (
            <Link to="/planner/map" style={STYLE_MODULE}>
              {_t('element.map')}
            </Link>
          )}
          {userInfo.rights.includes('ALERTS_READ') && isPlannerEnabled && (
            <Link to="/planner/alerts" style={STYLE_MODULE}>
              {_t('element.alerts')}
            </Link>
          )}
          {userInfo.rights.includes('LAPI_REVIEW_WRITE') &&
            isLapiReviewEnabled && (
              <Link to="/review" style={STYLE_MODULE}>
                {_t('element.lapiReview')}
              </Link>
            )}
          {userInfo.rights.includes('LAPI_REVIEW_QUALITY_CONTROL') &&
            isLapiReviewEnabled &&
            isQualityControlEnabled && (
              <Link to="/qualityControl" style={STYLE_MODULE}>
                {_t('element.qualityControl')}
              </Link>
            )}
          {userInfo.rights.includes('CONTROL_READ') &&
            canDetectAbusiveParkings && (
              <Link to="/planner/abusiveParking" style={STYLE_MODULE}>
                {_t('element.abusiveParking')}
              </Link>
            )}
        </div>
      </div>
    )}
  </div>
);

const Home = connect(state => {
  const { userInfo } = getApiState(state);
  const config = getConfigState(state);
  return {
    userInfo,
    isPlannerEnabled: config.modulesConfiguration.planner.enabled,
    isLapiReviewEnabled: config.modulesConfiguration.lapiReview.enabled,
    isQualityControlEnabled:
      config.lapiReviewConfigurationDTO.qualityControlEnabled,
    canDetectAbusiveParkings:
      config.controlConfiguration.canDetectAbusiveParkings,
  };
})(PlannerHome);

const Planner = ({ match }: RouteComponentProps): JSX.Element => (
  <div style={{ height: '100%' }}>
    <ServiceLoader>
      <Switch>
        <Route path={`${match.url}`} exact component={Home} />
        <Route path={`${match.url}/patrolZone`} exact component={PatrolZone} />
        <Route path={`${match.url}/routes`} exact component={Routes} />
        <Route path={`${match.url}/map`} exact component={Pings} />
        <Route path={`${match.url}/alerts`} exact component={Alerts} />
        <Route
          path={`${match.url}/abusiveParking`}
          exact
          component={AbusiveParkingPage}
        />
        <MissNotFound />
      </Switch>
    </ServiceLoader>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default withRouter(Planner);
