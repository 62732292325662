import React, { CSSProperties, useState } from 'react';
import DatePicker from 'material-ui/DatePicker';
import _cloneDeep from 'lodash.clonedeep';
import TextField from 'material-ui/TextField';
import moment from 'moment';

import { formatDate } from 'commons/Utils/dateUtil';
import BoButton from 'facade/BoButton';
import { BRD_GREY } from 'theme';

import { ParamsDTO } from './types';

const STYLE_PARAMS: CSSProperties = {
  display: 'flex',
  gap: 16,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  marginTop: 25,
  border: `1px solid ${BRD_GREY}`,
};

const { _tg } = window.loadTranslations(__filename);

type ParamsErrors = {
  statementStartDatetime: string;
  statementEndDatetime: string;
  minNumberOfControlsKO: string;
};

interface IProps {
  search: (params: ParamsDTO) => Promise<void>;
}

const Parameters = ({ search }: IProps): JSX.Element => {
  const [params, setParams] = useState<ParamsDTO>({
    statementStartDatetime: moment()
      .subtract(10, 'day')
      .toDate(),
    statementEndDatetime: new Date(),
    minNumberOfControlsKO: 7,
  });

  const [paramsErrors, setParamsErrors] = useState<ParamsErrors>({
    statementStartDatetime: '',
    statementEndDatetime: '',
    minNumberOfControlsKO: '',
  });

  const [disableSearch, setDisableSearch] = useState<boolean>(false);

  const manageParamsErrors = (params: ParamsDTO): void => {
    const updatedParamsErrors = _cloneDeep(paramsErrors);
    if (
      moment(params.statementStartDatetime).isAfter(
        moment(params.statementEndDatetime)
      )
    ) {
      updatedParamsErrors.statementStartDatetime = _tg(
        'tefps.Planner.AbusiveParking.errors.startDate'
      );
      updatedParamsErrors.statementEndDatetime = _tg(
        'tefps.Planner.AbusiveParking.errors.endDate'
      );
    } else {
      updatedParamsErrors.statementStartDatetime = '';
      updatedParamsErrors.statementEndDatetime = '';
    }
    if (!params.minNumberOfControlsKO || +params.minNumberOfControlsKO === 0) {
      updatedParamsErrors.minNumberOfControlsKO = _tg(
        'tefps.Planner.AbusiveParking.errors.minNumberOfControlsKOUnset'
      );
    } else if (
      moment(params.statementEndDatetime).diff(
        moment(params.statementStartDatetime),
        'days'
      ) < params.minNumberOfControlsKO
    ) {
      updatedParamsErrors.minNumberOfControlsKO = _tg(
        'tefps.Planner.AbusiveParking.errors.minNumberOfControlsKO'
      );
    } else {
      updatedParamsErrors.minNumberOfControlsKO = '';
    }
    setParamsErrors(updatedParamsErrors);
  };

  const onChangeDate = (startOrEnd: 'start' | 'end', newDate: Date): void => {
    const updatedParams = _cloneDeep(params);
    updatedParams[
      startOrEnd === 'start' ? 'statementStartDatetime' : 'statementEndDatetime'
    ] = newDate;
    setParams(updatedParams);
    manageParamsErrors(updatedParams);
  };

  const onChangeMinNumberOfControlsKO = (minNumberOfControlsKO: any): void => {
    if (/^\d*$/.test(minNumberOfControlsKO)) {
      const updatedParams = _cloneDeep(params);
      updatedParams.minNumberOfControlsKO = minNumberOfControlsKO;
      setParams(updatedParams);
      manageParamsErrors(updatedParams);
    }
  };

  const callSearch = async (): Promise<void> => {
    if (Object.values(paramsErrors).some(value => value.length > 0)) {
      return;
    }
    // If no errors, proceed with the search
    setDisableSearch(true);
    await search(params);
    setDisableSearch(false);
  };

  return (
    <div style={STYLE_PARAMS}>
      <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
        {_tg('tefps.Planner.AbusiveParking.params.title')}
      </div>

      <div style={{ flex: 1 }}>
        <DatePicker
          floatingLabelText={_tg(
            'tefps.Planner.AbusiveParking.params.statementStartDatetime'
          )}
          value={params.statementStartDatetime}
          onChange={(_event: Event | null | undefined, date: Date) =>
            onChangeDate('start', date)
          }
          locale={window.i18next.language}
          formatDate={formatDate}
          errorText={paramsErrors.statementStartDatetime}
        />
      </div>

      <div style={{ flex: 1 }}>
        <DatePicker
          floatingLabelText={_tg(
            'tefps.Planner.AbusiveParking.params.statementEndDatetime'
          )}
          value={params.statementEndDatetime}
          onChange={(_event: Event | null | undefined, date: Date) =>
            onChangeDate('end', date)
          }
          locale={window.i18next.language}
          formatDate={formatDate}
          errorText={paramsErrors.statementEndDatetime}
        />
      </div>

      <div style={{ flex: 1 }}>
        <TextField
          floatingLabelText={_tg(
            'tefps.Planner.AbusiveParking.params.minNumberOfControlsKO'
          )}
          value={params.minNumberOfControlsKO}
          onChange={(_, minNumberOfControlsKO) =>
            onChangeMinNumberOfControlsKO(minNumberOfControlsKO)
          }
          errorText={paramsErrors.minNumberOfControlsKO}
        />
      </div>

      <div style={{ flex: 1, textAlign: 'center' }}>
        <BoButton
          label={_tg('action.search')}
          primary
          disabled={disableSearch}
          onClick={() => callSearch()}
        />
      </div>
    </div>
  );
};
export default Parameters;
