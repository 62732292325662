import React, { useCallback, useState } from 'react';

import { FilterBarSection } from '@cvfm-front/tefps-ui';
import { ItemIdName } from 'api/commonTypes';
import services from 'commons/services';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { MapId } from 'commons/services/MapService';

const { _tg } = window.loadTranslations(__filename);

const ParkingSpaceAutocompleteId = (): JSX.Element => {
  /*
   * States
   */
  const [options, setOptions] = useState<ItemIdName[]>([]);

  /*
   * Callbacks
   */
  const handleOnChange = useCallback(async (_id, search) => {
    if (!search) {
      return;
    }

    await services.parkingSpaceMap.setSelectedId(
      MapId.PARKING_SPACE,
      search,
      true
    );
    void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
  }, []);
  const handleAutocomplete = useCallback(async search => {
    const res = await services.parkingSpaceApi.autocompleteParkingSpaceId(
      search
    );
    const newOptions = res.data.map(v => ({ id: v, name: v }));
    setOptions(newOptions);
  }, []);

  /*
   * Render
   */

  return (
    <FilterBarSection
      title={_tg('searchParkingSpace')}
      className="filter-bar-section__title__zero-margin-bottom"
      fields={[
        <Autocomplete
          id="search"
          options={options}
          search=""
          onChange={handleOnChange}
          onAutocomplete={handleAutocomplete}
        />,
      ]}
      actions={[]}
    />
  );
};

export default ParkingSpaceAutocompleteId;
